import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CouponService } from 'src/app/services/coupons.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { environment } from 'src/environments/environment';
import { LocalService } from '../../services/local.service';
import Swal from 'sweetalert2';
const ADD_COUPON="/coupons/v1/secure/save";
const COUPON_API="/coupons/v1/secure/cbo";
declare let $: any;
@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss'],
})
export class CouponsComponent implements OnInit {
  user: any = [];
  validateCode = true;
  codePattern!: string;
  operator!: string;
  msisdn!: string | null;
  cfa!: number;
  private data: any = [];
  private dataError: any = [];
  couponForm!: FormGroup;
  coupons!: any[];
  isWrongCoupon = false;
  isWrongNewPin = false;
  isValidOtp = false;
  errorMessage!: string;

  get f() {
    return this.couponForm.controls;
  }
  constructor(
    private couponService: CouponService,
    private tokenStorageService: TokenStorageService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private actevatedRoute: ActivatedRoute,
    private http: HttpClient,
    private localStore: LocalService
  ) {}

  addCoupon(operator: string, cfa: string) {
    this.operator = operator;
    if (operator == 'NT') {
      this.localStore.saveData('operator', '93000000');
    } else {
      if (operator == 'MA') {
        this.localStore.saveData('operator', '94000000');
      } else {
        if (operator == 'AN') {
          this.localStore.saveData('operator', '96000000');
        } else {
          this.localStore.saveData('operator', '90000000');
        }
      }
    }
    this.cfa = parseInt(cfa);
    if (!isNaN(this.cfa)) {
      const request = new Observable<boolean>((observer) => {
        this.http
          .post(
            environment.baseURL + ADD_COUPON,
            {
              operator: operator,
              cfa: this.cfa,
            },
            { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
          )
          .subscribe(
            (result) => {
              this.data = result;
              observer.next(true);
              observer.complete();
            },
            (error: HttpErrorResponse) => {
              this.dataError = error;
              observer.error(false);
              observer.complete();
            }
          );
      });

      request.subscribe(
        (result) => {
          if ('status' in this.data) {
            if (this.data['status'] == true) {
              $('.btn-modal-close').click();
              Swal.fire({
                title: 'Coupon Ajouté.!',
                text: 'le coupon a été ajouté avec succès. ',
                icon: 'success',
                confirmButtonText: 'OK',
              }).then((res) => {
                if (res.isConfirmed) {
                  window.location.reload();
                }
              });
            } else {
              this.isWrongCoupon = true;
            }
          } else {
            this.isWrongCoupon = true;
          }
        },
        (error) => {
          console.log(this.dataError);
          if ('status' in this.dataError) {
            if (this.dataError['status'] == 401) {
              this.authService.logout();
            }
          }
        }
      );
    } else {
      if (isNaN(this.cfa)) {
        this.isWrongCoupon = true;
        console.log(this.cfa);
      }
    }
  }

  initialization() {
    this.msisdn = sessionStorage.getItem('operator');
    if (this.msisdn == null) {
      this.msisdn = '93000000';
      this.localStore.saveData('operator', '93000000');
    }
    const request = new Observable<boolean>((observer) => {
      this.http
        .post(
          environment.baseURL + COUPON_API,
          {
            msisdn: this.msisdn,
          },
          { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
        )
        .subscribe(
          (result) => {
            this.data = result;
            observer.next(true);
            observer.complete();
          },
          (error: HttpErrorResponse) => {
            this.dataError = error;
            observer.error(false);
            observer.complete();
          }
        );
    });

    request.subscribe(
      (result) => {
        if ('code' in this.data) {
          if (this.data['code'] == 200) {
            this.localStore.removeData('coupons');
            this.localStore.saveData(
              'coupons',
              JSON.stringify(this.data['data'])
            );
          } else {
            //this.isWrongCoupon=true;
          }
        } else {
          //this.isWrongCoupon=true;
        }
      },
      (error) => {
        if ('code' in this.dataError) {
          if (this.dataError['code'] == 401) {
            this.authService.logout();
          }
        }
      }
    );
  }

  onSelectChange(event: Event) {
    //console.log((event.target as HTMLInputElement).value);
    this.localStore.saveData(
      'operator',
      (event.target as HTMLInputElement).value
    );
    this.initialization();
    this.coupons = this.couponService.getListeCoupons();
    //window.location.reload();
  }

  ngOnInit() {
    this.initialization();
    this.coupons = this.couponService.getListeCoupons();
    this.user = this.tokenStorageService.getUser();
    this.user = JSON.parse(this.user);
    this.couponForm = this.formBuilder.group(
      {
        operator: ['', [Validators.required]],
        cfa: ['', [Validators.required]],
      },
      {
        updateOn: 'blur',
      }
    );
    // this.loadScript('../../../assets/admin/js/jquery.min.js');
    // this.loadScript('../../../assets/admin/js/bootstrap.bundle.min.js');
  }
}
