import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-user-contact-list',
  templateUrl: './user-contact-list.component.html',
  styleUrls: ['./user-contact-list.component.scss']
})
export class UserContactListComponent implements OnInit {
  user: any=[];
  constructor(private router: Router, private tokenStorageService: TokenStorageService) {
    
  }

  ngOnInit() {
    this.user = this.tokenStorageService.getUser();
    this.user=JSON.parse(this.user);
    // this.loadScript('../../../assets/admin/js/jquery.min.js');
    // this.loadScript('../../../assets/admin/js/bootstrap.bundle.min.js');
  }
}
