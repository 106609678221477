<div class="features">
    <div class="container">
      <div class="row">
        <div class="col-md-12 m-auto">
          <div class="row mx-0" id="container">

            <div class="col-md-6 overlay-container p-0">
              <div class="overlay p-5">
                <h3 class="text-white">Connexion</h3>
                <img src="assets/images/login.png" alt="" class="mb-5" style="width: 300px;">
                <span style="font-size:13px;z-index: 100000; position: relative;" class="mt-3">Vous n’avez pas de compte ? <a routerLink="/inscription/account-type" class="text-white">Créez un compte</a></span>
              </div>
            </div>

            <div class="col-md-6 h-100">

              <a href="/accueil" class=""><img src="assets/images/logo1.png" class="my-4 ml-3" style="width:60px;"alt=""></a>
              <div *ngIf="this.wrongCredentials==true" class="alert alert-danger" role="alert">
                {{ errorMessage }}
              </div>
              <form [formGroup]="loginForm" (ngSubmit)="onLoginFormSubmit()" novalidate class="sl-form mx-3 mt-5">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-primary"><i class="fa fa-envelope text-white"></i></span>
                  </div>
                  <input type="email" formControlName="username" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f['username'].errors }"  placeholder="Email" aria-label="email" aria-describedby="basic-addon1" required>
                  <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
                    <div *ngIf="f['username'].errors['required']">le nom d'utilisateur est requis</div>
                </div>
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-primary" id="basic-addon1"><i class="fa fa-lock text-white"></i></span>
                  </div>
                  <input type="password" class="form-control form-control-lg" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"  placeholder="Mots de passe" aria-label="password" aria-describedby="basic-addon1" required>
                </div>
                <div class="my-5">
                  <button type="submit" class="w-100 btn btn-sp color-primary">
                    <span class="ml-2" id="text">Se Connecter</span>
                  </button>
                </div>
                <span class="mt-5"><a routerLink="/forgot-password" class="text-primary">Mot de passe oublié ?</a></span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

