<!-- Left sidebar -->
<div class="sidebar">
    <div class="widget admin-widget p-0">
        <div class="Profile-menu">
            <ul class="nav secondary-nav">
              <li class="nav-item"><a class="nav-link" routerLink="/dashboard" *ngxPermissionsOnly="['DASHBOARD']" routerLinkActive="active"><i class="fas fa-tachometer-alt"></i>Tableau de bord</a></li>

              <li class="nav-item"><a class="nav-link" routerLink="/profile" routerLinkActive="active"><i class="fab fa-autoprefixer"></i>Profile</a></li>
              <li class="nav-item"><a class="nav-link" routerLink="/recharge" *ngxPermissionsOnly="['RECHARGE']" routerLinkActive="active"><i class="fas fa-sim-card"></i>Recharge</a></li>
              <li class="nav-item"><a class="nav-link" routerLink="/shapshap" *ngxPermissionsOnly="['SHAPSHAP']" routerLinkActive="active"><i class="fas fa-sim-card"></i>ShapShap</a></li>
              <li class="nav-item"><a class="nav-link" routerLink="/transfer"  routerLinkActive="active"><i class="far fa-paper-plane"></i>Envoyer de l'argent</a></li>
              <li class="nav-item"><a class="nav-link" routerLink="/store/products" *ngxPermissionsOnly="['STORE']" routerLinkActive="active"><i class="fa fa-store"></i>Boutique</a></li>
              <!--<li class="nav-item"><a class="nav-link" href=""><i class="fas fa-wallet"></i>Rechager smartpay</a></li>-->

              <!-- <li class="nav-item"><a class="nav-link" href=""><i class="fas fa-university"></i>Mon compte</a></li>
              <li class="nav-item"><a class="nav-link" href=""><i class="fas fa-building"></i>Bank Cards</a></li> -->

              <!-- <li class="nav-item"><a class="nav-link" href=""><i class="fas fa-plus"></i>Deposit Money</a></li> -->

              <!-- <li class="nav-item"><a class="nav-link" href=""><i class="fas fa-piggy-bank"></i>Collect</a></li> -->

              <li class="nav-item"><a class="nav-link" routerLink="/transaction" routerLinkActive="active"><i class="fas fa-list-ul"></i>Transaction</a></li>
              <!-- <li class="nav-item"><a class="nav-link" routerLink="/contacts" routerLinkActive="active"><i class="far fa-list-alt"></i>Liste des contacts</a></li> -->
              <li class="nav-item"><a class="nav-link" routerLink="/parametre" *ngxPermissionsOnly="['SETTING']" routerLinkActive="active"><i class="fas fa-cog"></i>Parametre</a></li>
              <!-- <li class="nav-item"><a class="nav-link" routerLink="/coupons" routerLinkActive="active"><i class="fas fa-money-check"></i>Coupons</a></li> -->
            </ul>
        </div>
    </div>

    <div class="widget admin-widget">
        <i class="fas fa-comments admin-overlay-icon"></i>
        <h4>Service client</h4>
        <p>
            Des questions ou des préoccupations concernant votre compte ?
            <!--<br>Nos experts sont là pour vous aider!</p>-->

        <button class="btn btn-sp color-primary btn-center">
            <a routerLink="/recharge" class="text-white mr-0">
                Contactez-nous
            </a>
        </button>
    </div>

</div>
<!-- Left Panel End -->

