import { User } from '../../models/user';
import { LocalService } from '../../services/local.service';

var data: any= [];
var dataStr: string|null;
dataStr = LocalService.getUserData('user');
if(dataStr){
  data=JSON.parse(dataStr);
}

export const UserData: User = data;
