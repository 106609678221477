import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalService } from 'src/app/services/local.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-user-shapshap-statut',
  templateUrl: './user-shapshap-statut.component.html',
  styleUrls: ['./user-shapshap-statut.component.scss'],
})
export class UserShapshapStatutComponent implements OnInit {
  balance: any;
  transactionId: any;
  user: any = [];
  c2sData: any = [];
  c2s: boolean = false;
  userData: any = [];
  montant: any;
  pm: any;
  pf: any;
  phone: any;
  total: any;
  constructor(
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private localStore: LocalService
  ) {}

  ngOnInit() {
    this.c2sData = JSON.parse(
      this.localStore.getData('transition-shapshap-c2s')
    );
    if (
      this.c2sData &&
      'status' in this.c2sData &&
      this.c2sData['status'] == true
    ) {
      this.c2s = true;
    }
    this.userData = this.tokenStorageService.getUser();
    this.user = JSON.parse(this.userData);
    this.balance = this.user.wallet.balance;
    this.phone = this.localStore.getData('shapshap_msisdn');
    this.pm = this.localStore.getData('payment_method');
    this.montant = this.localStore.getData('shapshap_amount');
    this.pf = 0;
    this.total = parseInt(this.montant) + this.pf;
    if (this.c2sData['data']['transaction']['transaction_id']) {
      this.transactionId =
        this.c2sData['data']['transaction']['transaction_id'];
    }
  }
}
