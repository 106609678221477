<!-- Admin Hero section-->
<div class="hero-section">
    <div class="container">
        <div class="row  profile-complete-area">
            <div class="col">
                <div class="progress" data-percentage="50">
                    <span class="progress-left">
                        <span class="progress-bar"></span>
                    </span>
                    <span class="progress-right">
                        <span class="progress-bar"></span>
                    </span>
                    <div class="progress-value">
                        <div class="profile-thumb mt-3 mb-4">
                            <img class="rounded-circle" src="assets/admin/images/user-orange.png" alt="">
                            <div class="profile-thumb-edit verify-icon" data-toggle="tooltip" title="Profile Verified">
                                <i class="fas fa-check"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="profile-name">{{ user.firstname }} {{ user.lastname }}</p>
            </div>
            <div class="col">
                <div class="profile-item">
                    <i class="fas fa-mobile-alt bg-icon"></i>
                    <i class="fas fa-check-circle Verified-icon"></i>
                    <p class="title">Téléphone</p>
                </div>
            </div>
            <div class="col">
                <div class="profile-item">
                    <i class="fas fa-envelope bg-icon"></i>
                    <i class="fas fa-check-circle Verified-icon"></i>
                    <p class="title">Email</p>
                </div>
            </div>
            <div class="col">
                <a href="#">
                    <div class="profile-item">
                        <i class="fas fa-credit-card bg-icon"></i>
                        <i class="far fa-circle  Verified-icon"></i>
                        <p class="title">carte</p>
                    </div>
                </a>
            </div>
            <div class="col">
                <a href="#">
                    <div class="profile-item">
                        <i class="fas fa-university bg-icon"></i>
                        <i class="far fa-circle  Verified-icon"></i>
                        <p class="title">Compte bancaire</p>
                    </div>
                </a>
            </div>
        </div>

    </div>
  </div>
  <!-- Admin End of Hero section-->
  <!-- Profile bar -->
  <div class="profilebar">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="balance-area">
                    <select name="ctl00$ddlAccounts" class="custom-select" style="width:200px;">
                        <option selected="selected" value="00">CFA Balance</option>
                        <option value="01">EUR Balance</option>
                    </select>
                    <p class="total-blance">
                        {{ balance }} {{ currency }}
                        <a href="#" class="reload-btn"><i class="fas fa-sync"></i></a>
                    </p>

                </div>
            </div>
            <div class="col">
                <div class="local-time">
                    <p><b>Heure locale:</b> {{now|date:'HH:mm:ss'}}</p>
                </div>
            </div>
            <div class="col">
                <div class="local-time">
                    <p><b>Dernière visite: </b>{{lastLogin| date:"d LLL YYYY HH:mm:ss"}}</p>
                </div>
            </div>
            <div class="col notify-col text-right">
                <div class="notify-btn"><a href=""><i class="far fa-bell"></i></a></div>
            </div>
        </div>
    </div>
  </div>
  <!-- End Profile bar -->

