<div class="blog-page">
    <app-page-header></app-page-header>
    <!-- Banner section start -->
    <section class="banner features">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <img src="assets/images/cagnotte.png" alt="" class="">
          </div>
          <div class="col-md-8 m-auto">
            <div class="ban-content text-center">
              <h1 style="text-transform:none;">Liste des campagnes de collecte de fonds</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner section end -->

    <!-- Blog posts section start -->
    <section class="blog-posts">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="posts-wrap">
              <div class="card mb-5">
                <figure class="post-thumb">
                  <img src="assets/images/blog/1.jpg" alt="">
                </figure>
                <div class="card-body">
                  <h3><a routerLink="/page-cagnotte-details">Everything You Need To Know About Banking.</a></h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscied do eiusmod tempor incidid labore et dolore magna
                    aliqua. Quis ipsum suspen disse ultrices gravida. here are many variations of passages of them Lorem
                    Ipsum available, but the majority have suffered alteration in some form.</p>
                    <span class="mr-5"><i class="fa fa-user text-primary"></i> Mr Abdoul Madjid</span>
                    <span class=""><i class="fa fa-tags text-primary"></i> Religion</span>
                </div>
                <div class="card-footer bg-light">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="text-center">
                        <i class="fa fa-piggy-bank text-center text-primary"></i>
                        <span class="d-block" style=" font-size: 10px;">300.000 CFA</span>
                        <span class="">MONTANT</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="text-center">
                        <i class="fa fa-donate text-center text-primary"></i>
                        <span class="d-block" style=" font-size: 10px;">60.000 CFA</span>
                        <span class="">COLLECTE</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="text-center">
                        <i class="fa fa-clock text-center text-primary"></i>
                        <span class="d-block" style=" font-size: 10px;">47</span>
                        <span class="">JOURS</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-5">
                <figure class="post-thumb">
                  <img src="assets/images/blog/1.jpg" alt="">
                </figure>
                <div class="card-body">
                  <h3><a routerLink="/page-cagnotte-details">Everything You Need To Know About Banking.</a></h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscied do eiusmod tempor incidid labore et dolore magna
                    aliqua. Quis ipsum suspen disse ultrices gravida. here are many variations of passages of them Lorem
                    Ipsum available, but the majority have suffered alteration in some form.</p>
                    <span class="mr-5"><i class="fa fa-user text-primary"></i> Mr Abdoul Madjid</span>
                    <span class=""><i class="fa fa-tags text-primary"></i> Religion</span>
                </div>
                <div class="card-footer bg-light">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="text-center">
                        <i class="fa fa-piggy-bank text-center text-primary"></i>
                        <span class="d-block" style=" font-size: 10px;">300.000 CFA</span>
                        <span class="">MONTANT</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="text-center">
                        <i class="fa fa-donate text-center text-primary"></i>
                        <span class="d-block" style=" font-size: 10px;">60.000 CFA</span>
                        <span class="">COLLECTE</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="text-center">
                        <i class="fa fa-clock text-center text-primary"></i>
                        <span class="d-block" style=" font-size: 10px;">47</span>
                        <span class="">JOURS</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-5">
                <figure class="post-thumb">
                  <img src="assets/images/blog/1.jpg" alt="">
                </figure>
                <div class="card-body">
                  <h3><a routerLink="/page-cagnotte-details">Everything You Need To Know About Banking.</a></h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscied do eiusmod tempor incidid labore et dolore magna
                    aliqua. Quis ipsum suspen disse ultrices gravida. here are many variations of passages of them Lorem
                    Ipsum available, but the majority have suffered alteration in some form.</p>
                    <span class="mr-5"><i class="fa fa-user text-primary"></i> Mr Abdoul Madjid</span>
                    <span class=""><i class="fa fa-tags text-primary"></i> Religion</span>
                </div>
                <div class="card-footer bg-light">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="text-center">
                        <i class="fa fa-piggy-bank text-center text-primary"></i>
                        <span class="d-block" style=" font-size: 10px;">300.000 CFA</span>
                        <span class="">MONTANT</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="text-center">
                        <i class="fa fa-donate text-center text-primary"></i>
                        <span class="d-block" style=" font-size: 10px;">60.000 CFA</span>
                        <span class="">COLLECTE</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="text-center">
                        <i class="fa fa-clock text-center text-primary"></i>
                        <span class="d-block" style=" font-size: 10px;">47</span>
                        <span class="">JOURS</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pagination">
              <a href="" class="prev"><span class="bh"></span> <span>Previous</span></a>
              <a href="javascript:void(0)">1</a>
              <span class="current">2</span>
              <a href="javascript:void(0)">3</a>
              <a href="javascript:void(0)">4</a>
              <a href="" class="next"><span class="bh"></span> <span>Next</span></a>
            </div>
          </div>
          <div class="col-md-4">
            <div class="sidebar">
              <aside class="widget widget_search">
                <form action="#">
                  <input type="text" name="s" placeholder="recherche ..." required>
                  <i class="fas fa-search"></i>
                </form>
              </aside>
              <aside class="widget widget_recent_entries">
                <h3 class="widget-title">Collecte recent</h3>
                <div class="card">
                  <div class="card-body">
                    <h5 style="text-transform:uppercase">titre</h5>
                    <p class="text-justify">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit. amet consectetur
                    </p>
                    <span class="d-block"><i class="fa fa-user text-primary"></i> Mr Abdoul Madjid</span>
                  </div>
                </div>
                <div class="card mt-2">
                  <div class="card-body">
                    <h5 style="text-transform:uppercase">titre</h5>
                    <p class="text-justify">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit. amet consectetur
                    </p>
                    <span class="d-block"><i class="fa fa-user text-primary"></i> Mr Abdoul Madjid</span>
                  </div>
                </div>
                <div class="card mt-2">
                  <div class="card-body">
                    <h5 style="text-transform:uppercase">titre</h5>
                    <p class="text-justify">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit. amet consectetur
                    </p>
                    <span class="d-block"><i class="fa fa-user text-primary"></i> Mr Abdoul Madjid</span>
                  </div>
                </div>
              </aside>
              <aside class="widget widget_tag_cloud">
                <h3 class="widget-title">Categorie</h3>
                <div class="tag-cloud">
                  <a href="javascript:void(0)">Solidarité</a>
                  <a href="javascript:void(0)">Religion</a>
                  <a href="javascript:void(0)">Médical</a>
                  <a href="javascript:void(0)">Humanitaire</a>

                  <a href="javascript:void(0)">Projets</a>


                  <a href="javascript:void(0)">Sports</a>
                  <a href="javascript:void(0)">Evenements</a>
                  <a href="javascript:void(0)">Autres</a>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Blog posts section end -->
    <app-page-footer></app-page-footer>
  </div>

