import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ConfirmedValidator } from '../page-signup-customer/confirmed.validator';
import { CustomValidators } from '../page-signup-customer/custom-validators';
import { LocalService } from '../../../services/local.service';

declare let $: any;
@Component({
  selector: 'app-page-signup-company',
  templateUrl: './page-signup-company.component.html',
  styleUrls: ['./page-signup-company.component.scss'],
})
export class PageSignupCompanyComponent implements OnInit {
  signupForm!: FormGroup;
  passwordPattern =
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}$';
  usernamePattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  specialCharacterPattern: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  wrongCredentials = false;
  errorMessage = '';
  wrongNumber: boolean = false;
  data:any=[];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private localStore: LocalService
  ) {}

  async onFormSubmit(dialCode: string) {
    this.signupForm.value.phone = '';
    this.signupForm.value.phone = dialCode + this.signupForm.value.phoneNumber;
    try {
      this.data = await this.authService.register(this.signupForm.value);
      if ('code' in this.data && 'status' in this.data) {
        if (this.data['code'] == '200' && this.data['status'] == true) {
          this.router.navigate(['/login']);
        } else {
          this.wrongCredentials = true;
          if (this.data['message']) {
            this.errorMessage = this.data['message'];
          } else {
            this.errorMessage = "L'inscription a echoué veuillez ressayer svp!";
          }
        }
      } else {
        this.wrongCredentials = true;
        if (this.data['message']) {
          this.errorMessage = this.data['message'];
        } else {
          this.errorMessage = "L'inscription a echoué veuillez ressayer svp!";
        }
      }
    } catch(error) {
      this.wrongCredentials = true;
      this.errorMessage = "L'inscription a echoué veuillez ressayer svp!";
    }
    //this.router.navigate(['inscription/professionnel']);
  }

  initialization() {
    // ================================
    // Phone Number
    // ================================
    var input = <HTMLInputElement>document.querySelector('#phoneNumber');

    // Error messages based on the code returned from getValidationError
    var errorMap = [
      "Ce numéro n'est pas valide",
      'Code du pays non valide',
      'Numéro trop court',
      'Numéro trop long',
      'Numéro invalide',
    ];

    var intl = (<any>window).intlTelInput(input, {
      // allowDropdown: false,
      autoHideDialCode: false,
      // autoPlaceholder: "off",
      // dropdownContainer: document.body,
      // excludeCountries: ["us"],
      // formatOnDisplay: false,
      //initialCountry: "auto",
      initialCountry: 'ne',
      // geoIpLookup: function(callback) {
      //     $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
      //         var countryCode = (resp && resp.country) ? resp.country : "";
      //         callback(countryCode);
      //     });
      // },
      hiddenInput: 'full_number',
      //initialCountry: "ne",
      //localizedCountries: { 'ne': 'Niger' },
      // nationalMode: false,
      onlyCountries: ['ne'],
      // onlyCountries: ['us', 'gb', 'ch', 'ca', 'do'],
      // placeholderNumberType: "MOBILE",
      //preferredCountries: ['ne'],
      separateDialCode: true,
      utilsScript: 'assets/plugin/build/js/utils.js',
    });

    var reset = function () {
      $('#valid-msg').hide();
      $('#error-msg').hide();
    };

    // Validate on blur event
    $('#phoneNumber').on('blur keyup change countrychange', function () {
      reset();
      if (input.value.trim()) {
        if (intl.isValidNumber()) {
          $('#valid-msg').show();
          $('#error-req').show();
          $('#error-msg').hide();
          $('#submit').prop('disabled', false);
        } else {
          input.classList.add('error');
          var errorCode = intl.getValidationError();
          $('#error-msg').html(
            '<i class="fas fa-exclamation-triangle mr-2"></i>' +
              errorMap[errorCode]
          );
          $('#valid-msg').hide();
          $('#error-req').hide();
          $('#error-msg').show();
          $('#submit').prop('disabled', true);
        }
      }
    });

    // Reset on keyup/change event
    input.addEventListener('change', reset);
    input.addEventListener('keyup', reset);

    $('#phoneNumber').on('blur keyup change countrychange', () => {
      let getCode = intl.s['iso2'];
      let dialcode = intl.s['dialCode'];
      //console.log(dialcode);
      //sessionStorage.removeItem('country_code');
      this.localStore.saveData('country_code', getCode.toUpperCase());
      //alert(this.code);
      $('#code').val(getCode); //You will get country code only
      $('#dialcode').val(dialcode);
    });

    $('#birthday').datepicker({
      dateFormat: 'dd/mm/yyyy',
      changeMonth: true,
      changeYear: true,
      yearRange: '-85:-18',
    });
  }

  ngOnInit() {
    this.initialization();
    this.localStore.removeData('country_code');
    this.localStore.saveData('country_code', 'NE'.toUpperCase());
    if (!this.localStore.getData('user-email')) {
      this.router.navigate(['/inscription/set-email']);
    }

    this.signupForm = this.formBuilder.group(
      {
        firstname: ['', [Validators.required]],
        lastname: ['', [Validators.required]],
        username: [
          this.localStore.getData('user-email'),
          [Validators.required, Validators.pattern(this.usernamePattern)],
        ],
        //password: ["", [Validators.required,Validators.pattern(this.passwordPattern)]],
        password: [
          '',
          Validators.compose([
            // 1. Password Field is Required
            Validators.required,
            // 2. check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, { hasNumber: true }),
            // 3. check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // 4. check whether the entered password has a lower-case letter
            CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
            // 5. check whether the entered password has a special character
            CustomValidators.patternValidator(this.specialCharacterPattern, {
              hasSpecialCharacters: true,
            }),
            // 6. Has a minimum length of 8 characters
            Validators.minLength(8),
          ]),
        ],
        confirmPassword: ['', Validators.required],
        birthday: ['', [Validators.required]],
        companyName: [''],
        nif: [''],
        rccm: [''],
        category: [''],
        userType: ['company'],
        description: [''],
        sex: [null, [Validators.required]],
        address: ['', [Validators.required]],
        phoneNumber: ['', [Validators.required]],
        phone: [''],
        country: ['NE'],
        code: [''],
        role: ['partener'],
      },
      {
        validator: ConfirmedValidator('password', 'confirmPassword'),
        //updateOn: 'blur',
      }
    );

    // phone
    $('#valid-msg').hide();
    $('#error-msg').hide();
    $('#submit').prop('disabled', true);
  }
  get getControl() {
    return this.signupForm.controls;
  }
}
