export class ProduitPanier {
  name!: string;
  category!: string;
  description!: string;
  price: number = 0;
  quantity: number = 0;
  id!: string;
  image!:string;
  constructor() {}
}
