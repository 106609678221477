import {Component, OnInit} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProduitPanier } from "src/app/models/produit-panier";
import { Store } from "src/app/models/store";
import { LocalService } from "src/app/services/local.service";
import { StoreService } from "src/app/services/store.service";

declare let $: any;

@Component({
  selector: 'app-page-shop-details',
  templateUrl: './page-shop-details.component.html',
  styleUrls: ['./page-shop-details.component.scss'],
})
export class PageShopDetailsComponent implements OnInit {
  pm?: string;
  stores: Array<Store> = [];
  store!: Store;
  produits: any;
  panier: Array<ProduitPanier> = [];
  data: any = [];
  id!: string;
  page: number = 1;
  count: number = 0;
  tableSize: number = 12;
  tableSizes: any = [3, 6, 9, 12];
  constructor(
    private localStore: LocalService,
    private router: Router,
    private route: ActivatedRoute,
    private storeService: StoreService
  ) {}
  /* changePaymentMethodTo(paymentMethod: string){
    this.pm=paymentMethod;
  } */
  changePaymentMethodTo(paymentMethod: string) {
    this.pm = paymentMethod;
    $('.payment-method').removeClass('selected');
    $('#' + paymentMethod).addClass('selected');
    // console.log(this.sp);
  }
  initialization() {
    // ============================
    // Button Animation
    // ============================
    $('.btn')
      .on('mouseenter mousemove', (e: { pageX: number; pageY: number }) => {
        var parentOffset = $(this).offset(),
          relX = e.pageX - parentOffset.left,
          relY = e.pageY - parentOffset.top;
        $(this).find('.bh').css({ top: relY, left: relX });
        var origin = relX + 'px' + ' ' + relY + 'px';
      })
      .on('mouseout', (e: { pageX: number; pageY: number }) => {
        var parentOffset = $(this).offset(),
          relX = e.pageX - parentOffset.left,
          relY = e.pageY - parentOffset.top;
        $(this).find('.bh').css({ top: relY, left: relX });
      });

    //======================
    // Testimonial
    //======================
    $('.test-caro').owlCarousel({
      autoplay: true,
      dots: true,
      loop: true,
      margin: 30,
      nav: false,
      responsive: {
        0: {
          items: 1,
        },
        768: {
          items: 1,
        },
      },
    });
  }
  updatePanier(shopping_cart: Array<any>, id: string, quantity: Object) {
    return shopping_cart.map((item) =>
      item.id === id ? { ...item, ...quantity } : item
    );
  }
  async addProductToShoppingCart(p: any) {
    try {
      if (this.localStore.getData('user')) {
        let produit = new ProduitPanier();
        produit.name = p.name;
        produit.price = p.price;
        produit.quantity = 1;
        produit.id = p.id;
        produit.description = p.description;
        produit.image = p.images.length > 0 ? p.images['0'].id : '';
        const addItemRes = await this.storeService.addItemToCart(produit);
        if ('status' in addItemRes && addItemRes['status'] == true) {
          let existProduit = this.panier.find((x) => x.id == produit.id);
          if (!existProduit) {
            this.panier.push(produit);
            this.localStore.saveData('panier', JSON.stringify(this.panier));
            this.router.navigate(['/page-panier']);
          } else {
            this.panier = this.updatePanier(this.panier, produit.id, {
              quantity: existProduit.quantity + 1,
            });
            this.localStore.saveData('panier', JSON.stringify(this.panier));
            this.router.navigate(['/page-panier']);
          }
        } else {
        }
      } else {
        this.router.navigate(['/login']).then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
      }
    } catch (error) {}
  }
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.stores = JSON.parse(this.localStore.getData('stores'));
    let filtre = this.stores.filter((x) => x.id === this.id);
    this.store = filtre[0];
    console.log(this.store);
    this.initialization();
    this.fetchPosts();
  }
  async fetchPosts() {
    try {
      this.data = await this.storeService.getProductByStoreIdWT(this.id);
      if ('data' in this.data) {
        this.produits = this.data['data'];
      }
    } catch (error) {}
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.fetchPosts();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.fetchPosts();
  }
}
