<div class="contact-page">
    <app-page-header></app-page-header>
    <!-- Banner section start -->
    <section class=" features">
      <div class="container">
        <div class="row">

          <div class="col-md-8 my-auto">
            <div class="ban-content">
              <h2 class="text-white" style="text-transform:none;">Nous contacter</h2>
              <p class="text-white">Bénéficiez des conseils avisés d’un spécialiste dédié à votre cause. Obtenez des réponses précises et fiables susceptibles de répondre à vos besoins.</p>
            </div>
          </div>
          <div class="col-md-4">
            <img src="assets/images/contact-us.png" alt="" class="">
          </div>
        </div>
      </div>
    </section>
    <!-- Banner section end -->

    <!-- Contact section start -->
    <section class="contact">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-8 nopadd-rth">
            <div class="contact-from-wrap">
              <div class="sec-heading">
                <h2 class="sec-title" style="text-transform:none;">Envoyer nous un message</h2>
              </div>
              <form class="contact-form clearfix" [formGroup]="signupForm">
                <div class="form-group row align-items-end">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Nom</label>
                      <input formControlName="firstname" type="firstname" #firstname class="form-control form-control-sm" placeholder="nom" aria-label="firstname" aria-describedby="basic-addon1" required>
                      <div class="p-0" *ngIf="signupForm.controls['firstname'].invalid && (signupForm.controls['firstname'].dirty || signupForm.controls['firstname'].touched)">
                        <div class="text-danger p-0" *ngIf="signupForm.controls['firstname'].hasError('required')">
                          Le nom est requis!
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Prénom</label>
                      <input formControlName="lastname" type="lastname" #lastname class="form-control " placeholder="prenom" aria-label="lastname" aria-describedby="basic-addon1" required>
                      <div class="p-0" *ngIf="signupForm.controls['lastname'].invalid && (signupForm.controls['lastname'].dirty || signupForm.controls['lastname'].touched)">
                        <div class="text-danger p-0" *ngIf="signupForm.controls['lastname'].hasError('required')">
                          Le prénom est requis!
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Email</label>
                      <input formControlName="email" type="email" #email class="form-control " placeholder="Email professionnel" aria-label="email" aria-describedby="basic-addon1" required>
                      <div class="p-0" *ngIf="signupForm.controls['email'].invalid && (signupForm.controls['email'].dirty || signupForm.controls['email'].touched)">
                        <div class="text-danger p-0" *ngIf="signupForm.controls['email'].hasError('required')">
                          L'adresse email est requise!
                        </div>
                        <div class="text-danger p-0" *ngIf="signupForm.controls['email'].hasError('pattern')">
                          Veuillez entree une adresse email valide svp!
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Objet</label>
                      <input formControlName="object" type="object" #object class="form-control" placeholder="object" aria-label="object" aria-describedby="basic-addon1" required>
                      <div class="p-0" *ngIf="signupForm.controls['object'].invalid && (signupForm.controls['object'].dirty || signupForm.controls['object'].touched)">
                        <div class="text-danger p-0" *ngIf="signupForm.controls['object'].hasError('required')">
                          L'objet est requis!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Rediger votre message</label>
                  <textarea formControlName="message" type="message" #message class="form-control " placeholder="message" aria-label="email" aria-describedby="basic-addon1" placeholder="message" required></textarea>
                  <div class="p-0" *ngIf="signupForm.controls['message'].invalid && (signupForm.controls['message'].dirty || signupForm.controls['message'].touched)">
                    <div class="text-danger p-0" *ngIf="signupForm.controls['message'].hasError('required')">
                      Le message est requis!
                    </div>
                  </div>
                </div>
                <button class="btn-sp color-primary float-right ml-auto mr-0" type="submit" (click)="onFormSubmit(email.value,firstname.value,lastname.value,object.value,message.value)" [disabled]="signupForm.invalid">
                  <span class="spinner-border spinner-border-sm" id="spinner" role="status" aria-hidden="true"></span>
                  <span class="ml-2" id="text">Envoyer</span>
                </button>
              </form>
            </div>
          </div>
          <div class="col-md-4">
            <div class="contact-info-wrap features" style="position:relative;">
              <address>
                <i class="fas fa-envelope"></i>
                <span>Adresse email</span>
                <a href="javascript:void(0)">smartpay@dev4smart.net</a>
              </address>
              <address>
                <i class="fas fa-phone-alt"></i>
                <span>Téléphone</span>
                <a href="tel:+22790112212">+227 90 11 22 12</a>
                <a href="tel:+22790112212">+227 20 37 04 40</a>
              </address>
              <address>
                <i class="fas fa-map-marker-alt"></i>
                <span>Adresse</span>
                Niamey-Niger <br>
                Rue KK150 Koira-Kano
              </address>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Contact section end -->
    <!-- Contact Media start -->
    <div class="contact-media bg-offwhite mb-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-10 m-auto text-center">
            <div class="sec-heading">
              <h2 class="sec-title" style="text-transform:none;">Connectez-vous avec nos médias sociaux</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-sm-4 col-6">
            <a href="https://www.facebook.com/profile.php?id=100087358130436&mibextid=ZbWKwL" class="cm-box">
              <i class="fab fa-facebook-f"></i>
              Facebook
            </a>
          </div>
          <div class="col-lg-4 col-sm-4 col-6">
            <a href="https://twitter.com/NeSmartpay?t=BajCRnUj5KVDPCgykKJf0g&s=09" class="cm-box">
              <i class="fab fa-twitter"></i>
              Twitter
            </a>
          </div>
          <div class="col-lg-4 col-sm-4 col-6">
            <a href="https://www.linkedin.com/company/smartpay-ne/" class="cm-box">
              <i class="fab fa-linkedin"></i>
              Linkedin
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Contact Media end -->
    <app-page-footer></app-page-footer>
  </div>

