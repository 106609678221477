<div class="home-two">
    <app-page-header></app-page-header>
    <!-- Banner section start -->
    <section class="banner v2">
        <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
            <div class="ban-content">
                <!-- <span class="tagline">Simple and Easy Way to</span> -->
                <h1 style="text-transform: none;"><span class="welcome"></span></h1>
                <div class="d-flex">
                    <p class="welcome-message mr-2"></p><p class="solution"></p>
                </div>
                <span class="d-flex">
                <a href="javascript:void(0)" class="img-fluid mb-2"><img src="assets/images/google2.png" alt="" style="width:200px;"></a>
                <a href="javascript:void(0)" class="img-fluid mb-2 ml-2"><img src="assets/images/apple2.png" alt="" style="width:200px;"></a>
                </span>
            </div>
            </div>
            <div class="col-lg-5 col-md-12">
            <ul class="nav nav-tabs cmn-tab-nav">
                <li><a class="active" data-toggle="tab" href="#recharge">Recharge <i class="fa fa-sim-card ml-2"></i></a></li>
                <li><a data-toggle="tab" href="#iptv">Shopping<i class="fa fa-shopping-cart ml-2"></i></a></li>
<!--
                <li><a data-toggle="tab" href="#cdf">BulkSMS<i class="fa fa-comment ml-2"></i></a></li>
-->
            </ul>
            <div class="tab-content">
                <div id="recharge" class="tab-pane fade in active show">
                  <form [formGroup]="rechargeForm" class="currency-form">
                      <h5>Achat crédit téléphonique</h5>
                      <div class="form-field" id="phone_number">
                      <input type="tel" id="phone" formControlName="phone" value="" style="height: 50px; border: 1px solid #ced4da;" class="form-control" placeholder="numéro de téléphone">
                      </div>
                      <div class="mb-3" id="amount_pm">
                      <select formControlName="amount" class="form-control form-control-lg mb-4">
                          <option [ngValue]="null">Séléctionnez le montant</option>
                          <option [ngValue]="coupon.cfa" *ngFor="let coupon of coupons">{{coupon.cfa}}</option>
                      </select>
                      <div class="payment">
                          <h5 class="title">Modes de paiement </h5>
                          <div class="row">
                              <div class="col-md-6 col-sm-6 mb-3">
                                  <div class="single-payment payment-method selected" id="wallet" (click)="changePaymentMethodTo('wallet')">
                                      <img src="assets/admin/images/payment-4.png" alt="">
                                  </div>
                              </div>
                              <div class="col-md-6 col-sm-6 mb-3">
                                  <div class="single-payment payment-method" id="mobileMoney" (click)="changePaymentMethodTo('mobileMoney')">
                                      <img src="assets/admin/images/payment-2.png" alt="">
                                  </div>
                              </div>
                              <div class="col-md-6 col-sm-6 mb-3">
                                  <div class="single-payment payment-method" id="creditCard" (click)="changePaymentMethodTo('creditCard')">
                                      <img src="assets/admin/images/payment-1.png" alt="">
                                  </div>
                              </div>
                              <div class="col-md-6 col-sm-6 mb-3">
                                  <div class="single-payment payment-method" id="sta" (click)="changePaymentMethodTo('sta')">
                                      <img src="assets/admin/images/payment-sta.png" alt="">
                                  </div>
                              </div>
                          </div>
                      </div>
                      </div>
                      <div class="mb-3" id="mm_pm">
                      <label for="msisdn">Votre numéro mobile money</label>
                      <input type="tel" id="msisdn" formControlName="msisdn" value="" style="height: 50px; border: 1px solid #ced4da;" class="form-control" placeholder="numéro de téléphone">
                      </div>
                      <div class="my-5" id="valid_payment">
                      <p class="text-justify"><i class="fa fa-info mr-2 text-primary"></i>Merci de validé le paiement sur votre téléphone... ; Clicquer sur suivant pour verifier si votre paiement a été effectuer avec succés</p>
                      </div>
                      <div class="bg-offwhite  success form" id="recharge_status">
                      <div class="text-center">
                          <h1 class=""><i class="far fa-check-circle text-success"></i></h1>
                          <h3 class=" text-success">Success!</h3>
                          <p class="">Transactions terminées</p>
                          <p class="">Vous avez recharger <b>{{ montant }} FCFA</b> au numéro {{ phone }}.</p>
                      </div>
                      </div>
                      <input type="hidden" id="sp_vpn" name="sp_vpn" #sp_vpn value="0">
                      <input type="hidden" id="sp_vpn1" name="sp_vpn1" #sp_vpn1 value="0">
                      <span id="error-msg" class="text-danger"></span>
                      <div class="" *ngIf="wrongNumber">
                      <span id="error-req" class="text-danger"><i class="fas fa-exclamation-triangle mr-2"></i>{{ errorMessage }}</span>
                      </div>
                      <button class="btn btn-block btn-filled form-btn" type="button" (click)="onFormSubmit(sp_vpn.value,sp_vpn1.value)">
                      <span class="bh"></span>
                      <span class="spinner-border spinner-border-sm" id="spinner" role="status" aria-hidden="true"></span>
                      <span class="ml-2" id="text">Suivant</span><i class="fas fa-arrow-right"></i>
                      </button>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="single-payment payment-method selected" id="wallet" (click)="changePaymentMethodTo('wallet')">
                          <img src="assets/admin/images/mpt.png" alt="">
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div id="iptv" class="tab-pane fade">
                <form class="currency-form" action="#">
                    <h5>Séléctionnez boutique partenaire</h5>
                    <div class="row">
                      <div class="col-6" *ngFor="let store of stores; let indice=index;">
                          <a [routerLink]="['/shop/stores/details/', store.id ]"  class="card text-center py-4 my-2 ip-tv" id="fifi" (click)="changeShopTo('fifi')">
                            <i class="fa fa-store fa-2x"></i>
                            <span class="text-uppercase">{{ store.name }}</span>
                          </a>
                      </div>
                    </div>
                    <a routerLink="/shop/stores" class="btn w-100 btn-small btn-filled form-btn shp">
                      <span class="bh"></span>
                      <span>Voir Tout &nbsp;<i class="fas fa-list"></i></span>
                    </a>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="single-payment payment-method selected" id="wallet" (click)="changePaymentMethodTo('wallet')">
                        <img src="assets/admin/images/mpt.png" alt="">
                      </div>
                    </div>
                    <!--<div class="col-md-6 col-sm-6 mb-3">
                      <div class="single-payment payment-method" id="mobileMoney" (click)="changePaymentMethodTo('mobileMoney')">
                        <img src="assets/admin/images/payment-2.png" alt="">
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6 mb-3">
                      <div class="single-payment payment-method" id="creditCard" (click)="changePaymentMethodTo('creditCard')">
                        <img src="assets/admin/images/payment-1.png" alt="">
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6 mb-3">
                      <div class="single-payment payment-method" id="sta" (click)="changePaymentMethodTo('sta')">
                        <img src="assets/admin/images/payment-sta.png" alt="">
                      </div>
                    </div>-->
                  </div>
                </form>
                </div>
            </div>
            </div>
        </div>
        </div>
    </section>
    <!-- Banner section end -->


    <!-- Overview section start -->
    <div class="overview">
        <div class="container">
        <div class="row align-items-center">
            <div class="col-md-4">
                <figure class="image-dev4smart"><img src="assets/images/sp-banner.png" alt="" class=""></figure>

            </div>
            <div class="col-md-8">
            <div class="content-box">
                <span class="tagline bar title-apropos">APROPOS DE SmartPay </span>
    <!--
                <h2>L’innovation dans l’offre des services financiers électroniques</h2>
    -->
                <p class="text-justify">Agrégateur de recharge électronique et paiement en ligne tout en un. Il permet aux particuliers et entreprises d'effectuer des opérations financières d'achat, vente, encaissement et paiement à partir de leurs portefeuilles externes (Mobile Money, VISA & Mastercard, Services de Transfert d'Argent, etc.).
                </p>
                <button class="btn-sp color-primary"><a href="/apropos" class="text-white">En Savoir Plus</a></button>

            </div>
            </div>
        </div>
        </div>
    </div>
    <!-- Overview section end -->


    <!-- Home send money section start -->
    <div class="home-send-money bg-light py-5">
        <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-10 m-auto">
            <div class="sec-heading">
                <span class="tagline">Nous construisons une infrastructure financière qui facilite le quotidien des citoyens.</span>
                <h2 class="sec-title">SMARTPAY C'EST: </h2>
            </div>
            </div>
        </div>
        <div class="row">
            <!-- <div class="col-md-3 col-sm-6">
            <a href="javascript:void(0)" class="icon-list-block">
                <img src="assets/images/icons/categories/1.png" alt="">
                <span>eBanking</span>
            </a>
            </div>
            <div class="col-md-3 col-sm-6">
            <a href="javascript:void(0)" class="icon-list-block">
                <img src="assets/images/icons/categories/payment.png" alt="">
                <span>Mobile Money</span>
            </a>
            </div> -->
            <div class="col-md-4 col-sm-6">
            <a href="javascript:void(0)" class="icon-list-block">
                <img src="assets/images/icons/categories/sim.png" alt="">
                <span>Recharge électronique</span>
            </a>
            </div>
          <div class="col-md-4 col-sm-6">
            <a href="javascript:void(0)" class="icon-list-block">
              <img src="assets/images/icons/categories/facture.png" alt="">
              <span>Collecte des taxes et frais</span>
            </a>
          </div>
          <div class="col-md-4 col-sm-6">
            <a href="javascript:void(0)" class="icon-list-block">
              <img src="assets/images/icons/categories/8.png" alt="">
              <span>eCommerce & Monétisation</span>
            </a>
          </div>
            <div class="col-md-4 col-sm-6">
            <a href="javascript:void(0)" class="icon-list-block">
                <img src="assets/images/icons/categories/money.png" alt="">
                <span>Collecte de fonds</span>
            </a>
            </div>

            <div class="col-md-4 col-sm-6">
            <a href="javascript:void(0)" class="icon-list-block">
                <img src="assets/images/icons/categories/smart-tv.png" alt="">
                <span>Abonnement TV</span>
            </a>
            </div>
          <div class="col-md-4 col-sm-6">
            <a href="javascript:void(0)" class="icon-list-block">
              <img src="assets/images/icons/categories/diagram.png" alt="">
              <span>APIs de paiement</span>
            </a>
          </div>
        </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
          <div class="col-md-4">
            <figure class="image-vect1"><img src="assets/images/recharge.png" alt="" class="w-100"></figure>
          </div>
        <div class="col-md-8 my-auto">
            <div class="content-box">
            <span class="tagline bar">L’achat de crédit instantané</span>
            <p class="text-justify"> Grace à cette fonctionnalité, rechargez votre numéro ou celui d'un de vos proches au Niger en crédit de communication airtime.</p>
            <button  class="btn-sp  color-primary">Acheter du crédit</button>
            </div>
        </div>
        </div>
    </div>
  <div class="features" style="position:relative;">
    <div class="container">
      <div class="row">
        <div class="col-md-7 my-auto">
          <span class="tagline bar bar1" style="color:#fff;">Boutique en ligne e-Commerce</span>
          <p class="text-justify text-white">
            Détenteur d'une boutique ou d'un magasin commercial? Optez pour la monétisation de votre business en souscrivant à notre offre boutique en ligne et acceptez les paiements via SMARTPAY.
          </p>
<!--          <button class="btn-sp  color-primary" style="text-transform:none;"><a routerLink="/page-ecommerce" class="text-white">En savoir plus</a></button>-->
          <button class="btn-sp  color-primary" style="text-transform:none;"><a href="/page-ecommerce" class="text-white">En savoir plus</a></button>
        </div>
        <div class="col-md-5">
          <figure class="image-vect2"><img src="assets/images/boutique.png" alt="" class=""></figure>
        </div>
      </div>
    </div>
  </div>

    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <figure class="image-vect3"><img src="assets/images/cagnotte.png" alt="" class=""></figure>
        </div>
        <div class="col-md-7 my-auto">
          <span class="tagline bar">Lancez votre collecte de fonds sur SMARTPAY</span>
          <p class="text-justify">
            Notre service de collecte permet à tous de financer un projet à vocation humanitaire, sociale, solidaire ou divers. Seul ou en groupe, lancez gratuitement votre campagne de collecte en ligne en moins d’une minute !
          </p>
<!--          <button class="btn btn-sp color-primary" style="text-transform:none;"><a routerLink="/page-cagnotte" class="text-white">En savoir plus</a></button>-->
          <button class="btn btn-sp color-primary" style="text-transform:none;"><a routerLink="/page-cagnotte" class="text-white">En savoir plus</a></button>
        </div>
      </div>
    </div>
  <div class="features" style="position:relative;">
    <div class="my-5">
        <div class="container">
        <div class="row">

            <div class="col-md-8 my-auto">
            <div class="content-box">
                <span class="tagline bar bar1" style="color:#fff;">Devenir partenaire SmartPay</span>
                <p  class="text-justify text-white">Devenez partenaire SmartPay et gagnez quotidiennement des commissions sur chaque transaction effectuée à partir de votre réseau des points de vente.</p>
                <button  class="btn-sp  color-primary">En savoir plus</button>
            </div>
            </div>
          <div class="col-md-4">
            <img src="assets/images/tpe.png" alt="" class="w-100">
          </div>
        </div>
        </div>
    </div>
  </div>
    <!-- Access money section start -->
    <section class="access-money bg-light">
        <div class="container-fluid">
        <div class="row align-items-md-center">
            <div class="col-md-6">
            <div class="content-box-fluid right">
                <span class="tagline bar">Comment ça marche ?</span>
                <p>Créez votre Client Ou Entreprise SmartPay en seulement 3 étapes</p>
                <ul class="steps vertical">
                  <li>
                    <span>1</span>
                    Création du compte
                    <p style="text-transform:none!important" class="text-justify">Cliquez sur le bouton "Ouvrir un compte", choisisez le type de compte client ou entreprise, remplissez le formulaire d'inscription en 2 minutes et recevez vos identifiants d'accès par email.</p>
                  </li>

                <li>
                    <span>2</span>
                    Installer notre application mobile ou accéder via le web
                    <p style="text-transform:none!important" class="text-justify">Téléchargez l'application mobile SmartPay disponible Android ou iOS</p>
                </li>
                <li>
                    <span>3</span>
                    Profitez des fonctionnalités
                    <p style="text-transform:none!important" class="text-justify">Utilisez votre compte SMARTPAY pour effectuer vos paiements de façon rapide, sécurisée et fiable.</p>
                </li>
                </ul>
              <a href="/inscription/account-type"><button  class="btn-sp  color-primary">Ouvrir un compte</button></a>
            </div>
            </div>
            <div class="col-md-6 nopadd-rth">
            <figure class="imgBox">
                <img src="assets/images/installation.png" alt="">
            </figure>
            </div>
        </div>
        </div>
    </section>
    <!-- Access money section end -->


    <!-- Partners section start -->
   <!-- <section class="partners bg-offwhite">
        <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-10 m-auto">
            <div class="sec-heading">
                <h2 class="sec-title" style="text-transform:uppercase">Nos Partenairs</h2>
            </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
            <div class="partners-logo owl-carousel">
                <a href="javascript:void(0)"><img src="assets/images/partners/1.png" alt=""></a>
                <a href="javascript:void(0)"><img src="assets/images/partners/2.png" alt=""></a>
                <a href="javascript:void(0)"><img src="assets/images/partners/3.png" alt=""></a>
                <a href="javascript:void(0)"><img src="assets/images/partners/4.png" alt=""></a>
                <a href="javascript:void(0)"><img src="assets/images/partners/5.png" alt=""></a>
                <a href="javascript:void(0)"><img src="assets/images/partners/6.png" alt=""></a>
            </div>
            </div>
        </div>
        </div>
    </section>-->
    <!-- Partners section end -->
    <app-page-footer></app-page-footer>
</div>
