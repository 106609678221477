import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { LocalService } from 'src/app/services/local.service';
import { RechargeService } from 'src/app/services/recharge.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { SweetAlertOptions } from 'sweetalert2';
declare let $: any;
@Component({
  selector: 'app-user-recharge-confirme',
  templateUrl: './user-recharge-confirme.component.html',
  styleUrls: ['./user-recharge-confirme.component.scss'],
})
export class UserRechargeConfirmeComponent implements OnInit {
  balance!: number;
  user: any = [];
  userData: any = [];
  montant!: any;
  pm: any = 'SPWALLET';
  pf: number = 0;
  total!: number;

  // =================OTP=======================
  validateCode = true;
  codePattern?: string;
  otp!: string;
  msisdn!: string;
  amount!: number;
  data: any = [];
  c2sData: any = [];
  rechargeForm!: FormGroup;
  isWrongOtp = false;
  isValidOtp = false;
  errorMessage!: string;
  swalOptions: SweetAlertOptions = { icon: 'info' };
  // ==============================
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private http: HttpClient,
    private authService: AuthService,
    private localStore: LocalService,
    private swalService: FlashMessageService,
    private rechargeService: RechargeService
  ) {}
  async confirmation() {
    if (this.otp.length == 4) {
      try {
        this.data = await this.rechargeService.payCredite(
          this.montant,
          this.otp,
          this.msisdn,
          'SPWALLET',
          'NE'
        );
        if (
          this.data &&
          'status' in this.data &&
          'data' in this.data &&
          this.data['status'] == true &&
          this.data['data']
        ) {
          this.localStore.removeData('transaction-recharge-init');
          this.localStore.saveData('transaction-recharge-init',JSON.stringify(this.data));
          this.c2sData = await this.rechargeService.c2s(
            this.data['data']['transaction']['transaction_id'],
            this.localStore.getData('recharge_msisdn')
          )
          if (this.c2sData) {
            this.localStore.removeData('transition-recharge-c2s');
            this.localStore.saveData('transition-recharge-c2s', JSON.stringify(this.c2sData));
            this.router.navigate(['/recharge/statut']);
          } else {
            this.swalOptions.icon = 'info';
            this.swalOptions.html =
              'La transaction a echoué veuillez ressayer svp!';
            this.swalService.show(this.swalOptions);
          }
        } else {
          this.swalOptions.icon = 'info';
          this.swalOptions.html =
            'La transaction a echoué veuillez ressayer svp!';
          this.swalService.show(this.swalOptions);
          //this.errorMessage = this.data['message'];
        }
      }
      catch (error) {
        this.swalOptions.icon = 'error';
        this.swalOptions.html =
          'La transaction a echoué veuillez ressayer svp!';
        this.swalService.show(this.swalOptions);
        //this.errorMessage = "La transaction a echoué veuillez ressayer svp!";
      }
    } else {
      this.swalOptions.icon = 'info';
      this.swalOptions.html = "Le code otp n'est pas valide!";
      this.swalService.show(this.swalOptions);
    }
  }
  onOtpChange(otp: string) {
    this.otp = otp;
  }
  ngOnInit() {
    this.userData = this.tokenStorageService.getUser();
    this.msisdn = this.localStore.getData('recharge_msisdn');
    this.pm = this.localStore.getData('payment_method');
    this.montant = this.localStore.getData('recharge_amount');
    if (!this.userData || !this.msisdn || !this.pm || !this.montant) {
      this.router.navigateByUrl('/recharge');
    }
    this.user = JSON.parse(this.userData);
    this.balance = this.user.wallet.balance;
    this.amount = parseInt(this.montant);
    this.pf = 0;
    this.total = this.amount + this.pf;

    this.rechargeForm = this.formBuilder.group(
      {
        code: ['', [Validators.required]],
      },
      {
        updateOn: 'blur',
      }
    );
  }
}
