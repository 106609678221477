<div class="terms-page">
    <app-page-header></app-page-header>
    <!-- Banner section start -->
  <section class="banner features">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-12">
          <img src="assets/images/step.png" alt="" style="" class="ml-auto">
        </div>
        <div class="col-lg-9 col-md- col-12 my-auto">
          <div class="ban-content ">
            <h3 class="text-white">Politique de confidentialité de SmartPay</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
    <!-- Banner section end -->

    <!-- politique section start -->
    <section class="terms-tab">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-10 m-auto text-center">
            <div class="sec-heading">
              <p class="sec-subtitle">Date d'entrée en vigueur : 01 Janvier 2023 </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-10 col-md-12 m-auto">

            <div class="tab-content">
              <div id="general-qs" class="tab-pane fade in active show">
                <div class="accordion" id="accordion">
                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-1" aria-expanded="true">
                      1. Introduction
                    </h5>
                    <div id="faq-1" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">Bienvenue à <strong>“smartpay.ne”</strong>.
                          <br><br>
                          <strong>“SmartPay”</strong> NE ("nous", "notre" ou "nos") exploite <strong>"SmartPay"</strong> (ci-après dénommé <strong>"Service"</strong>).<br>
                          <br><br>
                          Notre politique de confidentialité régit votre visite du site web <strong>smartpay.ne</strong> et de l'application mobile <strong>SmartPay NE</strong>, et explique
                          comment nous recueillons, protégeons et divulguons les informations résultantes de votre utilisation de notre service.
                          <br><br>
                          Nous utilisons vos données pour fournir et améliorer le Service. En utilisant le Service, vous acceptez systématiquement la collecte et l'utilisation des informations
                          conformément à la présente politique. Sauf définition contraire dans cette politique de confidentialité, les termes utilisés ont la même signification que dans nos conditions générales d'utilisation. <br>
                          <br><br>
                          Nos conditions générales ("<strong>conditions</strong>") régissent toute utilisation de notre service et, avec la politique de confidentialité, constituent
                          votre accord avec nous ("<strong>accord</strong>"). <br>
                        </p>

                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-2" aria-expanded="true">
                      2. Définitions
                    </h5>
                    <div id="faq-2" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">
                          <br><strong>SERVICE</strong> désigne le site Web SmartPay.ne et les applications SmartPay.ne exploités par Dev4smart.
                          <br><br>Les <strong>DONNÉES PERSONNELLES </strong> sont des données concernant une personne physique vivante qui peut être identifiée à partir de ces données
                          (ou à partir de ces données et d'autres informations en notre possession ou susceptibles d'être en notre possession).
                          <br><br>
                          <br>Les <strong>DONNÉES D'UTILISATION</strong> sont des données collectées automatiquement soit générées par l'utilisation du Service, soit par l'infrastructure du Service
                          elle-même (par exemple, la durée de la visite d'une page). Les COOKIES sont de petits fichiers stockés sur votre appareil (ordinateur ou appareil mobile).
                          <br><br><strong>CONTRÔLEUR DE DONNÉES</strong> désigne une personne physique ou morale qui (seule ou conjointement ou en commun avec d'autres personnes) détermine les finalités et les
                          modalités de traitement de toute donnée personnelle. Aux fins de la présente politique de confidentialité, nous sommes un contrôleur de données de vos données.
                          <br><br>
                          <br><strong>Les responsables du traitement des données (ou prestataires de services) </strong> <br>
                          désignent toute personne physique ou morale qui traite les données pour le compte du responsable du traitement des données. Nous pouvons utiliser
                          les services de différents Prestataires de services afin de traiter vos données de manière plus efficace.
                          <br><br><strong>La PERSONNE concernée</strong>est toute personne physique vivante qui fait l'objet de Données à caractère personnel.
                          <br><br><strong>L'UTILISATEUR </strong>est la personne physique qui utilise notre Service. L'Utilisateur correspond à la Personne concernée, qui fait l'objet de
                          Données à caractère personnel.
                        </p>

                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                      <h5 class="collapsed" data-toggle="collapse" data-target="#faq-3" aria-expanded="false">
                        3. Collecte et utilisation des informations
                      </h5>
                      <div id="faq-3" class="collapse" data-parent="#accordion">
                        <div class="acr-body">
                          <p class="text-justify">Nous recueillons plusieurs types d'informations à des fins diverses pour vous fournir et améliorer notre service.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-4" aria-expanded="false">
                      4. Types de données
                    </h5>
                    <div id="faq-4" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify"><br><strong>Collectées Données</strong><br>
                          <br><strong>Personnelles</strong><br>
                          <br>Lors de l'utilisation de notre Service, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables qui
                          peuvent être utilisées pour vous contacter ou vous identifier ("<strong>Données personnelles</strong>"). Les informations personnellement identifiables peuvent
                          inclure, mais ne sont pas limitées à :<br>
                          <br>4.1. Adresse électronique<br>
                          <br>4.2. Prénom et nom de famille<br>
                          <br>4.3. Numéro de téléphone<br>
                          <br>4.4. Adresse, Pays, État, Province, Code postal, Ville<br>
                          <br>4.5. Cookies et données d'utilisation<br>
                          <br>4.6. Géolocalisation<br>
                          <br>Nous pouvons utiliser vos données personnelles pour vous envoyer des bulletins d'information, du matériel de marketing ou de promotion et d'autres
                          informations susceptibles de vous intéresser. Vous pouvez choisir de ne pas recevoir tout ou partie de ces communications en suivant le lien de désinscription.<br>
                          <br><strong>Données d'utilisation</strong> <br><br>
                          <br>Nous pouvons également collecter les informations que votre navigateur envoie chaque fois que vous visitez notre Service ou lorsque vous accédez au Service
                          par ou via un appareil quelconque ("<strong>Données d'utilisation</strong>").<br>
                          <br>Ces données d'utilisation peuvent inclure des informations telles que l'adresse de protocole Internet (par exemple, l'adresse IP) de votre ordinateur, le
                          type de navigateur, la version du navigateur, les pages de notre Service que vous visitez, l'heure et la date de votre visite, le temps passé sur ces pages,
                          les identifiants uniques des appareils et d'autres données de diagnostic.<br>
                          <br>Lorsque vous accédez au service avec un appareil, ces données d'utilisation peuvent inclure des informations telles que le type d'appareil que vous utilisez,
                          l'identifiant unique de votre appareil, l'adresse IP de votre appareil, votre appareil...
                          <br>Le système d'exploitation, le type de navigateur Internet que vous utilisez, les identifiants uniques des appareils et d'autres données de diagnostic.<br>
                          <br><strong>Données de localisation</strong><br>
                          <br>Nous pouvons utiliser et stocker des informations sur votre localisation si vous nous en donnez l'autorisation ("<strong>Données de localisation</strong>"). Nous utilisons
                          ces données pour fournir des fonctionnalités de notre Service, pour améliorer et personnaliser notre Service.<br>
                          <br>Vous pouvez activer ou désactiver les services de localisation lorsque vous utilisez notre service à tout moment par le biais des paramètres de votre appareil.<br>
                          <br><strong>Cookies de suivi des données</strong><br>
                          <br>Nous utilisons des cookies et des technologies de suivi similaires pour suivre l'activité sur notre service et nous conservons certaines informations.
                          <br>Les cookies sont des fichiers contenant une petite quantité de données qui peuvent inclure un identifiant unique anonyme. Les cookies sont envoyés à votre navigateur
                          depuis un site web et stockés sur votre appareil. D'autres technologies de suivi sont également utilisées, telles que des balises, des marqueurs et des scripts, pour collecter et suivre des informations et pour améliorer et analyser notre service.<br>
                          <br>Vous pouvez demander à votre navigateur de refuser tous les cookies ou d'indiquer quand un cookie est envoyé. Toutefois, si vous n'acceptez pas les cookies, il se peut que
                          vous ne puissiez pas utiliser certaines parties de notre service.<br>
                          <br>Exemples de cookies que nous utilisons :<br>
                          <br><strong>0.1. Cookies de session :</strong> Nous utilisons des cookies de session pour faire fonctionner notre service.<br>
                          <br><strong>0.2. Cookies de préférence :</strong>Nous utilisons des cookies de préférence pour mémoriser vos préférences et divers paramètres.<br>
                          <br><strong>0.3. Cookies de sécurité :</strong>Nous utilisons des cookies de sécurité à des fins de sécurité.<br>
                          <br><strong>0.4. Cookies publicitaires :</strong>Les cookies publicitaires sont utilisés pour vous proposer des publicités qui peuvent être pertinentes pour vous et vos intérêts.<br>
                          <br><strong>Autres données</strong><br>
                          <br>Lors de l'utilisation de notre service, nous pouvons également recueillir les informations suivantes : sexe, âge, date de naissance, lieu de naissance, détails du passeport ou CIN, citoyenneté, enregistrement au lieu de résidence et adresse réelle,
                          numéro de téléphone (travail, portable) autres données.<br>
                      </p>
                    </div>
                  </div>
                </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-5" aria-expanded="false">
                      5. Utilisation des données</h5>
                    <div id="faq-5" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">SmartPay.ne utilise les données collectées à diverses fins :<br>
                          <br>5.1. Pour fournir et maintenir notre service ;<br>
                          <br>5.2. Pour vous informer des modifications apportées à notre service ;<br>
                          <br>5.3. Pour vous permettre de participer à des fonctions interactives de notre service lorsque vous choisissez de le faire ;<br>
                          <br>5.4. Pour fournir une assistance à la clientèle ;<br>
                          <br>5.5. Pour recueillir des analyses ou des informations précieuses afin d'améliorer notre service ;<br>
                          <br>5.6. Pour contrôler l'utilisation de notre service ;<br>
                          <br>5.7. Pour détecter, prévenir et résoudre les problèmes techniques ;<br>
                          <br>5.8. Pour répondre à toute autre finalité pour laquelle vous les avez fournies ;<br>
                          <br>5.9. Pour remplir nos obligations et faire valoir nos droits découlant de tout contrat conclu entre vous et nous, y compris pour la facturation et le recouvrement ;<br>
                          <br>5.10. Pour vous fournir des avis concernant votre compte et/ou votre abonnement, notamment des avis d'expiration et de renouvellement, des instructions par courriel, etc ;<br>
                          <br>5.11. Pour vous fournir des nouvelles, des offres spéciales et des informations générales sur d'autres biens, services et événements que nous proposons et qui sont similaires à
                          ceux que vous avez déjà achetés ou sur lesquels vous vous êtes renseigné, à moins que vous n'ayez choisi de ne pas recevoir ces informations ;<br>
                          <br>5.12. De toute autre manière que nous pourrions décrire lorsque vous fournissez les informations ;<br>
                          <br>5.13. À toute autre fin pour contribuer à l'amélioration de nos services<br>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-6" aria-expanded="false">
                      6. Conservation des données
                    </h5>
                    <div id="faq-6" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">
                          <br>Nous ne conserverons vos Données personnelles qu'aussi longtemps que nécessaire aux fins énoncées dans la présente Politique de confidentialité. Nous conserverons et utiliserons
                          vos Données personnelles dans la mesure nécessaire pour nous conformer à nos obligations légales (par exemple, si nous sommes tenus de conserver vos données pour nous conformer aux
                          lois applicables), résoudre des litiges et appliquer nos accords et politiques juridiques.<br>
                          <br>Votre consentement à la présente politique de confidentialité, suivi de votre soumission de ces informations, représente votre accord à ce transfert.<br>
                          <br>SmartPay.NE prendra toutes les mesures raisonnablement nécessaires pour s'assurer que vos données sont traitées en toute sécurité et conformément à la présente politique de
                          confidentialité et aucun transfert de vos données personnelles n'aura lieu vers une organisation ou un pays à moins qu'il n'y ait des contrôles adéquats en place, y compris la
                          sécurité de vos données et autres informations personnelles.<br>
                          .</p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-7" aria-expanded="false">
                      7. Divulgation des données
                    </h5>
                    <div id="faq-7" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">Nous pouvons divulguer les informations personnelles que nous recueillons ou que vous fournissez :<br>
                          <br>7.1. Divulgation pour l'application de la loi. Dans certaines circonstances, nous pouvons être tenus de divulguer vos données personnelles si la loi l'exige ou en réponse à des
                          demandes valables des autorités publiques nigériennes.<br>
                          <br>7.2. Transaction commerciale. Si nous ou nos filiales sommes impliqués dans une fusion, une acquisition ou une vente d'actifs, vos Données personnelles peuvent être transférées.<br>
                          <br><strong>7.3. Autres.<br>
                          Nous pouvons également divulguer vos informations :</strong><br>
                          <br>7.3.1. À nos filiales, à nos sociétés affiliées, aux tiers avec lesquels nous faisons des affaires;<br>
                          <br>7.3.2. Aux entrepreneurs, aux prestataires de services et aux autres tiers avec lesquels nous faisons des affaires;<br>
                          <br>7.3.3. Pour atteindre l'objectif pour lequel vous les avez fournies ;<br>
                          <br>7.3.4. Dans le but d'inclure le logo de votre entreprise sur notre site web ;<br>
                          <br>7.3.5. À toute autre fin divulguée par nous lorsque vous fournissez les informations ;<br>
                          <br>7.3.6. Avec votre consentement dans tous les autres cas ;<br>
                          <br>7.3.7. Si nous pensons que la divulgation est nécessaire ou appropriée pour protéger les droits, la propriété ou la sécurité de la société, de nos clients ou d'autres personnes.<br>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-8" aria-expanded="false">
                      8. Sécurité des données
                    </h5>
                    <div id="faq-8" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">La sécurité de vos données est importante pour nous, mais n'oubliez pas qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est sûre à 100 %.
                          Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger vos données personnelles, nous ne pouvons vous garantir leur sécurité absolue.<br>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-9" aria-expanded="false">
                      9. Vos droits en matière de protection des données en vertu du règlement général sur la protection des données (RGPD) </h5>
                    <div id="faq-9" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">Nous nous efforçons de prendre des mesures raisonnables pour vous permettre de corriger, modifier, supprimer ou limiter l'utilisation de vos données personnelles.<br>
                          <br>Si vous souhaitez être informé des données personnelles que nous détenons à votre sujet et si vous voulez qu'elles soient supprimées de nos systèmes, veuillez nous envoyer un courriel à SmartPay@dev4smart.net<br>
                          <br>Dans certaines circonstances, vous disposez des droits suivants en matière de protection des données :<br>
                          <br>9.1. Le droit d'accéder aux informations que nous détenons sur vous ;<br>
                          <br>9.2. Le droit de rectification. Vous avez le droit de faire rectifier les informations vous concernant si elles sont inexactes ou incomplètes ;<br>
                          <br>9.3. Le droit d'opposition. Vous avez le droit de vous opposer à notre traitement de vos données personnelles ;<br>
                          <br>9.4. Le droit de restriction. Vous avez le droit de nous demander de restreindre le traitement de vos informations personnelles ;<br>
                          <br>9.5. Le droit à la portabilité des données. Vous avez le droit de recevoir une copie de vos données personnelles dans un format structuré, lisible par machine et couramment utilisé ;<br>
                          <br>9.6. Le droit de retirer votre consentement. Vous avez également le droit de retirer votre consentement à tout moment lorsque nous nous appuyons sur votre consentement pour traiter vos informations personnelles ;<br>
                          <br>Veuillez noter que nous pouvons vous demander de vérifier votre identité avant de répondre à de telles demandes. Veuillez noter que nous pourrions ne pas être en mesure de fournir le service sans certaines données nécessaires.<br>
                          <br>Vous avez le droit de vous plaindre auprès d'une autorité de protection des données au sujet de notre collecte et de notre utilisation de vos données personnelles.<br>
                          <br><strong>9.1. Les informations personnelles dont nous disposons à votre sujet. Si vous faites cette demande, nous vous retournerons :</strong><br>
                          <br>9.1.1. Les catégories d'informations personnelles que nous avons recueillies à votre sujet.<br>
                          <br>9.1.2. Les catégories de sources à partir desquelles nous recueillons vos informations personnelles.<br>
                          <br>9.1.3. Le but professionnel ou commercial de la collecte ou de la vente de vos informations personnelles.<br>
                          <br>9.1.4. Les catégories de tiers avec lesquels nous partageons des informations personnelles.<br>
                          <br>9.1.5. Les éléments spécifiques d'information personnelle que nous avons recueillis à votre sujet.<br>
                          <br>9.1.6. Une liste des catégories d'informations personnelles que nous avons vendues, ainsi que la catégorie de toute autre société à laquelle nous les avons vendues. Si nous n'avons pas vendu vos informations personnelles, nous vous en informerons.<br>
                          <br>9.1.7. Une liste des catégories d'informations personnelles que nous avons divulguées à des fins commerciales, ainsi que la catégorie de toute autre société avec laquelle nous les avons partagées.<br>
                          <br><strong>9.2. Pour ne plus vendre vos informations personnelles. </strong><br>
                          <br><strong>Nous ne vendons ni ne louons vos informations personnelles à des tiers, à quelque fin que ce soit. Nous ne vendons pas vos informations personnelles pour une contrepartie monétaire.<br>
                            Toutefois, dans certaines circonstances, un transfert d'informations personnelles à un tiers, ou au sein de notre famille de sociétés, sans contrepartie monétaire peut être considéré comme une "vente" en vertu de la loi californienne. Vous êtes le seul propriétaire de vos données personnelles et pouvez demander leur divulgation.</strong><br>
                          <br>Si vous demandez à ne plus vendre vos informations personnelles, nous cesserons de procéder à ces transferts.
                          <br>Veuillez noter que si vous nous demandez d'arrêter de vendre vos données, cela peut avoir un impact sur votre expérience avec nous, et vous ne pourrez peut-être pas participer à certains programmes ou services d'adhésion qui nécessitent l'utilisation de vos informations personnelles pour fonctionner. Mais en aucun cas, nous ne ferons de
                          discrimination à votre encontre pour avoir exercé vos droits.<br>
                          Veuillez également noter que vous avez le droit de nous demander de vous fournir ces informations jusqu'à deux fois par période de douze mois. Lorsque vous faites cette demande, les informations fournies peuvent être limitées aux informations personnelles que nous avons recueillies à votre sujet au cours des 12 mois précédents. <br>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-10" aria-expanded="false">
                      10. Fournisseurs de services
                    </h5>
                    <div id="faq-10" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">Nous pouvons employer des sociétés et des personnes tierces pour faciliter notre service (les "fournisseurs de services"), fournir le service en notre
                          nom, exécuter des services liés au service ou nous aider à analyser la façon dont notre service est utilisé.<br>
                          <br>Ces tiers ont accès à vos données personnelles uniquement pour effectuer ces tâches en notre nom et sont tenus de ne pas les divulguer ou les utiliser à d'autres fins.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-11" aria-expanded="false">
                      11. Analytique
                    </h5>
                    <div id="faq-11" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">Nous pouvons faire appel à des fournisseurs de services tiers pour surveiller et analyser l'utilisation de notre service.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-12" aria-expanded="false">
                      12. Outils CI/CD
                    </h5>
                    <div id="faq-12" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">Nous pouvons faire appel à des fournisseurs de services tiers pour automatiser le processus de développement de notre service.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-13" aria-expanded="false">
                      13. Publicité </h5>
                    <div id="faq-13" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">Nous pouvons faire appel à des fournisseurs de services tiers pour vous montrer des publicités afin de nous aider à soutenir et à maintenir notre service.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-14" aria-expanded="false">
                      14. Remarketing comportemental
                    </h5>
                    <div id="faq-14" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">Nous pouvons utiliser des services de remarketing pour vous faire de la publicité sur des sites Web tiers après votre visite de notre Service. Nous et nos
                          fournisseurs tiers utilisons des cookies pour informer, optimiser et diffuser des publicités en fonction de vos visites passées sur notre Service.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-15" aria-expanded="false">
                      15. Paiements
                    </h5>
                    <div id="faq-15" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">Nous pouvons fournir des produits et/ou des services payants dans le cadre du Service. Dans ce cas, nous utilisons des services tiers pour le traitement des
                          paiements (par exemple, des processeurs de paiement).<br>
                          <br>Nous ne stockons ni ne recueillons les détails de votre carte de paiement. Ces informations sont fournies directement à nos processeurs de paiement tiers dont l'utilisation de
                          vos informations personnelles est régie par leur politique de confidentialité. Ces processeurs de paiement adhèrent aux normes définies par PCI-DSS, gérées par le Conseil des normes
                          de sécurité PCI, qui est un effort conjoint de marques telles que Visa, Mastercard, Les 4 opérateurs téléphoniques du Niger, et les services de transfert d’argent.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-16" aria-expanded="false">
                      16. Liens vers d'autres sites
                    </h5>
                    <div id="faq-16" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">Notre service peut contenir des liens vers d'autres sites qui ne sont pas exploités par nous. Si vous cliquez sur un lien tiers, vous serez dirigé vers le
                          site de ce tiers. Nous vous conseillons vivement de consulter la politique de confidentialité de chaque site que vous visitez.<br>
                          <br>Nous n'avons aucun contrôle et n'assumons aucune responsabilité quant au contenu, aux politiques de confidentialité ou aux pratiques de tout site ou service tiers.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-17" aria-expanded="false">
                      17. La vie privée des enfants
                    </h5>
                    <div id="faq-17" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">Nos services ne sont pas destinés à être utilisés par des enfants de moins de 18 ans ("<strong>enfant</strong>" ou "<strong>enfants</strong>").<br>
                          <br>Nous ne collectons pas sciemment d'informations personnellement identifiables auprès d'enfants de moins de 18 ans. Si vous apprenez qu'un enfant nous a fourni
                          des données personnelles, veuillez nous contacter. Si nous apprenons que nous avons recueilli des données personnelles auprès d'enfants sans vérification du consentement
                          parental, nous prenons des mesures pour supprimer ces informations de nos serveurs.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-18" aria-expanded="false">
                      18. Modifications de la présente politique de confidentialité
                    </h5>
                    <div id="faq-18" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous informerons de toute modification en publiant la nouvelle
                          politique de confidentialité sur cette page.<br>
                          <br>Nous vous en informerons sur notre Service, avant que la modification ne prenne effet et nous mettrons à jour la "date d'entrée en vigueur" en haut de la présente Politique de confidentialité.<br>
                          <br>Nous vous conseillons de consulter régulièrement cette politique de confidentialité pour prendre connaissance des éventuelles modifications. Les modifications apportées à cette politique de
                          confidentialité entrent en vigueur lorsqu'elles sont publiées sur cette page.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-19" aria-expanded="false">
                      19. Nous contacter
                    </h5>
                    <div id="faq-19" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">Si vous avez des questions sur cette politique de confidentialité, veuillez nous contacter par e-mail : <strong>smartpay@dev4smart.net</strong>.<br>
                          <br>Cette politique de confidentialité a été créée par PolicyMaker.io le 2023-07-17 et adaptée le service smartpay.ne.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- politique section end -->

    <app-page-footer></app-page-footer>
</div>
