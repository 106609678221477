<div class="blog-page">
    <app-page-header-shop></app-page-header-shop>
    <!-- Banner section start -->
    <section class="banner features pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8 m-auto">
            <div class="ban-content text-center">
              <h1 style="text-transform:none;">Votre panier</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner section end -->

    <!-- Blog single post section start -->
    <section class="blog-single-post">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <article class="post-item">
              <div class="row">
                <div class="col-12">

                  <div class="tab-content">
                    <div id="benefits-1" class="tab-pane fade in active show">
                      <div class="sidebar">
                        <aside class="widget widget_categories">
                          <table class="table table-hover">
                            <thead>
                              <tr class="bg-dark text-white">
                                <th scope="col">#</th>
                                <th scope="col">Article</th>
                                <th scope="col">Titre</th>
                                <th scope="col">Prix</th>
                                <th scope="col">Qté</th>
                                <th>A</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let produit of panier;let i=index;" id="{{ produit.id }}">
                                <th>{{ i+1 }}</th>
                                <th scope="row">
                                  <img style="height: 70px;width: 70px;position: relative;"  ngSrc="merchant-store/v1/logo/{{ produit.image }}" fill priority  crossorigin="anonymous" class="img-fluid rounded-start" alt="...">
                                </th>
                                <td>{{ produit.name }}</td>
                                <td>{{ produit.price | number:'.0' }} FCFA</td>
                                <td><span>{{ produit.quantity }}<small><i class="fa fa-plus ml-5 text-success" style="cursor: pointer;" (click)="addQuantity(produit)"></i><i class="fa fa-minus ml-5" style="cursor: pointer;" (click)="removeQuantity(produit)"></i></small></span></td>
                                <td><span style="cursor: pointer;" class="" (click)="delete(produit)"><i class="fa fa-trash mt-2 text-danger"></i></span></td>
                              </tr>
                            </tbody>
                          </table>
                          <hr>
                          <a routerLink="/shop"><i class="fa fa-arrow-left mr-2 text-primary"></i> Continuez vos achats</a>
                          <a class="float-right"><i class="fa fa-shopping-cart text-primary"></i> {{ panier.length }} articles</a>
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
          <div class="col-md-4">
            <div class="sidebar">
              <div class="card">
                <div class="card-header">
                  <h5 class="text-center">Total</h5>
                </div>
                <div class="card-body">
                      <span class="d-block text-center"><i class="fa fa-donate mr-2"></i><strong> {{ total | number : '.0' }} FCFA</strong></span>
                </div>
                <div class="card-footer">
                  <button class="btn mt-3 btn-primary w-100" style="height: 45px;"><a href="/page-validation-panier" class="text-white">Passer au paiement &nbsp;<i class="fas fa-arrow-right"></i></a></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Blog single post section end -->
    <app-page-footer></app-page-footer>
  </div>

