import {  Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Product } from 'src/app/models/product';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { LocalService } from 'src/app/services/local.service';
import { StoreService } from 'src/app/services/store.service';
import { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.css'],
})
export class ProductAddComponent implements OnInit {
  errorMessage = '';
  wrongCredentials: boolean = false;
  productForm!: FormGroup;
  data: any = [];
  product!:Product;
  swalOptions: SweetAlertOptions = { icon: 'info' };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private localStore: LocalService,
    private swalService: FlashMessageService,
    private storeService: StoreService
  ) {}
  async onFormSubmit() {
    try {
      this.data = await this.storeService.addProduct(this.productForm.value);
      if (
        this.data &&
        'status' in this.data &&
        'data' in this.data &&
        this.data['status'] == true &&
        this.data['data']
      ) {
        this.router.navigate(['/store/products']).then(()=>{
          this.swalOptions.icon = 'success';
          this.swalOptions.html = 'Le produit à été ajouter avec succés!';
          this.swalService.show(this.swalOptions);
        });
      } else {
        this.swalOptions.icon = 'info';
        this.swalOptions.html = 'La transaction a echoué veuillez ressayer svp!';
        this.swalService.show(this.swalOptions);
        //this.errorMessage = this.data['message'];
      }
    } catch (error) {
      this.swalOptions.icon = 'error';
      this.swalOptions.html = 'Le produit n\'a pas été ajouter!';
      this.swalService.show(this.swalOptions);
      //this.errorMessage = "La transaction a echoué veuillez ressayer svp!";
    }
  }
  ngOnInit() {
    this.productForm = this.formBuilder.group(
      {
        name: ['', Validators.required],
        category: ['', Validators.required],
        description: ['', Validators.required],
        price: [null, Validators.required],
        quantity: [null, Validators.required],
      },
      {
        updateOn: 'change',
      }
    );
  }
}
