import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
declare let $: any;
@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
})
export class UserHeaderComponent implements OnInit {
  user: any = [];
  constructor(
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private authService: AuthService,
  ) {
  }

  async Logout() {
    this.authService.logout();
  }
  Initialisation() {}

  ngOnInit() {
    this.user = this.tokenStorageService.getUser();
    this.user = JSON.parse(this.user);
    //console.log(this.user);
    this.loadScript('../../../assets/admin/js/jquery.min.js');
    this.loadScript('../../../assets/admin/js/bootstrap.bundle.min.js');
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
}
