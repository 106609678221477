import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LocalService } from 'src/app/services/local.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { environment } from 'src/environments/environment';
const PAY_CREDIT_API = '/recharge/v1/init/payments';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { RechargeService } from 'src/app/services/recharge.service';
import { SweetAlertOptions } from 'sweetalert2';
import { FlashMessageService } from 'src/app/services/flash-message.service';
declare let $: any;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
const VALIDATION_OTP_API = '/recharge/v1/secure/validate-w2c';
const RECHARGE_API = '/recharge/v1/wallet/c2s';
@Component({
  selector: 'app-user-recharge-confirme-mobilemoney',
  templateUrl: './user-recharge-confirme-mobilemoney.component.html',
  styleUrls: ['./user-recharge-confirme-mobilemoney.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRechargeConfirmeMobilemoneyComponent {
  balance!: number;
  user: any = [];
  userData: any = [];
  montant!: any;
  pm: any = 'MOBILEMONEY';
  pf: number = 0;
  total!: number;

  // =================OTP=======================
  validateCode = true;
  codePattern?: string;
  otp!: string;
  msisdn!: string | null;
  amount!: number;
  data: any = [];
  c2sData: any = [];
  pushUSSDVerificationData: any = [];
  rechargeForm!: FormGroup;
  isWrongOtp = false;
  isValidOtp = false;
  errorMessage!: string;
  swalOptions: SweetAlertOptions = { icon: 'info' };
  // ==============================
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private http: HttpClient,
    private authService: AuthService,
    private localStore: LocalService,
    private rechargeService: RechargeService,
    private swalService: FlashMessageService
  ) {}
  async confirmation() {
    try {
      this.data = JSON.parse(this.localStore.getData('transaction-recharge-init'));
      console.log(this.data['data']);
      if (
        this.data &&
        'status' in this.data &&
        'data' in this.data &&
        this.data['status'] == true &&
        this.data['data']
      ) {
        //verifier si le push USSD à été valider
        this.pushUSSDVerificationData = await this.rechargeService.pinValidation(
          this.data['data']['transaction']['transaction_id']
        );
        if (this.pushUSSDVerificationData && 'status' in this.pushUSSDVerificationData && this.pushUSSDVerificationData['status']==true) {
          this.c2sData = await this.rechargeService.c2s(
            this.data['data']['transaction']['transaction_id'],
            this.localStore.getData('recharge_msisdn')
          );
          this.localStore.removeData('transition-recharge-c2s');
          this.localStore.saveData(
            'transition-recharge-c2s',
            JSON.stringify(this.c2sData)
          );
          this.router.navigate(['/recharge/statut']);
        } else {
          this.swalOptions.icon = 'info';
          this.swalOptions.html =
            "Le push USSD n'a pas été valider depuit le Téléphone, veuillez ressayer svp...";
          this.swalService.show(this.swalOptions);
        }

      } else {
        this.swalOptions.icon = 'info';
        this.swalOptions.html =
          'La transaction a echoué veuillez ressayer svp!';
        this.swalService.show(this.swalOptions);
        //this.errorMessage = this.data['message'];
      }
    } catch (error) {
      this.swalOptions.icon = 'info';
      this.swalOptions.html = 'La transaction a echoué veuillez ressayer svp!';
      this.swalService.show(this.swalOptions);
    }
  }
  ngOnInit() {
    this.userData = this.tokenStorageService.getUser();

    this.msisdn = this.localStore.getData('recharge_msisdn');
    this.pm = this.localStore.getData('payment_method');
    this.montant = this.localStore.getData('recharge_amount');
    if (
      !this.userData ||
      !this.msisdn ||
      !this.pm ||
      !this.montant ||
      !this.localStore.getData('transaction-recharge-init')
    ) {
      this.router.navigateByUrl('/recharge');
    }
    this.user = JSON.parse(this.userData);
    this.balance = this.user.wallet.balance;
    this.amount = parseInt(this.montant);
    this.pf = 0;
    this.total = this.amount + this.pf;
  }
}
