import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { UserData } from './user/user-data';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor() { }

  getUser(): User{
    return UserData;
  }
}
