import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalService } from './local.service';
const TRANSFER_API = '/wallet/v1/secure/transfer';
@Injectable({
  providedIn: 'root',
})
export class TransferService {
  transferData: any;
  constructor(private http: HttpClient, private localStore: LocalService) {}
  async transfer(amount: number,pin: string,acount_number: string): Promise<any> {
    try {
      const data = await this.http
        .post<any>(
          environment.baseURL + TRANSFER_API,
          {
            account_numberTo: acount_number,
            amount: amount,
            pin: pin,
          },
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.localStore.getData('token'),
            }),
          }
        )
        .toPromise();
      return data;
    } catch (error) {
      // Gérer les erreurs
      return "Une erreur s'est produite :"+ error;
    }
  }
}
