import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalService } from './local.service';
import { environment } from 'src/environments/environment';
import { ProduitPanier } from '../models/produit-panier';

const ADD_PRODUCT_API = '/merchant-store/v1/product/new';
const LIST_PRODUCT_API = '/merchant-store/v1/product/store/';
const LIST_PRODUCT_WT_API = '/merchant-store/v1/product/store-off/';
const EDIT_PRODUCT_API = '/merchant-store/v1/product/patch';
const DELETE_PRODUCT_API = '/merchant-store/v1/product/delete/';
const GET_PRODUCT_API = '/merchant-store/v1/product/';
const UPLOAD_PRODUCT_IMAGE_API = '/merchant-store/v1/product/files';
const UPDATE_STORE_INFO_API = '/merchant-store/v1/new';
const GET_STORE_INFO_API = '/merchant-store/v1/about';
const GET_STORE_ALL_API = '/merchant-store/v1/all';
const GET_ALL_PRODUCTS_API = '/merchant-store/v1/product';
const UPDATE_STORE_LOGO_API = '/merchant-store/v1/upload/logo';
const UPDATE_STORE_BANNER_API = '/merchant-store/v1/upload/banner';

const ADD_ITEM_TO_CART_API = '/cart/v1/shop/add';
const REMOVE_ITEM_TO_CART_API = '/cart/v1/shop/remove/fromcart';
const GET_CART_API = '/cart/v1/shop/cart';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  constructor(private http: HttpClient, private localStore: LocalService) {}

  async updateStoreInfo(credentials: {
    store_name: string;
    address: string;
    phone: number;
    description: string;
  }): Promise<any> {
    try {
      const data = await this.http
        .post<any>(
          environment.baseURL + UPDATE_STORE_INFO_API,
          {
            store_name: credentials.store_name,
            address: credentials.address,
            phone: credentials.phone,
            description: credentials.description,
          },
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.localStore.getData('token'),
            }),
          }
        )
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }

  async addProduct(credentials: {
    name: string;
    price: number;
    quantity: number;
    category: string;
    description: string;
  }): Promise<any> {
    try {
      const data = await this.http
        .post<any>(
          environment.baseURL + ADD_PRODUCT_API,
          {
            name: credentials.name,
            category: credentials.category,
            description: credentials.description,
            price: credentials.price,
            quantity: credentials.quantity,
          },
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.localStore.getData('token'),
            }),
          }
        )
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }

  async editProduct(credentials: {
    product_id: string;
    name: string;
    price: number;
    quantity: number;
    category: string;
    description: string;
  }): Promise<any> {
    try {
      const data = await this.http
        .patch<any>(
          environment.baseURL + EDIT_PRODUCT_API,
          {
            name: credentials.name,
            category: credentials.category,
            description: credentials.description,
            price: credentials.price,
            quantity: credentials.quantity,
            product_id: credentials.product_id,
          },
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.localStore.getData('token'),
            }),
          }
        )
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }

  async getProductByStoreId(id: string): Promise<any> {
    try {
      const data = await this.http
        .get<any>(environment.baseURL + LIST_PRODUCT_API + id, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.localStore.getData('token'),
          }),
        })
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }
  async getProductByStoreIdWT(id: string): Promise<any> {
    try {
      const data = await this.http
        .get<any>(environment.baseURL + LIST_PRODUCT_WT_API + id, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        })
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }
  async getProductById(id: string): Promise<any> {
    try {
      const data = await this.http
        .get<any>(environment.baseURL + GET_PRODUCT_API + id, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.localStore.getData('token'),
          }),
        })
        .toPromise();
      if ('status' in data && 'data' in data) {
        return data['data']['data'];
      } else {
        console.log(data);
        return data;
      }
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }
  async deleteProduct(id: string): Promise<any> {
    try {
      const data = await this.http
        .delete<any>(environment.baseURL + DELETE_PRODUCT_API + id, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.localStore.getData('token'),
          }),
        })
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }

  async uploadProductImage(fd: FormData): Promise<any> {
    try {
      const data = await this.http
        .post<any>(environment.baseURL + UPLOAD_PRODUCT_IMAGE_API, fd, {
          headers: new HttpHeaders({
            enctype: 'multipart/form-data',
            Authorization: 'Bearer ' + this.localStore.getData('token'),
          }),
        })
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }
  async getStores(): Promise<any> {
    try {
      const data = await this.http
        .get<any>(environment.baseURL + GET_STORE_ALL_API, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        })
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }
  async getProducts(): Promise<any> {
    try {
      const data = await this.http
        .get<any>(environment.baseURL + GET_ALL_PRODUCTS_API, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        })
        .toPromise();
      if ('status' in data && 'data' in data) {
        return data['data'];
      } else {
        console.log(data);
        return data;
      }
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }
  async getStoreInfo(): Promise<any> {
    try {
      const data = await this.http
        .get<any>(environment.baseURL + GET_STORE_INFO_API, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.localStore.getData('token'),
          }),
        })
        .toPromise();
      if ('status' in data && 'data' in data) {
        return data['data'];
      } else {
        console.log(data);
        return data;
      }
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }
  async uploadStoreLogo(fd: FormData): Promise<any> {
    try {
      const data = await this.http
        .post<any>(environment.baseURL + UPDATE_STORE_LOGO_API, fd, {
          headers: new HttpHeaders({
            enctype: 'multipart/form-data',
            Authorization: 'Bearer ' + this.localStore.getData('token'),
          }),
        })
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }

  async uploadStoreBanner(fd: FormData): Promise<any> {
    try {
      const data = await this.http
        .post<any>(environment.baseURL + UPDATE_STORE_BANNER_API, fd, {
          headers: new HttpHeaders({
            enctype: 'multipart/form-data',
            Authorization: 'Bearer ' + this.localStore.getData('token'),
          }),
        })
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }

  //====================SHOPPING======================
  async addItemToCart(produit: ProduitPanier): Promise<any> {
    try {
      const data = await this.http
        .post<any>(
          environment.baseURL + ADD_ITEM_TO_CART_API,
          {
            productId: produit.id,
            name: produit.name,
            price: produit.price,
            quantity: 1,
          },
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.localStore.getData('token'),
            }),
          }
        )
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }

  async removeItemToCart(id: string, q: number): Promise<any> {
    try {
      const data = await this.http
        .post<any>(
          environment.baseURL + REMOVE_ITEM_TO_CART_API,
          {
            productId: id,
            quantity: q,
          },
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.localStore.getData('token'),
            }),
          }
        )
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }

  async getCart(): Promise<any> {
    try {
      const data = await this.http
        .get<any>(environment.baseURL + GET_CART_API, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.localStore.getData('token'),
          }),
        })
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }

  async payCart(): Promise<any> {
    try {
      const data = await this.http
        .post<any>(
          environment.baseURL + GET_CART_API,
          {
            amount: {
              currency: 'XOF',
              value: 100.0,
            },
            credit_card: {
              card_number: '1234 5678 9012 3456',
              expiry_date: '12/22',
              cvv: '123',
              customer_name: 'John Doe',
              customer_email: 'johndoe@email.com',
            },
            payment_method: 'MOBILEMONEY', //MOBILEMONEY, SPWALLET
            service: '', //SHOPPING
            mobile_money: {
              msisdn: '22786595753', //22785279103   22786595753
            },
            cartId: '79a6ca10-08fa-46d1-a3ab-279c49503f79',
            wallet: {
              wallet_number: 'SPW-0602-1649-159322',
              pin: '1234',
            },
            merchant_details: {
              merchant_key: '1ccf1920-8c0c-41cd-b499-e34f698ed7bf',
            },
            country: 'NE',
          },
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.localStore.getData('token'),
            }),
          }
        )
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }
}

