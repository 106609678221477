import { Coupon } from '../../models/coupon';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

  var data: any= [];
  var dataStr: string|null;
  dataStr = localStorage.getItem('coupons');
  if(dataStr){
      dataStr = CryptoJS.AES.decrypt(dataStr, environment.key).toString(
        CryptoJS.enc.Utf8
      );
      data=JSON.parse(dataStr);
  }

export const Coupons: Coupon[] = data;
