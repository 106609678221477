<div class="home-two">
    <div class="p-0 m-0">
      <app-page-header></app-page-header>
      <!-- Banner section start -->
      <section class="banner features">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-12">
              <img src="assets/images/enter-otp.png" alt="" style="" class="ml-auto">
            </div>
            <div class="col-lg-9 col-md- col-12 my-auto">
              <div class="ban-content ">
                <h3 class="text-white">Confirmation de code OTP</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Banner section end -->
    </div>
    <div class="my-5">
      <div class="container sl-form-wrap">
        <div class="row">
          <div class="col-md-12">
            <ul class="steps">
              <li><span class="bg-primary text-white">1</span></li>
              <li><span class="bg-primary text-white">2</span></li>
              <li><span class="bg-primary text-white">3</span></li>
              <li><span>4</span></li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9 m-auto card p-5">
            <header class="mb-2">
              <h3 class="text-center">Entrez le code et confirmez</h3>
            </header>
            <form [formGroup]="signupForm" class="sl-form" >
              <div class="container d-flex justify-content-center align-items-center">
                <div class="form-group">
                  <!-- <input  id="Code" formControlName="code" placeholder="entrez le code a 6 chiffre" required> -->
                  <ng-otp-input  id="Code"  (onInputChange)="onOtpChange($any($event))" required  [config]="{length:6, allowNumbersOnly:true}"></ng-otp-input>
                  <div class="text-danger p-0" *ngIf="isWrongOtp">
                    {{ errorMessage }}
                  </div>
                </div>
              </div>

              <div class="d-flex mt-5">
                <p class="notice">Vous avez déjà un compte? <a routerLink="/login" class="text-primary">Se connecter</a></p>
                <button class="btn-sp color-primary float-right ml-auto mr-0 " (click)="onFormSubmit()">
                  <span class="ml-2" id="text">Confirmer</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <app-page-footer></app-page-footer>
  </div>
