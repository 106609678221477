import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { CustomValidators } from '../../inscription/page-signup-customer/custom-validators';
import { ConfirmedValidator } from '../../inscription/page-signup-customer/confirmed.validator';

const SET_NEW_PASSWORD_API="/user/v1/secure/reset-password/newpassword";
declare let $: any;
@Component({
  selector: 'app-page-forgot-password-set-new-password',
  templateUrl: './page-forgot-password-set-new-password.component.html',
  styleUrls: ['./page-forgot-password-set-new-password.component.scss']
})
export class PageForgotPasswordSetNewPasswordComponent implements OnInit {

  forgotPasswordForm!: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  data: any= [];
  errorMessage='';
  otp!: string|null;
  wrongCredentials=false;
  form: any = {};
  specialCharacterPattern: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {
      // redirect to home if already logged in
      if (this.authService.currentUserValue) {
        console.log(this.authService.currentUserValue);
          this.router.navigate(['/']);
      }
  }

  get f() { return this.forgotPasswordForm.controls; }

  async onForgotPasswordFormSubmit() {
    $("#spinner").show();
    $('#text').text("En cours...");
    const request=await new Observable<boolean>(observer=>{
      this.http.post(environment.baseURL+SET_NEW_PASSWORD_API,{
        otp:this.otp,
        password:this.f["password"].value
      },{headers: new HttpHeaders({ 'Content-Type': 'application/json'})}).subscribe(result=>{
        this.data=result;
        console.log(this.data);
        observer.next(true);
        observer.complete();
      },(error:HttpErrorResponse)=>{
        observer.error(false);
        observer.complete();
      })
    });

    request.subscribe(result=>{
      this.wrongCredentials=false;
      $("#spinner").hide();
      $('#text').text("Envoyer");
      if('status' in this.data){
        if(this.data['status']==true){
          sessionStorage.setItem('fp-t','success');
          sessionStorage.setItem('fp-m','Mots de passe modifié');
          this.router.navigateByUrl('/login');
        }else{
          this.wrongCredentials=true;
          this.errorMessage="Votre mots de passe n'as pas été modifier";
          sessionStorage.setItem('fp-t','error');
          sessionStorage.setItem('fp-m','Votre mots de passe n\'as pas été modifier');
          this.router.navigateByUrl('/login');

        }
      }else{
        this.wrongCredentials=true;
        this.errorMessage="Votre mots de passe n'as pas été modifier";
        sessionStorage.setItem('fp-t','error');
        sessionStorage.setItem('fp-m','Votre mots de passe n\'as pas été modifier');
        this.router.navigateByUrl('/login');
      }
    },error=>{
      this.wrongCredentials=true;
      this.errorMessage="Votre mots de passe n'as pas été modifier";
      sessionStorage.setItem('fp-t','error');
      sessionStorage.setItem('fp-m','Votre mots de passe n\'as pas été modifier');
      this.router.navigateByUrl('/login');
    });

    $("#spinner").hide();
    $('#text').text("Envoyer");
  }

  reloadPage() {
    window.location.reload();
  }

  ngOnInit():void {
    this.otp=sessionStorage.getItem('fp-otp');
    if(!this.otp){
        this.router.navigate(['/forgot-password/set-otp']);
    }

    $("#spinner").hide();
    this.forgotPasswordForm = this.formBuilder.group({
      password:["",Validators.compose([
        // 1. Password Field is Required
        Validators.required,
        // 2. check whether the entered password has a number
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        // 3. check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        // 4. check whether the entered password has a lower-case letter
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        // 5. check whether the entered password has a special character
        CustomValidators.patternValidator(this.specialCharacterPattern, { hasSpecialCharacters: true }),
        // 6. Has a minimum length of 8 characters
        Validators.minLength(8)])
      ],
      confirmPassword: ["",Validators.required],
    },{
      validator: ConfirmedValidator('password', 'confirmPassword'),
      //updateOn: 'blur',
    });
  }

}
