import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CouponService } from 'src/app/services/coupons.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { environment } from 'src/environments/environment';
import { LocalService } from 'src/app/services/local.service';
import { SweetAlertOptions } from 'sweetalert2';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { RechargeService } from 'src/app/services/recharge.service';
import { transition } from '@angular/animations';

declare let $: any;
@Component({
  selector: 'app-user-shapshap-montant',
  templateUrl: './user-shapshap-montant.component.html',
  styleUrls: ['./user-shapshap-montant.component.scss'],
})
export class UserShapshapMontantComponent implements OnInit {
  balance!: number;
  user: any;
  userData: any = [];
  data: any = [];
  recharge_phone!: string | null;
  montant!: number;
  pm!: string;
  pf!: number;
  isValidFormSubmitted = true;
  coupons!: any[];
  rechargeForm!: FormGroup;
  wrongNumber: boolean = false;
  errorMessage!: string;
  swalOptions: SweetAlertOptions = { icon: 'info' };
  constructor(
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private localStore: LocalService,
    private swalService: FlashMessageService,
    private rechargeService: RechargeService
  ) {}
  async confirmation(code: string, dialCode: string) {
    let full_number = dialCode + this.rechargeForm.value.phone;
      if (this.montant > this.balance) {
        this.swalOptions.icon = 'info';
        this.swalOptions.html =
          "Vous n'avez pas asser de credit sur votre compte SMARTPAY";
        this.swalService.show(this.swalOptions);
      } else {
        this.localStore.removeData('payment_method');
        this.localStore.saveData('payment_method', 'SPWALLET');
        this.localStore.removeData('shapshap_amount');
        this.localStore.saveData('shapshap_amount', this.montant.toString());
        this.router.navigateByUrl('/shapshap/confirmation');
      }
  }
  reloadCurrentPage() {
    this.router.navigate(['shapshap/montant']).then(() => {
      window.location.reload();
    });
  }
  initialization() {
    var input = <HTMLInputElement>document.querySelector('#phone');
    var errorMap = [
      "Ce numéro n'est pas valide",
      'Code du pays non valide',
      'Numéro trop court',
      'Numéro trop long',
      'Numéro invalide',
    ];

    var intl = (<any>window).intlTelInput(input, {
      autoHideDialCode: false,
      initialCountry: 'ne',
      hiddenInput: 'full_number',
      onlyCountries: ['ne'],
      preferredCountries: ['ne'],
      separateDialCode: true,
      utilsScript: 'assets/plugin/build/js/utils.js',
    });

    // Validate on blur event
    $('#phone').on('blur keyup change countrychange', () => {
      //reset();
      if (input.value.trim()) {
        if (intl.isValidNumber()) {
          this.isValidFormSubmitted = true;
          $('#valid-msg').show();
          $('#error-req').show();
          $('#error-msg').hide();
          $('#submit').prop('disabled', false);
        } else {
          input.classList.add('error');
          this.isValidFormSubmitted = false;
          var errorCode = intl.getValidationError();
          $('#error-msg').html(
            '<i class="fas fa-exclamation-triangle mr-2"></i>' +
              errorMap[errorCode]
          );
          $('#valid-msg').hide();
          $('#error-req').hide();
          $('#error-msg').show();
          $('#submit').prop('disabled', true);
        }
      }
    });
    $('#telephoneDiv').hide();
  }
  ngOnInit() {
    if (!this.localStore.getData('shapshap_msisdn')) {
      this.router.navigateByUrl('/shapshap');
    }
    this.userData = this.tokenStorageService.getUser();
    this.user = JSON.parse(this.userData);
    this.balance = this.user.wallet.balance;

    if (this.balance == null) {
      this.balance = 0;
    }
    this.pm = 'SPWALLET';
    this.pf = 0;
    this.montant = 50;
    this.initialization();
    this.rechargeForm = this.formBuilder.group(
      {
        phone: [null],
        code: [null],
        montant: [this.montant],
      },
      {
        updateOn: 'change',
      }
    );
    this.rechargeForm.controls['montant'].valueChanges.subscribe((value) => {
      this.montant = value;
    });
  }
}
