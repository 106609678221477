import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import {Component, OnInit} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { Coupon } from "src/app/models/coupon";
import { CouponService } from "src/app/services/coupons.service";
import { environment } from "src/environments/environment";
import {Coupons} from '../../../services/coupon/coupons-data';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {gsap} from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import { StoreService } from "src/app/services/store.service";
import { LocalService } from "src/app/services/local.service";
declare let $: any;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
//const base=environment.baseURL;
const base="https://api.smartpay.ne";
const PHONE_VERIFICATION_API="/coupons/v1/secure/cbo";
const MSISDN_VERIFICATION_API="/an/v1/secure/validate-payment";
const CHECK_PAYMENT_API="/an/v1/secure/check-payment";
const MM_RECHARGE_API="/an/v1/secure/c2s";
@Component({
  selector: 'app-page-accueil',
  templateUrl: './page-accueil.component.html',
  styleUrls: ['./page-accueil.component.scss']
})
export class PageAccueilComponent implements OnInit {

  isValidPhoneNumber = false;
  phone!: string;
  //code: string='ne';
  data: any= [];
  check_payment_response: any=[];
  recharge_response: any=[];
  coupons= Coupons;
  pm!: string;
  sp!: string;
  transaction_id!: string;
  montant!: number;
  operator!: string;
  rechargeStep=1;
  country_code: string='ne';
  rechargeForm!: FormGroup;
  full_number!: string;
  wrongNumber: boolean=false;
  errorMessage!: string;
  pcc!: string;
  stores:Array<any>=[];
  constructor(private formBuilder: FormBuilder, private router: Router,private localStore: LocalService,private storeService: StoreService,private http: HttpClient, private couponService: CouponService) { }
  onFormSubmit(sp_vpn: string, sp_vpn1: string) {
    if(this.rechargeStep==1){
      if(sp_vpn=='1'){
        //alert(this.rechargeForm.value.phone);
        $("#spinner").show();
        $('#text').text("rechargement...");
        const request=new Observable<boolean>(observer=>{
          this.http.post(environment.baseURL+PHONE_VERIFICATION_API,{
            msisdn:this.rechargeForm.value.phone
          },{headers: new HttpHeaders({ 'Content-Type': 'application/json'})}).subscribe(result=>{
            sessionStorage.removeItem('front_recharge_phone');
            sessionStorage.setItem('front_recharge_phone', this.rechargeForm.value.phone);
            this.data=result;
            sessionStorage.removeItem('coupons');
            sessionStorage.setItem('coupons', JSON.stringify(result));
            observer.next(true);
            observer.complete();
          },(error:HttpErrorResponse)=>{
            observer.error(false);
            observer.complete();
          })
        });

        request.subscribe(result=>{
          this.wrongNumber=false;
          this.rechargeStep=2;
          this.phone=this.rechargeForm.value.phone;
          $("#phone_number").hide();
          //this.coupons=this.couponService.getListeCoupons();
          this.coupons=this.data;
          //console.log(this.data);
          var test = this.coupons.sort((a:any, b:any) => { if (a.cfa < 0) {return -1; } if (b.cfa < 0 ) {return 1;} return b.cfa - a.cfa });
          //console.log(test[0]['cfa']);
          this.operator=test[0]['operator'];
          this.pm='wallet';
          $("#amount_pm").show();
          $("#spinner").hide();
          $('#text').text("Suivant");
          //this.router.navigateByUrl('/recharge/montant');
          //this.router.navigateByUrl('/inscription/confirmation', { state: { pcc: this.pcc, tel: this.full_number } });
        },error=>{
          this.wrongNumber=true;
          this.errorMessage="une erreur est survenue";
        });
      }
    }else if(this.rechargeStep==2){

      if(this.rechargeForm.value.amount != null){
        if(this.pm=='mobileMoney'){
          this.rechargeStep=3;
          $("#amount_pm").hide();
          $("#mm_pm").show();
        }else if(this.pm=='wallet'){

        }else if(this.pm=='creditCard'){
          Swal.fire(
            'Oops!',
            'ce mode de paiement n\'est pas encore disponible!!!',
            'info'
          );
        }else if(this.pm=='sta'){
          Swal.fire(
            'Oops!',
            'ce mode de paiement n\'est pas encore disponible!!!',
            'info'
          );
        }
      }
    }else if(this.rechargeStep==3){
      if(sp_vpn1=='1'){
        $("#spinner").show();
        $('#text').text("rechargement...");
        const request=new Observable<boolean>(observer=>{
          this.http.post(base+MSISDN_VERIFICATION_API,{
            msisdn:this.rechargeForm.value.msisdn,
            amount:this.rechargeForm.value.amount
          },{headers: new HttpHeaders({ 'Content-Type': 'application/json'})}).subscribe(result=>{
            // sessionStorage.removeItem('transaction_id');
            // sessionStorage.setItem('transaction_id', result['transaction_id']);
            this.data=result;
            this.transaction_id=this.data['transaction_id'];
            observer.next(true);
            observer.complete();
          },(error:HttpErrorResponse)=>{
            observer.error(false);
            observer.complete();
          })
        });

        request.subscribe(result=>{
          this.wrongNumber=false;
          this.rechargeStep=4;
          this.montant=this.rechargeForm.value.amount;
          $("#mm_pm").hide();
          $("#valid_payment").show();
          $("#spinner").hide();
          $('#text').text("Suivant");
        },error=>{
          this.wrongNumber=true;
          this.errorMessage="une erreur est survenue";
        });
      }
    }else if(this.rechargeStep==4){
        $("#spinner").show();
        $('#text').text("rechargement...");
        const request=new Observable<boolean>(observer=>{
          this.http.post(base+CHECK_PAYMENT_API,{
            transaction_id: this.transaction_id,
            msisdn: this.rechargeForm.value.msisdn
          },{headers: new HttpHeaders({ 'Content-Type': 'application/json'})}).subscribe(result=>{
            sessionStorage.removeItem('cs_mm');
            sessionStorage.setItem('cs_mm', JSON.stringify(result));
            this.check_payment_response=result;
            observer.next(true);
            observer.complete();
          },(error:HttpErrorResponse)=>{
            observer.error(false);
            observer.complete();
          })
        });

        request.subscribe(result=>{
          //const resultat=JSON.parse(JSON.stringify(sessionStorage.getItem('cs_mm')));
          if('status' in this.check_payment_response){
            if(this.check_payment_response['status']=='failed'){
              this.wrongNumber=true;
              //console.log(status);
              this.errorMessage=this.check_payment_response['message'];
              $("#spinner").hide();
              $('#text').text("Suivant");
            }else{
              $("#spinner").show();
              $('#text').text("rechargement...");
              this.wrongNumber=false;
              // ===============================================
              const request1=new Observable<boolean>(observer=>{
                this.http.post(base+MM_RECHARGE_API,{
                  msisdn:this.rechargeForm.value.phone,
                  transaction_id:this.transaction_id,
                  amount:this.montant,
                },{headers: new HttpHeaders({ 'Content-Type': 'application/json'})}).subscribe(result1=>{
                  this.recharge_response=result1;
                  observer.next(true);
                  observer.complete();
                },(error1:HttpErrorResponse)=>{
                  observer.error(false);
                  observer.complete();
                  $("#spinner").hide();
                  $('#text').text("Suivant");
                })
              });

              request1.subscribe(result1=>{
                if("status" in this.recharge_response){
                  if(this.recharge_response['status']==true){
                    this.wrongNumber=false;
                    $("#mm_pm").hide();
                    $("#valid_payment").hide();
                    $("#recharge_status").show();
                    this.rechargeStep=5;
                    $("#spinner").hide();
                    $('#text').text("Terminer");
                  }
                }else{
                  this.wrongNumber=true;
                  this.errorMessage="La recharge a echoué!!!";
                  $("#spinner").hide();
                  $('#text').text("Suivant");
                }

              },error1=>{
                this.wrongNumber=true;
                this.errorMessage="une erreur est survenue";
                $("#spinner").hide();
                $('#text').text("Suivant");
              });
              // ==============================================

            }
          }
        },error=>{
          this.wrongNumber=true;
          this.errorMessage="une erreur est survenue";
          $("#spinner").hide();
          $('#text').text("Suivant");
        });
    }else if(this.rechargeStep==5){
      window.location.reload();
    }
  }
  changePaymentMethodTo(paymentMethod: string){
    this.pm=paymentMethod;
    $('.payment-method').removeClass('selected');
    $('#'+this.pm).addClass('selected');
    //console.log(this.pm);
  }

  gsapAnimationFunction(){
    var tl = gsap.timeline();
    var tl2 = gsap.timeline();
    //sequenced one-after-the-other
    tl.from(".image-dev4smart", {duration: 2, x: -100, });
    tl2.from(".title-apropos", {duration: 2, x:100, text: ""})
    gsap.registerPlugin(TextPlugin);
    // gsap.

    gsap.defaults({ease: "none"});

    // ============================================================


    // ============================================================

    const tl1 = gsap.timeline({repeat:-1, repeatDelay:1, repeatRefresh: true ,yoyo:false});
    gsap.to(".welcome", {duration: 3, text:"Bienvenue sur SmartPay !"})
    tl1.to(".welcome-message", {
        duration: 3,
        text: "Agrégateur de recharge et paiement tout en un",

    },4).to(".welcome-message", {
      duration: 1,
      text: "",

    },">1").to(".welcome-message", {
        duration: 2,
        text: "Vous permettant de: ",

    },">").to(".solution", {
    duration: 0.5,
    text: "payer",

    },">").to(".solution", {
      duration: 0.5,
      text: "",

    },">1").to(".solution", {
        duration: 0.5,
        text: "vendre",

    },">").to(".solution", {
      duration: 1,
      text: "",

    },">1").to(".welcome-message", {
      duration: 1,
      text: "",

    },">").to(".welcome-message", {
      duration: 5,
      text: "Ou collecter de l'argent et sans vous déplacer",

    },">").to(".welcome-message", {
      duration: 1,
      text: "",

    },">1").to(".welcome-message", {
      duration: 1,
      text: "au Niger et depuis la diaspora. ",

    },">").to(".welcome-message", {
      duration: 1,
      text: "",

    },">1").to(".welcome-message", {
      duration: 3,
      text: "Nous construisons une infrastructure financière",

    },">").to(".welcome-message", {
      duration: 1,
      text: "",

    },">1").to(".welcome-message", {
      duration: 3,
      text: "Qui facilite le quotidien des citoyens.",

    },">").to(".welcome-message", {
      duration: 1,
      text: "",

    },">1");



    $(".image-dev4smart, .title-apropos").onScreen({
      container: window,
      direction: "vertical",
      doIn: function () {
        tl.restart()
        tl2.restart()
      },
      doOut: function () {
        // console.log("Out");
        // Do something to the matched elements as they get off scren
      }
    });

  }

  initialization() {
    //======================
    // Partners carousel
    //======================
    $(".partners-logo").owlCarousel({
      autoplay: true,
      dots: false,
      items: 6,
      loop: true,
      margin: 60,
      nav: false,
      smartSpeed: 500,
      responsive: {
        0: {
          items: 2,
          margin: 30
        },
        400: {
          items: 3,
          margin: 40
        },
        768: {
          items: 4,
          margin: 50
        },
        992: {
          items: 5,
          margin: 50
        },
        1199: {
          items: 6
        }
      }
    });

    // ============================
    // Button Animation
    // ============================
    // $(".btn").on("mouseenter mousemove",  (e: { pageX: number; pageY: number; }) => {
    //   var parentOffset = $(this).offset(),
    //     relX = e.pageX - parentOffset.left,
    //     relY = e.pageY - parentOffset.top;
    //   $(this).find(".bh").css({top: relY, left: relX});
    //   var origin = relX + "px" + " " + relY + "px";
    // }).on("mouseout",  (e: { pageX: number; pageY: number; }) => {
    //   var parentOffset = $(this).offset(),
    //     relX = e.pageX - parentOffset.left,
    //     relY = e.pageY - parentOffset.top;
    //   $(this).find(".bh").css({top: relY, left: relX});
    // });


    // ========================================pm======================
    // $('.payment-method').on('click', () => {
    //   $('.payment-method').removeClass('selected');
    //   $(this).addClass('selected');

    // })
    // ================================================================


    // ===============================================================phone===========================================
    var input = <HTMLInputElement>document.querySelector("#phone");
    var input_msisdn = <HTMLInputElement>document.querySelector("#msisdn");
    //errorMsg = document.querySelector("#error-msg"),
    //validMsg = document.querySelector("#valid-msg");

    // Error messages based on the code returned from getValidationError
    var errorMap = [ "Ce numéro n'est pas valide", "Code du pays non valide", "Numéro trop court", "Numéro trop long", "Numéro invalide"];


    var intl=(<any>window).intlTelInput(input, {
      // allowDropdown: false,
      autoHideDialCode: false,
      // autoPlaceholder: "off",
      // dropdownContainer: document.body,
      // excludeCountries: ["us"],
      // formatOnDisplay: false,
      //initialCountry: "auto",
      initialCountry: "ne",
      // geoIpLookup: function(callback) {
      //     $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
      //         var countryCode = (resp && resp.country) ? resp.country : "";
      //         callback(countryCode);
      //     });
      // },
      hiddenInput: "full_number",
      //initialCountry: "ne",
      //sessionizedCountries: { 'ne': 'Niger' },
      // nationalMode: false,
      onlyCountries: ['ne'],
      // placeholderNumberType: "MOBILE",
      //preferredCountries: ['ne'],
      separateDialCode: true,
      utilsScript: "assets/plugin/build/js/utils.js",
    });

    var intl2=(<any>window).intlTelInput(input_msisdn, {
      // allowDropdown: false,
      autoHideDialCode: false,
      // autoPlaceholder: "off",
      // dropdownContainer: document.body,
      // excludeCountries: ["us"],
      // formatOnDisplay: false,
      //initialCountry: "auto",
      initialCountry: "ne",
      // geoIpLookup: function(callback) {
      //     $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
      //         var countryCode = (resp && resp.country) ? resp.country : "";
      //         callback(countryCode);
      //     });
      // },
      hiddenInput: "full_number",
      //initialCountry: "ne",
      //sessionizedCountries: { 'ne': 'Niger' },
      // nationalMode: false,
      onlyCountries: ['ne'],
      // placeholderNumberType: "MOBILE",
      //preferredCountries: ['ne'],
      separateDialCode: true,
      utilsScript: "assets/plugin/build/js/utils.js",
    });

    var reset = function() {
      $("#valid-msg").hide();
      $("#error-msg").hide();
    };

    // Validate on blur event
    $("#phone").on("blur keyup change countrychange", function() {
      reset();
      if(input.value.trim()){
          if(intl.isValidNumber()){
              //validMsg.classList.remove("hide");
              $("#sp_vpn").val(1);
              $("#valid-msg").show();
              $("#error-req").show();
              $("#error-msg").hide();
              $('#submit').prop('disabled', false);
          }else{
            $("#sp_vpn").val(0);
              input.classList.add("error");
              var errorCode = intl.getValidationError();
              $("#error-msg").html('<i class="fas fa-exclamation-triangle mr-2"></i>'+errorMap[errorCode]);
              $("#valid-msg").hide();
              $("#error-req").hide();
              $("#error-msg").show();
              $('#submit').prop('disabled', true);
          }
      }
    });

    $("#msisdn").on("blur keyup change countrychange", function() {
      reset();
      if(input_msisdn.value.trim()){
          if(intl2.isValidNumber()){
              //validMsg.classList.remove("hide");
              $("#sp_vpn1").val(1);
              $("#valid-msg").show();
              $("#error-req").show();
              $("#error-msg").hide();
              $('#submit').prop('disabled', false);
          }else{
            $("#sp_vpn1").val(0);
              input_msisdn.classList.add("error");
              var errorCode = intl.getValidationError();
              $("#error-msg").html('<i class="fas fa-exclamation-triangle mr-2"></i>'+errorMap[errorCode]);
              $("#valid-msg").hide();
              $("#error-req").hide();
              $("#error-msg").show();
              $('#submit').prop('disabled', true);
          }
      }
    });

    // Reset on keyup/change event
    input.addEventListener('change', reset);
    input.addEventListener('keyup', reset);
    // Reset on keyup/change event
    input_msisdn.addEventListener('change', reset);
    input_msisdn.addEventListener('keyup', reset);

    // $("#phone").on("blur keyup change countrychange", function() {
    //   let getCode = intl.s['iso2'];
    //   let dialcode= intl.s['dialCode'];
    //   sessionStorage.setItem('country_code',getCode.toUpperCase( ));
    //   $("#code").val(getCode);//You will get country code only
    //   $("#dialcode").val(dialcode);
    // });

    // =======================IP-TV======================
    /* $('.ip-tv').on('click', () => {
      $('.ip-tv').removeClass('active');
      $(this).addClass('active');
  }) */
  }

  async getStores(){
    try {
      const stores_req = await this.storeService.getStores();
      if ('data' in stores_req) {
        this.stores=stores_req['data'];
        this.localStore.saveData('stores',JSON.stringify(this.stores));
      }
    } catch (error) {

    }
  }
  changeShopTo(ipTv: string){
    this.sp=ipTv;
    $('.ip-tv').removeClass('active');
    $('#'+ipTv).addClass('active');
    // console.log(this.sp);
  }

  ngOnInit() {
    this.getStores();
    this.initialization();
    this.gsapAnimationFunction();
    this.rechargeStep=1;
    this.pm='wallet';
    this.rechargeForm = this.formBuilder.group({
        phone: [null],
        code: [null],
        sp_vpn:["0"],
        amount:[null],
        msisdn:[null],
        sp_vpn1:["0"],
      },{
        updateOn: 'blur'
    });
    $("#amount_pm").hide();
    $("#mm_pm").hide();
    $("#spinner").hide();
    $("#recharge_status").hide();
    $("#valid_payment").hide();
    $("#valid-msg").hide();
    $("#error-msg").hide();
    $('#submit').prop('disabled', true);
  }

}
