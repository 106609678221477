import { Injectable } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { UserService } from './user.service';
import { isEmpty } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LoadPermissionsService {
  user: any = [];
  permissions: any = [];
  constructor(
    private permissionsService: NgxPermissionsService,
    private roleService: NgxRolesService,
    private userService: UserService
  ) {}
  public loadUserPermissions() {
    this.roleService.flushRoles();
    this.user = this.userService.getUser();
    let permissions: string[]=[];
    if(this.user){
      permissions = ['SETTING', 'DASHBOARD', 'PROFILE'];

      if (this.user.role == 'admin') {
        // Or use your own function/service to validate role
        permissions.push('CICO');
        permissions.push('CROWD');
        permissions.push('RECHARGE');
        permissions.push('SHAPSHAP');
        permissions.push('RESTAURANT');
        permissions.push('COLLECT');
        permissions.push('STORE');
        permissions.push('COUPON');
        // this.permissionsService.loadPermissions(permissions);
        // this.roleService.addRoles({
        //   ADMIN: permissions,
        // });
      } else {
        if (this.user.role == 'partner') {
          if (this.user.merchantCico != null) {
            if (this.user.merchantCico._cico) {
              permissions.push('CICO');
            }
          }

          if (this.user.merchantCrowd != null) {
            if (this.user.merchantCrowd.isRaising) {
              permissions.push('CROWD');
            }
          }

          if (this.user.merchantCollect != null) {
            if (this.user.merchantCollect._collected) {
              permissions.push('COLLECT');
            }
          }

          if (this.user.merchantRecharge != null) {
            if (this.user.merchantRecharge.isRecharge) {
              permissions.push('SHAPSHAP');
            }
          }

          if (this.user.merchantRestaurant != null) {
            if (this.user.merchantRestaurant.isRestaurant) {
              permissions.push('RESTAURANT');
            }
          }

          if (this.user.merchantStore != null) {
            if (this.user.merchantStore.isStore) {
              permissions.push('STORE');
            }
          }
          // this.permissionsService.loadPermissions(permissions);
          // this.roleService.addRoles({
          //   PARTNER: permissions,
          // });
        } else {
          if (this.user.role == 'customer') {
            // this.permissionsService.loadPermissions(permissions);
            // this.roleService.addRoles({
            //   CUSTOMER: permissions,
            //   //'PARTNER': this.permissions
            // });
            permissions.push('RECHARGE');
          }
        }
      }

      //this.permissionsService.loadPermissions(permissions);
      //console.log('permission:-----------------' , permissions);
      // this.permissionsService.addPermission('CICO', ("isCico", this.user.merchantCico) => {
      //     return !!this.user.merchantCico["isCico"];
      // });
      let data = { role: this.user.role, permissions: permissions };
      return data;
    }else{
      return null;
    }

  }
}
