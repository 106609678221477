<!-- Document Wrapper  -->
<div id="main-wrapper">
    <app-user-header></app-user-header>

    <app-user-profilebar></app-user-profilebar>


    <!-- Admin Content Section  -->
    <div id="content" class="py-4">
        <div class="container">
            <div class="row">
                  <div class="col-lg-3" >
                    <app-user-left-menu></app-user-left-menu>
                  </div>
                  <!-- Middle Panel  -->
                  <div class="col-lg-9">
                      <app-store-navbar></app-store-navbar>
                      <div class="infoItems tab-content py-4" id="pills-tabContent">
                        <div id="menu1" class="">
                          <div id="carouselExampleIndicator" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                              <div class="carousel-item active">
                                <img class="d-block w-100" src="assets/images/blog/1.jpg" alt="First slide">
                              </div>
                              <div class="carousel-item">
                                <img class="d-block w-100" src="assets/images/blog/1.jpg" alt="Second slide">
                              </div>
                              <div class="carousel-item">
                                <img class="d-block w-100" src="assets/images/blog/1.jpg" alt="Third slide">
                              </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                          <div class="row">
                              <p class="col-sm-6 my-auto"><b>Logo</b></p>
                              <p class="col-sm-6 d-flex">
                                <img class="rounded-circle " style="width:70px; height:70px; " *ngIf="store.logo == null" src="assets/images/1.jpg" >
                                <img class="rounded-circle " style="width:70px; height:70px; " *ngIf="store.logo != null"  width="70" height="70" ngSrc="merchant-store/v1/logo/{{ store.logo.id }}" priority crossorigin="anonymous">
                                <span class="float-right mt-3 p-2">
                                  <form>
                                    <input type="file" name="file" id="file" class="inputfile" accept="image/png, image/jpeg" (change)="onFileSelected($event)"/>
                                    <label for="file"><i class="fa fa-upload mr-1"></i>Choisir une image</label>
                                  </form>
                                </span>
                              </p>
                          </div>
                          <hr>
                          <div class="row">
                              <p class="col-sm-6"><b>Banniere</b></p>
                              <form class="col-sm-6 d-flex">
                                <input type="file" id="banner" class="inputfile" accept="image/png, image/jpeg" (change)="onBannerSelected($event)" multiple/>
                                <label for="file"><i class="fa fa-upload mr-1"></i>Choisir une image</label>
                              </form>
                          </div>
                          <hr>
                          <div class="row">
                              <p class="col-sm-6"><b>Nom de la boutique</b></p>
                              <p class="col-sm-6 text-uppercase">{{ store.name }}</p>
                          </div>
                          <hr>
                          <div class="row">
                              <p class="col-sm-6"><b>Téléphone</b></p>
                              <p class="col-sm-6 text-uppercase">{{ store.phone }}</p>
                          </div>
                          <hr>
                          <div class="row">
                              <p class="col-sm-6"><b>Adresse</b></p>
                              <p class="col-sm-6">{{ store.address }}</p>
                          </div>
                          <hr>
                          <div class="row">
                              <p class="col-sm-12"><b>Description</b></p>
                              <p class="col-sm-12">{{ store.description }}</p>
                          </div>
                        </div>
                      </div>
                    <a class="float-right mt-2" href="#" data-toggle="modal" data-target="#change_pin"><i class="fas fa-edit mr-2"></i> Modifier</a>
                  </div>
            </div>
        </div>
    </div>
    <!-- Modal -->
    <div class="modal fade step-secourity" id="change_pin" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog  modal-dialog-centered " role="document">
          <div class="modal-content">
              <div class="modal-header bg-dark">
                  <h5 class="modal-title text-primary" id="exampleModalLabel">
                      <p>Mettre a jour les informations de la boutique</p>
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                    <form [formGroup]="storeForm">
                        <div class="input-group">
                            <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><i class="fas fa-building"></i></span>
                            </div>
                            <input type="text" formControlName="store_name" #store_name class="form-control" placeholder="Nom de la Boutique" aria-label="store_name" aria-describedby="basic-addon1">
                        </div>
                        <div style="font-size:10px;" class="p-0" *ngIf="storeForm.controls['store_name'].invalid && (storeForm.controls['store_name'].dirty || storeForm.controls['store_name'].touched)">
                            <div class="text-danger p-0" *ngIf="storeForm.controls['store_name'].hasError('required')">
                              Le nom de la boutique est requis
                            </div>
                        </div>
                        <!-- ============================PHONE================================ -->
                        <div class="input-group mt-3">
                            <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                            </div>
                            <input type="text" formControlName="phone" #phone class="form-control" placeholder="Téléphone" aria-label="phone" aria-describedby="basic-addon1">
                        </div>

                        <div style="font-size:10px;" class="p-0" *ngIf="storeForm.controls['phone'].invalid && (storeForm.controls['phone'].dirty || storeForm.controls['phone'].touched)">
                            <div class="text-danger p-0" *ngIf="storeForm.controls['phone'].hasError('required')">
                                Le numéro de téléphone est requis
                            </div>
                        </div>
                        <!-- ============================ADRESSE================================ -->
                        <div class="input-group mt-3">
                            <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><i class="fas fa-map-marker"></i></span>
                            </div>
                            <input type="text" formControlName="address" #address class="form-control" placeholder="adresse" aria-label="address" aria-describedby="basic-addon1">
                        </div>
                        <div style="font-size:10px;" class="p-0" *ngIf="storeForm.controls['address'].invalid && (storeForm.controls['address'].dirty || storeForm.controls['address'].touched)">
                            <div class="text-danger p-0" *ngIf="storeForm.controls['address'].hasError('required')">
                                L'adresse est requise
                            </div>
                        </div>
                        <!-- ============================DESCRIPTION================================ -->
                        <div class="mt-3">
                            <textarea type="text" formControlName="description" #description class="form-control" placeholder="description de la boutique" aria-label="description" aria-describedby="basic-addon1"></textarea>
                        </div>
                        <div style="font-size:10px;" class="p-0" *ngIf="storeForm.controls['description'].invalid && (storeForm.controls['description'].dirty || storeForm.controls['description'].touched)">
                            <div class="text-danger p-0" *ngIf="storeForm.controls['description'].hasError('required')">
                                La description est requise
                            </div>
                        </div>
                    </form>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary btn-modal-close" data-dismiss="modal">Fermer</button>
                  <button type="submit" (click)="onFormSubmit()" [disabled]="storeForm.invalid" class="btn btn-primary">Modifier</button>
              </div>
          </div>
      </div>
    </div>
    <!-- Content end -->
    <app-page-footer></app-page-footer>
</div>
<!-- Document Wrapper end -->
