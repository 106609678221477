<!-- Document Wrapper  -->
<div id="main-wrapper">
    <app-user-header></app-user-header>

    <app-user-profilebar></app-user-profilebar>


    <!-- Admin Content Section  -->
    <div id="content" class="py-4">
        <div class="container">
            <div class="row">
                  <div class="col-lg-3" >
                  <app-user-left-menu></app-user-left-menu>
                  </div>
                  <!-- Middle Panel  -->
                  <div class="col-lg-9">
                      <div class="profile-content">
                          <h3 class="admin-heading bg-dark text-white"><p>Recharge</p></h3>
                          <ul class="nav nav-pills">
                            <li class="nav-item">
                                <a class="nav-link active">recharge</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link">Montant</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link">Confirmation</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link">Statut</a>
                            </li>
                          </ul>
                          <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                  <form [formGroup]="rechargeForm" class="sl-form">
                                      <div class="d-flex justify-content-center align-items-center">
                                          <div class="form-group">
                                              <div class="text-center mb-4">
                                                  <h3 class="text-5 msg-header">Recharger le mobile d'un proche</h3>
                                                  <p class="text-4 text-center">Le transfert de crédit mobile international est un moyen simple d’offrir du crédit et de maintenir le contact avec vos proches résidant à l'étranger.</p>
                                              </div>
                                              <!-- <label for="phone">Numéro de téléphone</label> -->
                                              <input type="tel" id="phone" formControlName="phone" value="" class="form-control" placeholder="numéro de téléphone">
                                              <input type="hidden" id="code" name="code" #code value="ne">
                                              <input type="hidden" id="dialcode" name="dialcode" #dialcode value="227">
                                              <div class="" *ngIf="!wrongNumber && (rechargeForm.controls['phone'].dirty || rechargeForm.controls['phone'].touched)">
                                                  <span id="valid-msg" class="text-success"><i class="fa fa-check mr-2"></i> Numéro valide</span>
                                              </div>
                                              <span id="error-msg" class="text-danger"></span>
                                              <div class="" *ngIf="wrongNumber">
                                                  <span id="error-req" class="text-danger"><i class="fas fa-exclamation-triangle mr-2"></i>{{ errorMessage }}</span>
                                              </div>
                                          </div>
                                      </div>
                                      <!-- <div class="d-flex my-2">
                                      <p class="notice">Vous avez déja un compte? <a routerLink="/login" class="text-primary">Se connecter</a></p>
                                      <button id="submit" class="btn-sp color-primary float-right ml-auto mr-0"  (click)="onFormSubmit(code.value,dialcode.value)" [disabled]="!rechargeForm.valid">Envoyer</button>
                                      </div> -->
                                      <ul class="pager mt-4">
                                          <li>&nbsp;</li>
                                          <li>
                                              <button type="submit" class="btn btn-sp color-primary mr-0" (click)="onFormSubmit(code.value,dialcode.value)">
                                                      Continuer <i class="fas fa-chevron-right"></i>
                                              </button>

                                          </li>
                                      </ul>
                                  </form>
                            </div>

                        </div>
                      </div>

                  </div>
                  <!-- Middle Panel End -->
            </div>
        </div>
    </div>
    <!-- Content end -->

    <app-page-footer></app-page-footer>
  </div>
  <!-- Document Wrapper end -->

