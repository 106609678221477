<div class="home-one">
    <app-page-header></app-page-header>
    <!-- Banner section start -->
    <section class="banner v1 features">
      <div class="container mb-5">
        <div class="row">
          <div class="col-md-8 my-auto">
            <div class="ban-content">
              <h2 class="text-white text-justify" style="text-transform: none;">Nous construisons une infrastructure financière qui facilite le quotidien</h2>
            </div>
          </div>
          <div class="col-md-4">
            <img src="assets/images/infrastructure.png" alt="" class="">
          </div>
        </div>
      </div>
    </section>
    <!-- Banner section end -->


    <!-- Overview section start -->
    <section class="overview">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-5">
            <div class="content-box">
              <h2>Pourquoi SmartPay ?</h2>
              <p class="text-justify">
                Le succès du mobile money au Kenya a sorti près d’un million de personnes de la pauvreté. Pourtant, plus de 10 ans plus tard, la plupart des Africains n’ont toujours pas accès à des outils d’épargne, de transfert ou de crédit abordables qui leur permettraient d’entreprendre et assurer les besoins de leurs familles.
              </p>
            </div>
          </div>
          <div class="col-md-6 offset-md-1">
            <span class="tagline bar">Nos Valeurs</span>
            <div class="row">
              <div class="col-sm-6">
                <div class="iconBox mb100 d-flex">
                  <i class="fa fa-check mr-2 text-primary"></i>
                  <h6 style="text-transform: none;">Notre mission passe avant nos égos</h6>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="iconBox mb100 d-flex">
                  <i class="fa fa-check mr-2 text-primary"></i>

                  <h6 style="text-transform: none;">Nos clients sont notre priorité</h6>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="iconBox d-flex">
                  <i class="fa fa-check mr-2 text-primary"></i>

                  <h6 style="text-transform: none;">Nous sommes d’une franchise absolue </h6>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="iconBox d-flex">
                  <i class="fa fa-check mr-2 text-primary"></i>

                  <h6 style="text-transform: none;">Nous maximisons nos capacités d'apprentissage</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Overview section end -->

    <div class="bg-light">
      <div class="container ">
        <div class="row">
          <div class="col-md-4">
            <img src="assets/images/services.png" alt="" class="">
          </div>
          <div class="col-md-8 my-auto">
              <span class="tagline bar">Une révolution de services financiers qui commence par l’Afrique</span>
              <p class="text-justify">
                SmartPay apporte une solution au problème en utilisant la technologie pour bâtir un réseau financier radicalement inclusif et extrêmement abordable. Chaque jour nous relevons de nouveaux challenges pour nos utilisateurs qui batissent l’économie de demain!
              </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Home send money section start -->
    <section class="home-send-money bg-offwhite">
      <div class="container">
        <div class="row align-items-md-center">
          <div class="col-md-5 nopadd-rth">
            <figure class="recv-img">
              <img src="assets/images/mission.png" alt="" class="rcvMobile" style="position: relative;">
            </figure>
          </div>
          <div class="col-md-6 offset-md-1">
            <div class="content-box">
              <span class="tagline bar">MISSION</span>
              <h2>Notre mission, faire de l’Afrique le premier continent cashless du monde</h2>
              <p>Sur un continent où plus de la moitié de la population n’a pas de compte bancaire, SmartPay offre un réseau de services financiers sans frais de gestion de compte, disponible instantanément et partout !</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Home send money section end -->

    <!-- Features section start -->
    <section class="features">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-8 col-md-9 m-auto">
            <div class="sec-heading text-center">
              <h2 class="sec-title" style="text-transform: none;">Nous rassemblons tout en un seul endroit</h2>
              <p class="sec-subtitle">
                SmartPay est un agrégateur de recharge électronique et paiement en ligne tout en un. Il permet aux particuliers et entreprises d'effectuer des opérations financières d'achat, vente, encaissement et paiement à partir de leurs portefeuilles externes (Mobile Money, VISA & Mastercard, Services de Transfert d'Argent, etc.).
              </p>
            </div>
          </div>
        </div>
        <div class="features-scrns">
          <figure class="scrn-1"><img src="assets/images/computer.png" alt="" ></figure>
          <figure class="scrn-2" ><img src="assets/images/man.png" alt=""></figure>
          <figure class="scrn-3"><img src="assets/images/white-phone.png" alt="" style="width: 500px;"></figure>
        </div>
      </div>
    </section>
    <!-- Features section end -->

    <!-- speciality section start -->
    <section class="partners bg-offwhite">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-10 m-auto">
            <div class="sec-heading">
              <span class="tagline">Nos Partenaires</span>
              <h2 class="sec-title">Ils nous font confiance</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="partners-logo owl-carousel">
              <a href="javascript:void(0)"><img src="assets/images/partners/1.png" alt=""></a>
              <a href="javascript:void(0)"><img src="assets/images/partners/2.png" alt=""></a>
              <a href="javascript:void(0)"><img src="assets/images/partners/3.png" alt=""></a>
              <a href="javascript:void(0)"><img src="assets/images/partners/4.png" alt=""></a>
            <!--  <a href="javascript:void(0)"><img src="assets/images/partners/5.png" alt=""></a>
              <a href="javascript:void(0)"><img src="assets/images/partners/6.png" alt=""></a>-->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- speciality section end -->

    <app-page-footer></app-page-footer>
  </div>

