import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-left-menu',
  templateUrl: './user-left-menu.component.html',
  styleUrls: ['./user-left-menu.component.scss']
})
export class UserLeftMenuComponent implements OnInit {
  constructor(private router: Router) { }
  // reloadCurrentPage() {
  //   this.router.navigate(['recharge'])
  //   .then(() => {
  //     window.location.reload();
  //   });
  // }
  ngOnInit() {
  }
}
