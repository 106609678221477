<!-- Document Wrapper  -->
<div id="main-wrapper">
    <app-user-header></app-user-header>
    
    <app-user-profilebar></app-user-profilebar>
    
  
    <!-- Admin Content Section  -->
    <div id="content" class="py-4">
        <div class="container">
            <div class="row">
                  <div class="col-lg-3">
                  <app-user-left-menu></app-user-left-menu>
                  </div>
                 <!-- Middle Panel -->
                 <div class="col-lg-9 profile-area">
                  <h3 class="admin-heading bg-offwhite">
                      <a href="#edit-personal-details" class="btn-link pbtn" data-id="edit-personal-details"><i class="fas fa-edit mr-1"></i>Update</a>
                      <p>Payments Contats</p>
                      <span>Your contact information</span>
                  </h3>
           
                  <!-- Edit personal info End -->
                  <div class="infoItems shadow">
                      <ul class="nav nav-tabs">
                          <li><a data-toggle="tab" href="#menu1" class="active">Paying To</a></li>
  
                          <li><a data-toggle="tab" href="#menu2">Receiving from</a></li>
                      </ul>
  
                      <div class="tab-content">
                          <div id="menu1" class="tab-pane fade in active">
                              <div class="row">
                                  <div class="col-12 col-sm-6 col-lg-4 cardBox">
                                      <div class="account-card account-card-primary">
                                          <p class="pirotry text-right bg-success">Active</p>
                                          <div class="row">
                                              <h3 class="card-number col-12">James Smith</h3>
                                              <p class="card-number col-12">jamessmith@roysha.com</p>
                                          </div>
                                          <div class="row">
                                              <div class="col-6">
                                                  <a href="#" class="btn-contact">Pay</a>
                                              </div>
                                              <div class="col-6">
                                                  <i class="fas fa-university"></i>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-sm-6 col-lg-4 cardBox">
                                      <div class="account-card account-card-primary">
                                          <p class="pirotry text-right bg-success">Active</p>
                                          <div class="row">
                                              <h3 class="card-number col-12">Michael Smith</h3>
                                              <p class="card-number col-12">michaelsmith@gmail.com</p>
                                          </div>
                                          <div class="row">
                                              <div class="col-6">
                                                  <a href="#" class="btn-contact">Pay</a>
                                              </div>
                                              <div class="col-6">
                                                      <i class="far fa-user-circle"></i>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-sm-6 col-lg-4 cardBox">
                                      <div class="account-card account-card-primary">
                                          <p class="pirotry text-right bg-success">Active</p>
                                          <div class="row">
                                              <h3 class="card-number col-12">Robert Smith</h3>
                                              <p class="card-number col-12">jamessmith@roysha.com</p>
                                          </div>
                                          <div class="row">
                                              <div class="col-6">
                                                  <a href="#" class="btn-contact">Pay</a>
                                              </div>
                                              <div class="col-6">
                                                  <i class="fas fa-university"></i>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-sm-6 col-lg-4 cardBox">
                                      <div class="account-card account-card-primary">
                                          <p class="pirotry text-right bg-success">Active</p>
                                          <div class="row">
                                              <h3 class="card-number col-12">Maria Garcia</h3>
                                              <p class="card-number col-12">jamessmith@roysha.com</p>
                                          </div>
                                          <div class="row">
                                              <div class="col-6">
                                                  <a href="#" class="btn-contact">Pay</a>
                                              </div>
                                              <div class="col-6">
                                                      <i class="far fa-user-circle"></i>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-sm-6 col-lg-4 cardBox">
                                      <div class="account-card account-card-primary">
                                          <p class="pirotry text-right bg-success">Active</p>
                                          <div class="row">
                                              <h3 class="card-number col-12">David Smith</h3>
                                              <p class="card-number col-12">jamessmith@roysha.com</p>
                                          </div>
                                          <div class="row">
                                              <div class="col-6">
                                                  <a href="#" class="btn-contact">Pay</a>
                                              </div>
                                              <div class="col-6">
                                                  <i class="fas fa-university"></i>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-sm-6 col-lg-4 cardBox">
                                      <div class="account-card account-card-primary">
                                          <p class="pirotry text-right bg-success">Active</p>
                                          <div class="row">
                                              <h3 class="card-number col-12">Maria Rodriguez</h3>
                                              <p class="card-number col-12">jamessmith@roysha.com</p>
                                          </div>
                                          <div class="row">
                                              <div class="col-6">
                                                  <a href="#" class="btn-contact">Pay</a>
                                              </div>
                                              <div class="col-6">
                                                  <i class="fas fa-university"></i>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <!-- END First Tab -->
                          <div id="menu2" class="tab-pane fade">
                                  <div class="row">
                                          <div class="col-12 col-sm-6 col-lg-4 cardBox">
                                              <div class="account-card account-card-primary">
                                                  <p class="pirotry text-right bg-success">Active</p>
                                                  <div class="row">
                                                      <h3 class="card-number col-12">James Smith</h3>
                                                      <p class="card-number col-12">jamessmith@roysha.com</p>
                                                  </div>
                                                  <div class="row">
                                                      <div class="col-6">
                                                          <a href="#" class="btn-contact">Request</a>
                                                      </div>
                                                      <div class="col-6">
                                                          <i class="fas fa-university"></i>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="col-12 col-sm-6 col-lg-4 cardBox">
                                              <div class="account-card account-card-primary">
                                                  <p class="pirotry text-right bg-success">Active</p>
                                                  <div class="row">
                                                      <h3 class="card-number col-12">Michael Smith</h3>
                                                      <p class="card-number col-12">michaelsmith@gmail.com</p>
                                                  </div>
                                                  <div class="row">
                                                      <div class="col-6">
                                                          <a href="#" class="btn-contact">Request</a>
                                                      </div>
                                                      <div class="col-6">
                                                              <i class="far fa-user-circle"></i>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="col-12 col-sm-6 col-lg-4 cardBox">
                                              <div class="account-card account-card-primary">
                                                  <p class="pirotry text-right bg-success">Active</p>
                                                  <div class="row">
                                                      <h3 class="card-number col-12">Robert Smith</h3>
                                                      <p class="card-number col-12">jamessmith@roysha.com</p>
                                                  </div>
                                                  <div class="row">
                                                      <div class="col-6">
                                                          <a href="#" class="btn-contact">Request</a>
                                                      </div>
                                                      <div class="col-6">
                                                          <i class="fas fa-university"></i>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="col-12 col-sm-6 col-lg-4 cardBox">
                                              <div class="account-card account-card-primary">
                                                  <p class="pirotry text-right bg-success">Active</p>
                                                  <div class="row">
                                                      <h3 class="card-number col-12">Maria Garcia</h3>
                                                      <p class="card-number col-12">jamessmith@roysha.com</p>
                                                  </div>
                                                  <div class="row">
                                                      <div class="col-6">
                                                          <a href="#" class="btn-contact">Request</a>
                                                      </div>
                                                      <div class="col-6">
                                                              <i class="far fa-user-circle"></i>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="col-12 col-sm-6 col-lg-4 cardBox">
                                              <div class="account-card account-card-primary">
                                                  <p class="pirotry text-right bg-success">Active</p>
                                                  <div class="row">
                                                      <h3 class="card-number col-12">David Smith</h3>
                                                      <p class="card-number col-12">jamessmith@roysha.com</p>
                                                  </div>
                                                  <div class="row">
                                                      <div class="col-6">
                                                          <a href="#" class="btn-contact">Request</a>
                                                      </div>
                                                      <div class="col-6">
                                                          <i class="fas fa-university"></i>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="col-12 col-sm-6 col-lg-4 cardBox">
                                              <div class="account-card account-card-primary">
                                                  <p class="pirotry text-right bg-success">Active</p>
                                                  <div class="row">
                                                      <h3 class="card-number col-12">Maria Rodriguez</h3>
                                                      <p class="card-number col-12">jamessmith@roysha.com</p>
                                                  </div>
                                                  <div class="row">
                                                      <div class="col-6">
                                                          <a href="#" class="btn-contact">Request</a>
                                                      </div>
                                                      <div class="col-6">
                                                          <i class="fas fa-university"></i>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                          </div>
                      </div>
  
  
                  </div>
  
  
  
              </div>
              <!-- Middle Panel End -->
            </div>
        </div>
    </div>
    <!-- Content end -->
  
    <app-page-footer></app-page-footer>
  </div>
  <!-- Document Wrapper end -->
  
