import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalService } from '../../../services/local.service';
declare let $: any;
@Component({
  selector: 'app-page-signup-identification',
  templateUrl: './page-signup-identification.component.html',
  styleUrls: ['./page-signup-identification.component.scss'],
})
export class PageSignupIdentificationComponent implements OnInit {
  myForm!: FormGroup;
  isPhone: boolean = true;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private localStore: LocalService
  ) {
    this.myForm = this.formBuilder.group({
      website: this.formBuilder.array([], [Validators.required]),
    });
  }
  onItemChange(event: Event): void {
    if ((event.target as HTMLInputElement).value == 'Phone') {
      this.isPhone = true;
    } else {
      this.isPhone = false;
    }
  }
  onFormSubmit() {
    if(this.isPhone){
      this.localStore.removeData('send-otp-to-user');
      this.localStore.saveData('send-otp-to-user', 'CBP');
      this.router.navigate(['/inscription/set-phone']).then(()=>{
        window.location.reload();
      });
    } else {
      if(this.localStore.getData('account-type') == 'customer') {
        this.localStore.removeData('send-otp-to-user');
        this.localStore.saveData('send-otp-to-user', 'CBE');
        this.router.navigate(['/inscription/set-email']);
      } else {
        this.localStore.removeData('send-otp-to-user');
        this.localStore.saveData('send-otp-to-user', 'COBE');
        this.router.navigate(['/inscription/set-email']);
      }
    }
  }
  initialization() {
    $(document).ready(() => {
      $('#phone').change(() => {
        $('#p_phone').show();
        $('#p_email').hide();
      });

      $('#email').change(() => {
        $('#p_email').show();
        $('#p_phone').hide();
      });
    });
  }

  ngOnInit() {
    if (!this.localStore.getData('account-type') ||
        this.localStore.getData('account-type') == null &&
        (this.localStore.getData('account-type')!="company"||this.localStore.getData('account-type')!="customer")
    ){
      this.router.navigateByUrl('/inscription/account-type');
    }
    this.initialization();
  }
}
