import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-user-profilebar',
  templateUrl: './user-profilebar.component.html',
  styleUrls: ['./user-profilebar.component.scss']
})
export class UserProfilebarComponent implements OnInit {
  currency="CFA";
  balance: any;
  lastLogin: any;
  user: any=[];
  public now: Date = new Date();
  constructor(private router: Router, private tokenStorageService: TokenStorageService) {
    setInterval(() => {
      this.now = new Date();
    }, 1);
  }

  ngOnInit() {
    this.user = this.tokenStorageService.getUser();
    this.user=JSON.parse(this.user);
    this.balance=this.user.wallet.balance;
    if (this.balance == null) {
      this.balance = 0;
    }
    this.lastLogin=this.user.lastLogger;
    // this.loadScript('../../../assets/admin/js/jquery.min.js');
    // this.loadScript('../../../assets/admin/js/bootstrap.bundle.min.js');
  }

}
