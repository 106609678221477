import {Component, OnInit} from "@angular/core";
import { Router } from "@angular/router";
import { ProduitPanier } from "src/app/models/produit-panier";
import { AuthService } from "src/app/services/auth.service";
import { LocalService } from "src/app/services/local.service";
import { StoreService } from "src/app/services/store.service";
import { TokenStorageService } from "src/app/services/token-storage.service";

declare let $: any;

@Component({selector: "app-page-header-shop", templateUrl: "./page-header-shop.component.html", styleUrls: ["./page-header-shop.component.scss"]})
export class PageHeaderShopComponent implements OnInit {
  isLoggedIn = false;
  user: any=[];
  bt!: string;
  items:number=0;
  panier:Array<ProduitPanier>=[];
  data:any=[];
  // showAdminBoard = false;
  // showModeratorBoard = false;
  // username?: string;
  constructor(private router: Router,private localStore: LocalService, private tokenStorageService: TokenStorageService, private authService: AuthService,private storeService: StoreService) {

  }

  changeMenuTo(navB: string){
    this.bt=navB;
    $('.nav-b').removeClass('active');
    $('#'+navB).addClass('active');
    // console.log(this.bt);
  }

  async Logout(){
    await this.authService.logout();
  }

  initialization() {
    //======================
    // Sticky menu
    //======================

    $(window).scroll(function () {
      if ($(window).scrollTop() >= 1) {
        $(".header-main").addClass("fixed-header");
      } else {
        $(".header-main").removeClass("fixed-header");
      }
    });

    //======================
    // Preloder
    //======================
    $(window).on("load", function () {
      $("#status").fadeOut();
      $("#preloader").delay(500).fadeOut("slow");
      $("body").delay(500).css({overflow: "visible"});
    });

    //======================
    // Mobile menu
    //======================
    $("#mobile-menu-toggler").on("click", function (e: { preventDefault: () => void; }) {
      e.preventDefault();
      $(".primary-menu > ul").slideToggle();
    });
    $(".has-menu-child").append('<i class="menu-dropdown fas fa-angle-down"></i>');

    if ($(window).width() <= 991) {
      $(".menu-dropdown").on("click",  () => {
        $(this).prev().slideToggle("slow");
        $(this).toggleClass("fa-angle-down fa-angle-up");
      });
    }
  }

  switchLanguage(language: string) {

  }

  ngOnInit() {
    if (this.localStore.getData('user')) {
      this.user = JSON.parse(this.localStore.getData('user'));
    }
    if (this.localStore.getData('panier')){
      this.items = JSON.parse(this.localStore.getData('panier')).length;
    }
    this.initialization();
    this.isLoggedIn = !!this.tokenStorageService.getToken();
  }
}

