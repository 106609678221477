import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalService } from '../../../services/local.service';
import { SweetAlertOptions } from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { Router } from '@angular/router';

declare let $: any;
@Component({
  selector: 'app-page-signup-set-code',
  templateUrl: './page-signup-set-code.component.html',
  styleUrls: ['./page-signup-set-code.component.scss'],
})
export class PageSignupSetCodeComponent implements OnInit {
  validateCode = true;
  codePattern!: string;
  otp!: string;
  private data: any = [];
  phone!: string | null;
  email!: string | null;
  signupForm!: FormGroup;
  isWrongOtp = false;
  isValidOtp = false;
  errorMessage!: string;
  swalOptions: SweetAlertOptions = { icon: 'info' };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private localStore: LocalService,
    private authService: AuthService,
    private swalService: FlashMessageService
  ) {}

  get f() {
    return this.signupForm.controls;
  }
  async onFormSubmit() {
    if (this.otp.length == 6) {
      try {
        this.data = await this.authService.confirmeOTP(
          this.otp
        );
        if ('status' in this.data) {
          if (this.data['status'] == true) {
            this.localStore.saveData('otp-status', 'valid');
            this.isWrongOtp = false;
            this.isValidOtp = true;
            if (this.localStore.getData('account-type') == 'customer') {
              this.router.navigateByUrl('/inscription/customer');
            } else {
              if (this.localStore.getData('account-type') == 'company') {
                this.router.navigateByUrl('/inscription/company');
              } else {
                this.router.navigateByUrl('/inscription/account-type');
              }
            }
          } else {
            this.localStore.saveData('otp-status', 'invalid');
            this.isWrongOtp = true;
            this.isValidOtp = false;
            if ('message' in this.data) {
              this.errorMessage = this.data['message'];
            } else {
              this.errorMessage = 'une erreur est survenue';
            }
            this.swalOptions.icon = 'info';
            this.swalOptions.html = this.errorMessage;
            this.swalService.show(this.swalOptions);
          }
        } else {
          this.isWrongOtp = true;
          this.isValidOtp = false;
          this.errorMessage = 'une erreur est survenue';
          this.swalOptions.icon = 'info';
          this.swalOptions.html = this.errorMessage;
          this.swalService.show(this.swalOptions);
        }
      } catch (error) {
        if ('message' in this.data) {
          this.errorMessage = this.data['message'];
        } else {
          this.errorMessage = "Une erreur s'est produite :";
        }
        this.swalOptions.icon = 'info';
        this.swalOptions.html = this.errorMessage;
        this.swalService.show(this.swalOptions);
      }
    }
  }

  onOtpChange(otp: string) {
    this.otp = otp.toString();
    if (this.otp.length == 6) {
      this.onFormSubmit();
    }
  }

  ngOnInit() {
    if (this.localStore.getData('account-type') == 'customer') {
      if (!this.localStore.getData('user-phone')) {
        this.router.navigate(['/inscription/set-phone']);
      }
      this.phone = this.localStore.getData('user-phone');
    } else {
      if (this.localStore.getData('account-type') == 'company') {
        if (!this.localStore.getData('user-email')) {
          this.router.navigate(['/inscription/set-email']);
        }
        this.email = this.localStore.getData('user-email');
      } else {
        this.router.navigate(['/inscription/choose-account']);
      }
    }

    // if(typeof this.codePattern == 'undefined'){
    //   this.router.navigate(['/inscription/identification']);
    // }
    //this.phone=history.state.tel;
    this.signupForm = this.formBuilder.group(
      {
        code: ['', [Validators.required]],
      },
      {
        updateOn: 'change',
      }
    );
  }
}
