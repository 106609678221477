import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {Component, OnInit} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { ReCaptchaV3Service } from 'ng-recaptcha';

declare let $: any;
const CONTACT_API='/contact/v1/secure/send'
@Component({selector: "app-page-contact", templateUrl: "./page-contact.component.html", styleUrls: ["./page-contact.component.scss"]})
export class PageContactComponent implements OnInit {
  isValidFormSubmitted = false;
  validateEmail = true;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  email="";
  firstname="";
  lastname="";
  object="";
  message="";
  reCAPTCHAToken: string = "";
  tokenVisible: boolean = false;
  type!: string|null;
  errorMessage!: string;
  private data: any = [];
  signupForm!: FormGroup;
  constructor(private formBuilder: FormBuilder, private router: Router, private http: HttpClient,private recaptchaV3Service: ReCaptchaV3Service) { }
  
  onFormSubmit(email: string, firstname: string, lastname: string, object: string, message: string) { 
    this.email=email;
    this.object=object;
    this.lastname=lastname;
    this.firstname=firstname;
    this.message=message;

    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
        this.tokenVisible = true;
        this.reCAPTCHAToken = `Token [${token}] generated`;
        $("#spinner").show();
        $('#text').text("En cours...");
        const request=new Observable<boolean>(observer=>{
          this.http.post(environment.baseURL+CONTACT_API,{
            email: this.email,
            firstname: this.firstname,
            lastname: this.lastname,
            message: this.message,
            object: this.object
          }).subscribe(result=>{
            this.data=result;
            observer.next(true);
            observer.complete();
          },(error:HttpErrorResponse)=>{
            observer.error(false);
            observer.complete();
          })
        });

        request.subscribe(result=>{
          this.validateEmail=true;
          if(('status' in this.data)){
            if(this.data['status']==true){
              Swal.fire({
                title: 'Fait avec succès.!',
                text: 'votre message a été envoyé avec succès. Merci de nous avoir contactés et nous vous répondrons dans les plus brefs délais, l’équipe d’assistance SmartPay. ',
                icon: 'success',
                confirmButtonText: 'ok'
              });
              this.signupForm.reset();
            }else{
              Swal.fire({
                title: 'Erreur!',
                text: 'votre message n\'a pas été envoyé',
                icon: 'error',
                confirmButtonText: 'ok'
              })
            }
          }else{
            Swal.fire({
              title: 'Erreur!',
              text: 'votre message n\'a pas été envoyé',
              icon: 'error',
              confirmButtonText: 'ok'
            })
          }
          $("#spinner").hide();
          $('#text').text("Envoyer");
        },error=>{
          Swal.fire({
            title: 'Erreur!',
            text: 'votre message n\'a pas été envoyé',
            icon: 'error',
            confirmButtonText: 'ok'
          })
          $("#spinner").hide();
          $('#text').text("Envoyer");
        })
    });

    
  }

  

  ngOnInit() {
    $("#spinner").hide();
   
    this.signupForm = this.formBuilder.group({
      firstname:[null, [Validators.required]],
      lastname:[null, [Validators.required]],
      object:[null, [Validators.required]],
      message:[null, [Validators.required]],
      email: [null, [Validators.required, Validators.pattern(this.emailPattern)]]
    }, 
    {
      updateOn: 'blur'
    });
  }
}
