<div class="blog-page">
    <app-page-header-shop></app-page-header-shop>
    <!-- Banner section start -->
    <section class="banner features">
      <div class="container">
        <div class="row">
          <!-- <div class="col-md-4">
            <img src="assets/images/boutique.png" alt="" class="">
          </div> -->
          <div class="col-md-12  mb-5">
            <div class="ban-content text-center">
              <h1 style="text-transform:none;">Nos boutiques en ligne</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner section end -->

    <!-- Blog posts section start -->
    <section class="blog-posts">
      <div class="container">
        <div class="row">
          <div class="col-md-6 mb-5" *ngFor="let store of stores
                                  | paginate
                                    : {
                                        itemsPerPage: tableSize,
                                        currentPage: page,
                                        totalItems: count
                                      };
                                  let i = index
                                ">
            <div class="card">
              <div class="row">
                <div class="col-md-4">
                  <img class="h-100" src="assets/images/blog/1.jpg" *ngIf="store.logo == null" alt="Logo">
                  <img class="h-100" *ngIf="store.logo != null" style="max-height: 150px;" fill ngSrc="merchant-store/v1/logo/{{ store.logo.id }}" priority crossorigin="anonymous" alt="Logo">
                </div>
                <div class="col-md-8">
                  <div class="pr-4">
                    <h5 class="card-title text-uppercase mt-3"><i class="fa fa-store mr-2 text-primary"></i>{{ store.name }}</h5>
                    <p class="card-text text">{{ store.description }}</p>
                  </div>
                  <div class="">
                    <small class="mr-4"><i class="fa fa-map-marker text-primary"></i> {{ store.address }}</small>
                    <small class="mr-4"><i class="fa fa-phone-alt text-primary"></i> {{ store.phone }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <pagination-controls class="pagination"
            previousLabel="Précedent"
            nextLabel="Suivant"
            (pageChange)="onTableDataChange($event)"
        ></pagination-controls>
      </div>
    </section>
    <!-- Blog posts section end -->
    <app-page-footer></app-page-footer>
  </div>

