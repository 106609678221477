import { Injectable } from '@angular/core';
 
import { Coupon } from '../models/coupon';
import { Coupons } from './coupon/coupons-data';
 
@Injectable({
    providedIn: 'root'
})
export class CouponService {
 
  constructor() { }
 
  getListeCoupons(): Coupon[] {
    return Coupons;
  }
 
  // getCoupon(id: number): Coupon {
  //   return Coupons.find(coupon => coupon.id === id);
  // }
}