import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LocalService } from 'src/app/services/local.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-user-transfer',
  templateUrl: './user-transfer.component.html',
  styleUrls: ['./user-transfer.component.css'],
})
export class UserTransferComponent implements OnInit {
  user: any = [];
  amount!: number;
  account_number!: string;
  pf!: number;
  transferForm!: FormGroup;
  get f() {
    return this.transferForm.controls;
  }
  constructor(
    private tokenStorageService: TokenStorageService,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private localStore: LocalService
  ) {}
  onFormSubmit(account_number: string, amount: string) {
    this.localStore.removeData('transfer_amount');
    this.localStore.saveData('transfer_amount', amount);
    this.localStore.removeData('transfer_account_number');
    this.localStore.saveData('transfer_account_number', 'SPW-'+account_number);
    this.router.navigate(['/transfer/confirme']);
  }
  ngOnInit() {
    this.user = this.tokenStorageService.getUser();
    this.user = JSON.parse(this.user);
    this.pf = 0;
    this.amount = 50;
    this.account_number='';
    this.transferForm = this.formBuilder.group(
      {
        account_number: [
          this.account_number,
          [
            Validators.required,
            Validators.minLength(14),
            Validators.maxLength(14),
          ],
        ],
        amount: [this.amount, [Validators.required]],
      },
      {
        updateOn: 'change',
      }
    );
    this.transferForm.controls['amount'].valueChanges.subscribe((value) => {
      this.amount = value;
    });

    // this.transferForm.controls['account_number'].valueChanges.subscribe(
    //   (value) => {
    //     if(value.length==4){
    //       this.account_number = value+'-';
    //     }else{
    //       this.account_number = value;
    //     }
    //   }
    // );
  }
}
