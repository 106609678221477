<!-- Document Wrapper  -->
<div id="main-wrapper">
    <app-user-header></app-user-header>

    <app-user-profilebar></app-user-profilebar>


    <!-- Admin Content Section  -->
    <div id="content" class="py-4">
        <div class="container">
            <div class="row">
                  <div class="col-lg-3" >
                    <app-user-left-menu></app-user-left-menu>
                  </div>
                  <!-- Middle Panel  -->
                  <div class="col-lg-9">
                      <div class="profile-content">
                          <h3 class="admin-heading bg-dark text-white"><p>ShapShap</p></h3>
                          <ul class="nav nav-pills">
                            <li class="nav-item">
                                <a class="nav-link">ShapShap</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link active">Montant</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link">Confirmation</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link">Statut</a>
                            </li>
                          </ul>
                          <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <form [formGroup]="rechargeForm" class="form bg-offwhite py-5">
                                    <!-- <div class="Withdarw-header">
                                        <p class="text-white"><b>Balance du compte <i class="fa fa-audio-description\" aria-hidden="true"></i></b></p>
                                        <h3 class="av-balance"> {{ balance }} CFA</h3>
                                        <p>Recharger le numéro <b>{{ recharge_phone }}</b></p>
                                    </div> -->

                                    <!-- <div class="row payment mt-4">
                                      <div class="col-md-3 col-sm-6" *ngFor="let coupon of coupons; let i=index" >
                                          <div class="single-payment cp_selected" style="height: 200px !important;" [ngClass]="{'selected': i == 0}" (click)="onClick($event, coupon.cfa)" id="{{ coupon.cfa }}">
                                              <img src="{{'../../../assets/admin/images/operator/' + coupon.operator + '.png'}}" class="mx-auto" style="position: relative;width: calc(100% - 60px);top: 0;transform: translateY(0%);" alt="">
                                              <hr>
                                              <div class="">
                                                  <p>{{ coupon.cfa }}</p>
                                                  <p>XOF</p>
                                              </div>
                                          </div>
                                      </div>
                                    </div> -->
                                    <h5>Montant de recharge</h5>
                                    <div class="input-group">
                                        <div class="input-group-prepend btn-sp-prepend">
                                          <strong class="input-group-text px-4 text-white" style="background-color: transparent !important;">
                                            XOF
                                          </strong>
                                        </div>
                                        <input type="number" min="50" step="5" id="montant" formControlName="montant" class="form-control form-control-lg" value="50">
                                    </div>
                                    <div class="form-group mt-3" id="telephoneDiv">
                                        <h5>Entrez votre numéro Mobile Money</h5>
                                        <input type="tel" id="phone" formControlName="phone" value="" class="form-control" placeholder="numéro de téléphone">
                                        <input type="hidden" id="code" name="code" #code value="ne">
                                        <input type="hidden" id="dialcode" name="dialcode" #dialcode value="227">
                                        <div class="" *ngIf="!wrongNumber && (rechargeForm.controls['phone'].dirty || rechargeForm.controls['phone'].touched)">
                                            <span id="valid-msg" class="text-success"><i class="fa fa-check mr-2"></i> Numéro valide</span>
                                        </div>
                                        <span id="error-msg" class="text-danger"></span>
                                        <div class="" *ngIf="wrongNumber">
                                            <span id="error-req" class="text-danger"><i class="fas fa-exclamation-triangle mr-2"></i>{{ errorMessage }}</span>
                                        </div>
                                    </div>
                                    <p class="text-muted text-center">Le destinataire sera rechargé de <b>{{ montant }} CFA</b></p>
                                    <hr>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p class="text-left"><b>Frais de transaction </b></p>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="text-right">
                                                {{ pf }} FCFA
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p class="text-left"><b>Vous enverrez</b></p>
                                        </div>
                                        <div class="col-md-6">
                                            <span class="float-right">{{ montant }} CFA</span>
                                        </div>
                                    </div>
                                    <ul class="pager mt-4">
                                        <li>

                                            <button class="btn btn-sp color-primary mr-0">
                                              <a (click)="reloadCurrentPage()" href="/recharge" class="text-white">
                                                  <i class="fas fa-chevron-left"></i>
                                                  Retour
                                              </a>
                                          </button>
                                        </li>
                                        <li>

                                            <button class="btn btn-sp color-primary mr-0 text-white" (click)="confirmation(code.value,dialcode.value)">
                                                  Continuer
                                                  <i class="fas fa-chevron-right"></i>
                                              <!-- <a routerLink="/recharge/confirmation" class="text-white">
                                                  Continuer
                                                  <i class="fas fa-chevron-right"></i>
                                              </a> -->
                                          </button>
                                        </li>
                                    </ul>
                                </form>
                            </div>

                        </div>
                      </div>

                  </div>
                  <!-- Middle Panel End -->
            </div>
        </div>
    </div>
    <!-- Content end -->

    <app-page-footer></app-page-footer>
  </div>
  <!-- Document Wrapper end -->
