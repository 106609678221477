<div class="landing-ecommerce">
    <app-page-header></app-page-header>
    <!-- Banner section start -->
    <section class="banner features">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <img src="assets/images/ip-tv1.png" alt="" class="">
          </div>
          <div class="col-md-8 my-auto">
            <div class="ban-content">
              <h1>Nos tarifs IPTV</h1>
              <p>Des prix exceptionnels sur les abonnements de 3 mois, 6 mois et 1 An, ainsi que notre nouveau venu le PACK Test 01 mois.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner section end -->
  
    <!-- Benefits section start -->
    <section class="benefits">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-10 m-auto">
            <div class="sec-heading">
              <p class="sec-subtitle">
                Des prix jamais vu sur le marché pour cette qualité de retransmisson professionel garantie sans freeze ni coupures 24H/24, 7j/7 et un support technique de qualité. Accés total aux +5000 chaines TV SD/HD/FHD et +10000 Films et Séries à la demande.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ul class="nav nav-tabs list-tab-nav">
              <li><a class="active" data-toggle="tab" href="#benefits-1">Abonement 1 Mois</a></li>
              <li><a data-toggle="tab" href="#benefits-2">Abonement 3 Mois</a></li>
              <li><a data-toggle="tab" href="#benefits-3">Abonement 6 Mois</a></li>
              <li><a data-toggle="tab" href="#benefits-4">Abonement 1 An</a></li>
            </ul>
  
            <div class="tab-content">
              <div id="benefits-1" class="tab-pane fade in active show">
                <div class="row">
                  <div class="col-md-11 m-auto">
                    <div class="package-table">
                      <table>
                        <tr>
                          <th>Services</th>
                          <th>Small Startup</th>
                          <th>Big Company</th>
                        </tr>
                        <tr>
                          <td>Pay With 1 Click</td>
                          <td><i class="fas fa-check-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Accepting Card</td>
                          <td><i class="fas fa-check-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Data Collection</td>
                          <td><i class="fas fa-check-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Integration</td>
                          <td><i class="fas fa-times-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Secure Payment</td>
                          <td><i class="fas fa-times-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Credit Balance</td>
                          <td><i class="fas fa-times-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Digital Wallet</td>
                          <td><i class="fas fa-check-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div id="benefits-2" class="tab-pane fade">
                <div class="row">
                  <div class="col-md-11 m-auto">
                    <div class="package-table">
                      <table>
                        <tr>
                          <th>Services</th>
                          <th>Small Startup</th>
                          <th>Big Company</th>
                        </tr>
                        <tr>
                          <td>Pay With 1 Click</td>
                          <td><i class="fas fa-check-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Accepting Card</td>
                          <td><i class="fas fa-check-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Data Collection</td>
                          <td><i class="fas fa-check-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Integration</td>
                          <td><i class="fas fa-times-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Secure Payment</td>
                          <td><i class="fas fa-times-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Credit Balance</td>
                          <td><i class="fas fa-times-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Digital Wallet</td>
                          <td><i class="fas fa-check-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div id="benefits-3" class="tab-pane fade">
                <div class="row">
                  <div class="col-md-11 m-auto">
                    <div class="package-table">
                      <table>
                        <tr>
                          <th>Services</th>
                          <th>Small Startup</th>
                          <th>Big Company</th>
                        </tr>
                        <tr>
                          <td>Pay With 1 Click</td>
                          <td><i class="fas fa-check-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Accepting Card</td>
                          <td><i class="fas fa-check-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Data Collection</td>
                          <td><i class="fas fa-check-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Integration</td>
                          <td><i class="fas fa-times-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Secure Payment</td>
                          <td><i class="fas fa-times-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Credit Balance</td>
                          <td><i class="fas fa-times-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Digital Wallet</td>
                          <td><i class="fas fa-check-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div id="benefits-4" class="tab-pane fade">
                <div class="row">
                  <div class="col-md-11 m-auto">
                    <div class="package-table">
                      <table>
                        <tr>
                          <th>Services</th>
                          <th>Small Startup</th>
                          <th>Big Company</th>
                        </tr>
                        <tr>
                          <td>Pay With 1 Click</td>
                          <td><i class="fas fa-check-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Accepting Card</td>
                          <td><i class="fas fa-check-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Data Collection</td>
                          <td><i class="fas fa-check-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Integration</td>
                          <td><i class="fas fa-times-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Secure Payment</td>
                          <td><i class="fas fa-times-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Credit Balance</td>
                          <td><i class="fas fa-times-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                        <tr>
                          <td>Digital Wallet</td>
                          <td><i class="fas fa-check-circle"></i></td>
                          <td><i class="fas fa-check-circle"></i></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Benefits section end -->
    <app-page-footer></app-page-footer>
  </div>
  
