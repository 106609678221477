import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalService } from '../../../services/local.service';
import { AuthService } from 'src/app/services/auth.service';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { SweetAlertOptions } from 'sweetalert2';
declare let $: any;
const httpOptions = {
  headers: new HttpHeaders()
}
httpOptions.headers.append('Access-Control-Allow-Origin', '*');
httpOptions.headers.append('Content-Type', 'application/json');
const PHONE_VERIFICATION_API="/user/v1/secure/send-guard-otp";
@Component({
  selector: 'app-page-signup-set-phone',
  templateUrl: './page-signup-set-phone.component.html',
  styleUrls: ['./page-signup-set-phone.component.scss'],
})
export class PageSignupSetPhoneComponent implements OnInit {
  isValidFormSubmitted = false;
  phone!: string;
  private data: any = [];
  country_code: string = 'ne';
  signupForm!: FormGroup;
  full_number!: string;
  wrongNumber: boolean = false;
  errorMessage!: string;
  pcc!: string;
  swalOptions: SweetAlertOptions = { icon: 'info' };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private localStore: LocalService,
    private authService: AuthService,
    private swalService: FlashMessageService
  ) {}

  async onFormSubmit(code: string, dialCode: string) {
    this.full_number = dialCode + this.signupForm.value.phone;
    try {
      this.data = await this.authService.sendOTP(
        null,
        this.full_number,
        this.localStore.getData('send-otp-to-user')
      );
      if ('status' in this.data && 'comptor' in this.data) {
        if (
          this.data['status'] == true &&
          parseInt(this.data['comptor']) <= 3
        ) {
          this.localStore.removeData('user-phone');
          this.localStore.saveData('user-phone', this.full_number);
          this.localStore.removeData('msisdn');
          this.localStore.saveData('msisdn', this.signupForm.value.phone);
          this.router.navigateByUrl('/inscription/confirmation-otp');
        }
      } else {
        this.wrongNumber = true;
        console.log(this.data);
        if ('message' in this.data) {
          this.errorMessage = this.data['message'];
        } else {
          this.errorMessage = 'Une erreur s\'est produite :';
        }
        this.swalOptions.icon = 'info';
        this.swalOptions.html = this.errorMessage;
        this.swalService.show(this.swalOptions);
      }
    } catch (error) {
      this.wrongNumber = true;
      if ('message' in this.data) {
        this.errorMessage = this.data['message'];
      } else {
        this.errorMessage = 'Une erreur s\'est produite :';
      }
      this.swalOptions.icon = 'info';
      this.swalOptions.html = this.errorMessage;
      this.swalService.show(this.swalOptions);
    }
  }
  initialization() {
    var input = <HTMLInputElement>document.querySelector('#phone');
    var errorMap = [
      "Ce numéro n'est pas valide",
      'Code du pays non valide',
      'Numéro trop court',
      'Numéro trop long',
      'Numéro invalide',
    ];

    var intl = (<any>window).intlTelInput(input, {
      autoHideDialCode: false,
      initialCountry: 'ne',
      hiddenInput: 'full_number',
      onlyCountries: ['ne'],
      separateDialCode: true,
      utilsScript: 'assets/plugin/build/js/utils.js',
    });

    // var reset = function () {
    //   $('#valid-msg').hide();
    //   $('#error-msg').hide();
    // };

    // Validate on blur event
    $('#phone').on('blur keyup change countrychange', () => {
      //reset();
      let getCode = intl.s['iso2'];
      let dialcode = intl.s['dialCode'];
      this.localStore.saveData('country-code', getCode.toUpperCase());
      $('#code').val(getCode); //You will get country code only
      $('#dialcode').val(dialcode);
      if (input.value.trim()) {
        if (intl.isValidNumber()) {
          $('#valid-msg').show();
          $('#error-req').show();
          $('#error-msg').hide();
          $('#submit').prop('disabled', false);
        } else {
          input.classList.add('error');
          var errorCode = intl.getValidationError();
          $('#error-msg').html(
            '<i class="fas fa-exclamation-triangle mr-2"></i>' +
              errorMap[errorCode]
          );
          $('#valid-msg').hide();
          $('#error-req').hide();
          $('#error-msg').show();
          $('#submit').prop('disabled', true);
        }
      }
    });
  }

  ngOnInit() {
    if (
      !this.localStore.getData('send-otp-to-user') ||
      this.localStore.getData('send-otp-to-user') != 'CBP'
    ) {
      this.router.navigateByUrl('/inscription/identification');
    }
    this.initialization();
    this.localStore.removeData('country-code');
    this.localStore.saveData('country-code', 'NE'.toUpperCase());
    this.signupForm = this.formBuilder.group(
      {
        phone: [null, [Validators.required]],
        code: [null, [Validators.required]],
      },
      {
        updateOn: 'change',
      }
    );
    $('#valid-msg').hide();
    $('#error-msg').hide();
    $('#submit').prop('disabled', true);
  }
}
