import {Component, OnInit} from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { TokenStorageService } from "src/app/services/token-storage.service";

declare let $: any;

@Component({selector: "app-page-header", templateUrl: "./page-header.component.html", styleUrls: ["./page-header.component.scss"]})
export class PageHeaderComponent implements OnInit {
  isLoggedIn = false;
  user: any=[];
  bt!: string;
  // showAdminBoard = false;
  // showModeratorBoard = false;
  // username?: string;
  constructor(private router: Router, private tokenStorageService: TokenStorageService, private authService: AuthService) {

  }

  changeMenuTo(navB: string){
    this.bt=navB;
    $('.nav-b').removeClass('active');
    $('#'+navB).addClass('active');
    // console.log(this.bt);
  }

  async Logout(){
    await this.authService.logout();
  }



  //ngOnInit(): void {
    //this.isLoggedIn = !!this.tokenStorageService.getToken();
    // if (this.isLoggedIn) {
    //   const user = this.tokenStorageService.getUser();
    //   this.roles = user.roles;
    //   this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
    //   this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');
    //   this.username = user.username;
    // }
  //}

  initialization() {
    //======================
    // Sticky menu
    //======================

    $(window).scroll(function () {
      if ($(window).scrollTop() >= 1) {
        $(".header-main").addClass("fixed-header");
      } else {
        $(".header-main").removeClass("fixed-header");
      }
    });

    //======================
    // Preloder
    //======================
    $(window).on("load", function () {
      $("#status").fadeOut();
      $("#preloader").delay(500).fadeOut("slow");
      $("body").delay(500).css({overflow: "visible"});
    });

    //======================
    // Mobile menu
    //======================
    $("#mobile-menu-toggler").on("click", function (e: { preventDefault: () => void; }) {
      e.preventDefault();
      $(".primary-menu > ul").slideToggle();
    });
    $(".has-menu-child").append('<i class="menu-dropdown fas fa-angle-down"></i>');

    if ($(window).width() <= 991) {
      $(".menu-dropdown").on("click",  () => {
        $(this).prev().slideToggle("slow");
        $(this).toggleClass("fa-angle-down fa-angle-up");
      });
    }
  }

  switchLanguage(language: string) {

  }
  ngOnInit() {
    this.user=this.tokenStorageService.getUser();
    if(this.user){
      this.user = JSON.parse(this.user);
    }


    this.initialization();
    this.isLoggedIn = !!this.tokenStorageService.getToken();
  }
}

