import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { Router } from '@angular/router';
import {Observable, BehaviorSubject, Subject, of, Subscription, timeout} from "rxjs";
import { delay } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalService } from './local.service';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { StoreService } from './store.service';
import { ProduitPanier } from '../models/produit-panier';

const LOGIN_API = '/user/v1/secure/login';
const USER_INFO_API = '/user/v1/secure/info';
const REGISTER_API='/user/v1/secure/register';
const UPDATE_API='/user/v1/secure/update';
const CHANGE_PASSWORD_API='/user/v1/secure/upd-pass';
const LOGOUT_API='/user/v1/secure/logout';
const TRANSACTION_API='/recharge/v1/wallet/recharge/tx';
const SEND_OTP_API = '/user/v1/secure/send-guard-otp';
const SIGNUP_OTP_CONFIRMATION_API = '/user/v1/secure/check-otp';
const httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa('username:password')})
};
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private data: any = [];
  private payload: any = [];
  private updateUser: any = [];
  private currentUserSubject: BehaviorSubject<User | null>;
  public currentUser: Observable<User | null>;
  public resultat = '';
  tokenSubscription = new Subscription();
  timeout!: number | Date;
  panier: Array<ProduitPanier> = [];
  constructor(
    private http: HttpClient,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private localStore: LocalService,
    private ngxPermissionsService: NgxPermissionsService,
    private roleService: NgxRolesService,
    private storeService: StoreService
  ) {
    this.currentUserSubject = new BehaviorSubject(
      JSON.parse(JSON.stringify(this.localStore.getData('user')))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }
  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  async login(credentials: { username: any; password: any }): Promise<any> {
    try {
      const data = await this.http
        .post<any>(
          environment.baseURL + LOGIN_API,
          {},
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization:
                'Basic ' +
                btoa(credentials.username + ':' + credentials.password),
            }),
          }
        )
        .toPromise();
      this.payload = data['data']['payload'];
      if (
        data['code'] == '200' &&
        data['status'] == true &&
        data['data'] != ''
      ) {
        this.timeout =
          this.jwtHelper
            .getTokenExpirationDate(this.payload['token'])!
            .valueOf() - new Date().valueOf();
        this.expirationCounter(this.timeout);
        //var testvar = this.expirationCounter(this.timeout);
        //console.log(testvar);
        this.localStore.saveData('token', this.payload['token']);
        let user=data['data']['user'];
        user['merchantCollect'] = data['data']['collect_info'];
        user['merchantCico'] = data['data']['cico_info'];
        this.localStore.saveData('user', JSON.stringify(user));
        this.loadCartData();
        this.currentUserSubject.next(data);
      } else {
        this.localStore.saveData('login-status', '0');
        this.localStore.saveData('login-message', this.data['message']);
      }
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }
  async loadCartData() {
    try {
      const cart_res = await this.storeService.getCart();
      if (
        'status' in cart_res &&
        cart_res['status'] == true &&
        'data' in cart_res && 'items' in
        cart_res['data']
      ) {
        const items = cart_res['data']['items'];
        this.panier=[];
        for (let produit of items) {
          let p=new ProduitPanier()
          p.id = produit.product.id;
          p.name = produit.product.name;
          p.price = produit.product.price;
          p.quantity = produit.quantity;
          p.description = produit.product.description;
          p.image = produit.product.images.length>0?produit.product.images[0].id:null;
          this.panier.push(p);
        }
        console.log(this.panier);
        this.localStore.saveData('panier',JSON.stringify(this.panier));
      }
    } catch (error) {}
  }
  userInfo() {
    return new Observable<boolean>((observer) => {
      this.http
        .get(environment.baseURL + USER_INFO_API, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.localStore.getData('token'),
          }),
        })
        .subscribe(
          (result) => {
            this.data = result;
            this.payload = this.data['data'];
            if (
              this.data['code'] == '200' &&
              this.data['status'] == true &&
              this.data['data'] != ''
            ) {
              this.localStore.saveData('user', JSON.stringify(this.payload));
              this.currentUserSubject.next(result);
              observer.next(true);
              observer.complete();
            } else {
              this.localStore.saveData('login-status', '0');
              this.localStore.saveData('login-message', this.data['message']);
              observer.error(false);
              observer.complete();
            }
          },
          (error: HttpErrorResponse) => {
            observer.error(false);
            observer.complete();
          }
        );
    });
  }

  async logout() {
    try {
      const data = await this.http.get(environment.baseURL + LOGOUT_API, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.localStore.getData('token'),
        }),
      });
      this.roleService.flushRoles();
      this.ngxPermissionsService.flushPermissions();
      console.log(this.roleService.getRoles());
      localStorage.clear();
      this.currentUserSubject.next(null);
    } catch (error) {
      localStorage.clear();
      this.currentUserSubject.next(null);
    }
  }

  async register(credentials: {
    firstname: any;
    lastname: any;
    username: any;
    password: any;
    birthday: any;
    nif: any;
    rccm: any;
    companyName: any;
    category: any;
    userType: any;
    typeAccount: any;
    sex: any;
    address: any;
    phone: any;
    country: any;
    description: any;
    role: any;
  }): Promise<any> {
    try {
      const data = this.http
        .post<any>(
          environment.baseURL + REGISTER_API,
          {
            firstname: credentials.firstname,
            lastname: credentials.lastname,
            username: credentials.username,
            password: credentials.password,
            birthday: credentials.birthday,
            nif: credentials.nif,
            rccm: credentials.rccm,
            companyName: credentials.companyName,
            description: credentials.description,
            role: credentials.role,
            category: credentials.category,
            userType: credentials.userType,
            typeAccount: credentials.typeAccount,
            sex: credentials.sex,
            address: credentials.address,
            phone: credentials.phone,
            country: credentials.country,
          },
          httpOptions
        )
        .toPromise();
      return data;
    } catch (error) {}
  }

  update(credentials: {
    firstname: any;
    lastname: any;
    birthday: any;
    address: any;
    country: any;
    companyName: any;
    nif: any;
    rccm: any;
  }): Observable<any> {
    return new Observable<boolean>((observer) => {
      this.http
        .patch(
          environment.baseURL + UPDATE_API,
          {
            firstname: credentials.firstname,
            lastname: credentials.lastname,
            birthday: credentials.birthday,
            address: credentials.address,
            country: credentials.country,
            // username: credentials.username,
            // password: credentials.password,
            nif: credentials.nif,
            rccm: credentials.rccm,
            companyName: credentials.companyName,
            // description: credentials.description,
            // userType:credentials.userType,
            // typeAccount:credentials.typeAccount,
            // sex: credentials.sex,
            //phone: credentials.phone,
          },
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.localStore.getData('token'),
            }),
          }
        )
        .subscribe(
          (result) => {
            this.updateUser = result;
            if ('status' in this.updateUser) {
              if (this.updateUser['status'] == true) {
                if (
                  this.updateUser['code'] == '200' &&
                  this.updateUser['status'] == true &&
                  this.updateUser['data'] != ''
                ) {
                  this.localStore.saveData(
                    'user',
                    JSON.stringify(this.updateUser['data']['user'])
                  );
                  this.currentUserSubject.next(result);
                  this.router.navigateByUrl('/profile');
                } else {
                  this.localStore.saveData('update-status', '0');
                  this.localStore.saveData(
                    'update-message',
                    this.updateUser['message']
                  );
                }
              } else {
                observer.error(false);
                observer.complete();
              }
            }
          },
          (error: HttpErrorResponse) => {
            observer.error(false);
            observer.complete();
          }
        );
    });
  }
  changePassword(credentials: {
    oldPassword: any;
    password: any;
  }): Observable<any> {
    return new Observable<boolean>((observer) => {
      this.http
        .post(
          environment.baseURL + CHANGE_PASSWORD_API,
          {
            old_password: credentials.oldPassword,
            new_password: credentials.password,
          },
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.localStore.getData('token'),
            }),
          }
        )
        .subscribe(
          (result) => {
            this.data = result;
            if ('status' in this.data) {
              if (this.data['status'] == true) {
                if (this.data['code'] == '200' && this.data['status'] == true) {
                  this.router.navigateByUrl('/profile');
                } else {
                  this.localStore.saveData('change-password-status', '0');
                  this.localStore.saveData(
                    'change-password-message',
                    this.data['message']
                  );
                }
              } else {
                observer.error(false);
                observer.complete();
              }
            }
          },
          (error: HttpErrorResponse) => {
            observer.error(false);
            observer.complete();
          }
        );
    });
  }

  expirationCounter(timeout: number | Date) {
    this.tokenSubscription.unsubscribe();
    this.tokenSubscription = of(null)
      .pipe(delay(timeout))
      .subscribe((expired) => {
        this.logout();
        this.router.navigate(['/login']).then(() => {
          window.location.reload();
        });
      });
  }

  getAllTransactions(): Observable<any> {
    return this.http.get(environment.baseURL + TRANSACTION_API, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.localStore.getData('token'),
      }),
    });
  }

  async sendOTP(
    email: string | null,
    phone: string | null,
    type: string
  ): Promise<any> {
    var parametre = {};
    try {
      if (type == 'CBE' || type == 'COBE') {
        parametre = {
          email: email,
          type: type,
        };
      } else {
        parametre = {
          phone: phone,
          type: type,
        };
      }
      const data = await this.http
        .post<any>(environment.baseURL + SEND_OTP_API, parametre)
        .toPromise();
      return data;
    } catch (error) {
      // Gérer les erreurs
      console.error("Une erreur s'est produite :", error);
      throw error;
    }
  }

  async confirmeOTP(otp: string): Promise<any> {
    try {
      const data = await this.http
        .post<any>(environment.baseURL + SIGNUP_OTP_CONFIRMATION_API, {
          otp: otp,
        })
        .toPromise();
      return data;
    } catch (error) {
      // Gérer les erreurs
      console.error("Une erreur s'est produite :", error);
      throw error;
    }
  }
}
