<!-- Document Wrapper  -->
<div id="main-wrapper">
    <app-user-header></app-user-header>

    <app-user-profilebar></app-user-profilebar>


    <!-- Admin Content Section  -->
    <div id="content" class="py-4">
        <div class="container">
            <div class="row">
                  <div class="col-lg-3" >
                  <app-user-left-menu></app-user-left-menu>
                  </div>
                  <!-- Middle Panel  -->
                  <div class="col-lg-9">
                      <div class="profile-content">
                          <h3 class="admin-heading bg-dark text-white"><p>Recharge</p></h3>
                          <ul class="nav nav-pills">
                            <li class="nav-item">
                                <a class="nav-link">recharge</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link">Montant</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link">Confirmation</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link active">Statut</a>
                            </li>
                          </ul>
                          <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <div class="bg-offwhite  success form">
                                    <div class="text-center">
                                        <div class="alert-success py-5" *ngIf="c2s">
                                            <h1 class=""><i class="far fa-check-circle text-success"></i></h1>
                                            <h3 class=" text-success">Success!</h3>
                                            <p class="">Transactions terminées</p>
                                            <p class="">Vous avez recharger <b>{{ montant }} FCFA</b> au numéro {{ phone }}.</p>
                                            <p>Voir les détails de la transaction sous <a routerLink="/transaction">Transaction</a></p>
                                            <p>
                                                <span class="name"><b>ID de la transaction:</b></span>
                                                <span class="decs">{{ transactionId }}</span>
                                            </p>
                                        </div>
                                        <div class="alert-danger py-5" *ngIf="!c2s">
                                            <h1 class=""><i class="fas fa-times-circle text-danger"></i></h1>
                                            <h3 class=" text-danger">Erreur!</h3>
                                            <p class="">La transactions à echouillée</p>
                                            <p class="">La recharge de <b>{{ montant }} FCFA</b> au numéro {{ phone }} à echouillée.</p>
                                        </div>

                                        <button class="btn btn-sp color-primary mr-0  mt-5">
                                            <a routerLink="/recharge" class="text-white mr-0">
                                                <span>Effectuez une autre recharge</span>
                                            </a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>

                  </div>
                  <!-- Middle Panel End -->
            </div>
        </div>
    </div>
    <!-- Content end -->

    <app-page-footer></app-page-footer>
  </div>
  <!-- Document Wrapper end -->



