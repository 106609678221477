import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
declare let $: any;
@Component({
  selector: 'app-user-edit-profile',
  templateUrl: './user-edit-profile.component.html',
  styleUrls: ['./user-edit-profile.component.scss']
})
export class UserEditProfileComponent implements OnInit {

  user: any=[];
  updateForm!: FormGroup;
  passwordPattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}$";
  usernamePattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  specialCharacterPattern: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  wrongCredentials=false;
  isCompany=false;
  errorMessage="";
  constructor( private formBuilder: FormBuilder, private router: Router, private authService: AuthService, private tokenStorageService: TokenStorageService) {}

  onFormSubmit() {
    this.authService.update(this.updateForm.value).subscribe(result=>{
      this.wrongCredentials=false;
      //this.router.navigate(['/profile']);
    },error=>{
      this.wrongCredentials=true;
      this.errorMessage="La modification a echoué veuillez ressayer svp!";
    })
    //this.router.navigate(['inscription/professionnel']);
  }



  ngOnInit() {
    this.user = this.tokenStorageService.getUser();
    this.user = JSON.parse(this.user);
    this.updateForm = this.formBuilder.group({});
    if (this.user.role!='customer') {
      this.isCompany=true;
      this.updateForm = this.formBuilder.group(
        {
          firstname: [this.user.firstname, [Validators.required]],
          lastname: [this.user.lastname, [Validators.required]],
          birthday: [this.user.birthday, [Validators.required]],
          address: [this.user.address, [Validators.required]],
          country: [this.user.country, [Validators.required]],
          // username: [this.user.username, [Validators.required, Validators.pattern(this.usernamePattern)]],
          // password:["",Validators.compose([Validators.required])],
          companyName: [this.user.company_name,[Validators.required]],
          nif: [this.user.nif, [Validators.required]],
          rccm: [this.user.rccm, [Validators.required]],
          // description: [this.user.description],
          // sex: [this.user.sex, [Validators.required]],
          // phone: [this.user.account.phone,[Validators.required]],
        },
        {
          //validator: CustomValidators.passwordMatchValidator,
          //updateOn: 'blur',
        }
      );
    } else {
      this.isCompany=false;
      this.updateForm = this.formBuilder.group(
        {
          firstname: [this.user.firstname, [Validators.required]],
          lastname: [this.user.lastname, [Validators.required]],
          birthday: [this.user.birthday, [Validators.required]],
          address: [this.user.address, [Validators.required]],
          country: [this.user.country, [Validators.required]],
          //username: [this.user.username, [Validators.required, Validators.pattern(this.usernamePattern)]],
          //password:["",Validators.compose([Validators.required]) ],
          //description: [""],
          //sex: [this.user.sex, [Validators.required]],
          //phone: [this.user.account.phone,[Validators.required]],
        },
        {
          //validator: CustomValidators.passwordMatchValidator,
          //updateOn: 'blur',
        }
      );
    }
  }
}
