<div class="profile-content">
  <h3 class="admin-heading bg-dark text-white"><p>Boutique</p></h3>
  <ul class="nav nav-pills">
    <li class="nav-item">
      <a routerLink="/store/products" routerLinkActive="active" class="nav-link">Produits</a>
    </li>
    <li class="nav-item">
        <a class="nav-link">Commandes</a>
    </li>
    <li class="nav-item">
        <a class="nav-link">Clients</a>
    </li>
    <li class="nav-item">
        <a routerLink="/store/info" routerLinkActive="active" class="nav-link">Configuration</a>
    </li>
  </ul>
</div>
