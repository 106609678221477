import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalService } from '../../../services/local.service';
declare let $: any;
@Component({
  selector: 'app-page-signup-choose-account-type',
  templateUrl: './page-signup-choose-account-type.component.html',
  styleUrls: ['./page-signup-choose-account-type.component.scss'],
})
export class PageSignupChooseAccountTypeComponent implements OnInit {
  myForm!: FormGroup;
  isParticulier: boolean = true;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private localStore: LocalService
  ) {
    this.myForm = this.formBuilder.group({
      website: this.formBuilder.array([], [Validators.required]),
    });
  }
  onItemChange(event: Event): void {
    if ((event.target as HTMLInputElement).value == 'particulier') {
      this.isParticulier = true;
    } else {
      this.isParticulier = false;
    }
    console.log('isParticulier', this.isParticulier);
  }
  onFormSubmit() {
    if (this.isParticulier) {
      this.localStore.removeData('account-type');
      this.localStore.saveData('account-type', 'customer');
      this.router.navigate(['/inscription/identification']);
    } else {
      this.localStore.removeData('account-type');
      this.localStore.saveData('account-type', 'company');
      this.localStore.removeData('send-otp-to-user');
      this.localStore.saveData('send-otp-to-user', 'COBE');
      this.router.navigate(['/inscription/set-email']);
    }
  }
  initialization() {
    $(document).ready(() => {
      $('#particulier').change(() => {
        $('#p_professionnel').hide();
        $('#p_particulier').show();
      });

      $('#professionnel').change(() => {
        $('#p_particulier').hide();
        $('#p_professionnel').show();
      });
    });
  }

  ngOnInit() {
    this.initialization();
  }
}
