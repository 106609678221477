<!-- Document Wrapper  -->
<div id="main-wrapper">
    <app-user-header></app-user-header>

    <app-user-profilebar></app-user-profilebar>


    <!-- Admin Content Section  -->
    <div id="content" class="py-4">
        <div class="container">
            <div class="row">
                  <div class="col-lg-3" >
                  <app-user-left-menu></app-user-left-menu>
                  </div>
                  <!-- Middle Panel  -->
                  <div class="col-lg-9">
                      <div class="profile-content">
                          <h3 class="admin-heading bg-dark text-white"><p>Recharge</p></h3>
                          <ul class="nav nav-pills">
                            <li class="nav-item">
                                <a class="nav-link">recharge</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link">Montant</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link active">Confirmation</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link">Statut</a>
                            </li>
                          </ul>
                          <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <form id="withdraw-send-money" method="post" class="form bg-offwhite py-4">
                                    <div class="Withdarw-header">
                                        <p class="text-4 text-center">Vous êtes sur le point de recharger le numéro: {{ msisdn }}</p>
                                        <!-- <hr>
                                        <p class="text-white"><b>Montant courant</b></p>
                                        <h3 class="av-balance"> 20.000</h3> -->
                                    </div> <br>
                                    <div class="alert-info">
                                      <div class="py-3 text-center mb-4">
                                        <p>Veuillez confirmer votre code pin sur votre Téléphone pour valider la transaction... <br>Cliquer sur le bouton <strong>confirmer</strong> pour continuer</p>
                                      </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p class="text-left"><b>Montant de recharge</b></p>
                                        </div>
                                        <div class="col-md-6">
                                            <span class="float-right">{{ montant }} CFA</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p class="text-left"><b>Frais de Transactions </b></p>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="text-right">
                                                {{ pf }} FCFA
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <p class="text-left"><b>Total</b></p>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="text-right">
                                                <span class="float-right">{{ total }} CFA</span>
                                            </div>
                                        </div>
                                    </div>
                                    <ul class="pager mt-4">
                                        <li>

                                            <button class="btn btn-sp color-primary mr-0">
                                              <a routerLink="/recharge/montant" class="text-white">
                                                  <i class="fas fa-chevron-left"></i>
                                                  Retour
                                              </a>
                                          </button>
                                        </li>
                                        <li>
                                          <button class="btn btn-sp color-primary mr-0 text-white" (click)="confirmation()">
                                                  Confirmer
                                                  <i class="fas fa-chevron-right"></i>
                                              <!-- <a routerLink="/recharge/statut" class="text-white">
                                                  Confirmer
                                                  <i class="fas fa-chevron-right"></i>
                                              </a> -->
                                          </button>

                                        </li>
                                    </ul>
                                </form>
                            </div>
                        </div>
                      </div>

                  </div>
                  <!-- Middle Panel End -->
            </div>
        </div>
    </div>
    <!-- Content end -->

    <app-page-footer></app-page-footer>
  </div>
  <!-- Document Wrapper end -->


