<!-- Document Wrapper  -->
<div id="main-wrapper">
    <app-user-header></app-user-header>

    <app-user-profilebar></app-user-profilebar>


    <!-- Admin Content Section  -->
    <div id="content" class="py-4">
        <div class="container">
            <div class="row">
                  <div class="col-lg-3" style="width:20%;">
                    <app-user-left-menu></app-user-left-menu>
                  </div>
                  <!-- Middle Panel  -->
                  <div class="col-lg-9 profile-area">
                    <h3 class="admin-heading bg-offwhite">
                        <p>Profile</p>
                        <span>Vos informations personnelles</span>
                        <a routerLink="/profile/edit" class=""><button  type="button" class="edite border-0"><span aria-hidden="true"><i class="fas fa-edit mr-1"></i></span></button></a>
                    </h3>


                    <!-- Edit personal info End -->
                    <div class="infoItems shadow">
                      <ul class="nav nav-tabs">
                          <li><a data-toggle="tab" href="#menu1" class="active">Compte</a></li>

                          <li><a data-toggle="tab" href="#menu2">Connexion et securité</a></li>
                      </ul>

                      <div class="tab-content">
                          <div id="menu1" class="tab-pane fade in active">
                              <div class="row">
                                  <p class="col-sm-3"><b>Nom</b></p>
                                  <p class="col-sm-9">{{ user.firstname }} {{ user.lastname }}</p>
                              </div>
                              <hr>
                              <div class="row">
                                  <p class="col-sm-3"><b>Date de naissance</b></p>
                                  <p class="col-sm-9">{{ user.birthday }}</p>
                              </div>
                              <hr>
                              <div class="row">
                                  <p class="col-sm-3"><b>Statut du compte</b></p>
                                  <p class="col-sm-9"><span class="text-success" data-toggle="tooltip" data-original-title="Active"><i class="fas fa-check-circle"></i></span></p>
                              </div>
                              <hr>
                              <div class="row">
                                  <p class="col-sm-3"><b>Email</b></p>
                                  <div class="col-sm-9">
                                      <p class="mb-0">{{ user.username }}</p>
                                  </div>
                              </div>
                              <hr>
                              <div class="row">
                                  <p class="col-sm-3"><b>Téléphone</b></p>
                                  <p class="col-sm-9">{{ user.wallet.phone }}</p>
                              </div>
                              <hr>
                              <div class="row">
                                  <p class="col-sm-3"><b>Adresse</b></p>
                                  <p class="col-sm-9">{{ user.address }}</p>
                              </div>
                              <div *ngIf=" user.userType == 'partner' ">
                                <hr>
                                <div class="row">
                                    <p class="col-sm-3"><b>Entreprise</b></p>
                                    <p class="col-sm-9">{{ user.company_name }}</p>
                                </div>
                                <hr>
                                <div class="row">
                                    <p class="col-sm-3"><b>NIF</b></p>
                                    <p class="col-sm-9">{{ user.nif }}</p>
                                </div>
                                <hr>
                                <div class="row">
                                    <p class="col-sm-3"><b>RCCM</b></p>
                                    <p class="col-sm-9">{{ user.rccm }}</p>
                                </div>
                              </div>
                              <hr>
                              <div class="row">
                                  <p class="col-sm-3"><b>Type de compte</b></p>
                                  <p class="col-sm-9 text-uppercase">{{ user.userType }}</p>
                              </div>
                              <hr>
                              <div class="row">
                                  <p class="col-sm-3"><b>ID du compte</b></p>
                                  <p class="col-sm-9">{{ user.id }}</p>
                              </div>
                          </div>
                          <!-- END First Tab -->
                          <div id="menu2" class="tab-pane fade">
                              <div class="row">
                                  <p class="col-sm-3"><b>Langue</b></p>
                                  <p class="col-sm-9">Français (France)</p>
                              </div>
                              <hr>
                              <div class="row">
                                  <p class="col-sm-3"><b>Fuseau horaire</b></p>
                                  <p class="col-sm-9">(GMT+01:00) Afrique de l'ouest</p>
                              </div>
                              <hr>
                              <div class="row">
                                    <p class="col-sm-3"><b>Code pin</b></p>
                                    <div class="input-group col-sm-9">
                                        <input [type]="pin"  class="form-control-plaintext" readonly="readonly" autocomplete="new-password" id="security" value="{{ pin }}">
                                        <div class="input-group-append">
                                        <span (click)="chowPin()" class="input-group-text" style="cursor: pointer;">
                                            <i class="fa fa-eye mr-2"  aria-hidden="true" *ngIf="!pin_show"></i><strong *ngIf="!pin_show">Afficher</strong>
                                            <i class="fa fa-eye-slash mr-2" aria-hidden="true" *ngIf="pin_show"></i><strong *ngIf="pin_show">Masquer</strong>
                                        </span>
                                        <a class="input-group-text" href="#" data-toggle="modal" data-target="#change_pin"><i class="fas fa-edit mr-2"></i> Modifier</a>
                                        </div>
                                    </div>
                              </div>
                              <hr>
                              <div class="row">
                                  <p class="col-sm-3"><b>Mots de passe</b></p>
                                  <div class="input-group col-sm-9">
                                    <input [type]="password"  class="form-control-plaintext" readonly autocomplete="new-password" id="security" value="{{ password }}">
                                    <div class="input-group-append">
                                      <span (click)="onClick()" class="input-group-text" style="cursor: pointer;">
                                        <i class="fa fa-eye mr-2"  aria-hidden="true" *ngIf="!show"></i><strong *ngIf="!show">Afficher</strong>
                                        <i class="fa fa-eye-slash mr-2" aria-hidden="true" *ngIf="show"></i><strong *ngIf="show">Masquer</strong>
                                      </span>
                                      <a routerLink="/profile/edit/securite" class="input-group-text"><i class="fas fa-edit mr-2"></i> Modifier</a>
                                    </div>
                                  </div>
                              </div>
                              <!-- Modal -->
                              <div class="modal fade step-secourity" id="change_pin" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog  modal-dialog-centered " role="document">
                                    <div class="modal-content">
                                        <div class="modal-header bg-dark">
                                            <h5 class="modal-title text-primary" id="exampleModalLabel">
                                                <p>Modifier votre code PIN</p>
                                            </h5>
                                            <p class="text-justify text-white">Chaque fois que vous vous connectez,avant de faire une transaction vous utiliserez le code PIN en plus de votre mot de passe.</p>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                              <form [formGroup]="changePinForm">
                                                  <div class="input-group">
                                                      <div class="input-group-prepend">
                                                      <span class="input-group-text" id="basic-addon1"><i class="fas fa-lock"></i></span>
                                                      </div>
                                                      <input type="text" formControlName="oldpin" #oldpin class="form-control" placeholder="Ancien code PIN" aria-label="oldpin" aria-describedby="basic-addon1">
                                                  </div>
                                                  <div style="font-size:10px;" class="p-0" *ngIf="changePinForm.controls['oldpin'].invalid && (changePinForm.controls['oldpin'].dirty || changePinForm.controls['oldpin'].touched) || this.isWrongOldPin">
                                                      <div class="text-danger p-0" *ngIf="changePinForm.controls['oldpin'].hasError('required')">
                                                        L'ancien pin est requis
                                                      </div>
                                                      <div class="text-danger p-0" *ngIf="changePinForm.controls['oldpin'].hasError('minlength') || changePinForm.controls['oldpin'].hasError('maxlength') || this.isWrongOldPin">
                                                        Veuillez entrez un code pin valid contenant 4 chiffre svp
                                                      </div>
                                                  </div>
                                                  <div class="input-group mt-3">
                                                      <div class="input-group-prepend">
                                                      <span class="input-group-text" id="basic-addon1"><i class="fas fa-lock"></i></span>
                                                      </div>
                                                      <input type="text" formControlName="newpin" #newpin class="form-control" placeholder="Nouveau code PIN" aria-label="newpin" aria-describedby="basic-addon1">
                                                  </div>
                                                  <!-- ============================================================ -->
                                                  <div style="font-size:10px;" class="p-0" *ngIf="changePinForm.controls['newpin'].invalid && (changePinForm.controls['newpin'].dirty || changePinForm.controls['newpin'].touched) || this.isWrongNewPin">
                                                      <div class="text-danger p-0" *ngIf="changePinForm.controls['newpin'].hasError('required')">
                                                          Le nouveau pin est requis
                                                      </div>
                                                      <div class="text-danger p-0" *ngIf="changePinForm.controls['newpin'].hasError('minlength') || changePinForm.controls['newpin'].hasError('maxlength') || this.isWrongNewPin">
                                                          Veuillez entrez un code pin valid contenant 4 chiffre svp
                                                      </div>
                                                  </div>
                                              </form>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary btn-modal-close" data-dismiss="modal">Fermer</button>
                                            <button type="submit" (click)="changePIN(oldpin.value, newpin.value)" [disabled]="changePinForm.invalid" class="btn btn-primary">Modifier</button>
                                        </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <!-- Middle Panel End -->
            </div>
        </div>
    </div>
    <!-- Content end -->

    <app-page-footer></app-page-footer>
</div>
  <!-- Document Wrapper end -->

