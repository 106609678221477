import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ConfirmedValidator } from './confirmed.validator';
import { CustomValidators } from './custom-validators';
import { LocalService } from '../../../services/local.service';

declare let $: any;
@Component({
  selector: 'app-page-signup-customer',
  templateUrl: './page-signup-customer.component.html',
  styleUrls: ['./page-signup-customer.component.scss'],
})
export class PageSignupCustomerComponent implements OnInit {
  signupForm!: FormGroup;
  passwordPattern ='^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}$';
  usernamePattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  specialCharacterPattern: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  wrongCredentials = false;
  errorMessage = '';
  wrongNumber: boolean = false;
  msisdn='';
  data:any=[];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private localStore: LocalService
  ) {}

  async onFormSubmit() {
    try {
      this.data = await this.authService.register(this.signupForm.value);
      if ('code' in this.data && 'status' in this.data) {
        if (this.data['code'] == '200' && this.data['status'] == true) {
          this.router.navigate(['/login']);
        } else {
          this.wrongCredentials = true;
          if (this.data['message']) {
            this.errorMessage = this.data['message'];
          } else {
            this.errorMessage = "L'inscription a echoué veuillez ressayer svp!";
          }
        }
      } else {
        this.wrongCredentials = true;
        if (this.data['message']) {
          this.errorMessage = this.data['message'];
        } else {
          this.errorMessage = "L'inscription a echoué veuillez ressayer svp!";
        }
      }
    } catch (error) {
      this.wrongCredentials = true;
      this.errorMessage = "L'inscription a echoué veuillez ressayer svp!";
    }
  }

  initialization() {
    var input = <HTMLInputElement>document.querySelector('#phone');
    //errorMsg = document.querySelector("#error-msg"),
    //validMsg = document.querySelector("#valid-msg");

    // Error messages based on the code returned from getValidationError
    var errorMap = [
      "Ce numéro n'est pas valide",
      'Code du pays non valide',
      'Numéro trop court',
      'Numéro trop long',
      'Numéro invalide',
    ];

    var intl = (<any>window).intlTelInput(input, {
      // allowDropdown: false,
      autoHideDialCode: false,
      // autoPlaceholder: "off",
      // dropdownContainer: document.body,
      // excludeCountries: ["us"],
      // formatOnDisplay: false,
      //initialCountry: "auto",
      initialCountry: 'ne',
      // geoIpLookup: function(callback) {
      //     $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
      //         var countryCode = (resp && resp.country) ? resp.country : "";
      //         callback(countryCode);
      //     });
      // },
      hiddenInput: 'full_number',
      //initialCountry: "ne",
      //sessionizedCountries: { 'ne': 'Niger' },
      // nationalMode: false,
      onlyCountries: ['ne'],
      // onlyCountries: ['us', 'gb', 'ch', 'ca', 'do'],
      // placeholderNumberType: "MOBILE",
      //preferredCountries: ['ne'],
      separateDialCode: true,
      utilsScript: 'assets/plugin/build/js/utils.js',
    });

    var reset = function () {
      $('#valid-msg').hide();
      $('#error-msg').hide();
    };

    // Validate on blur event
    $('#phone').on('blur keyup change countrychange', function () {
      reset();
      if (input.value.trim()) {
        if (intl.isValidNumber()) {
          //validMsg.classList.remove("hide");
          $('#valid-msg').show();
          $('#error-req').show();
          $('#error-msg').hide();
          $('#submit').prop('disabled', false);
        } else {
          input.classList.add('error');
          var errorCode = intl.getValidationError();
          $('#error-msg').html(
            '<i class="fas fa-exclamation-triangle mr-2"></i>' +
              errorMap[errorCode]
          );
          $('#valid-msg').hide();
          $('#error-req').hide();
          $('#error-msg').show();
          $('#submit').prop('disabled', true);
        }
      }
    });
  }

  ngOnInit() {
    this.msisdn = this.localStore.getData('msisdn');
    this.initialization();
    if (
      !this.localStore.getData('country-code') ||
      !this.localStore.getData('user-phone') ||
      !this.localStore.getData('otp-status') ||
      this.localStore.getData('otp-status') == 'invalid'
    ) {
      this.router.navigate(['/inscription/set-phone']);
    }
    // let phone_number=JSON.parse(spcphone);
    this.signupForm = this.formBuilder.group(
      {
        firstname: ['', [Validators.required]],
        lastname: ['', [Validators.required]],
        username: [
          '',
          [Validators.required, Validators.pattern(this.usernamePattern)],
        ],
        //password: ["", [Validators.required,Validators.pattern(this.passwordPattern)]],
        password: [
          '',
          Validators.compose([
            // 1. Password Field is Required
            Validators.required,
            // 2. check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, { hasNumber: true }),
            // 3. check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // 4. check whether the entered password has a lower-case letter
            CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
            // 5. check whether the entered password has a special character
            CustomValidators.patternValidator(this.specialCharacterPattern, {
              hasSpecialCharacters: true,
            }),
            // 6. Has a minimum length of 8 characters
            Validators.minLength(8),
          ]),
        ],
        confirmPassword: ['', Validators.required],
        birthday: ['', [Validators.required]],
        companyName: [''],
        nif: [''],
        rccm: [''],
        category: [''],
        userType: ['customer'],
        sex: [null, [Validators.required]],
        address: ['', [Validators.required]],
        phone: [this.localStore.getData('user-phone')],
        country: [this.localStore.getData('country-code')],
        code: [''],
        role: ['customer'],
      },
      {
        validator: ConfirmedValidator('password', 'confirmPassword'),
        //updateOn: 'blur',
      }
    );
  }
  get getControl() {
    return this.signupForm.controls;
  }
}
