<!-- Footer strat -->
<footer class="footer">
  <div class="foo-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-4 col-sm-6">
          <div class="widget foo-nav">
            <h5>Adresse</h5>
            <ul>
              <li><i class="fa fa-map-marker-alt"></i> Koira Kano, Niamey | NIGER</li>
              <li><i class="fa fa-phone-alt"></i> +227 20 37 04 40 | +227 90 11 22 12</li>
              <li><i class="fa fa-envelope me-3"></i> smartpay@dev4smart.net</li>
            </ul>
            <div class="d-flex mt-3 pt-2">
              <a class="btn btn-outline-light btn-circle rounded-circle" href="https://www.facebook.com/profile.php?id=100087358130436&mibextid=ZbWKwL"><i class="fab fa-facebook-f"></i></a>
              <a class="btn btn-outline-light btn-circle rounded-circle" href="https://twitter.com/NeSmartpay?t=BajCRnUj5KVDPCgykKJf0g&s=09"><i class="fab fa-twitter"></i></a>
              <a class="btn btn-outline-light btn-circle rounded-circle" href="https://www.linkedin.com/company/smartpay-ne/"><i class="fab fa-linkedin-in"></i></a>
          </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="widget foo-nav ">
            <h5>SMARTPAY</h5>
            <ul>
              <li><a class="link" href="javascript:void(0)">Recharge Airtime</a></li>
              <li><a class="link" href="javascript:void(0)">eCommerce & Monetisation</a></li>
              <li><a class="link" href="javascript:void(0)">Collecte de fonds & taxes</a></li>
              <li><a class="link" href="javascript:void(0)">Documents d'API</a></li>
              <li><a class="link" routerLink="/faq">FAQs</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6">
          <div class="widget foo-nav">
            <h5>Newsletter</h5>
            <p class="text-white text-justify">Inscrivez-vous à notre newsletter et recevez les dernières informations sur SMARTPAY !</p>
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Entrez votre adresse email" aria-label="email" aria-describedby="basic-addon2">
              <div class="input-group-append">
                <button class="btn btn-outline-primary" type="submit">Envoyer</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="foo-btm">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="foo-navigation">
            <ul>
              <li><a class="link" routerLink="/terms-conditions">Conditions d'utilisation</a></li>
              <li><a class="link" routerLink="/politique-confidentialite">Politique de confidentialité</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 text-center">
          <span class="text-white">
            <i class="fas fa-copyright"></i> 2020 - {{currentYearLong()}} <a class="border-bottom text-white" href="#">SmartPay</a> by <a class="border-bottom text-white" href="https://www.dev4smart.net" target="_blank">Dev4smart</a>
        </span>
      </div>
      </div>
    </div>
  </div>
</footer>
  <swal #swalBox  [swalVisible]="isSwalVisible" (confirm)="handleConfirm($event, swalConfirmData, swalComponentContext)"></swal>
