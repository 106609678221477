import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { ConfirmedValidator } from '../../inscription/page-signup-customer/confirmed.validator';
import { CustomValidators } from '../../inscription/page-signup-customer/custom-validators';
import { SweetAlertOptions } from 'sweetalert2';
import { FlashMessageService } from 'src/app/services/flash-message.service';
declare let $: any;

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss'],
})
export class UserChangePasswordComponent implements OnInit {
  user: any = [];
  updateForm!: FormGroup;
  passwordPattern =
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}$';
  usernamePattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  specialCharacterPattern: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  wrongCredentials = false;
  isCompany = false;
  errorMessage = '';
  swalOptions: SweetAlertOptions = { icon: 'info' };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private tokenStorageService: TokenStorageService,
    private swalService: FlashMessageService
  ) {}

  onFormSubmit() {
    this.authService.changePassword(this.updateForm.value).subscribe(
      (result) => {
        this.wrongCredentials = false;
        //this.router.navigate(['/profile']);
        this.swalOptions.icon = 'success';
        this.swalOptions.html = 'votre mots de passe a été modifié avec succès!';
        this.swalService.show(this.swalOptions);
      },
      (error) => {
        this.wrongCredentials = true;
        this.errorMessage = 'La modification a echoué veuillez ressayer svp!';
        this.swalOptions.icon = 'error';
        this.swalOptions.html ='La modification a echoué veuillez ressayer svp!';
        this.swalService.show(this.swalOptions);
      }
    );
    //this.router.navigate(['inscription/professionnel']);
  }

  ngOnInit() {
    this.user = this.tokenStorageService.getUser();
    this.user = JSON.parse(this.user);
    this.updateForm = this.formBuilder.group({});
    this.updateForm = this.formBuilder.group(
      {
        oldPassword: ['', Validators.required],
        password: [
          '',
          Validators.compose([
            // 1. Password Field is Required
            Validators.required,
            // 2. check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, { hasNumber: true }),
            // 3. check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // 4. check whether the entered password has a lower-case letter
            CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
            // 5. check whether the entered password has a special character
            CustomValidators.patternValidator(this.specialCharacterPattern, {
              hasSpecialCharacters: true,
            }),
            // 6. Has a minimum length of 8 characters
            Validators.minLength(8),
          ]),
        ],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: ConfirmedValidator('password', 'confirmPassword'),
        //updateOn: 'blur',
      }
    );
  }
}
