import { Component } from '@angular/core';
import { RechargeService } from 'src/app/services/recharge.service';

@Component({
  selector: 'app-user-transaction',
  templateUrl: './user-transaction.component.html',
  styleUrls: ['./user-transaction.component.scss']
})
export class UserTransactionComponent {
  transactions: any;
  data:any=[];
  page: number = 1;
  count: number = 0;
  tableSize: number = 7;
  tableSizes: any = [3, 6, 9, 12];
  constructor(private rechargeService: RechargeService) {}
  ngOnInit(): void {
    this.fetchPosts();
  }
  async fetchPosts() {
    try {
      this.data=await this.rechargeService.transaction();
      if ('data' in  this.data) {
        this.transactions=this.data['data'];
      }
    } catch (error) {

    }
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.fetchPosts();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.fetchPosts();
  }
}
