<!-- Header start -->
  <div class="header-top1">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-sm-8">
                <div class="d-inline-flex ml-auto">
                  <a href="#" class="top-text"><i class="fas fa-phone-alt"></i> (+227) 90 11 22 12</a>
                  <a href="#" class="top-text"><i class="fas fa-envelope"></i> smartpay@dev4smart.net</a>
                </div>
            </div>
            <div class="col-sm-4 text-sm-right">
                  <div class="social-icons">
                      <a href="https://www.facebook.com/profile.php?id=100087358130436&mibextid=ZbWKwL"><i class="fab fa-facebook-f"></i></a>
                      <a href="https://twitter.com/NeSmartpay?t=BajCRnUj5KVDPCgykKJf0g&s=09"><i class="fab fa-twitter"></i></a>
                    <a href="https://www.linkedin.com/company/smartpay-ne/"><i class="fab fa-linkedin"></i></a>
                  </div>
                  <div class="header-language dropdown" style="z-index:1000;position:relative;">
                      <a href="dropdown-toggle" class="langbtn d-flex" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <img src="assets/images/flags/france.png" alt="" class="mr-1" style="width:16px;height: 16px;">
                          <span style="margin-top:-5px;">FR</span>
                          <i class="fas fa-angle-down"></i>
                      </a>
                      <ul class="list-unstyled dropdown-menu">
                          <li class="active "><a href="#" class="d-flex"><img src="assets/images/flags/france.png" alt="" class="mr-1" style="width:16px;height: 16px;"> <span style="margin-top:-5px;">FR</span></a></li>
                          <li ><a href="#" class="d-flex"><img src="assets/images/flags/english.png" alt="" class="mr-1" style="width:16px;height: 16px;"> <span style="margin-top:-5px;">EN</span></a></li>
                      </ul>
                  </div>
            </div>
        </div>
    </div>
  </div>
  <header class="header">

    <div class="container d-flex align-items-center" style="position: relative;">
      <a class="logo" href="/">
        <img src="assets/images/logo.png" style="width: 60px; height:60px ;" alt="">
      </a>
      <nav class="primary-menu text-md-right">
        <a id="mobile-menu-toggler" href="javascript:void(0)"><i class="fas fa-bars"></i></a>
        <ul>
          <!-- <li><a routerLink="/" *ngClass="{'active': class=='login btn-round btn-outline btn'} " class="nav-b active" id="accueil" (click)="changeMenuTo('accueil')">Accueil</a></li> -->
          <li><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="login btn-round btn-outline btn nav-b" id="accueil" (click)="changeMenuTo('accueil')">Accueil</a></li>
          <li><a routerLink="/page-ecommerce" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="login btn-round btn-outline btn nav-b" id="apropos" (click)="changeMenuTo('apropos')">E-commerce</a></li>
          <li><a routerLink="/shop" routerLinkActive="active" class="login btn-round btn-outline btn nav-b" id="boutique" (click)="changeMenuTo('boutique')">Social Shop</a></li>
          <li><a routerLink="/page-restau-list" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="login btn-round btn-outline btn nav-b mr-3" id="contact" (click)="changeMenuTo('contact')">Restauration</a></li>
          <li><a *ngIf="!this.isLoggedIn" routerLink="/login" class="login btn btn-outline btn-round p-3 mr-3"><span class="bh"></span>
              <span>Connexion</span></a>
          </li>
          <li><a  *ngIf="!this.isLoggedIn" routerLink="/inscription/account-type" class="login btn_signup btn btn-outline btn-round p-3 "><span class="bh"></span>
              <span  class="btn_signup_text" style="text-transform: none;"> Ouvrir un compte</span></a>
          </li>
          <li *ngIf="this.isLoggedIn" class="has-menu-child pro-menu-drop">
            <a href="#">
                <div class="header-pro-thumb">
                    <img class="rounded-circle" src="assets/admin/images/profile.png" alt="">
                </div> {{ user.firstname }} {{ user.lastname }} <i class="fas fa-chevron-down"></i>
            </a>
            <ul class="dropdown-menu-md sub-menu profile-drop">
                <li class="dropdown-header bg-dark">
                    <div>
                        <div class="small text-white"><span>{{ user.username }}</span></div>
                    </div>
                </li>
                <li>
                    <hr class="mx-n3 mt-0">
                </li>
                <li class="nav__create-new-profile-link">
                    <a routerLink="/profile">
                        <span>Profile</span>
                    </a>
                </li>
                <li class="nav__create-new-profile-link">
                    <a routerLink="/dashboard">
                        <span>Tableau de bord</span>
                    </a>
                </li>
                <li class="divider"></li>
                <li class="nav__dropdown-menu-items">
                    <a href="profile-notifications.html">
                        <i class="icon icon-setting"></i>
                        <span>Parametres</span>
                    </a>
                </li>
                <li class="nav__dropdown-menu-items">
                    <a (click)="Logout()" href=""><i class="icon icon-logout"></i><span>Deconnexion</span></a></li>
            </ul>
          </li>
        </ul>
      </nav>
      <a href="/page-panier" class="btn position-relative mt-2">
        <i class="fa fa-shopping-cart fa-2x"></i>
        <span class="position-relative top-0 start-100 translate-middle badge rounded-pill bg-danger">
          {{ items }}
        </span>
      </a>
    </div>
  </header>
  <!-- <header class="header">
    <div class="container d-flex align-items-center">
      <a class="logo" href="/">
        <img src="assets/images/logo.png" style="width: 60px; height:60px ;" alt="">
      </a>
      <nav class="primary-menu text-md-right">
        <a id="mobile-menu-toggler" href="javascript:void(0)"><i class="fas fa-bars"></i></a>
        <ul>
          <li class="current-menu-item has-menu-child"><a href="javascript:void(0)">Home</a>
            <ul class="sub-menu">
              <li><a routerLink="/home-01">Home 01</a></li>
              <li><a routerLink="/accueil">Home 02</a></li>
              <li><a routerLink="/home-03">Home 03</a></li>
            </ul>
          </li>
          <li><a routerLink="/landing-page-send">Send Money</a></li>
          <li><a routerLink="/landing-page-receive">Receive Money</a></li>
          <li class="has-menu-child"><a href="javascript:void(0)">Pages</a>
            <ul class="sub-menu">
              <li><a routerLink="/how-it-works-send">How it works - Send</a></li>
              <li><a routerLink="/how-it-works-receive">How it works - Receive</a></li>
              <li><a routerLink="/how-it-works-paybills">How it works - Paybills</a></li>
              <li><a routerLink="/landing-page-ecommerce">Landing - Ecommerce</a></li>
              <li><a routerLink="/faq">Faq</a></li>
              <li><a routerLink="/404">404 Error page</a></li>
              <li><a routerLink="/coming-soon">Coming soon</a></li>
              <li><a routerLink="/signup">Sign up</a></li>
            </ul>
          </li>
          <li class="has-menu-child">
            <a routerLink="/about-01">About</a>
            <ul class="sub-menu">
              <li><a routerLink="/about-01">About 01</a></li>
              <li><a routerLink="/about-02">About 02</a></li>
            </ul>
          </li>
          <li class="has-menu-child">
            <a routerLink="/blog">Blog</a>
            <ul class="sub-menu">
              <li><a routerLink="/blog">Blog</a></li>
              <li><a routerLink="/blog-details">Blog Details</a></li>
            </ul>
          </li>
          <li><a routerLink="/contact">Contact</a></li>
          <li><a routerLink="/login" class="login btn btn-outline btn-round"><span class="bh"></span>
              <span>login</span></a>
          </li>
        </ul>
      </nav>
    </div>
  </header> -->
  <!-- Header end -->

