import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FlashMessageService, SwalConfirmItem } from 'src/app/services/flash-message.service';
import { LocalService } from 'src/app/services/local.service';
import { StoreService } from 'src/app/services/store.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { SweetAlertOptions } from 'sweetalert2';
declare let $: any;
@Component({
  selector: 'product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css'],
})
export class ProductListComponent implements OnInit {
  data: any = [];
  produits: any;
  user: any;
  storeId!: string;
  page: number = 1;
  count: number = 0;
  tableSize: number = 3;
  tableSizes: any = [3, 6, 9, 12];
  swalOptions: SweetAlertOptions = { icon: 'info' };
  currentProductonModal: any;
  carrouselIsSelected: boolean = true;
  formData!:FormData;
  selectedFile!: File;
  public confirmItem: SwalConfirmItem = {
    fnConfirm: this.deleteConfirm,
    confirmData: null,
    context: this,
  };

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private localStore: LocalService,
    private storeService: StoreService,
    private swalService: FlashMessageService
  ) {}

  async onFormSubmit() {
    try {
      console.log('Form Data', this.formData);
      const res = await this.storeService.uploadProductImage(this.formData);
      if ('status' in res && res['status']==true) {
        window.location.reload();
        this.swalOptions.icon = 'success'; //set the icon
        this.swalOptions.html = `L'image a été ajouter`;
        this.swalService.show(this.swalOptions);

      }else{
        this.swalOptions.icon = 'error';
        this.swalOptions.html = "L'image n'a pas été ajouter";
        this.swalService.show(this.swalOptions);
      }
    } catch (error) {
      this.swalOptions.icon = 'error';
      this.swalOptions.html = "L\'image n'a pas été ajouter";
      this.swalService.show(this.swalOptions);
    }
  }

  onFileSelected(event:any, imageFor:any) {
    this.selectedFile = <File>event.target.files;
    this.formData=new FormData();
    this.formData.append('product_id', this.currentProductonModal.id);
    let fileName=''
    for (const file of event.target.files) {
      this.formData.append(
        'products-files',
        file
      );
      fileName = fileName+file.name+';';
    }
    $('.custom-file-label').html(fileName);
    event.target.value = null;
    //this.formData.append('products-files', this.selectedFile, this.selectedFile.name);
  }
  ngOnInit() {
    this.user = JSON.parse(this.localStore.getData('user'));
    this.storeId = this.user.merchantStore.id;
    this.fetchPosts();
  }
  async fetchPosts() {
    try {
      this.data = await this.storeService.getProductByStoreId(this.storeId);
      if ('data' in this.data) {
        this.produits = this.data['data'];
      }
    } catch (error) {

    }
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.fetchPosts();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.fetchPosts();
  }
  public delete(value: any, name: any) {
    this.swalOptions.icon = 'question';
    this.swalOptions.title = 'Supprimer produit';
    this.swalOptions.html = `Etes vous sur de vouloir supprimer le produit ${name}?`;
    this.swalOptions.showConfirmButton = true;
    this.swalOptions.showCancelButton = true;
    this.confirmItem.confirmData = value; //pass the data to delete confirm function
    this.confirmItem.fnConfirm = this.deleteConfirm;
    this.swalService.setConfirm(this.confirmItem);
    this.swalService.show(this.swalOptions);
  }

  async deleteConfirm(isConfirm: string, data: any, context: any) {
    //console.log('delete item', data);
    // call the service to delete user
    try {
      const deleteReponse = await context.storeService.deleteProduct(data);
      this.swalOptions.showCancelButton = false; //just need to show the OK button
      context.confirmItem.fnConfirm = null; //reset the confirm function to avoid call this function again and again.
      this.swalService.setConfirm(context.confirmItem);
      if ('status' in deleteReponse && deleteReponse['status'] == true) {
        this.swalOptions.icon = 'success'; //set the icon
        this.swalOptions.html = `Le produit a été supprimer`;
      } else {
        this.swalOptions.icon = 'error';
        this.swalOptions.html = "Le produit n'a pas été supprimer";
      }
      await context.fetchPosts();
      this.swalService.show(this.swalOptions);
    } catch (error) {
      this.swalService.showErrors(error, this.swalOptions);
    }
  }

  voirImages(produit: any) {
    this.currentProductonModal = produit;
    $('.custom-file-label').html('Charger une image');
    console.log(this.currentProductonModal);
  }

  Slide() {
    if (this.carrouselIsSelected) {
      this.carrouselIsSelected = false;
    } else {
      this.carrouselIsSelected = true;
    }
  }
}
