<!-- Document Wrapper  -->
<div id="main-wrapper">
    <app-user-header></app-user-header>

    <app-user-profilebar></app-user-profilebar>


    <!-- Admin Content Section  -->
    <div id="content" class="py-4">
        <div class="container">
            <div class="row">
                  <div class="col-lg-3" >
                    <app-user-left-menu></app-user-left-menu>
                  </div>
                  <!-- Middle Panel  -->
                  <div class="col-lg-9">
                      <app-store-navbar></app-store-navbar>
                      <div class="tab-content py-4" id="pills-tabContent">
                        <div class="row p-0 m-0">
                          <div class="col-4 p-0 m-0">
                            <h5 href="" class=" mb-4 m-0">Liste des produits</h5>
                          </div>
                          <div class="col-8 p-0 m-0">
                            <div class="input-group input-group-sm">
                              <input type="text" class="form-control" placeholder="search" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
                              <div class="input-group-append">
                                <span class="input-group-text bg-dark" id="inputGroup-sizing-sm"><i class="text-white fa fa-search"></i></span>
                                <a routerLink="/store/products/add" class="bg-primary input-group-text"><i class="text-white fa fa-plus"></i></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-deck row  mb-5">
                            <div class="card col-md-4 p-0 items" *ngFor="let produit of produits
                                  | paginate
                                    : {
                                        itemsPerPage: tableSize,
                                        currentPage: page,
                                        totalItems: count
                                      };
                                  let i = index
                                ">
                              <img class="card-img-top" style="max-height: 200px !important;min-height: 200px !important; height: 200px important;" *ngIf="produit.images.length == 0" src="assets/images/posts/1.jpg" alt="Card image cap">
                              <div style="max-height: 200px;">
                                <img class="card-img-top" style="max-height: 200px !important;min-height: 200px !important; height: 200px important;" *ngIf="produit.images.length > 0" ngSrc="merchant-store/v1/logo/{{ produit.images[0].id }}" height="200" width="100" priority alt="{{ produit.name }}"  crossorigin="anonymous">
                              </div>
                              <p *ngIf="!produit.archive || produit.quantity > 0" class="pirotry text-right bg-success">Actif</p>
                              <p *ngIf="produit.archive || produit.quantity == 0" class="pirotry text-right bg-danger">Inactif</p>
                              <div class="card-body py-2 bg-secondary">
                                <h6 class="card-title mb-0 text-white">{{ produit.name }}</h6>
                              </div>
                              <div class="card-footer">
                                <small><i class="fa fa-hourglass-half mr-2"></i>{{ produit.quantity }} en stock</small>
                                <small class="float-right"><i class="fa fa-money-bill-wave mr-2"></i>{{ produit.price }} XOF</small>
                                <p class="card-text text">{{ produit.description }}</p>
                              </div>
                              <div class="card-footer">
                                <span class="float-right">
                                  <span class="mr-3"></span>
                                  <a class="mr-3" href="#" (click)="voirImages(produit)" data-toggle="modal" data-target="#picture"><i class="fa fa-images"></i></a>
                                  <a [routerLink]="['/store/products/edit/', produit.id ]" class="text-primary mr-3"><i class="fa fa-edit"></i></a>
                                  <span (click)="delete(produit.id,produit.name)" href="" class="text-danger"><i class="fa fa-trash"></i></span>
                                </span>
                                <small class="text-muted"><i class="fa fa-tag mr-2"></i>{{ produit.category }}</small>
                              </div>
                            </div>
                        </div>
                        <pagination-controls
                          previousLabel="Prev"
                          nextLabel="Next"
                          (pageChange)="onTableDataChange($event)"
                      ></pagination-controls>
                      </div>
                  </div>
            </div>
        </div>
    </div>
    <!-- Content end -->
<!-- MODAL -->
<div class="modal fade step-secourity " id="picture" tabindex="-1" role="dialog" aria-labelledby="pictureModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-header bg-dark">
              <h5 class="modal-title text-primary" id="pictureModalLabel">
                  <span *ngIf="currentProductonModal">{{ currentProductonModal.name }}</span>
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
            <div *ngIf="carrouselIsSelected && currentProductonModal" id="carouselExampleControls" class="carousel slide" data-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active" *ngIf="currentProductonModal.images.length == 0">
                  <img class="d-block w-100" src="assets/images/posts/1.jpg" alt="First slide">
                </div>
                <div style="min-height: 300px;" class="carousel-item" *ngFor="let image of currentProductonModal.images;let index = index" [class.active]="index == 0">
                  <img class="d-block w-100" ngSrc="merchant-store/v1/logo/{{ image.id }}" fill priority alt="Second slide"  crossorigin="anonymous">
                </div>
              </div>
              <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
            <form *ngIf="!carrouselIsSelected" (submit)="onFormSubmit()" class="py-5">
              <div class="input-group mb-3">
                <div class="custom-file">
                  <input type="file" accept="image/png, image/jpeg" class="custom-file-input" id="imgProduct" multiple (change)="onFileSelected($event, 'FRONT')" aria-describedby="inputGroupFileAddon01">
                  <label class="custom-file-label" for="inputGroupFile01">Charger une image</label>
                </div>
              </div>
              <button type="submit" class="float-right btn-sp color-primary w-100" [disabled]="!formData">Enregistrer</button>
            </form>
          </div>
          <div class="modal-footer">
              <!-- <button type="button" class="btn btn-secondary btn-modal-close" data-dismiss="modal">Fermer</button> -->
              <button type="submit" (click)="Slide()"  class="btn btn-primary mr-0"><i *ngIf="carrouselIsSelected" class="fa fa-plus"></i><i *ngIf="!carrouselIsSelected" class="fa fa-images"></i></button>
          </div>
      </div>
  </div>
</div>
<!-- /MODAL -->
    <app-page-footer></app-page-footer>
</div>
<!-- Document Wrapper end -->
