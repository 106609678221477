<!-- Document Wrapper  -->
<div id="main-wrapper">
    <app-user-header></app-user-header>
    
    <app-user-profilebar></app-user-profilebar>
    
  
    <!-- Admin Content Section  -->
    <div id="content" class="py-4">
        <div class="container">
            <div class="row">
                  <div class="col-lg-3">
                  <app-user-left-menu></app-user-left-menu>
                  </div>
                  <!-- Middle Panel  -->
                  <div class="col-lg-9">
                      <div class="profile-content">
                          <h3 class="admin-heading bg-offwhite">
                              <p>Transaction details</p>
                              <span>Details Activity</span>
                          </h3>
                          <div class="order-summery transaction-summery">
                              <div class="order-details-area table">
                                  <div class="table-head">
                                      <div class="modal_header">
                                          <span class="status bg-success">Paid</span>
                                          <div class="row">
                                              <div class="col">
                                                  <div class="content-area">
                                                      <b>Payment sent to</b>
                                                      <i class="fas fa-building"></i>
                                                      Envato Pty Ltd
                                                      <p>23 October 2019 at 10:59:14 CEST</p>
                                                      <p> Transaction ID: 3SN08103GH522126652E</p>
                                                      <p>Payment Status: COMPLETED</p>
                                                      <p>Payment Type: Checkout</p>
                                                  </div>
                                              </div>
                                              <div class="col">
                                                  <div class="content-area text-right">
                                                      <div class="price">
                                                          <b>Ammount</b>
                                                          <p>$559.00</p>
                                                      </div>                                                        
                                                  </div>
  
                                              </div>
                                          </div>
  
                                      </div>
  
                                      <div class="table-content bg-offwhite">
                                          <b>
                                              <div class="row">
                                                  <div class="col">Order Details</div>
                                                  <div class="col">Qunatity</div>
                                                  <div class="col">Price</div>
                                                  <div class="col text-right">Subtotal</div>
                                              </div>
                                          </b>
                                      </div>
                                      <div class="table-content">
  
                                          <div class="row">
                                              <div class="col">
                                                  Leospa – Clean & Minimal SCSS HTML5 Template - Single Licence</div>
                                              <div class="col">01</div>
                                              <div class="col">9.99 USD</div>
                                              <div class="col text-right">9.99 USD</div>
                                          </div>
                                      </div>
                                      <div class="table-content">
                                          <div class="row">
                                              <div class="col">
                                                  Leospa – Clean & Minimal SCSS HTML5 Template - Single Licence</div>
                                              <div class="col">01</div>
                                              <div class="col">9.99 USD</div>
                                              <div class="col text-right">9.99 USD</div>
                                          </div>
                                      </div>
                                      <div class="table-content">
                                          <div class="row">
                                              <div class="col">
                                                  Leospa – Clean & Minimal SCSS HTML5 Template - Single Licence</div>
                                              <div class="col">01</div>
                                              <div class="col">9.99 USD</div>
                                              <div class="col text-right">9.99 USD</div>
                                          </div>
                                      </div>
  
                                      <div class="row">
                                          <div class="col-md-5 ml-auto">
                                              <div class="sub-content">
                                                  <br>
                                                  <div class="row">
                                                      <div class="col">Purchase Total</div>
                                                      <div class="col text-right"> -6,00 USD</div>
                                                  </div>
                                                  <div class="row">
                                                      <div class="col">Sales Tax</div>
                                                      <div class="col text-right"> 0,00 USD</div>
                                                  </div>
                                                  <div class="row">
                                                      <div class="col">Shipping Amount</div>
                                                      <div class="col text-right">0,00 USD</div>
                                                  </div>
                                                  <div class="row">
                                                      <div class="col">Handling Amount</div>
                                                      <div class="col text-right">0,00 USD</div>
                                                  </div>
                                                  <div class="row">
                                                      <div class="col">Insurance Amount</div>
                                                      <div class="col text-right">0,00 USD</div>
                                                  </div>
                                                  <div class="row">
                                                      <div class="col">Gross Amount</div>
                                                      <div class="col text-right">-6,00 USD</div>
                                                  </div>
                                                  <div class="row">
                                                      <div class="col">Roysha Fee</div>
                                                      <div class="col text-right">0,00 USD</div>
                                                  </div>
                                                  <hr>
                                                  <div class="row">
                                                      <div class="col"><b>Net Amount</b></div>
                                                      <div class="col text-right">6,00 USD</div>
                                                  </div>
                                                  <div class="row">
                                                      <div class="col">
  
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="table-footer mt-3 bg-offwhite p-3">
                                          <div class="row">
                                              <div class="col">
                                                  <p><b>Invoice ID: </b>8c8e15c76d4a059xccfe0dad4e5ce5sd</p>
                                                  <p class="mb-0"><b>Funding details</b></p>
                                                  <p>
                                                      Funding Type: Promotional Return
                                                      Funding Source: -6,00 USD - PayPal Account
                                                  </p>
                                              </div>
                                              <div class="col">
                                                  <p class="mb-0"><b>Contact info</b></p>
                                                  <p>
                                                      Envato Pty Ltd
                                                      The recipient of this payment has verified their account and is located outside the US.
                                                      http://market.envato.com/
                                                      help@market.envato.com
                                                  </p>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="table-footer mt-3 bg-offwhite p-3">
                                          <div class="row">
                                              <div class="col">
                                                  <div class="invoice-option  btn-refund text-center my-4">
                                                      <a href="#" class="invoice-btn btn"><i class="far fa-file-pdf"></i> Save as PDF</a>
                                                      <a href="#" class="invoice-btn btn"><i class="fas fa-print"></i> Print Receipt</a>
                                                      <a href="#" class="invoice-btn btn"><i class="far fa-envelope"></i> Email Receipt</a>
                                                      <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-reply"></i> Action 
                                                          <div class="dropdown-menu">
                                                                  <a class="dropdown-item" href="#">Refund</a>
                                                                  <a class="dropdown-item" href="#">Archive</a>
                                                              </div>
                                                      </button>
                                                                                                                  
                                                  </div>
                                                                                                
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <!-- Recent Activity End -->
                      </div>
                      <!-- Middle Panel End -->
                  </div>
                  <!-- Middle Panel End -->
            </div>
        </div>
    </div>
    <!-- Content end -->
  
    <app-page-footer></app-page-footer>
  </div>
  <!-- Document Wrapper end -->
  
