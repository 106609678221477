import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalService } from 'src/app/services/local.service';

declare let $: any;
@Component({
  selector: 'app-user-shapshap',
  templateUrl: './user-shapshap.component.html',
  styleUrls: ['./user-shapshap.component.scss'],
})
export class UserShapshapComponent implements OnInit {
  isValidFormSubmitted = false;
  //code: string='ne';
  data: any = [];
  country_code: string = 'ne';
  private recaptchaSiteKey = '6LcdOz4iAAAAAOtDEOnNWQc1v2Q3pRfRbKYuxVrG';
  rechargeForm!: FormGroup;
  wrongNumber: boolean = false;
  errorMessage!: string;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private localStore: LocalService
  ) {}

  onFormSubmit(code: string, dialCode: string) {
    let full_number = dialCode + this.rechargeForm.value.phone;
    console.log(full_number);
    this.localStore.removeData('shapshap_msisdn');
    this.localStore.saveData('shapshap_msisdn', full_number);
    this.router.navigate(['/shapshap/montant']).then(()=>{
      window.location.reload()
    });
  }

  initialization() {
    var input = <HTMLInputElement>document.querySelector('#phone');
    var errorMap = [
      "Ce numéro n'est pas valide",
      'Code du pays non valide',
      'Numéro trop court',
      'Numéro trop long',
      'Numéro invalide',
    ];

    var intl = (<any>window).intlTelInput(input, {
      autoHideDialCode: false,
      initialCountry: 'ne',
      hiddenInput: 'full_number',
      preferredCountries: ['ne'],
      separateDialCode: true,
      utilsScript: 'assets/plugin/build/js/utils.js',
    });

    var reset = function () {
      $('#valid-msg').hide();
      $('#error-msg').hide();
    };

    // Validate on blur event
    $('#phone').on('blur keyup change countrychange', () => {
      //reset();
      if (input.value.trim()) {
        if (intl.isValidNumber()) {
          this.isValidFormSubmitted = true;
          $('#valid-msg').show();
          $('#error-req').show();
          $('#error-msg').hide();
          $('#submit').prop('disabled', false);
        } else {
          input.classList.add('error');
          this.isValidFormSubmitted = false;
          var errorCode = intl.getValidationError();
          $('#error-msg').html(
            '<i class="fas fa-exclamation-triangle mr-2"></i>' +
              errorMap[errorCode]
          );
          $('#valid-msg').hide();
          $('#error-req').hide();
          $('#error-msg').show();
          $('#submit').prop('disabled', true);
        }
      }
    });
  }
  ngOnInit() {
    this.initialization();
    this.rechargeForm = this.formBuilder.group(
      {
        phone: [null],
        code: [null],
      },
      {
        updateOn: 'change',
      }
    );

    $('#valid-msg').hide();
    $('#error-msg').hide();
    $('#submit').prop('disabled', true);
  }
}
