import {Component, OnInit} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from "../../../services/auth.service";
import { TokenStorageService } from "../../../services/token-storage.service";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
//const EMAIL_VERIFICATION_API="/user/v1/secure/reset-password/otp";
const EMAIL_VERIFICATION_API="/user/v1/secure/reset-pass";
const OTP_VERIFICATION_API="/an/v1/secure/validate-payment";
const SET_NEW_PASSWORD_API="/an/v1/secure/check-payment";
declare let $: any;
@Component({
  selector: 'app-page-forgot-password-set-email',
  templateUrl: './page-forgot-password-set-email.component.html',
  styleUrls: ['./page-forgot-password-set-email.component.scss']
})
export class PageForgotPasswordSetEmailComponent implements OnInit {
  forgotPasswordForm!: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  data: any= [];
  errorMessage='';
  wrongCredentials=false;
  form: any = {};
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {
      // redirect to home if already logged in
      if (this.authService.currentUserValue) {
        console.log(this.authService.currentUserValue);
          this.router.navigate(['/']);
      }
  }

  get f() { return this.forgotPasswordForm.controls; }

  async onForgotPasswordFormSubmit() {
    $("#spinner").show();
    $('#text').text("En cours...");

    const request=await new Observable<boolean>(observer=>{
      this.http.post(environment.baseURL+EMAIL_VERIFICATION_API,{
        email:this.f["email"].value
      },{headers: new HttpHeaders({ 'Content-Type': 'application/json'})}).subscribe(result=>{
        this.data=result;
        observer.next(true);
        observer.complete();
      },(error:HttpErrorResponse)=>{
        observer.error(false);
        observer.complete();
      })
    });

    request.subscribe(result=>{
      this.wrongCredentials=false;
      console.log(this.data);
      $("#spinner").hide();
      $('#text').text("Envoyer");
      if('status' in this.data){
        if(this.data['status']==true){
          sessionStorage.removeItem('fpe');
          sessionStorage.setItem('fpe',this.f["email"].value);
          this.router.navigateByUrl('/forgot-password/set-otp');
        }else{
          this.wrongCredentials=true;
          this.errorMessage="Utilisateur introuvable, veuillez vérifier l'adresse !";
        }
      }else{
        this.wrongCredentials=true;
        this.errorMessage="Utilisateur introuvable, veuillez vérifier l'adresse !";
      }
    },error=>{
      this.wrongCredentials=true;
      this.errorMessage="Utilisateur introuvable, veuillez vérifier l'adresse !";
    });
    $("#spinner").hide();
    $('#text').text("Envoyer");
  }

  reloadPage() {
    window.location.reload();
  }

  initialization() {
    //======================
    // Preloder
    //======================
    $(window).on("load", function () {
      $("#status").fadeOut();
      $("#preloader").delay(500).fadeOut("slow");
      $("body").delay(500).css({overflow: "visible"});
    });
    $(".sl-slider-caro").owlCarousel({dots: true, items: 1, nav: false, smartSpeed: 500});
  }

  ngOnInit():void {
    $("#spinner").hide();
    this.initialization();
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

}
