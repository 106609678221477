<div class="blog-page">
    <app-page-header-shop></app-page-header-shop>
    <!-- Banner section start -->
    <section class="banner features pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8 m-auto">
            <div class="ban-content text-center">
              <h1 style="text-transform:none;">{{ store.name }}</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner section end -->

    <!-- Blog single post section start -->
    <section class="blog-single-post">
      <div class="container p-0">
        <div class="row">
          <div class="col-md-12">
            <article class="post-item">
              <!-- Testimonial section start -->
              <div class="testimonial ">
                <div class="container">
                  <div class="test-caro owl-carousel">
                    <div class="single-testimonial">
                      <div class="">
                        <img src="assets/images/blog/1.jpg" alt="">
                      </div>
                    </div>
                    <div class="single-testimonial">
                      <div class="">
                        <img src="assets/images/blog/1.jpg" alt="">
                      </div>
                    </div>
                    <div class="single-testimonial">
                      <div class="">
                        <img src="assets/images/blog/1.jpg" alt="">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Testimonial section end -->
              <!-- <figure class="post-thumb">
                <img src="assets/images/blog/1.jpg" alt="">
              </figure> -->
              <div class="row">
                <div class="col-12">
                  <ul class="nav nav-tabs list-tab-nav">
                    <li><a class="active" data-toggle="tab" href="#benefits-1">Catalogue</a></li>
                    <li><a data-toggle="tab" href="#benefits-2">A Propos</a></li>
                  </ul>

                  <div class="tab-content">
                    <div id="benefits-1" class="tab-pane fade in active show">

                      <div class="row row-cols-1 row-cols-md-3 g-4 my-5">
                        <div class="col-md-3 mb-4" *ngFor="let produit of produits
                                  | paginate
                                    : {
                                        itemsPerPage: tableSize,
                                        currentPage: page,
                                        totalItems: count
                                      };
                                  let i = index
                                ">
                          <div class="card">
                            <img class="card-img-top" style="max-height: 200px !important;min-height: 200px !important; height: 200px important;" *ngIf="produit.images.length == 0" src="assets/images/posts/1.jpg" alt="Card image cap">
                            <div style="max-height: 200px;">
                              <img class="card-img-top" style="max-height: 200px !important;min-height: 200px !important; height: 200px important;" *ngIf="produit.images.length > 0" ngSrc="merchant-store/v1/logo/{{ produit.images[0].id }}" height="200" width="100" priority alt="{{ produit.name }}"  crossorigin="anonymous">
                            </div>
                            <div class="card-body">
                              <h5 class="card-title text">{{ produit.name }}</h5>
                              <p class="card-text text">{{ produit.description }}</p>
                                <span class=""><i class="fa fa-money-bill-alt text-primary"></i> {{ produit.price }} XOF</span>
                                <span style="cursor: pointer;" (click)="addProductToShoppingCart(produit)" class="float-right"><i class="fa fa-cart-arrow-down"></i></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <pagination-controls
                          previousLabel="Prev"
                          nextLabel="Next"
                          (pageChange)="onTableDataChange($event)"
                      ></pagination-controls>
                    </div>
                    <div id="benefits-2" class="tab-pane fade">
                      <div class="content-box mt-5">
                        <h5 style="text-transform:none;">{{ store.name }}</h5>
                        <p>{{ store.description }}</p>
                      </div>
                      <div class="sidebar my-">
                        <aside class="widget widget_categories">
                          <ul>
                            <li>
                              <a href="javascript:void(0)"><i class="fa fa-map-marker-alt mr-2 text-primary"></i>{{ store.address }}</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)"><i class="fa fa-phone-alt mr-2 text-primary"></i>{{ store.phone }}</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)"><i class="fa fa-envelope mr-2 text-primary"></i>shop@ecommerce.com</a>
                            </li>
                          </ul>
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
    <!-- Blog single post section end -->
    <app-page-footer></app-page-footer>
  </div>

