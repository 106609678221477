import {Component, OnInit} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from "../../../services/auth.service";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { FlashMessageService } from "src/app/services/flash-message.service";

declare let $: any;

@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.scss'],
})
export class PageLoginComponent implements OnInit {
  loginForm!: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  loginData: any;
  errorMessage = '';
  wrongCredentials = false;
  form: any = {};
  swalOptions: SweetAlertOptions = { icon: 'info' };
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private swalService: FlashMessageService
  ) {
    // redirect to home if already logged in
    if (this.authService.currentUserValue) {
      console.log(this.authService.currentUserValue);
      this.router.navigate(['/']);
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  async onLoginFormSubmit() {
    this.loginData = await this.authService.login({
      username: this.f['username'].value,
      password: this.f['password'].value,
    });
    if (
      this.loginData['code'] == '200' &&
      this.loginData['status'] == true &&
      this.loginData['data'] != ''
    ) {
      this.wrongCredentials = false;
      setTimeout(() => {
        this.router.navigateByUrl('/dashboard').then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
      }, 1000);
    } else {
      this.wrongCredentials = true;
      this.errorMessage = 'Email ou mot de passe incorrect';
    }
  }

  reloadPage() {
    window.location.reload();
  }

  initialization() {
    //======================
    // Preloder
    //======================
    const fp_m = sessionStorage.getItem('fp-m');
    const fp_t = sessionStorage.getItem('fp-t');
    if (fp_t && fp_m) {
      if (fp_t == 'success') {
        this.swalOptions.icon = 'success';
        this.swalOptions.html = 'Votre mot de passe a été modifié avec succès !';
        this.swalService.show(this.swalOptions);
      } else {
        this.swalOptions.icon = 'error';
        this.swalOptions.html = 'Votre mot de passe n\'a pas été modifié !';
        this.swalService.show(this.swalOptions);
      }
      sessionStorage.removeItem('fp-t');
      sessionStorage.removeItem('fp-m');
    }
  }

  ngOnInit(): void {
    this.initialization();
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }
}
