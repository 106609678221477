import { Component } from '@angular/core';

@Component({
  selector: 'app-user-transaction-details',
  templateUrl: './user-transaction-details.component.html',
  styleUrls: ['./user-transaction-details.component.scss']
})
export class UserTransactionDetailsComponent {

}
