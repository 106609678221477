import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalService } from './local.service';
import { Observable } from 'rxjs';
const TRANSACTION_ID_API = '/recharge/v1/init/payments';
const CHECK_TRANSACTION_STATUS_API = '/recharge/v1/c2s';
const PIN_VALIDATION_API = '/recharge/v1/enquiry/payments';
const TRANSACTION_API = '/payment-method-manager/history';
@Injectable({
  providedIn: 'root',
})
export class RechargeService {
  isLoading = false;
  isError = false;
  data: any;
  transactionData: any;
  c2sData: any;
  rechargeData: any;
  constructor(private http: HttpClient, private localStore: LocalService) {}

  async payCredite(
    amount: number,
    pin: string | null,
    mobile_money_msisdn: string | null,
    payment_method: 'SPWALLET' | 'MOBILEMONEY' | 'CREDITCARD',
    country: 'NE'
  ): Promise<any> {
    try {
      const data = await this.http
        .post<any>(
          environment.baseURL + TRANSACTION_ID_API,
          {
            amount: {
              currency: 'XOF',
              value: amount,
            },
            wallet: {
              pin: pin,
            },
            payment_method: payment_method, //MOBILEMONEY, SPWALLET, CREDITCARD
            mobile_money: {
              msisdn: mobile_money_msisdn,
            },
            country: country,
          },
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.localStore.getData('token'),
            }),
          }
        )
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }

  async pinValidation(transaction_id: string): Promise<any> {
    try {
      const data = await this.http
        .post<any>(
          environment.baseURL + PIN_VALIDATION_API,
          {
            transaction_id: transaction_id,
          },
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.localStore.getData('token'),
            }),
          }
        )
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }

  async c2s(transaction_id: string, msisdn: string): Promise<any> {
    try {
      const data = await this.http
        .post<any>(
          environment.baseURL + CHECK_TRANSACTION_STATUS_API,
          {
            msisdn: msisdn,
            transaction: {
              id: transaction_id,
            },
          },
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.localStore.getData('token'),
            }),
          }
        )
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }

  async transaction(): Promise<any> {
    try {
      const data = await this.http
        .get<any>(
          environment.baseURL + TRANSACTION_API,
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.localStore.getData('token'),
            }),
          }
        )
        .toPromise();
      return data;
    } catch (error) {
      return "Une erreur s'est produite :" + error;
    }
  }
}
