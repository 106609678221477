import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { LocalService } from 'src/app/services/local.service';
import { RechargeService } from 'src/app/services/recharge.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { TransferService } from 'src/app/services/transfer.service';
import { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-user-transfer-confirme',
  templateUrl: './user-transfer-confirme.component.html',
  styleUrls: ['./user-transfer-confirme.component.css']
})
export class UserTransferConfirmeComponent {
  balance!: number;
  user: any = [];
  userData: any = [];
  pm: any = 'SPWALLET';
  pf: number = 0;
  total!: number;

  // =================OTP=======================
  validateCode = true;
  codePattern?: string;
  otp!: string;
  account_number!: string;
  amount!: number;
  data: any = [];
  c2sData: any = [];
  rechargeForm!: FormGroup;
  isWrongOtp = false;
  isValidOtp = false;
  errorMessage!: string;
  swalOptions: SweetAlertOptions = { icon: 'info' };
  // ==============================
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private http: HttpClient,
    private authService: AuthService,
    private localStore: LocalService,
    private swalService: FlashMessageService,
    private transferService: TransferService
  ) {}
  async confirmation() {
    if (this.otp.length == 4) {
      try {
        this.data = await this.transferService.transfer(
          this.amount,
          this.otp,
          this.account_number
        );
        this.localStore.removeData('transfer-status');
        this.localStore.saveData('transfer-status', JSON.stringify(this.data));
        if (this.data['code']=='401') {
          this.swalOptions.icon = 'info';
          this.swalOptions.html = this.data['message'];
          this.swalService.show(this.swalOptions);
        }else{
          this.router.navigate(['/transfer/statut']);
        }
      } catch (error) {
        this.swalOptions.icon = 'error';
        this.swalOptions.html = 'La transaction a echoué veuillez ressayer svp!';
        this.swalService.show(this.swalOptions);
        //this.errorMessage = "La transaction a echoué veuillez ressayer svp!";
      }
    } else {
      this.swalOptions.icon = 'info';
      this.swalOptions.html = "Le code otp n'est pas valide!";
      this.swalService.show(this.swalOptions);
    }
  }
  onOtpChange(otp: string) {
    this.otp = otp;
    if (this.otp.length==4) {
      this.confirmation();
    }
  }
  ngOnInit() {
    this.userData = this.tokenStorageService.getUser();
    this.account_number = this.localStore.getData('transfer_account_number');
    this.amount = parseInt(this.localStore.getData('transfer_amount'));
    if (!this.userData || !this.account_number || !this.amount) {
      this.router.navigateByUrl('/transfer');
    }
    this.user = JSON.parse(this.userData);
    this.balance = this.user.wallet.balance;
    this.pf = 0;
    this.total = this.amount + this.pf;

    this.rechargeForm = this.formBuilder.group(
      {
        code: ['', [Validators.required]],
      },
      {
        updateOn: 'blur',
      }
    );
  }
}
