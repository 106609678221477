import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SweetAlertOptions } from 'sweetalert2';
import { AuthService } from '../services/auth.service';
import { FlashMessageService } from './flash-message.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  swalOptions: SweetAlertOptions = { icon: 'error' };
  message:string="";
  constructor(
    private authService: AuthService,
    private swalService: FlashMessageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if ([400,401,403,404,500, 0].includes(err.status)) {
          switch (err.status) {
            case 0:
              this.message = 'Blocage d’une requête multiorigine';
              break;
            case 400:
              this.message = 'La requête est invalide ou mal formée';
              break;
            case 401:
              this.authService.logout();
              this.message ='Authentifiez-vous pour accéder à la ressource demandée';
              break;
            case 403:
              this.message = 'Accés refuser';
              break;
            case 404:
              this.message =
                "La ressource demandée n'a pas été trouvée sur le serveur";
              break;
            case 500:
              this.message =
                "Une erreur interne s'est produite du côté du serveur lors du traitement de la requête.";
              break;
            default:
              this.message = 'Une erreur est survenue';
              break;
          }
          this.swalOptions.icon = 'error';
          this.swalOptions.html = this.message;
          this.swalService.show(this.swalOptions);
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
