import {Component, OnInit} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from "../../../services/auth.service";
import { TokenStorageService } from "../../../services/token-storage.service";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const OTP_VERIFICATION_API="/user/v1/secure/reset-password/validate/otp";
declare let $: any;
@Component({
  selector: 'app-page-forgot-password-set-otp',
  templateUrl: './page-forgot-password-set-otp.component.html',
  styleUrls: ['./page-forgot-password-set-otp.component.scss']
})
export class PageForgotPasswordSetOtpComponent implements OnInit {

  loading = false;
  submitted = false;
  error = '';
  wrongCredentials=false;
  form: any = {};
// ================otp==================
  validateCode = true;
  codePattern!: string;
  otp!: string;
  private data: any = [];
  phone!: string | null;
  email!: string | null;
  forgotPasswordForm!: FormGroup;
  isWrongOtp=false;
  isValidOtp=false;
  errorMessage!: string;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {
      // redirect to home if already logged in
      if (this.authService.currentUserValue) {
        console.log(this.authService.currentUserValue);
          this.router.navigate(['/']);
      }
  }

  get f() { return this.forgotPasswordForm.controls; }
  onFormSubmit() {
    if(this.otp.length==6){
      const fpe=sessionStorage.getItem('fpe');
      if(fpe){
        $("#spinner").show();
        $('#text').text("En cours...");
        const request=new Observable<boolean>(observer=>{
          this.http.post(environment.baseURL+OTP_VERIFICATION_API,{
            otp: this.otp,
            //username: fpe
          }).subscribe(result=>{
            this.data=result;
            observer.next(true);
            observer.complete();
          },(error:HttpErrorResponse)=>{
            observer.error(false);
            observer.complete();
          })
        });

        request.subscribe(result=>{
          console.log(this.otp);
          if('status' in this.data){
            if(this.data['status']==true){
              sessionStorage.setItem('fp-otp',this.otp)
              this.isWrongOtp=false;
              this.isValidOtp=true
              this.router.navigateByUrl('/forgot-password/set-new-password');
            }else{
              sessionStorage.setItem('otps','spof')
              this.isWrongOtp=true;
              this.isValidOtp=false;
              if("message" in this.data){
                this.errorMessage=this.data['message'];
              }else{
                this.errorMessage="une erreur est survenue";
              }
            }
          }else{
            this.isWrongOtp=true;
            this.isValidOtp=false;
            this.errorMessage="une erreur est survenue";
          }
          $("#spinner").hide();
          $('#text').text("Confirmer");
        },error=>{
          this.isWrongOtp=true;
          this.isValidOtp=false;
          this.errorMessage="une erreur est survenue";
          $("#spinner").hide();
          $('#text').text("Confirmer");
        })
      }else{
        this.router.navigateByUrl('/forgot-password');
      }
    }


  }

  onOtpChange(otp: string) {
    this.otp = otp.toString();
  }

  ngOnInit() {
    $("#spinner").hide();
    let fpe=sessionStorage.getItem('fpe');
    if(!fpe){
        this.router.navigate(['/forgot-password']);
    }
    this.forgotPasswordForm = this.formBuilder.group({
      code: ["", [Validators.required]]
    },
    {
      updateOn: 'blur'
    });

  }

}
