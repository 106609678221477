import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOtpInputModule } from  'ng-otp-input';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtModule } from "@auth0/angular-jwt";
import { AppComponent } from './app.component';
import { PageFooterComponent } from './components/partials/page-footer/page-footer.component';
import { PageAccueilComponent } from './components/front/page-accueil/page-accueil.component';
import { PageNotFoundComponent } from './components/front/page-not-found/page-not-found.component';
import { PageHeaderComponent } from './components/partials/page-header/page-header.component';
import { PageHeaderShopComponent } from './components/front/page-header-shop/page-header-shop.component';
import { PageLoginComponent } from './components/login/page-login/page-login.component';
import { UserDashboardComponent } from './components/back/user-dashboard/user-dashboard.component';
import { PageAproposComponent } from './components/front/page-apropos/page-apropos.component';
import { PageCagnotteComponent } from './components/front/page-cagnotte/page-cagnotte.component';
import { PageCagnotteDetailsComponent } from './components/front/page-cagnotte-details/page-cagnotte-details.component';
import { PageCagnotteListComponent } from './components/front/page-cagnotte-list/page-cagnotte-list.component';
import { PageShopListComponent } from './components/front/page-shop-list/page-shop-list.component';
import { PageSocialShopComponent } from './components/front/page-social-shop/page-social-shop.component';
import { PageShopDetailsComponent } from './components/front/page-shop-details/page-shop-details.component';
import { PagePanierComponent } from './components/front/page-panier/page-panier.component';
import { PageValidationPanierComponent } from './components/front/page-validation-panier/page-validation-panier.component';
import { PageRestauListComponent } from './components/front/page-restau-list/page-restau-list.component';
import { PageRestauDetailsComponent } from './components/front/page-restau-details/page-restau-details.component';
import { PageContactComponent } from './components/front/page-contact/page-contact.component';
import { PageEcommerceComponent } from './components/front/page-ecommerce/page-ecommerce.component';
import { PageFaqComponent } from './components/front/page-faq/page-faq.component';
import { PageTermsComponent } from './components/front/page-terms/page-terms.component';
import { PagePolitiqueComponent } from './components/front/page-politique/page-politique.component';
import { PageSignupChooseAccountTypeComponent } from './components/inscription/page-signup-choose-account-type/page-signup-choose-account-type.component';
import { PageSignupSetEmailComponent } from './components/inscription/page-signup-set-email/page-signup-set-email.component';
import { PageSignupSetPhoneComponent } from './components/inscription/page-signup-set-phone/page-signup-set-phone.component';
import { PageSignupSetCodeComponent } from './components/inscription/page-signup-set-code/page-signup-set-code.component';
import { PageSignupCustomerComponent } from './components/inscription/page-signup-customer/page-signup-customer.component';
import { PageSignupCompanyComponent } from './components/inscription/page-signup-company/page-signup-company.component';
import { PageTransfertComponent } from './components/front/page-transfert/page-transfert.component';
import { PageIpTvComponent } from './components/front/page-ip-tv/page-ip-tv.component';
import { UserRechargeComponent } from './components/back/recharge/user-recharge/user-recharge.component';
import { UserRechargeConfirmeComponent } from './components/back/recharge/user-recharge-confirme/user-recharge-confirme.component';
import { UserRechargeConfirmeMobilemoneyComponent } from './components/back/recharge/user-recharge-confirme-mobilemoney/user-recharge-confirme-mobilemoney.component';
import { UserRechargeMontantComponent } from './components/back/recharge/user-recharge-montant/user-recharge-montant.component';
import { UserRechargeStatutComponent } from './components/back/recharge/user-recharge-statut/user-recharge-statut.component';
//SHAPSHAP
import { UserShapshapComponent } from './components/back/shapshap/user-shapshap/user-shapshap.component';
import { UserShapshapConfirmeComponent } from './components/back/shapshap/user-shapshap-confirme/user-shapshap-confirme.component';
import { UserShapshapMontantComponent } from './components/back/shapshap/user-shapshap-montant/user-shapshap-montant.component';
import { UserShapshapStatutComponent } from './components/back/shapshap/user-shapshap-statut/user-shapshap-statut.component';

import { UserLeftMenuComponent } from './components/back/user-left-menu/user-left-menu.component';
import { UserProfilebarComponent } from './components/back/user-profilebar/user-profilebar.component';
import { UserHeaderComponent } from './components/back/user-header/user-header.component';
import { UserProfileComponent } from './components/back/user-profile/user-profile.component';
import { UserEditProfileComponent } from './components/back/user-edit-profile/user-edit-profile.component';
import { UserSettingComponent } from './components/back/user-setting/user-setting.component';
import { UserTransferComponent } from './components/back/transfer/user-transfer/user-transfer.component';
import { UserTransferStatutComponent } from './components/back/transfer/user-transfer-statut/user-transfer-statut.component';
import { UserTransferConfirmeComponent } from './components/back/transfer/user-transfer-confirme/user-transfer-confirme.component';
import { UserContactListComponent } from './components/back/user-contact-list/user-contact-list.component';
import { UserChangePasswordComponent } from './components/back/user-change-password/user-change-password.component';
//STORE
import { ProductListComponent } from './components/back/store/product/product-list/product-list.component';
import { ProductAddComponent } from './components/back/store/product/product-add/product-add.component';
import { ProductEditComponent } from './components/back/store/product/product-edit/product-edit.component';
import { StoreNavbarComponent } from './components/back/store/store-navbar/store-navbar.component';
import { StoreInfoComponent } from './components/back/store/setting/store-info/store-info.component';

import { PageForgotPasswordSetEmailComponent } from './components/login/page-forgot-password-set-email/page-forgot-password-set-email.component';
import { PageForgotPasswordSetOtpComponent } from './components/login/page-forgot-password-set-otp/page-forgot-password-set-otp.component';
import { PageForgotPasswordSetNewPasswordComponent } from './components/login/page-forgot-password-set-new-password/page-forgot-password-set-new-password.component';
import { PageSignupIdentificationComponent } from './components/inscription/page-signup-identification/page-signup-identification.component';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { NgxPaginationModule } from 'ngx-pagination';
import { environment } from 'src/environments/environment';
import { UserTransactionComponent } from './components/back/user-transaction/user-transaction.component';
import { UserTransactionDetailsComponent } from './components/back/user-transaction-details/user-transaction-details.component';
import { CouponsComponent } from './components/coupons/coupons.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerInterceptor } from './services/spinner-interceptor';
import { ErrorInterceptor } from './services/error.interceptor';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxPermissionsModule, NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { PermissionDeniedComponent } from './components/permission-denied/permission-denied.component';
import { APP_INITIALIZER } from '@angular/core';
import { LoadPermissionsService } from './services/permissions.service';
import { NgOptimizedImage, provideImgixLoader } from '@angular/common';

export function tokenGetter() {
  return localStorage.getItem("token");
}
export function permissionsFactory(
  loadPermissionsService: LoadPermissionsService,
  ngxPermissionsService: NgxPermissionsService,
  roleService: NgxRolesService
) {
  return () => {
    let data = loadPermissionsService.loadUserPermissions();
    if(data){
      ngxPermissionsService.loadPermissions(data['permissions']);
      if (data['role'] == 'admin') {
        roleService.addRoles({
          ADMIN: data['permissions'],
        });
      } else {
        if (data['role'] == 'partner') {
          roleService.addRoles({
            PARTENER: data['permissions'],
          });
        } else {
          if (data['role'] == 'customer') {
            roleService.addRoles({
              CUSTOMER: data['permissions'],
            });
          }
        }
      }
    }

    console.log(roleService.getRoles());
  };
}
@NgModule({
  declarations: [
    AppComponent,
    PageFooterComponent,
    PageAccueilComponent,
    PageNotFoundComponent,
    PageHeaderComponent,
    PageHeaderShopComponent,
    PageLoginComponent,
    UserDashboardComponent,
    PageAproposComponent,
    PageCagnotteComponent,
    PageCagnotteDetailsComponent,
    PageCagnotteListComponent,
    PageShopListComponent,
    PageShopDetailsComponent,
    PageSocialShopComponent,
    PagePanierComponent,
    PageValidationPanierComponent,
    PageRestauListComponent,
    PageRestauDetailsComponent,
    PageContactComponent,
    PageEcommerceComponent,
    PageFaqComponent,
    PageTermsComponent,
    PagePolitiqueComponent,
    PageSignupChooseAccountTypeComponent,
    PageSignupSetEmailComponent,
    PageSignupSetPhoneComponent,
    PageSignupSetCodeComponent,
    PageSignupCustomerComponent,
    PageSignupCompanyComponent,
    PageTransfertComponent,
    PageIpTvComponent,
    UserRechargeComponent,
    UserRechargeConfirmeComponent,
    UserRechargeConfirmeMobilemoneyComponent,
    UserRechargeMontantComponent,
    UserRechargeStatutComponent,
    UserShapshapComponent,
    UserShapshapConfirmeComponent,
    UserShapshapMontantComponent,
    UserShapshapStatutComponent,
    UserLeftMenuComponent,
    UserProfilebarComponent,
    UserHeaderComponent,
    UserProfileComponent,
    UserEditProfileComponent,
    UserSettingComponent,
    UserContactListComponent,
    UserChangePasswordComponent,
    UserTransferComponent,
    UserTransferConfirmeComponent,
    UserTransferStatutComponent,
    ProductListComponent,
    ProductAddComponent,
    ProductEditComponent,
    StoreNavbarComponent,
    StoreInfoComponent,
    PageForgotPasswordSetEmailComponent,
    PageForgotPasswordSetOtpComponent,
    PageForgotPasswordSetNewPasswordComponent,
    PageSignupIdentificationComponent,
    UserTransactionComponent,
    UserTransactionDetailsComponent,
    CouponsComponent,
    PermissionDeniedComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SweetAlert2Module.forRoot(),
    NgOtpInputModule,
    RecaptchaV3Module,
    NgxPaginationModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [],
        disallowedRoutes: [''],
      },
    }),
    BrowserAnimationsModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    NgxMaskDirective,
    NgxMaskPipe,
    NgxPermissionsModule.forRoot(),
    NgOptimizedImage,
  ],
  providers: [
    {
      provide: [RECAPTCHA_V3_SITE_KEY, HTTP_INTERCEPTORS],
      useValue: environment.recaptcha.siteKey,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    provideNgxMask(),
    {
      provide: APP_INITIALIZER,
      useFactory: permissionsFactory,
      deps: [LoadPermissionsService, NgxPermissionsService, NgxRolesService],
      multi: true,
    },
    provideImgixLoader('https://www.sandbox.smartpay.ne/'),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
