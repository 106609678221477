<div class="home-two">
    <div class="p-0 m-0">
      <app-page-header></app-page-header>
      <!-- Banner section start -->
      <section class="banner features">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-12">
              <img src="assets/images/step.png" alt="" class="ml-auto">
            </div>
            <div class="col-lg-9 col-md- col-12 my-auto">
              <div class="ban-content ">
                <h3 class="text-white">Ouvrez votre compte gratuitement</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Banner section end -->
    </div>
    <div class="py-5">
      <div class="container sl-form-wrap">
        <div class="row">
          <div class="col-md-12">
            <ul class="steps">
              <li><span class="bg-primary text-white">1</span></li>
              <li><span class="bg-primary text-white">2</span></li>
              <li><span class="bg-primary text-white">3</span></li>
              <li><span class="bg-primary text-white">4</span></li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9 m-auto card p-5">
            <header class="mb-2">
              <h3 class="">Création d'un compte professionel</h3>
            </header>
            <div class="content-box">
              <p class="mt-4">Vous êtes à un pas de la création de votre compte professionnel. Tous les champs sont obligatoires</p>
            </div>
            <form [formGroup]="signupForm" class="sl-form">
              <div class="mb-2">
                <h5 class="">Informations personnelles</h5>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"><i class="fa fa-user"></i></span>
                    </div>
                    <input type="text" formControlName="firstname" id="firstname" class="form-control" placeholder="Prénom" aria-label="firstname" aria-describedby="basic-addon1" required>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"><i class="fa fa-user"></i></span>
                    </div>
                    <input type="text" formControlName="lastname" id="lastname" class="form-control" placeholder="Nom" aria-label="lastname" aria-describedby="basic-addon1" required>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"><i class="fa fa-calendar"></i></span>
                    </div>
                    <input type="date" formControlName="birthday" id="birthday" class="form-control" placeholder="date de naissance" aria-label="birthday" aria-describedby="basic-addon1" required>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"><i class="fas fa-restroom"></i></span>
                    </div>
                    <select formControlName="sex" class="form-control" id="Sex">
                      <option [ngValue]="null">Sélectionnez le sexe</option>
                      <option [ngValue]="true">Homme</option>
                      <option [ngValue]="false">Femme</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="mb-2 mt-3">
                <h5 class="">Information sur l'entreprise</h5>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"><i class="fa fa-building"></i></span>
                    </div>
                    <input type="text" formControlName="companyName" id="companyName" class="form-control" placeholder="Nom de l'entreprise" aria-label="address" aria-describedby="basic-addon1" required>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"><i class="fa fa-map-marker"></i></span>
                    </div>
                    <input type="text" formControlName="address" id="address" class="form-control" placeholder="Adresse" aria-label="address" aria-describedby="basic-addon1" required>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"><i class="fa fa-barcode"></i></span>
                    </div>
                    <input type="text" formControlName="nif" id="nif" class="form-control" placeholder="NIF" aria-label="nif" aria-describedby="basic-addon1" required>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"><i class="fa fa-barcode"></i></span>
                    </div>
                    <input type="text" formControlName="rccm" id="rccm" class="form-control" placeholder="RCCM" aria-label="rccm" aria-describedby="basic-addon1" required>
                  </div>
                </div>

              </div>
              <div class="row">
                  <div class="col-md-12">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-list"></i></span>
                      </div>
                      <select formControlName="category" class="form-control"  id="category">
                        <option value="">Secteur d'activité</option>
                        <option value="res">Restauration</option>
                        <option value="hot">Hotellerie</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fa fa-map-marker"></i></span>
                      </div>
                      <textarea type="text" formControlName="description" id="description" class="form-control" placeholder="Description de l'entreprise" aria-label="description" aria-describedby="basic-addon1"></textarea>
                    </div>
                  </div>
              </div>



              <!--<div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fa fa-edit"></i></span>
                </div>
                <textarea class="form-control" formControlName="description" id="description" aria-label="Description" placeholder="Ajoutez la description de votre entreprise ou initiative" required></textarea>
              </div>-->
              <div class="mb-2 mt-4">
                <h5 class="">Information de connexion</h5>
              </div>

              <div class="col-md-6">
                <!-- <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-phone"></i></span>
                  </div>
                  <input type="text" formControlName="phone" id="phone" class="form-control" placeholder="Téléphone" aria-label="phone" aria-describedby="basic-addon1" required>
                </div> -->
              </div>

              <div class="row">
                <div class="col-md-6">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope"></i></span>
                </div>
                <input type="text" formControlName="username" id="username" class="form-control" placeholder="Email" aria-label="email" aria-describedby="basic-addon1" required>
              </div>
                </div>
                <div class="col-md-6">
              <div class="form-group mb-3">
                <!-- <label for="phone">Numéro de téléphone</label> -->
                <input type="tel" id="phoneNumber" formControlName="phoneNumber" value="" class="form-control" placeholder="numéro de téléphone">
                <input type="hidden" id="code" name="code" #code value="ne">
                <input type="hidden" id="dialcode" formControlName="dialcode" #dialcode value="227">
                <div class="" *ngIf="!wrongNumber && (signupForm.controls['phoneNumber'].dirty || signupForm.controls['phoneNumber'].touched)">
                  <span id="valid-msg" class="text-success"><i class="fa fa-check mr-2"></i> Numéro valide</span>
                </div>
                <span id="error-msg" class="text-danger"></span>
                <div class="" *ngIf="wrongNumber">
                  <span id="error-req" class="text-danger"><i class="fas fa-exclamation-triangle mr-2"></i>{{ errorMessage }}</span>
                </div>
              </div>
              </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"><i class="fa fa-lock"></i></span>
                    </div>
                    <input type="password" formControlName="password" id="password" class="form-control" placeholder="Mot de passe" aria-label="password" aria-describedby="basic-addon1" required>

                    <div class="p-0" *ngIf="signupForm.controls['password'].invalid && (signupForm.controls['password'].dirty || signupForm.controls['password'].touched)">
                        <div class="" [ngClass]="signupForm.controls['password'].hasError('required') || signupForm.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                          <i class="fa {{ signupForm.controls['password'].hasError('required') ||
                          signupForm.controls['password'].hasError('minlength') ? 'fa-times-circle' :
                          'fa-check-circle' }}">
                          </i>
                          Doit comporter au moins 8 caractères !
                        </div>
                        <div class="" [ngClass]="signupForm.controls['password'].hasError('required') || signupForm.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                          <i class="fa {{ signupForm.controls['password'].hasError('required') ||
                          signupForm.controls['password'].hasError('hasSmallCase') ? 'fa-times-circle' :
                          'fa-check-circle' }}">
                          </i>
                          Doit avoir au moins une lettre minuscle!
                        </div>
                        <div class="" [ngClass]="signupForm.controls['password'].hasError('required') || signupForm.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                          <i class="fa {{ signupForm.controls['password'].hasError('required') ||
                          signupForm.controls['password'].hasError('hasCapitalCase') ? 'fa-times-circle' :
                          'fa-check-circle' }}">
                          </i>
                          Doit avoir au moins une lettre majuscle!
                        </div>
                        <div class="" [ngClass]="signupForm.controls['password'].hasError('required') || signupForm.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                          <i class="fa {{ signupForm.controls['password'].hasError('required') ||
                          signupForm.controls['password'].hasError('hasNumber') ? 'fa-times-circle' :
                          'fa-check-circle' }}">
                          </i>
                          Doit avoir au moins un chiffre!
                        </div>
                        <div class="" [ngClass]="signupForm.controls['password'].hasError('required') || signupForm.controls['password'].hasError('hasSpecialCharacters')  ? 'text-danger' : 'text-success'">
                          <i class="fa {{ signupForm.controls['password'].hasError('required') ||
                          signupForm.controls['password'].hasError('hasSpecialCharacters') ? 'fa-times-circle' :
                          'fa-check-circle' }}">
                          </i>
                          Doit avoir au moins un caractère spécial!
                        </div>
                    </div>
                  </div>
                  <div class="text-success" *ngIf="signupForm.controls['password'].valid">
                    <i class="fa fa-check-circle mr-2"></i>
                    Valide
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"><i class="fa fa-lock"></i></span>
                    </div>
                    <input type="password" formControlName="confirmPassword" id="confirmPassword" class="form-control" placeholder="Confirmez le mot de passe" aria-label="password" aria-describedby="basic-addon1" required>
                    <div class="text-danger text-justify" *ngIf="signupForm.controls['confirmPassword'].hasError('NoPassswordMatch') && (signupForm.controls['confirmPassword'].dirty || signupForm.controls['confirmPassword'].touched)">
                      Les mots de passe ne correspondent pas
                    </div>
                  </div>
                </div>
              </div>
              <input hidden id="userType" formControlName="userType" class="form-control">
              <input hidden id="typeAccount" formControlName="typeAccount" class="form-control">
              <input hidden id="phone" formControlName="phone" class="form-control">
              <input hidden id="country" formControlName="country" class="form-control">
              <div class="text-danger" *ngIf="wrongCredentials">{{ errorMessage }}</div>
              <div class="d-flex">
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="tnc">
                    <label class="custom-control-label" for="tnc">J'accepte&nbsp;&nbsp;<a data-toggle="modal" data-target=".bd-example-modal-xl" class="text-primary">les conditions d'utilisation</a> et la <a data-toggle="modal" data-target=".bd-example-modal-xl-2" class="text-primary">politique de confidentialité</a></label>
                  </div>
                </div>
                <button class="btn-sp color-primary float-right ml-auto mr-0 h-auto"  (click)="onFormSubmit(dialcode.value)">
                  <span class="ml-2" id="text">Envoyer</span>
                </button>
              </div>
              <p class="notice">Vous avez déjà un compte? <a routerLink="/login" class="text-primary">Se connecter</a></p>
            </form>
          </div>
        </div>
      </div>
    </div>
  <div class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title align-content-center" id="exampleModalCenterTitle">Conditions d'utilisation de SmartPay</h5>
          <button type="button" class="btn btn-primary" data-dismiss="modal">X</button>
        </div>

        <section class="terms-tab">
          <div class="container">
            <div class="row">
              <div class="col-lg-10 col-md-12 m-auto">

                <div class="tab-content">
                  <div id="general-qs" class="tab-pane fade in active show">
                    <div class="accordion" id="accordion">
                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-1" aria-expanded="true">
                          Article 1 - Objet
                        </h5>
                        <div id="faq-1" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Les présentes conditions générales établissent les conditions
                              contractuelles exclusivement applicables entre la société <strong>“DEV4SMART”</strong> et les utilisateurs du service <strong>“SmartPay”</strong>.
                              <br>
                              <strong>“SmartPay”</strong> est un agrégateur de recharge électronique et paiement en ligne  appartenant à la société DEV4SMART
                              intervenant en qualité de prestataire technique, en partenariat avec les opérateurs Mobile Money (OTM) du Niger et les Banques partenaires. <br>
                              <br>
                              Le Client s’engage dès lors à se conformer aux présentes conditions générales d’utilisation ainsi qu’à toutes les modifications ultérieures qui
                              pourraient survenir au titre de l’utilisation du service <strong>“SmartPay”</strong>
                              <br>
                              <strong>DÉFINITIONS</strong>
                              <br><strong>•	Activités :</strong> désigne l'agrégation de recharge électronique et paiement en ligne  à travers le service dénommé <strong>“SmartPay”</strong>
                              mise à la disposition des Clients.
                              <br><strong>•	Banque ou Opérateur Mobile Money :</strong> Établissement bancaire émetteur de la Monnaie électronique, dûment autorisé par la BCEAO ;
                              <br><strong>•	Compte “SmartPay” :</strong> le compte d'agrégation de recharge électronique et paiement en ligne unique associé à un Client du service « SmartPay » et intégrant diverses
                              informations d’identification ;
                              <br><strong>•	Conditions Générales :</strong> désigne les conditions contractuelles, exclusivement applicables entre la Société et ses Clients, détaillées
                              dans les présentes conditions générales d’utilisation.
                              <br><strong>•	« Données » :</strong> désigne les informations fournies directement ou indirectement par l’Utilisateur dans le cadre de l’utilisation du service
                              “SmartPay”, notamment lors de son Inscription et de la création de son Compte. L’Utilisateur garantit qu’il fournit des informations exactes et à jour,
                              en particulier s’agissant de son identité et ses coordonnées.
                              <br><strong>•	« Données à Caractère Personnel » :</strong> désigne toute information relative à une personne physique ou morale identifiée.
                              <br><strong>•	Client :</strong> un Client détenant un compte “SmartPay” et dûment autorisé à l’utiliser ;
                              <br><strong>•	Société :</strong> désigne DEV4SMART, Société à Responsabilité Limitée, immatriculée au RCCM-NI-NIA-2016-B-3473 et le NIF 40675/R au Niger;
                              <br><strong>•	Transaction :</strong> désigne sans limitation :
                              <br>•	 L’achat de crédit téléphonique;
                              <br>•	 L'émission et la réception des paiements à travers la plateforme « SmartPay »;
                              <br>•	 Collecte ou don de fonds en toute simplicité;
                              <br>•	 Toute opération de débit ou de crédit passée sur le compte “SmartPay” du Client ou avec votre portefeuille externe (Mobile Money, cartes VISA ou Mastercard);
                              <br><strong>•	 Utilisateur :</strong> désigne tout Client ayant souscrit au service “SmartPay”.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-2" aria-expanded="false">
                          Article 2 – Engagement de "DEV4SMART"
                        </h5>
                        <div id="faq-2" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify"><strong>“DEV4SMART”</strong>  s’engage à ce que le service “SmartPay” soit conforme aux lois et règlements applicables
                              à l’activité de paiement en ligne au Niger. A ce titre, “SmartPay” se réserve le droit de faire évoluer le service “SmartPay” et les présentes
                              conditions Générales d’Utilisation à tout moment, sans notification préalable.
                              <br>2.1. <strong>“SmartPay”</strong> met en place un dispositif de réception et de traitement des réclamations
                              des Clients par appel téléphonique, WhatsApp et l'email. “SmartPay" s’engage à traiter les réclamations du Client dans un délai minimal de vingt quatre (24) heures et maximal de soixante douze (72) heures,
                              selon la nature de la réclamation présentée.
                              <br>2.2. Dans les limites des présentes conditions générales d’utilisation et sous réserve de l’identification du client, “SmartPay” s’engage à rétablir le Compte de Monnaie électronique du Client dans un délai de soixante douze (72) heures;
                              <br>2.3. “SmartPay” met en place un dispositif permettant de garantir la traçabilité des réclamations reçues et traitées;
                              <br>2.4. Toutes les transactions effectuées par le client donnent lieu à la production d’un reçu électronique précisant notamment :
                              <br>• le numéro de référence de la transaction ;
                              <br>• la nature du service ;
                              <br>• le numéro d’immatriculation du distributeur ou du sous-distributeur, le cas échéant ;
                              <br>• l’heure, le montant et les frais de la transaction :
                              <br>• l’identité de l’expéditeur ou du récepteur de la transaction lorsqu’applicable.
                              <br>2.5. “SmartPay” s’engage expressément à loger dans un compte séquestre, ouvert auprès de ses Banques Partenaires, tous les paiements et
                              autres sommes reçus relativement aux transactions relatives à “SmartPay”.
                              <br>2.6. Afin d’optimiser le service “SmartPay”, “DEV4SMART” s’engage notamment à mettre en œuvre tous les moyens raisonnables pour
                              assurer le bon fonctionnement du service ainsi que l’exécution diligente des transferts et des transactions initiés par le Client.
                              <br>2.7. “SmartPay” n’est, en aucun cas et d’aucune façon, tenue responsable des pertes consécutives à la mauvaise manipulation de son
                              téléphone par le Client, au dysfonctionnement du téléphone, au mauvais fonctionnement du service ou à un retard du réseau mobile par
                              suite d’événements dont la société n’a pas la maîtrise, tels que le transport des données, la défaillance dans le fonctionnement des
                              matériels ou des réseaux de télécommunication, les interruptions de service par suite de cas fortuits ou de force majeure (guerre,
                              incendie, dégâts des eaux, soulèvement populaires…). Cette liste est simplement indicative et non limitative.
                              <br>2.8. De même, ne lui sont pas imputés les dommages directs ou indirects, survenus en conséquence d’un acte frauduleux ou sortant du code
                              de conduite défini par “SmartPay” d’un agent de distribution, d’un Distributeur ou sous distributeur causant un dommage au Client.
                              <br>2.9. Pour respecter les contraintes juridiques, d’audit et la réglementation en matière de lutte contre le blanchiment et le
                              financement du terrorisme, les informations reçues sur les Clients peuvent être transmises sur demande aux autorités compétentes.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-3" aria-expanded="false">
                          Article 3 – Conditions d’accès au service “SmartPay”
                        </h5>
                        <div id="faq-3" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">3.1 – Le compte “SmartPay” du client est activé pour effectuer des opérations d'achat ou de paiement à partir d'un portefeuille externe (Mobile Money, cartes VISA ou Mastercard, Transfert d'argent, etc.);
                              <br>3.2 – Pour avoir accès au Service, le client devra :
                              <br>•	 Être nécessairement une personne domiciliée dans le pays dans lequel cet accord s’applique ;
                              <br>•	 Remplir le formulaire de souscription électronique après téléchargement de l’application  web ou mobile;
                              <br>•	 Être équipé d’un terminal mobile et d’une carte SIM compatibles avec la technologie utilisée par “SmartPay”;
                              <br>•	 Être titulaire d’un numéro de téléphone connecté à la plateforme “SmartPay” ne faisant l’objet d’aucune
                              restriction (légale ou réglementaire);
                              <br>3.3 – “SmartPay” se réserve toutefois le droit de refuser l’ouverture d’un compte à tout client, sans avoir à s’en justifier.
                              <br>3.4 – À tout moment, “SmartPay” peut suspendre l’accès du client au service “SmartPay” au cas où il
                              existerait des faits laissant présumer l’utilisation frauduleuse ou la tentative d’utilisation frauduleuse de ses
                              services. Le client en est informé par tout moyen.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-4" aria-expanded="false">
                          Article 4 – Tarification
                        </h5>
                        <div id="faq-4" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">4.1 – Le service <strong>“SmartPay”</strong> ne comprend aucun frais de gestion ou d’abonnement.
                              <br>4.2 – Les frais applicables aux transactions sont disponibles sur l’application “SmartPay” et sont automatiquement prélevés sur le compte du client lors de la transaction
                              conformément aux <strong>conditions tarifaires</strong> en vigueur à ce moment.
                              <br>Les conditions tarifaires sont affichées au niveau du point de service ou sur le site internet de la société
                              et peuvent faire l’objet de mise à jour à tout moment, sans information préalable directe du client.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-5" aria-expanded="false">
                          Article 5 – Mot de passe relevant du compte “SmartPay”
                        </h5>
                        <div id="faq-5" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p>5.1 – Un mot de passe est choisi par chaque nouveau client lorsqu’il se connecte
                              pour la première fois à l’application du service “SmartPay”. Le mot de passe du client, qui est effectif
                              instantanément, lui permet d’accéder à l’interface de son application à tout moment;
                              <br>Il lui sera demandé ce meme mot de passe à chaque fois que sa session s'expire;
                              <br>5.2 – Le client peut à tout moment changer son mot de passe en allant dans l’application “SmartPay” et choisir l'option "Mot de passe oublié ?";
                              Il devra suivre les indications mentionnées dans le menu pour pouvoir procéder au changement.
                              <br>5.3 – Dès lors que le Client est connecté, il peut effectuer toute transaction avec son compte;
                              <br>5.4 – Pour tout problème relatif au mot de passe (réinitialisation, blocage, déblocage, etc.),
                              le client doit appeler le service client de “SmartPay” au +227 90 11 22 12 ou adresser un mail à l'adresse smartpay@dev4smart.net.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-6" aria-expanded="false">
                          Article 6 – Cas de perte ou de vol du téléphone ou de la carte sim associée au compte “SmartPay”
                        </h5>
                        <div id="faq-6" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">6.1 – En cas de perte du téléphone ou de la carte sim associée à son compte “SmartPay”, le Client s’engage
                              à informer instantanément “SmartPay” par email via smartpay@dev4smart.net.ou en appelant le service client +227 90 11 22 12, suivi d’une
                              notification écrite de demande de blocage de son compte “SmartPay” pour motif de vol + les pièces justificatives
                              qu’il est le propriétaire de la carte SIM (copie abonnement ou tout document équivalent).
                              <br>6.2 – Le client peut également appeler le service client de “SmartPay” pour orientations sur la procédure en vigueur.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-7" aria-expanded="false">
                          Article 7 – Responsabilité du client
                        </h5>
                        <div id="faq-7" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">7.1 – Le client est responsable de la bonne utilisation de son compte utilisateur
                              “SmartPay”. Il doit prendre toutes mesures nécessaires pour conserver et préserver le dispositif de
                              sécurité personnalisé (mot de passe) attaché à son compte et, en particulier, à ne pas le communiquer à des tiers.
                              Il est seul responsable de l’utilisation faite de son mot de passe, quel que soit l’utilisateur, et des
                              conséquences qui en résultent.
                              <br>7.2 – Sauf preuve contraire apportée par le client, toute connexion ou opération effectuée à partir de son mot de passe sera réputée avoir été effectuée par lui.
                              <br>7.3 – Aucune responsabilité ne pourra être imputée à “SmartPay” du fait de l’utilisation frauduleuse de son mot de passe par un tiers avant son opposition.
                              <br>7.4 – Le client s’engage à ce que les informations personnelles et de sécurité qu’il renseigne soient complètes,
                              réelles, exactes et à jour, et qu’elles n’aient ni pour objet ni pour effet de porter à confusion avec un tiers,
                              d’induire en erreur sur son identité, ou de porter atteinte à “SmartPay”, ou à des droits de tiers .
                              Il s’engage à les mettre systématiquement à jour pour tout changement y relatif .</p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-8" aria-expanded="false">
                          Article 8 – Achat de credit téléphonique ou paiement en ligne
                        </h5>
                        <div id="faq-8" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">8.1 – L’ordre d'achat de credit téléphonique ou paiement en ligne via un compte “SmartPay” est définitif et irrévocable.
                              <br>8.2 – Les transactions d'achat de credit téléphonique ou paiement en ligne peuvent être refusées pour plusieurs raisons :
                              <br>•	 Si le solde du client dans son portefeuille externe est inférieur au montant de la transaction qu'il souhaite effectuer ;
                              <br>•	 Si le montant de l'opération est supérieur aux plafonds autorisés ;
                              <br>•	 Si le plafond de disponibilité journalier est dépassé et ou la personne atteint le nombre de transaction journalier ;
                              <br>•	 Si le téléphone du client perd la connexion au réseau pendant la transaction ;
                              <br>•	 Si la session de l’utilisateur s'expire avant d'emettre les informations;
                              <br>•	Etc….
                              <br>8.3 – Les opérations peuvent être suspendues pendant un délai pour cause de maintenance des serveurs.
                              Le moment de cette brève interruption ne peut être déterminé à l’avance. Dans ce cas, le client aura la possibilité
                              de ré-effectuer la transaction qui n’aura pas abouti lors de la reprise du serveur.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-9" aria-expanded="false">
                          Article 9 – Colllecte ou don de fonds depuis “SmartPay”
                        </h5>
                        <div id="faq-9" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Les clients ou partenaires peuvent effectuer des dons ou collectes de fonds depuis leur compte “SmartPay”.
                              <br>“SmartPay” ne peut être tenue responsable des difficultés rencontrées lors d'une opération de collecte ou de dons de fonds.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-10" aria-expanded="false">
                          Article 10 – Délai de conservation des documents & délai de réclamation
                        </h5>
                        <div id="faq-10" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">10.1 – En cas de réclamation du client, “SmartPay” s’engage à apporter les meilleurs soins aux
                              informations qu’ils seront amenés à fournir quant aux conditions d’exécution de l’opération contestée.
                              <br>10.2 – Tout document ou reproduction détenu par “SmartPay” relatif aux opérations visées
                              dans les présentes conditions générales seront conservées conformément à la réglementation en
                              vigueur.
                              <br>10.3 – Aucune réclamation du client n’est recevable trente (30) jours calendaires après la date de l’opération
                              que le client aurait contestée.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-11" aria-expanded="false">
                          Article 11 – Protection des données à caractère personnel
                        </h5>
                        <div id="faq-11" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">11.1 – La signature du présent vaut autorisation donnée à SmartPay agissant dans le cadre
                              du service de collecter et traiter, et au besoin transmettre ses données à caractère personnel Conformément à la
                              loi sur les données à caractère personnel en vigueur au Niger. Les données recueillies peuvent être : le Nom et Prénom, le numéro de téléphone, l'adresse email, les numéros RCCM et NIF des partenaires, etc. Ces données seront conservées pour une durée minimale de dix (10) ans.
                              <br>11.2 – Tout Client peut également, pour des motifs légitimes, s’opposer au traitement des Données personnelles le concernant.
                              <br>11.3 - SmartPay se réserve le droit d’utiliser ces données dans le cadre d’opérations de marketing direct, pour
                              communiquer, par SMS, par téléphone ou par courriel, ses offres commerciales sur ses produits et services.
                              <br>11.4 Le client disposera par ailleurs d’un droit d’opposition, d’accès, de rectification, de modification et de
                              suppression concernant les données qui le concernent. S’il souhaite exercer ce droit et obtenir communication des
                              informations le concernant, il peut s’adresser à SmartPay au +227 90 11 22 12 ou adresser un mail à l'adresse smartpay@dev4smart.net.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-12" aria-expanded="false">
                          Article 12 – Résiliation du compte “SmartPay”
                        </h5>
                        <div id="faq-12" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">12.1 – À tout moment, le Client peut informer le Service client de "SmartPay"
                              de sa volonté de clôturer son compte “SmartPay” par courrier physique ou électonique au smartpay@dev4smart.net. Le service Client “SmartPay”
                              procédera à la fermeture du compte “SmartPay” dans un délai de quarante-huit (48) heures après réception
                              du courrier et vérification de conformité.
                              <br>12.2 – L’acceptation d’une demande de résiliation de compte “SmartPay” est conditionnée par l’authentification
                              du demandeur, la validité du compte “SmartPay”.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-13" aria-expanded="false">
                          Article 13 – Comptes inactifs
                        </h5>
                        <div id="faq-13" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">13.1 – Tout compte client resté inactif pendant un délai de six (6) mois, “SmartPay” peut suspendre le compte.
                              <br>13.2 – En cas de décès, le compte ne peut être reactivé que par les ayant-droit du Client.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-14" aria-expanded="false">
                          Article 14 – Suspension et Clôture du compte « SmartPay »
                        </h5>
                        <div id="faq-14" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">14.1 – “SmartPay” se réserve le droit de suspendre temporairement l’accès
                              au compte pour des raisons techniques, de sécurité ou
                              de maintenance après en avoir avisé le client et sans que ces opérations
                              n’ouvrent droit à une quelconque indemnité. Il s’engage à limiter ce type
                              d’interruptions au strict nécessaire.
                              <br>14.2 – Le Compte du Client est clôturé par “SmartPay” dans les cas
                              suivants sans encourt d’une responsabilité quelconque ou justification :
                              <br>• Sur instructions des autorités judiciaires, policières et/ou de la BCEAO ;
                              <br>• Si “SmartPay” ou ses agents ont connaissance ou soupçonnent que le
                              Compte est utilisé à des fins frauduleuses ou illégales ;
                              <br>• Si “SmartPay” estime que le Client ne respecte pas les présentes
                              Conditions Générales d’Utilisation ou utilise de façon inappropriée le service au
                              risque de compromettre l’intégrité du système ;
                              <br>• Si un organisme national ou international dûment habilité diligente une
                              enquête ou lorsqu’une procédure pénale est engagée à l’encontre du Client ou
                              pour toute autre raison qui, de l’avis de SmartPay jette le trouble sur la
                              réputation commerciale de celle-ci ;
                              <br>• Si le Client notifie de la perte de son téléphone, suivant réception par
                              SmartPay d’une demande de clôture de compte spécifiant les raisons de
                              cette clôture et accompagné des documents produits lors de l’enregistrement ;
                              <br>• Si le Client notifie à “SmartPay” ou qu’il a révélé son code PIN à toute
                              autre personne, suivant réception par “SmartPay” d’une demande de
                              clôture de compte spécifiant les raisons de cette clôture et accompagné des
                              documents produits lors de l’enregistrement.
                              <br>14.3 – La clôture du compte à l’initiative de l’une ou l’autre partie entraîne la
                              résiliation sans formalité ni délai du présent contrat.
                              <br>14.4 – Périodes de validité du compte :
                              Un compte « SmartPay» reste valide tant que sa
                              dernière transaction a été effectuée dans un délai inférieur à 6 mois.
                              <br>• 14.5 Au-delà de 6 mois sans transaction sur le compte « SmartPay » du
                              client, SmartPay se réserve le droit de le clôturer, après en avoir
                              notifié le Client
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-15" aria-expanded="false">
                          Article 15 – Cas de force majeure
                        </h5>
                        <div id="faq-15" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">15.1 – En cas de force majeure tel que défini par la loi et les jurisprudences, ni les opérateurs de téléphonie, ni “SmartPay” ne pourront être tenus responsable pour un
                              retard ou une défaillance quelconque dans l’exécution de leurs prestations. Par événement de force majeure, il faut entendre tout fait imprévisible, insurmontable ou échappant au contrôle des parties et dont la survenance
                              empêche l’exécution normale de la présente convention comme par exemple : une décision gouvernementale, une guerre, des émeutes, un sabotage, un incendie, une inondation, un cyclone, une épidémie, une quarantaine, une grève, un lock-out, etc.
                              <br>15.2 – L’événement de force majeure aura pour effet de suspendre l’exécution
                              de l’obligation devenue impossible.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-16" aria-expanded="false">
                          Article 16 – Preuves des opérations effectuées
                        </h5>
                        <div id="faq-16" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">16.1 – Le client reconnaît la validité des documents et justificatifs physiques
                              et/ou électroniques produits par “SmartPay” lors de ses opérations ou transactions.
                              <br>16.2 – Toutes les opérations effectuées dans le cadre des présentes conditions
                              générales auront pour preuve les supports informatiques échangés de façon
                              journalière entre les partenaires et “SmartPay”
                              Les enregistrements des transactions et leur reproduction sur support
                              informatique constituent pour les partenaires, “SmartPay” et le client, un
                              mode valable et recevable de preuve de la réalisation de l’opération et la
                              justification de son imputation comptable, ce qui doit être accepté par le client
                              sans aucune réserve.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-17" aria-expanded="false">
                          Article 17 – Modifications
                        </h5>
                        <div id="faq-17" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Les partenaires et “SmartPay” se réservent le droit d’apporter des modifications aux conditions générales de leurs services, lesquelles seront portées à la connaissance du client, soit par SMS, soit par voie d’affichage dans
                              le réseau de distribution de chacune des Parties dans un délai d’un (01) mois avant la date de leur entrée en vigueur.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-18" aria-expanded="false">
                          Article 18 – Blanchiment de capitaux
                        </h5>
                        <div id="faq-18" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">18.1 – SmartPay est soumis à l’ensemble de la réglementation relative à la lutte contre le blanchiment de capitaux et le financement du terrorisme et de la prolifération en vigueur au Niger.
                              <br>18.2 – En application des lois et règlements en vigueur en matière de lutte contre le blanchiment de capitaux et le financement du terrorisme et de la prolifération, “SmartPay” est tenue de s’informer auprès de tout Client
                              pour toute opération ou relation d’affaires de l’origine, de l’objet et de la destination de l’opération ou de l’ouverture du Compte. Il doit, par ailleurs,
                              réaliser toutes les diligences nécessaires à l’identification du client et le cas échéant, du Bénéficiaire effectif du Compte et/ou des Opérations de paiement liées à celui-ci.
                              <br>18.3 – Le Client reconnaît que “SmartPay” peut mettre un terme ou reporter à tout moment l’usage d’un identifiant, l’accès à un Compte ou l’exécution d’une opération ou d’un Remboursement en l’absence d’élément
                              suffisant sur son objet ou sa nature. Il est informé qu’une opération réalisée dans le cadre des présentes peut faire l’objet de l’exercice du droit à la
                              communication aux autorités compétentes du Niger.
                              <br>18.4 – Le Client peut, conformément à la réglementation, accéder à toutes les informations ainsi communiquées sous réserve que ce droit d’accès ne remette pas en cause la finalité de lutte contre le blanchiment des capitaux et le
                              financement du terrorisme et de la prolifération lorsque ces données sont relatives au demandeur.
                              <br>18.5 – Aucune poursuite et aucune action en responsabilité civile ne peut être
                              intentée ni aucune sanction professionnelle prononcée contre
                              “SmartPay”, ses dirigeants ou ses préposés qui ont fait de bonne foi les déclarations de soupçon auprès aux autorités compétentes du Niger .
                              <br>18.6 – Le client est informé que conformément aux dispositions légales et règlementaires en vigueur relatives au blanchiment
                              des capitaux et au financement du terrorisme, la Banque et/ou “SmartPay” pour des raisons d’identification, peuvent exiger la présentation de tout document probant.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-19" aria-expanded="false">
                          Article 19 – Sécurité
                        </h5>
                        <div id="faq-19" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">19.1. "DEV4SMART" s’engage à assurer la sécurité et la confidentialité des
                              données des Client et Partenaires, conformément à la réglementation en vigueur sur la protection des données personnelles.
                              <br>19.2 – “SmartPay” et ses Partenaires ne sont toutefois pas tenus
                              responsables à l’égard du Client des éventuelles erreurs, omissions,
                              interruptions ou retards des opérations réalisées résultant d’un accès non
                              autorisé au service “SmartPay” .
                              <br>19.3 – “DEV4SMART” n’est pas tenu responsable des vols, destructions ou
                              communications non autorisées de données résultant d’un accès non autorisé au
                              service “SmartPay”.
                              <br>19.4 – “SmartPay” demeure étrangère au lien de droit existant entre le
                              Client et le Bénéficiaire de l’Opération de Paiement. La société n’est pas tenue
                              responsable des fautes, manquements ou négligence du Client ou du
                              Bénéficiaire l’un envers l’autre.
                              <br>19.5 – “SmartPay” est responsable de la sécurité et de la confidentialité
                              des données qu’il échange avec le client dans le cadre des présentes au titre de
                              la création et de la gestion de son Compte, ainsi que des Opérations de
                              paiement associées au Compte.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-20" aria-expanded="false">
                          Article 20 – Clause de sauvegarde
                        </h5>
                        <div id="faq-20" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">20.1 – L’invalidité ou la nullité de l’une des clauses des présentes conditions générales, pour quelque motif que ce soit,
                              ne saurait en aucun cas affecter la validité et le respect des autres clauses des présentes conditions générales.
                              <br>20.2 – Le fait que l’une des parties n’ait pas exigé l’application d’une clause quelconque des présentes conditions générales, que ce soit de façon permanente ou temporaire, ne pourra en aucun cas être considéré comme une renonciation aux droits de cette partie découlant de ladite clause.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-21" aria-expanded="false">
                          Article 21 – Droit applicable – Attribution de juridiction
                        </h5>
                        <div id="faq-21" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Les relations entre “SmartPay” et ses Clients sont régies par les lois en
                              vigueur au Niger en général.<br>
                              En cas de différend, les parties conviennent de recourir à un règlement amiable. A défaut d’accord amiable dans un délai de trente (30) jours à compter de la
                              survenance du différend, la partie la plus diligente peut saisir les juridictions compétentes du Niger.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-22" aria-expanded="false">
                          Article 22 – Langue utilisée
                        </h5>
                        <div id="faq-22" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">La langue utilisée durant la relation tant précontractuelle que contractuelle est le
                              français.<br>
                              La souscription du contrat se fait en français. Toute traduction faite dans une autre langue ne l’est qu’à titre informatif.
                            </p>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Quitter</button>
        </div>

      </div>
    </div>
  </div>

  <div class="modal fade bd-example-modal-xl-2" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title align-content-center" id="exampleModalCenterTitle-2">Politique de confidentialité de SmartPay</h5>
          <button type="button" class="btn btn-primary" data-dismiss="modal">X</button>
        </div>

        <section class="terms-tab">
          <div class="container">
            <div class="row">
              <div class="col-lg-10 col-md-12 m-auto">

                <div class="tab-content">
                  <div id="general-qs" class="tab-pane fade in active show">
                    <div class="accordion" id="accordion">
                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-1" aria-expanded="true">
                          1. Introduction
                        </h5>
                        <div id="faq-1" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Bienvenue à <strong>“smartpay.ne”</strong>.
                              <br><br>
                              <strong>“SmartPay”</strong> NE ("nous", "notre" ou "nos") exploite <strong>"SmartPay"</strong> (ci-après dénommé <strong>"Service"</strong>).<br>
                              <br><br>
                              Notre politique de confidentialité régit votre visite du site web <strong>smartpay.ne</strong> et de l'application mobile <strong>SmartPay NE</strong>, et explique
                              comment nous recueillons, protégeons et divulguons les informations résultantes de votre utilisation de notre service.
                              <br><br>
                              Nous utilisons vos données pour fournir et améliorer le Service. En utilisant le Service, vous acceptez systématiquement la collecte et l'utilisation des informations
                              conformément à la présente politique. Sauf définition contraire dans cette politique de confidentialité, les termes utilisés ont la même signification que dans nos conditions générales d'utilisation. <br>
                              <br><br>
                              Nos conditions générales ("<strong>conditions</strong>") régissent toute utilisation de notre service et, avec la politique de confidentialité, constituent
                              votre accord avec nous ("<strong>accord</strong>"). <br>
                            </p>

                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-2" aria-expanded="true">
                          2. Définitions
                        </h5>
                        <div id="faq-2" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">
                              <br><strong>SERVICE</strong> désigne le site Web SmartPay.ne et les applications SmartPay.ne exploités par Dev4smart.
                              <br><br>Les <strong>DONNÉES PERSONNELLES </strong> sont des données concernant une personne physique vivante qui peut être identifiée à partir de ces données
                              (ou à partir de ces données et d'autres informations en notre possession ou susceptibles d'être en notre possession).
                              <br><br>
                              <br>Les <strong>DONNÉES D'UTILISATION</strong> sont des données collectées automatiquement soit générées par l'utilisation du Service, soit par l'infrastructure du Service
                              elle-même (par exemple, la durée de la visite d'une page). Les COOKIES sont de petits fichiers stockés sur votre appareil (ordinateur ou appareil mobile).
                              <br><br><strong>CONTRÔLEUR DE DONNÉES</strong> désigne une personne physique ou morale qui (seule ou conjointement ou en commun avec d'autres personnes) détermine les finalités et les
                              modalités de traitement de toute donnée personnelle. Aux fins de la présente politique de confidentialité, nous sommes un contrôleur de données de vos données.
                              <br><br>
                              <br><strong>Les responsables du traitement des données (ou prestataires de services) </strong> <br>
                              désignent toute personne physique ou morale qui traite les données pour le compte du responsable du traitement des données. Nous pouvons utiliser
                              les services de différents Prestataires de services afin de traiter vos données de manière plus efficace.
                              <br><br><strong>La PERSONNE concernée</strong>est toute personne physique vivante qui fait l'objet de Données à caractère personnel.
                              <br><br><strong>L'UTILISATEUR </strong>est la personne physique qui utilise notre Service. L'Utilisateur correspond à la Personne concernée, qui fait l'objet de
                              Données à caractère personnel.
                            </p>

                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-3" aria-expanded="false">
                          3. Collecte et utilisation des informations
                        </h5>
                        <div id="faq-3" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Nous recueillons plusieurs types d'informations à des fins diverses pour vous fournir et améliorer notre service.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-4" aria-expanded="false">
                          4. Types de données
                        </h5>
                        <div id="faq-4" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify"><br><strong>Collectées Données</strong><br>
                              <br><strong>Personnelles</strong><br>
                              <br>Lors de l'utilisation de notre Service, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables qui
                              peuvent être utilisées pour vous contacter ou vous identifier ("<strong>Données personnelles</strong>"). Les informations personnellement identifiables peuvent
                              inclure, mais ne sont pas limitées à :<br>
                              <br>4.1. Adresse électronique<br>
                              <br>4.2. Prénom et nom de famille<br>
                              <br>4.3. Numéro de téléphone<br>
                              <br>4.4. Adresse, Pays, État, Province, Code postal, Ville<br>
                              <br>4.5. Cookies et données d'utilisation<br>
                              <br>4.6. Géolocalisation<br>
                              <br>Nous pouvons utiliser vos données personnelles pour vous envoyer des bulletins d'information, du matériel de marketing ou de promotion et d'autres
                              informations susceptibles de vous intéresser. Vous pouvez choisir de ne pas recevoir tout ou partie de ces communications en suivant le lien de désinscription.<br>
                              <br><strong>Données d'utilisation</strong> <br><br>
                              <br>Nous pouvons également collecter les informations que votre navigateur envoie chaque fois que vous visitez notre Service ou lorsque vous accédez au Service
                              par ou via un appareil quelconque ("<strong>Données d'utilisation</strong>").<br>
                              <br>Ces données d'utilisation peuvent inclure des informations telles que l'adresse de protocole Internet (par exemple, l'adresse IP) de votre ordinateur, le
                              type de navigateur, la version du navigateur, les pages de notre Service que vous visitez, l'heure et la date de votre visite, le temps passé sur ces pages,
                              les identifiants uniques des appareils et d'autres données de diagnostic.<br>
                              <br>Lorsque vous accédez au service avec un appareil, ces données d'utilisation peuvent inclure des informations telles que le type d'appareil que vous utilisez,
                              l'identifiant unique de votre appareil, l'adresse IP de votre appareil, votre appareil...
                              <br>Le système d'exploitation, le type de navigateur Internet que vous utilisez, les identifiants uniques des appareils et d'autres données de diagnostic.<br>
                              <br><strong>Données de localisation</strong><br>
                              <br>Nous pouvons utiliser et stocker des informations sur votre localisation si vous nous en donnez l'autorisation ("<strong>Données de localisation</strong>"). Nous utilisons
                              ces données pour fournir des fonctionnalités de notre Service, pour améliorer et personnaliser notre Service.<br>
                              <br>Vous pouvez activer ou désactiver les services de localisation lorsque vous utilisez notre service à tout moment par le biais des paramètres de votre appareil.<br>
                              <br><strong>Cookies de suivi des données</strong><br>
                              <br>Nous utilisons des cookies et des technologies de suivi similaires pour suivre l'activité sur notre service et nous conservons certaines informations.
                              <br>Les cookies sont des fichiers contenant une petite quantité de données qui peuvent inclure un identifiant unique anonyme. Les cookies sont envoyés à votre navigateur
                              depuis un site web et stockés sur votre appareil. D'autres technologies de suivi sont également utilisées, telles que des balises, des marqueurs et des scripts, pour collecter et suivre des informations et pour améliorer et analyser notre service.<br>
                              <br>Vous pouvez demander à votre navigateur de refuser tous les cookies ou d'indiquer quand un cookie est envoyé. Toutefois, si vous n'acceptez pas les cookies, il se peut que
                              vous ne puissiez pas utiliser certaines parties de notre service.<br>
                              <br>Exemples de cookies que nous utilisons :<br>
                              <br><strong>0.1. Cookies de session :</strong> Nous utilisons des cookies de session pour faire fonctionner notre service.<br>
                              <br><strong>0.2. Cookies de préférence :</strong>Nous utilisons des cookies de préférence pour mémoriser vos préférences et divers paramètres.<br>
                              <br><strong>0.3. Cookies de sécurité :</strong>Nous utilisons des cookies de sécurité à des fins de sécurité.<br>
                              <br><strong>0.4. Cookies publicitaires :</strong>Les cookies publicitaires sont utilisés pour vous proposer des publicités qui peuvent être pertinentes pour vous et vos intérêts.<br>
                              <br><strong>Autres données</strong><br>
                              <br>Lors de l'utilisation de notre service, nous pouvons également recueillir les informations suivantes : sexe, âge, date de naissance, lieu de naissance, détails du passeport ou CIN, citoyenneté, enregistrement au lieu de résidence et adresse réelle,
                              numéro de téléphone (travail, portable) autres données.<br>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-5" aria-expanded="false">
                          5. Utilisation des données</h5>
                        <div id="faq-5" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">SmartPay.ne utilise les données collectées à diverses fins :<br>
                              <br>5.1. Pour fournir et maintenir notre service ;<br>
                              <br>5.2. Pour vous informer des modifications apportées à notre service ;<br>
                              <br>5.3. Pour vous permettre de participer à des fonctions interactives de notre service lorsque vous choisissez de le faire ;<br>
                              <br>5.4. Pour fournir une assistance à la clientèle ;<br>
                              <br>5.5. Pour recueillir des analyses ou des informations précieuses afin d'améliorer notre service ;<br>
                              <br>5.6. Pour contrôler l'utilisation de notre service ;<br>
                              <br>5.7. Pour détecter, prévenir et résoudre les problèmes techniques ;<br>
                              <br>5.8. Pour répondre à toute autre finalité pour laquelle vous les avez fournies ;<br>
                              <br>5.9. Pour remplir nos obligations et faire valoir nos droits découlant de tout contrat conclu entre vous et nous, y compris pour la facturation et le recouvrement ;<br>
                              <br>5.10. Pour vous fournir des avis concernant votre compte et/ou votre abonnement, notamment des avis d'expiration et de renouvellement, des instructions par courriel, etc ;<br>
                              <br>5.11. Pour vous fournir des nouvelles, des offres spéciales et des informations générales sur d'autres biens, services et événements que nous proposons et qui sont similaires à
                              ceux que vous avez déjà achetés ou sur lesquels vous vous êtes renseigné, à moins que vous n'ayez choisi de ne pas recevoir ces informations ;<br>
                              <br>5.12. De toute autre manière que nous pourrions décrire lorsque vous fournissez les informations ;<br>
                              <br>5.13. À toute autre fin pour contribuer à l'amélioration de nos services<br>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-6" aria-expanded="false">
                          6. Conservation des données
                        </h5>
                        <div id="faq-6" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">
                              <br>Nous ne conserverons vos Données personnelles qu'aussi longtemps que nécessaire aux fins énoncées dans la présente Politique de confidentialité. Nous conserverons et utiliserons
                              vos Données personnelles dans la mesure nécessaire pour nous conformer à nos obligations légales (par exemple, si nous sommes tenus de conserver vos données pour nous conformer aux
                              lois applicables), résoudre des litiges et appliquer nos accords et politiques juridiques.<br>
                              <br>Votre consentement à la présente politique de confidentialité, suivi de votre soumission de ces informations, représente votre accord à ce transfert.<br>
                              <br>SmartPay.NE prendra toutes les mesures raisonnablement nécessaires pour s'assurer que vos données sont traitées en toute sécurité et conformément à la présente politique de
                              confidentialité et aucun transfert de vos données personnelles n'aura lieu vers une organisation ou un pays à moins qu'il n'y ait des contrôles adéquats en place, y compris la
                              sécurité de vos données et autres informations personnelles.<br>
                              .</p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-7" aria-expanded="false">
                          7. Divulgation des données
                        </h5>
                        <div id="faq-7" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Nous pouvons divulguer les informations personnelles que nous recueillons ou que vous fournissez :<br>
                              <br>7.1. Divulgation pour l'application de la loi. Dans certaines circonstances, nous pouvons être tenus de divulguer vos données personnelles si la loi l'exige ou en réponse à des
                              demandes valables des autorités publiques.<br>
                              <br>7.2. Transaction commerciale. Si nous ou nos filiales sommes impliqués dans une fusion, une acquisition ou une vente d'actifs, vos Données personnelles peuvent être transférées.<br>
                              <br><strong>7.3. Autres.<br>
                                Nous pouvons également divulguer vos informations :</strong><br>
                              <br>7.3.1. À nos filiales, à nos sociétés affiliées, aux tiers avec lesquels nous faisons des affaires<br>
                              <br>7.3.2. Aux entrepreneurs, aux prestataires de services et aux autres tiers avec lesquels nous faisons des affaires.<br>
                              <br>7.3.3. Pour atteindre l'objectif pour lequel vous les avez fournies ;<br>
                              <br>7.3.4. Dans le but d'inclure le logo de votre entreprise sur notre site web ;<br>
                              <br>7.3.5. À toute autre fin divulguée par nous lorsque vous fournissez les informations ;<br>
                              <br>7.3.6. Avec votre consentement dans tous les autres cas ;<br>
                              <br>7.3.7. Si nous pensons que la divulgation est nécessaire ou appropriée pour protéger les droits, la propriété ou la sécurité de la société, de nos clients ou d'autres personnes.<br>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-8" aria-expanded="false">
                          8. Sécurité des données
                        </h5>
                        <div id="faq-8" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">La sécurité de vos données est importante pour nous, mais n'oubliez pas qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est sûre à 100 %.
                              Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger vos données personnelles, nous ne pouvons pas garantir leur sécurité absolue.<br>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-9" aria-expanded="false">
                          9. Vos droits en matière de protection des données en vertu du règlement général sur la protection des données (RGPD)                    </h5>
                        <div id="faq-9" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Nous nous efforçons de prendre des mesures raisonnables pour vous permettre de corriger, modifier, supprimer ou limiter l'utilisation de vos données personnelles.<br>
                              <br>Si vous souhaitez être informé des données personnelles que nous détenons à votre sujet et si vous voulez qu'elles soient supprimées de nos systèmes, veuillez nous envoyer un courriel à SmartPay@dev4smart.net<br>
                              <br>Dans certaines circonstances, vous disposez des droits suivants en matière de protection des données :<br>
                              <br>9.1. Le droit d'accéder aux informations que nous détenons sur vous ;<br>
                              <br>9.2. Le droit de rectification. Vous avez le droit de faire rectifier les informations vous concernant si elles sont inexactes ou incomplètes ;<br>
                              <br>9.3. Le droit d'opposition. Vous avez le droit de vous opposer à notre traitement de vos données personnelles ;<br>
                              <br>9.4. Le droit de restriction. Vous avez le droit de nous demander de restreindre le traitement de vos informations personnelles ;<br>
                              <br>9.5. Le droit à la portabilité des données. Vous avez le droit de recevoir une copie de vos données personnelles dans un format structuré, lisible par machine et couramment utilisé ;<br>
                              <br>9.6. Le droit de retirer votre consentement. Vous avez également le droit de retirer votre consentement à tout moment lorsque nous nous appuyons sur votre consentement pour traiter vos informations personnelles ;<br>
                              <br>Veuillez noter que nous pouvons vous demander de vérifier votre identité avant de répondre à de telles demandes. Veuillez noter que nous pourrions ne pas être en mesure de fournir le service sans certaines données nécessaires.<br>
                              <br>Vous avez le droit de vous plaindre auprès d'une autorité de protection des données au sujet de notre collecte et de notre utilisation de vos données personnelles.<br>
                              <br><strong>9.1. Les informations personnelles dont nous disposons à votre sujet. Si vous faites cette demande, nous vous retournerons :</strong><br>
                              <br>9.1.1. Les catégories d'informations personnelles que nous avons recueillies à votre sujet.<br>
                              <br>9.1.2. Les catégories de sources à partir desquelles nous recueillons vos informations personnelles.<br>
                              <br>9.1.3. Le but professionnel ou commercial de la collecte ou de la vente de vos informations personnelles.<br>
                              <br>9.1.4. Les catégories de tiers avec lesquels nous partageons des informations personnelles.<br>
                              <br>9.1.5. Les éléments spécifiques d'information personnelle que nous avons recueillis à votre sujet.<br>
                              <br>9.1.6. Une liste des catégories d'informations personnelles que nous avons vendues, ainsi que la catégorie de toute autre société à laquelle nous les avons vendues. Si nous n'avons pas vendu vos informations personnelles, nous vous en informerons.<br>
                              <br>9.1.7. Une liste des catégories d'informations personnelles que nous avons divulguées à des fins commerciales, ainsi que la catégorie de toute autre société avec laquelle nous les avons partagées.<br>
                              <br><strong>9.2. Pour ne plus vendre vos informations personnelles. </strong><br>
                              <br><strong>Nous ne vendons ni ne louons vos informations personnelles à des tiers, à quelque fin que ce soit. Nous ne vendons pas vos informations personnelles pour une contrepartie monétaire.<br>
                                Toutefois, dans certaines circonstances, un transfert d'informations personnelles à un tiers, ou au sein de notre famille de sociétés, sans contrepartie monétaire peut être considéré comme une "vente" en vertu de la loi californienne. Vous êtes le seul propriétaire de vos données personnelles et pouvez demander leur divulgation.</strong><br>
                              <br>Si vous demandez à ne plus vendre vos informations personnelles, nous cesserons de procéder à ces transferts.<br>
                              <br>Veuillez noter que si vous nous demandez d'arrêter de vendre vos données, cela peut avoir un impact sur votre expérience avec nous, et vous ne pourrez peut-être pas participer à certains programmes ou services d'adhésion qui nécessitent l'utilisation de vos informations personnelles pour fonctionner. Mais en aucun cas, nous ne ferons de
                              discrimination à votre encontre pour avoir exercé vos droits.<br>
                            </p>
                          </div>
                        </div>Veuillez noter que vous avez le droit de nous demander de vous fournir ces informations jusqu'à deux fois par période de douze mois. Lorsque vous faites cette demande, les informations fournies peuvent être limitées aux informations personnelles que nous avons recueillies à votre sujet au cours des 12 mois précédents.
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-10" aria-expanded="false">
                          10. Fournisseurs de services
                        </h5>
                        <div id="faq-10" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Nous pouvons employer des sociétés et des personnes tierces pour faciliter notre service (les "fournisseurs de services"), fournir le service en notre
                              nom, exécuter des services liés au service ou nous aider à analyser la façon dont notre service est utilisé.<br>
                              <br>Ces tiers ont accès à vos données personnelles uniquement pour effectuer ces tâches en notre nom et sont tenus de ne pas les divulguer ou les utiliser à d'autres fins.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-11" aria-expanded="false">
                          11. Analytique
                        </h5>
                        <div id="faq-11" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Nous pouvons faire appel à des fournisseurs de services tiers pour surveiller et analyser l'utilisation de notre service.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-12" aria-expanded="false">
                          12. Outils CI/CD
                        </h5>
                        <div id="faq-12" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Nous pouvons faire appel à des fournisseurs de services tiers pour automatiser le processus de développement de notre service.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-13" aria-expanded="false">
                          13. Publicité </h5>
                        <div id="faq-13" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Nous pouvons faire appel à des fournisseurs de services tiers pour vous montrer des publicités afin de nous aider à soutenir et à maintenir notre service.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-14" aria-expanded="false">
                          14. Remarketing comportemental
                        </h5>
                        <div id="faq-14" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Nous pouvons utiliser des services de remarketing pour vous faire de la publicité sur des sites Web tiers après votre visite de notre Service. Nous et nos
                              fournisseurs tiers utilisons des cookies pour informer, optimiser et diffuser des publicités en fonction de vos visites passées sur notre Service.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-15" aria-expanded="false">
                          15. Paiements
                        </h5>
                        <div id="faq-15" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Nous pouvons fournir des produits et/ou des services payants dans le cadre du Service. Dans ce cas, nous utilisons des services tiers pour le traitement des
                              paiements (par exemple, des processeurs de paiement).<br>
                              <br>Nous ne stockons ni ne recueillons les détails de votre carte de paiement. Ces informations sont fournies directement à nos processeurs de paiement tiers dont l'utilisation de
                              vos informations personnelles est régie par leur politique de confidentialité. Ces processeurs de paiement adhèrent aux normes définies par PCI-DSS, gérées par le Conseil des normes
                              de sécurité PCI, qui est un effort conjoint de marques telles que Visa, Mastercard, Les 4 opérateurs téléphoniques du Niger, et les services de transfert d’argent. Les exigences de
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-16" aria-expanded="false">
                          16. Liens vers d'autres sites
                        </h5>
                        <div id="faq-16" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Notre service peut contenir des liens vers d'autres sites qui ne sont pas exploités par nous. Si vous cliquez sur un lien tiers, vous serez dirigé vers le
                              site de ce tiers. Nous vous conseillons vivement de consulter la politique de confidentialité de chaque site que vous visitez.<br>
                              <br>Nous n'avons aucun contrôle et n'assumons aucune responsabilité quant au contenu, aux politiques de confidentialité ou aux pratiques de tout site ou service tiers.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-17" aria-expanded="false">
                          17. La vie privée des enfants
                        </h5>
                        <div id="faq-17" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Nos services ne sont pas destinés à être utilisés par des enfants de moins de 18 ans ("<strong>enfant</strong>" ou "<strong>enfants</strong>").<br>
                              <br>Nous ne collectons pas sciemment d'informations personnellement identifiables auprès d'enfants de moins de 18 ans. Si vous apprenez qu'un enfant nous a fourni
                              des données personnelles, veuillez nous contacter. Si nous apprenons que nous avons recueilli des données personnelles auprès d'enfants sans vérification du consentement
                              parental, nous prenons des mesures pour supprimer ces informations de nos serveurs.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-18" aria-expanded="false">
                          18. Modifications de la présente politique de confidentialité
                        </h5>
                        <div id="faq-18" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous informerons de toute modification en publiant la nouvelle
                              politique de confidentialité sur cette page.<br>
                              <br>Nous vous en informerons sur notre Service, avant que la modification ne prenne effet et nous mettrons à jour la "date d'entrée en vigueur" en haut de la présente Politique de confidentialité.<br>
                              <br>Nous vous conseillons de consulter régulièrement cette politique de confidentialité pour prendre connaissance des éventuelles modifications. Les modifications apportées à cette politique de
                              confidentialité entrent en vigueur lorsqu'elles sont publiées sur cette page.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h5 class="collapsed" data-toggle="collapse" data-target="#faq-19" aria-expanded="false">
                          19. Nous contacter
                        </h5>
                        <div id="faq-19" class="collapse" data-parent="#accordion">
                          <div class="acr-body">
                            <p class="text-justify">Si vous avez des questions sur cette politique de confidentialité, veuillez nous contacter par e-mail : <strong>smartpay@dev4smart.net</strong>.<br>
                              <br>Cette déclaration de confidentialité a été créée pour contact@dev4smart.net par PolicyMaker.io le 2020-07-17.
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Quitter</button>
        </div>

      </div>
    </div>
  </div>

    <app-page-footer></app-page-footer>
  </div>

