<div class="blog-page">
    <app-page-header-shop></app-page-header-shop>
    <!-- Banner section start -->
    <section class="banner features pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8 m-auto">
            <div class="ban-content text-center">
              <h1 style="text-transform:none;">Nom du restaurant</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner section end -->
    <!-- Landing overview section start -->
    <!-- <div class="landing-overview py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-10 m-auto">
            <div class="sec-heading">
              <h3 class="">Menu du jour</h3>
            </div>
            <div class="card mb-3" >
              <div class="row g-0">
                <div class="col-md-4">
                  <img src="assets/images/blog/nv.jpg" class="img-fluid rounded-start" alt="...">
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">This is a longer card with supporting. This is a longer card with supporting. This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="/page-panier" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Landing overview section end -->
    
    <!-- System works start -->
    <section class="system-works bg-offwhite">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- <ul class="nav nav-tabs cmn-tab-nav">
              <li><a class="active" data-toggle="tab" href="#money-transition">Entrées</a></li>
              <li><a data-toggle="tab" href="#tracking-activity">Resistances</a></li>
              <li><a data-toggle="tab" href="#secure-payment">Desserts</a></li>
              <li><a data-toggle="tab" href="#secure-payments">Boissons</a></li>
              <li><a data-toggle="tab" href="#reservation">Réservation</a></li>
            </ul> -->

            <div class="tab-content">
              <div id="money-transition" class="tab-pane fade in active show">
                <div class="row row-cols-1 row-cols-md-3 g-4 my-5">
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                        <span class="mr-"><i class="fa fa-tags text-primary"></i> Résistance</span><br>
                        <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                        <a href="#" class="btn btn-primary"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                        <span class="mr-"><i class="fa fa-tags text-primary"></i> Dessert</span><br>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.
                        </p>
                        <span class="mr-"><i class="fa fa-tags text-primary"></i> Résistance</span><br>
                        <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                        <a href="#" class="btn btn-primary"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                        <span class="mr-"><i class="fa fa-tags text-primary"></i> Entrée</span><br>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row row-cols-1 row-cols-md-3 g-4 my-5">
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                        <span class="mr-"><i class="fa fa-tags text-primary"></i> Boisson</span><br>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                        <span class="mr-"><i class="fa fa-tags text-primary"></i> Entrée</span><br>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.
                        </p>
                        <span class="mr-"><i class="fa fa-tags text-primary"></i> Boisson</span><br>
                        <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                        <a href="#" class="btn btn-primary"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                        <span class="mr-"><i class="fa fa-tags text-primary"></i> Résistance</span><br>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pagination">
                  <a href="" class="prev"><span class="bh"></span> <span>Précedent</span></a>
                  <a href="javascript:void(0)">1</a>
                  <span class="current">2</span>
                  <a href="javascript:void(0)">3</a>
                  <a href="javascript:void(0)">4</a>
                  <a href="" class="next"><span class="bh"></span> <span>Suivant</span></a>
                </div>
              </div>
              <!-- <div id="tracking-activity" class="tab-pane fade">
                <div class="row row-cols-1 row-cols-md-3 g-4 my-5">
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.
                        </p>
                        <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                        <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row row-cols-1 row-cols-md-3 g-4 my-5">
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.
                        </p>
                        <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                        <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pagination">
                  <a href="" class="prev"><span class="bh"></span> <span>Précedent</span></a>
                  <a href="javascript:void(0)">1</a>
                  <span class="current">2</span>
                  <a href="javascript:void(0)">3</a>
                  <a href="javascript:void(0)">4</a>
                  <a href="" class="next"><span class="bh"></span> <span>Suivant</span></a>
                </div>
              </div>
              <div id="secure-payment" class="tab-pane fade">
                <div class="row row-cols-1 row-cols-md-3 g-4 my-5">
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.
                        </p>
                        <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                        <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row row-cols-1 row-cols-md-3 g-4 my-5">
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.
                        </p>
                        <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                        <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pagination">
                  <a href="" class="prev"><span class="bh"></span> <span>Précedent</span></a>
                  <a href="javascript:void(0)">1</a>
                  <span class="current">2</span>
                  <a href="javascript:void(0)">3</a>
                  <a href="javascript:void(0)">4</a>
                  <a href="" class="next"><span class="bh"></span> <span>Suivant</span></a>
                </div>
              </div>
              <div id="secure-payments" class="tab-pane fade">
                <div class="row row-cols-1 row-cols-md-3 g-4 my-5">
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.
                        </p>
                        <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                        <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row row-cols-1 row-cols-md-3 g-4 my-5">
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.
                        </p>
                        <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                        <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src="assets/images/blog/nv.jpg" class="card-img-top">
                      <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a longer card with supporting.</p>
                          <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> 122 XOF</span>
                          <a href="#" class="btn btn-primary ml-2"><i class="fa fa-shopping-cart"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pagination">
                  <a href="" class="prev"><span class="bh"></span> <span>Précedent</span></a>
                  <a href="javascript:void(0)">1</a>
                  <span class="current">2</span>
                  <a href="javascript:void(0)">3</a>
                  <a href="javascript:void(0)">4</a>
                  <a href="" class="next"><span class="bh"></span> <span>Suivant</span></a>
                </div>
              </div>
              <div id="reservation" class="tab-pane fade">
                <div class="">
                  <form class="currency-form" action="#">
                    <h4>Réservez votre table </h4>
                    <div class="row col-md-12">
                      <div class="form-field col-md-4">
                        <label>Prénom</label>
                        <div class="join-field">
                          <input type="text" name="send_amount" placeholder="votre prénom">
                        </div>
                      </div>
                      <div class="form-field col-md-4">
                        <label>Nom</label>
                        <div class="join-field">
                          <input type="text" name="send_amount" placeholder="votre nom">
                        </div>
                      </div>
                      <div class="form-field col-md-4">
                        <label>Numéro de tel.</label>
                        <div class="join-field">
                          <input type="number" name="send_amount" placeholder="votre numéro de tel." >
                        </div>
                      </div>
                    </div>
                    <div class="row col-md-12">
                      <div class="form-field col-md-4">
                        <label>Date</label>
                        <div class="join-field">
                          <input type="date" name="send_amount" >
                        </div>
                      </div>
                      <div class="form-field col-md-4">
                        <label>Heure</label>
                        <div class="join-field">
                          <input type="time" name="send_amount" >
                        </div>
                      </div>
                      <div class="form-field col-md-4">
                        <label>Numéro de table </label>
                        <select id="rcv_country" name="rcv_country" class="form-control">
                          <option>Selectionner la table </option>
                          <option value="usa">1</option>
                          <option value="canada">12</option>
                          <option value="australia">27</option>
                        </select>
                      </div>
                    </div>
                    <button class="btn btn-block btn-filled form-btn">
                      <span class="bh"></span> <span>Valider <i class="fas fa-arrow-right"></i></span>
                    </button>
                  </form>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- System works end -->
    <!-- Blog single post section end -->
    <app-page-footer></app-page-footer>
  </div>
  
