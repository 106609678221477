<div class="blog-page">
    <app-page-header-shop></app-page-header-shop>
    <!-- Banner section start -->
    <section class="banner features pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8 m-auto">
            <div class="ban-content text-center">
              <h1 style="text-transform:none;">Validez votre commande</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner section end -->

    <!-- Blog single post section start -->
    <section class="blog-single-post">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="">
              <div class="container sl-form-wrap">
                <div class="row">
                  <div class="col-md-12 m-auto card p-5">
                    <!-- <header class="mb-2">
                      <h3 class="">Création d'un compte professionel</h3>
                    </header> -->
                    <div class="content-box">
                      <p class="">Vous êtes à un pas de finaliser votre achat. Tous les champs sont obligatoires</p>
                    </div>
                    <form  class="sl-form">
                      <div class="mb-2">
                        <h5 class="">Informations de livraison</h5>
                        <p class="notice">Vous avez déjà un compte? <a routerLink="/login" class="text-primary">Se connecter</a></p>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1"><i class="fa fa-user"></i></span>
                            </div>
                            <input type="text" formControlName="firstname" id="firstname" class="form-control" placeholder="Prénom" aria-label="firstname" aria-describedby="basic-addon1" required>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1"><i class="fa fa-user"></i></span>
                            </div>
                            <input type="text" formControlName="lastname" id="lastname" class="form-control" placeholder="Nom" aria-label="lastname" aria-describedby="basic-addon1" required>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1"><i class="fa fa-map-marker"></i></span>
                            </div>
                            <input type="text" formControlName="address" id="address" class="form-control" placeholder="Adresse" aria-label="address" aria-describedby="basic-addon1" required>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group mb-3">
                            <!-- <label for="phone">Numéro de téléphone</label> -->
                            <input type="tel" id="phoneNumber" formControlName="phoneNumber" value="" class="form-control" placeholder="numéro de téléphone">
                            <input type="hidden" id="code" name="code" #code value="ne">
                            <input type="hidden" id="dialcode" formControlName="dialcode" #dialcode value="227">
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input sign-up" id="signup" (click)="showData()" >
                              <label class="custom-control-label" for="signup">Créer un compte avec les informations saisies</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" *ngIf="compte == true">
                          <label for="username">Email</label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope"></i></span>
                            </div>
                            <input type="email" formControlName="username" id="username" class="form-control" placeholder="entrez votre adresse email" aria-label="email" aria-describedby="basic-addon1"  required>
                          </div>
                        </div>
                        <div class="col-md-6" *ngIf="compte == true">
                          <label for="birthday">Date de naissance</label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1"><i class="fa fa-calendar"></i></span>
                            </div>
                            <input type="date" formControlName="birthday" id="birthday" class="form-control" placeholder="entrez votre date de naissance" aria-label="birthday" aria-describedby="basic-addon1"  required>
                          </div>
                        </div>
                          <div class="col-md-6" *ngIf="compte == true">
                            <label for="password">Mot de passe</label>
                            <div class="input-group mb-3"id="compte" >
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1"><i class="fa fa-lock"></i></span>
                              </div>
                              <input type="password" formControlName="password" id="password" class="form-control" placeholder="***********" aria-label="password" aria-describedby="basic-addon1">

                            </div>
                          </div>
                          <div class="col-md-6" *ngIf="compte == true">
                            <label for="password">Confirmation Mot de passe</label>
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1"><i class="fa fa-lock"></i></span>
                              </div>
                              <input type="password" id="confirmPassword" formControlName="confirmPassword" class="form-control" placeholder="***********" aria-label="password" aria-describedby="basic-addon1">

                            </div>
                          </div>
                      </div>
                      <div class="mb-2 mt-3">
                        <h5 class="">Information de payement</h5>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-md-12">
                          <div class="accordion has-numbering1" id="accordion">
                            <div class="accordion-item active">
                              <h6 class="collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true">
                                <input type="radio" name="paiement" id="payementm1" checked> Payez avec SmartPay
                              </h6>
                              <div id="collapseOne" class="collapse show" data-parent="#accordion">
                                <div class="acr-body">
                                  <img src="assets/admin/images/smartpay.png" alt="">
                                  <div class="form-group row align-items-end">
                                    <div class="col-sm-8 mt-4 mb-sm-0">
                                      <p>Numéro du wallet</p>
                                      <input type="number" class="form-control" placeholder="Numéro du wallet">
                                    </div>
                                    <div class="col-sm-4 mt-3">
                                      <p>Entrez votre code</p>
                                      <input type="number" class="form-control" placeholder="Entrez votre code">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h6 class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false">
                                <input type="radio" name="paiement" id="payementm2"> Payez avec mobile money
                              </h6>

                              <div id="collapseTwo" class="collapse" data-parent="#accordion">
                                <div class="acr-body">
                                  <img src="assets/admin/images/mobilemoney.jpg" alt="">
                                  <p>Entrez votre numéro mobile money et valider le payement avec votre téléphone</p>
                                  <div class="form-group mb-3">
                                    <!-- <label for="phone">Numéro de téléphone</label> -->
                                    <input type="tel" id="phoneNumber1" formControlName="phoneNumber2" value="" class="form-control" placeholder="numéro de téléphone">
                                    <input type="hidden" id="code2" name="code2" #code >
                                    <input type="hidden" id="dialcode2" formControlName="dialcode2" #dialcode >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h6 class="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false">
                                <input type="radio" name="paiement" id="payementm3"> Payez avec votre carte Visa ou Mastercard
                              </h6>

                              <div id="collapseThree" class="collapse" data-parent="#accordion">
                                <div class="acr-body">
                                  <img src="assets/admin/images/mastercard1.png" alt="">
                                  <div class="form-group row align-items-end">
                                    <div class="col-sm-8 mt-4 mb-sm-0">
                                      <input type="number" class="form-control" placeholder="Numéro de carte">
                                    </div>
                                    <div class="col-sm-4 mt-3">
                                      <input type="month" class="form-control" placeholder="date">
                                    </div>
                                  </div>
                                  <div class="form-group row align-items-end">
                                    <div class="col-sm-8  mb-sm-0">
                                      <input type="text" class="form-control" placeholder="Nom & Prénom">
                                    </div>
                                    <div class="col-sm-4 mt-3">
                                      <input type="number" class="form-control" placeholder="Code de la carte">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h6 class="collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false">
                                <input type="radio" name="paiement" id="payementm4"> Payez avec les services de transfert d'argent
                              </h6>

                              <div id="collapseFour" class="collapse" data-parent="#accordion">
                                <div class="acr-body">
                                  <img src="assets/admin/images/sta.png" alt="">
                                  <p>Faites le transfert à travers le service e-money de l'une des services de transfert d'argent sur le numéro 00000000 et importez le réçu.
                                  </p>
                                  <input type="file" class="form-control" placeholder="Importez le reçu">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex mt-3">
                        <div class="form-group">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="tnc">
                            <label class="custom-control-label" for="tnc">J'accepte&nbsp;&nbsp;<a data-toggle="modal" data-target=".bd-example-modal-xl" class="text-primary">les conditions d'utilisation</a> et la <a data-toggle="modal" data-target=".bd-example-modal-xl-2" class="text-primary">politique de confidentialité</a></label>
                          </div>
                        </div>
                        <button class="btn-sp color-primary float-right ml-auto mr-0 h-auto">
                          <span class="ml-2" id="text">Envoyer</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="sidebar">
              <div class="card">
                <div class="card-header">
                  <h5 class="text-center">Produits</h5>
                </div>
                <div class="card-body">
                  <table class="table table-hover">
                    <tbody>
                      <tr>
                        <th scope="row">
                          <a class="btn position-relative">
                            <img src="assets/images/blog/nv.jpg" class="img-fluid rounded-start" alt="..." style="height: 80px; width: 80px;">
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary text-white">
                              1
                            </span>
                          </a>
                        </th>
                        <td>50.000 FCFA</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <a class="btn position-relative">
                            <img src="assets/images/blog/nv.jpg" class="img-fluid rounded-start" alt="..." style="height: 80px; width: 80px;">
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary text-white">
                              2
                            </span>
                          </a>
                        </th>
                        <td>5.000 FCFA</td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-hover">
                    <tbody>
                      <tr>
                        <th scope="row">
                          <i class="fa fa-donate mr-2"></i>Total:
                        </th>
                        <td><strong  class="ml-5"> 55.000 FCFA</strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- <div class="card-footer">
                  <button class="btn mt-3 btn-primary w-100" style="height: 45px;">Valider la Commande &nbsp;<i class="fas fa-arrow-right"></i></button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Blog single post section end -->
    <app-page-footer></app-page-footer>
  </div>

