<div class="blog-page">
    <app-page-header></app-page-header>
    <!-- Banner section start -->
    <section class="banner features pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8 m-auto">
            <div class="ban-content text-center">
              <h1 style="text-transform:none;">Titre de la collecte</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner section end -->
  
    <!-- Blog single post section start -->
    <section class="blog-single-post">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <article class="post-item">
              <figure class="post-thumb">
                <img src="assets/images/blog/1.jpg" alt="">
              </figure>
              <div class="row">
                <div class="col-12">
                  <ul class="nav nav-tabs list-tab-nav">
                    <li><a class="active" data-toggle="tab" href="#benefits-1">Détails</a></li>
                    <li><a data-toggle="tab" href="#benefits-2">Galleries</a></li>
                    <li><a data-toggle="tab" href="#benefits-3">Contributeurs</a></li>
                  </ul>
        
                  <div class="tab-content">
                    <div id="benefits-1" class="tab-pane fade in active show">
                      <div class="content-box py-5">
                        <h5 style="text-transform:none;">Our Goal Is To Provide The Best Service To Our Users</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                          maecenas</p>
                        <p>ccumsanamet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                          magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                      </div>
                    </div>
                    <div id="benefits-2" class="tab-pane fade">
                      <div class="my-5">
                        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                          <ol class="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                          </ol>
                          <div class="carousel-inner">
                            <div class="carousel-item active">
                              <img class="d-block w-100" src="assets/images/blog/1.jpg" alt="First slide">
                            </div>
                            <div class="carousel-item">
                              <img class="d-block w-100" src="assets/images/blog/1.jpg" alt="Second slide">
                            </div>
                            <div class="carousel-item">
                              <img class="d-block w-100" src="assets/images/blog/1.jpg" alt="Third slide">
                            </div>
                          </div>
                          <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                          </a>
                          <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div id="benefits-3" class="tab-pane fade">
                      <div class="sidebar my-5">
                        <aside class="widget widget_categories">
                          <ul>
                            <li>
                              <a href="javascript:void(0)"><i class="fa fa-user mr-2 text-primary"></i>Anonyme<span>50.000 FCFA</span></a>
                              <p class="text-justify">Qu'Allah facilite, merci de nous inviter à œuvrer</p>
                            </li>
                            <li>
                              <a href="javascript:void(0)"><i class="fa fa-user mr-2 text-primary"></i>Anonyme<span>5.000 FCFA</span></a>
                              <p class="text-justify">Qu'Allah facilite, merci de nous inviter à œuvrer</p>
                            </li>
                            <li>
                              <a href="javascript:void(0)"><i class="fa fa-user mr-2 text-primary"></i>Anonyme<span>10.000 FCFA</span></a>
                              <p class="text-justify">Qu'Allah facilite, merci de nous inviter à œuvrer</p>
                            </li>
                            <li>
                              <a href="javascript:void(0)"><i class="fa fa-user mr-2 text-primary"></i>Anonyme<span>3.000 FCFA</span></a>
                              <p class="text-justify">Qu'Allah facilite, merci de nous inviter à œuvrer</p>
                            </li>
                          </ul>
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
          <div class="col-md-4">
            <div class="sidebar">
              <div class="card">
                <div class="card-header">
                  <h5 class="text-center">Objectif: 2.000.000 FCFA</h5>
                  <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                  </div>
                </div>
                <div class="card-body">
                      <span class="d-block"><i class="fa fa-donate mr-2"></i>Collectés: 1.005.000 CFA</span>
                      <span class="d-block"><i class="fa fa-clock mr-2"></i>Jours restants: 20</span>
                </div>
                <div class="card-footer">
                  <i class="fa fa-user text-primary"></i> Mr Abdoul Madjid
                </div>
              </div>
              <div class="card mt-3">
                <div class="card-header bg-primary">
                  <h5 class="text-center text-white">Je participe !</h5>
                </div>
                <div class="card-body">
                  <form class="" action="#">
                    <div class="input-group">
                      <input type="number" class="form-control" style="height: 45px;" id="validationDefaultUsername" placeholder="Montant..." aria-describedby="inputGroupPrepend2" required>
                      <div class="input-group-append">
                        <span class="input-group-text" id="inputGroupPrepend2">FCFA</span>
                      </div>
                    </div>
                    <h6 class="mt-3">Vos coordonnées </h6>
                    <div class="form-group mb-3">
                      <input type="text" class="form-control" style="height: 45px;" id="validationDefault01" placeholder="Nom complet..." required>
                    </div>
                    <div class="form-group mb-3">
                      <input type="email" class="form-control" style="height: 45px;" id="validationDefault02" placeholder="Adresse email..." required>
                    </div>
                    <div class="form-group mb-3">
                      <textarea class="form-control" placeholder="Commentaire..." id="exampleFormControlTextarea1" rows="3"></textarea>
                    </div>
                    <div class="form-group">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck">
                        <label class="form-check-label" style="font-size: 14px;" for="gridCheck">
                          Afficher votre nom dans les contributeurs
                        </label>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck">
                        <label class="form-check-label" style="font-size: 14px;" for="gridCheck">
                          J'accepte la politique de confidentialité
                        </label>
                      </div>
                    </div>
                    <div class="payment">
                      <h6 class="mt-3">Moyen de paiement </h6>
                      <div class="row">
                        <div class="col-md-6 col-sm-12 mb-3">
                            <div class="single-payment payment-method selected" id="wallet" (click)="changePaymentMethodTo('wallet')">
                                <img src="../../../assets/admin/images/payment-4.png" alt="">
                            </div>
                        </div>
                        
                        <div class="col-md-6 col-sm-12 mb-3">
                            <div class="single-payment payment-method" id="mobileMoney" (click)="changePaymentMethodTo('mobileMoney')">
                                <img src="../../../assets/admin/images/payment-2.png" alt="">
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div class="single-payment payment-method" id="creditCard" (click)="changePaymentMethodTo('creditCard')">
                                <img src="../../../assets/admin/images/payment-1.png" alt="">
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="single-payment payment-method" id="sta" (click)="changePaymentMethodTo('creditCard')">
                              <img src="../../../assets/admin/images/payment-sta.png" alt="">
                          </div>
                        </div>
                        <div class="col-12">
                          <button class="btn mt-3 btn-primary w-100" style="height: 45px;">Donner</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="card-footer">
                  <p class="text-justify">
                    <i class="fa fa-info-circle fa-2x text-info mr-2"></i>Quel que soit le montant, chacun peut participer à cette collecte. Pas besoin de créer un compte ou de s'inscrire, c'est rapide et les paiements par Carte Bancaire sont 100% sécurisés.
                  </p>
                  <div class="social-share mb-0" style="border-bottom:none;">
                    <p class="text-justify">Si vous ne pouvez pas participer financièrement, partagez cette collecte autour de vous au maximum. MERCI !</p>
                    <label>Partager: </label>
                    <a href="javascript:void(0)" style="background-color: #3b5999;"><i class="fab fa-facebook-f"></i></a>
                    <a href="javascript:void(0)" style="background-color: #399fed;"><i class="fab fa-twitter"></i></a>
                    <a href="javascript:void(0)" style="background-color: #0077B5;"><i class="fab fa-linkedin"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Blog single post section end -->
    <app-page-footer></app-page-footer>
  </div>
  
