import {Component, OnInit} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { ProduitPanier } from "src/app/models/produit-panier";
import { FlashMessageService, SwalConfirmItem } from "src/app/services/flash-message.service";
import { LocalService } from "src/app/services/local.service";
import { StoreService } from "src/app/services/store.service";
import { SweetAlertOptions } from "sweetalert2";

declare let $: any;

@Component({
  selector: 'app-page-panier',
  templateUrl: './page-panier.component.html',
  styleUrls: ['./page-panier.component.scss'],
})
export class PagePanierComponent implements OnInit {
  pm?: string;
  item?: BigInteger;
  panier: Array<ProduitPanier> = [];
  total: number = 0;
  swalOptions: SweetAlertOptions = { icon: 'info' };

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private localStore: LocalService,
    private storeService: StoreService,
    private swalService: FlashMessageService
  ) {}
  changePaymentMethodTo(paymentMethod: string) {
    this.pm = paymentMethod;
  }
  public confirmItem: SwalConfirmItem = {
    fnConfirm: this.deleteConfirm,
    confirmData: null,
    context: this,
  };
  public delete(p: ProduitPanier) {
    this.swalOptions.icon = 'question';
    this.swalOptions.title = 'Supprimer produit';
    this.swalOptions.html = `Etes vous sur de vouloir supprimer le produit ${p.name} du panier?`;
    this.swalOptions.showConfirmButton = true;
    this.swalOptions.showCancelButton = true;
    this.confirmItem.confirmData = p; //pass the data to delete confirm function
    this.confirmItem.fnConfirm = this.deleteConfirm;
    this.swalService.setConfirm(this.confirmItem);
    this.swalService.show(this.swalOptions);
  }

  async deleteConfirm(isConfirm: string, data: ProduitPanier, context: any) {
    try {
      const res = await context.storeService.removeItemToCart(data.id,data.quantity);
      if ('status' in res && res['status']==true) {
        this.panier = context.removeProduct(data.id);
        context.localStore.saveData('panier', JSON.stringify(this.panier));
        this.swalOptions.showCancelButton = false;
        context.confirmItem.fnConfirm = null;
        this.swalService.setConfirm(context.confirmItem);
        this.swalOptions.icon = 'success';
        this.swalOptions.html = `Le produit a été supprimer`;
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        this.swalService.show(this.swalOptions);
      } else {
        this.swalOptions.icon = 'error';
        this.swalOptions.html = "Le produit n'a pas été supprimer";
        this.swalService.show(this.swalOptions);
      }
    } catch (error) {
      this.swalOptions.icon = 'error';
      this.swalOptions.html = "Le produit n'a pas été supprimer";
      this.swalService.showErrors(error, this.swalOptions);
    }
  }
  updatePanier(shopping_cart: Array<any>, id: string, quantity: Object) {
    return shopping_cart.map((item) =>
      item.id === id ? { ...item, ...quantity } : item
    );
  }

  public removeProduct(id: string) {
    return this.panier.filter((x) => x.id !== id);
  }

  async addQuantity(p: ProduitPanier) {
    try {
      const addItemRes = await this.storeService.addItemToCart(p);
      if ('status' in addItemRes && addItemRes['status'] == true){
        this.panier = this.updatePanier(this.panier, p.id, {
          quantity: p.quantity + 1,
        });
        this.localStore.saveData('panier', JSON.stringify(this.panier));
        this.calculTotal();
      }else{
        this.swalOptions.icon = 'error';
        this.swalOptions.html = "La quantité n'a pas été ajouter";
        this.swalService.show(this.swalOptions);
      }
    } catch (error) {

    }

  }

  async removeQuantity(p: ProduitPanier) {
    try {
          if (p.quantity==1) {
            this.delete(p);
          }else{
            const addItemRes = await this.storeService.removeItemToCart(p.id,1);
            if ('status' in addItemRes && addItemRes['status'] == true) {
              this.panier = this.updatePanier(this.panier, p.id, {
                quantity: p.quantity - 1,
              });
              this.localStore.saveData('panier', JSON.stringify(this.panier));
              this.calculTotal();
            } else {
              this.swalOptions.icon = 'error';
              this.swalOptions.html = "La quantité n'a pas été diminuer";
              this.swalService.show(this.swalOptions);
            }
          }
        } catch (error) {}

  }

  calculTotal() {
    this.total = 0;
    for (let produit of this.panier) {
      this.total = this.total + produit.price * produit.quantity;
    }
  }
  ngOnInit() {
    this.panier = JSON.parse(this.localStore.getData('panier'));
    this.calculTotal();
  }
  onAddItem() {
    this.item;
  }
}
