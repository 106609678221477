<div class="blog-page">
    <app-page-header-shop></app-page-header-shop>
    <!-- Banner section start -->
    <section class="banner features">
      <div class="container">
        <div class="row">
          <!-- <div class="col-md-4">
            <img src="assets/images/boutique.png" alt="" class="">
          </div> -->
          <div class="col-md-12  mb-5">
            <div class="ban-content text-center">
              <h1 style="text-transform:none;">Nos restaurants en ligne</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner section end -->

    <!-- Blog posts section start -->
    <section class="blog-posts">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="posts-wrap">
              <div class="card mb-5">
                <figure class="post-thumb">
                  <img src="assets/images/blog/1.jpg" alt="">
                </figure>
                <div class="card-body">
                  <h3><a href="/page-restau-details">Restaurant A</a> | Niamey</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscied do eiusmod tempor incidid labore et dolore magna
                    aliqua. Quis ipsum suspen disse ultrices gravida. here are many variations of passages of them Lorem
                    Ipsum available, but the majority have suffered alteration in some form.</p>
                    <span class="mr-4"><i class="fa fa-eye text-primary"></i> 122</span>
                    <span class="mr-4"><i class="fa fa-shopping-cart text-primary"></i> 122</span>
                    <span class="mr-4"><i class="fa fa-tags text-primary"></i> Restauration</span>
                    <span class="mr-4"><i class="fa fa-phone-alt text-primary"></i> +227 00 000 000</span>
                </div>
              </div>
              <div class="card mb-5">
                <figure class="post-thumb">
                  <img src="assets/images/blog/1.jpg" alt="">
                </figure>
                <div class="card-body">
                  <h3><a href="/page-restau-details">Restaurant B</a> | Niamey</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscied do eiusmod tempor incidid labore et dolore magna
                    aliqua. Quis ipsum suspen disse ultrices gravida. here are many variations of passages of them Lorem
                    Ipsum available, but the majority have suffered alteration in some form.</p>
                    <span class="mr-4"><i class="fa fa-eye text-primary"></i> 122</span>
                    <span class="mr-4"><i class="fa fa-shopping-cart text-primary"></i> 122</span>
                    <span class="mr-4"><i class="fa fa-tags text-primary"></i> Restauration</span>
                    <span class="mr-4"><i class="fa fa-phone-alt text-primary"></i> +227 00 000 000</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="posts-wrap">
              <div class="card mb-5">
                <figure class="post-thumb">
                  <img src="assets/images/blog/1.jpg" alt="">
                </figure>
                <div class="card-body">
                  <h3><a href="/page-restau-details">Restaurant D</a> | Niamey</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscied do eiusmod tempor incidid labore et dolore magna
                    aliqua. Quis ipsum suspen disse ultrices gravida. here are many variations of passages of them Lorem
                    Ipsum available, but the majority have suffered alteration in some form.</p>
                    <span class="mr-4"><i class="fa fa-eye text-primary"></i> 122</span>
                    <span class="mr-4"><i class="fa fa-shopping-cart text-primary"></i> 122</span>
                    <span class="mr-4"><i class="fa fa-tags text-primary"></i> Restauration</span>
                    <span class="mr-4"><i class="fa fa-phone-alt text-primary"></i> +227 00 000 000</span>
                </div>
              </div>
              <div class="card mb-5">
                <figure class="post-thumb">
                  <img src="assets/images/blog/1.jpg" alt="">
                </figure>
                <div class="card-body">
                  <h3><a href="/page-restau-details">Sawki Restaurant</a> | Niamey</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscied do eiusmod tempor incidid labore et dolore magna
                    aliqua. Quis ipsum suspen disse ultrices gravida. here are many variations of passages of them Lorem
                    Ipsum available, but the majority have suffered alteration in some form.</p>
                    <span class="mr-4"><i class="fa fa-eye text-primary"></i> 122</span>
                    <span class="mr-4"><i class="fa fa-shopping-cart text-primary"></i> 122</span>
                    <span class="mr-4"><i class="fa fa-tags text-primary"></i> Restauration</span>
                    <span class="mr-4"><i class="fa fa-phone-alt text-primary"></i> +227 00 000 000</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <a href="" class="prev"><span class="bh"></span> <span>Précedent</span></a>
          <a href="javascript:void(0)">1</a>
          <span class="current">2</span>
          <a href="javascript:void(0)">3</a>
          <a href="javascript:void(0)">4</a>
          <a href="" class="next"><span class="bh"></span> <span>Suivant</span></a>
        </div>
      </div>
    </section>
    <!-- Blog posts section end -->
    <app-page-footer></app-page-footer>
  </div>

