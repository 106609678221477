import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { environment } from 'src/environments/environment';
import { LocalService } from '../../../services/local.service';
import { SweetAlertOptions } from 'sweetalert2';
import { FlashMessageService } from 'src/app/services/flash-message.service';
const CHANGE_PIN_API="/recharge/v1/change/pin";
declare let $: any;
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  user: any = [];
  validateCode = true;
  codePattern!: string;
  oldpin!: number;
  newpin!: number;
  private data: any = [];
  private dataError: any = [];
  changePinForm!: FormGroup;
  isWrongOldPin = false;
  isWrongNewPin = false;
  isValidOtp = false;
  errorMessage!: string;
  swalOptions: SweetAlertOptions = { icon: 'info' };
  password = 'password';
  pin = 'password';
  show = false;
  pin_show = false;
  get f() {
    return this.changePinForm.controls;
  }
  constructor(
    private tokenStorageService: TokenStorageService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private actevatedRoute: ActivatedRoute,
    private http: HttpClient,
    private localStore: LocalService,
    private swalService: FlashMessageService
  ) {}

  changePIN(oldpin: string, newpin: string) {
    this.oldpin = parseInt(oldpin);
    this.newpin = parseInt(newpin);
    if (!isNaN(this.oldpin) || !isNaN(this.newpin)) {
      const request = new Observable<boolean>((observer) => {
        this.http
          .post(
            environment.baseURL + CHANGE_PIN_API,
            {
              oldpin: oldpin,
              newpin: newpin,
            },
            {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.localStore.getData('token'),
              }),
            }
          )
          .subscribe(
            (result) => {
              this.data = result;
              observer.next(true);
              observer.complete();
            },
            (error: HttpErrorResponse) => {
              this.dataError = error;
              observer.error(false);
              observer.complete();
            }
          );
      });

      request.subscribe(
        (result) => {
          if ('status' in this.data) {
            if (this.data['status'] == true) {
              $('#change_pin').remove();
              $('.modal-backdrop').remove();
              $('body').removeClass('modal-open');
              this.swalOptions.icon = 'success';
              this.swalOptions.html =
                'votre code PIN a été modifié avec succès!';
              this.swalService.show(this.swalOptions);
            } else {
              this.isWrongOldPin = true;
              this.swalOptions.icon = 'error';
              this.swalOptions.html = this.data['message'];
              this.swalService.show(this.swalOptions);
            }
          } else {
            this.isWrongOldPin = true;
            this.swalOptions.icon = 'error';
            this.swalOptions.html = this.data['message'];
            this.swalService.show(this.swalOptions);
          }
        },
        (error) => {
          console.log(this.dataError);
          if ('status' in this.dataError) {
            if (this.dataError['status'] == 401) {
              this.authService.logout();
            }
          }
        }
      );
    } else {
      if (isNaN(this.oldpin)) {
        this.isWrongOldPin = true;
        console.log(this.oldpin);
      }
      if (isNaN(this.newpin)) {
        this.isWrongNewPin = true;
      }
    }
  }
  initialization() {}
  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
  chowPin() {
    if (this.pin === 'password') {
      this.pin = 'text';
      this.pin_show = true;
    } else {
      this.pin = 'password';
      this.pin_show = false;
    }
  }
  ngOnInit() {
    this.user = this.tokenStorageService.getUser();
    this.user = JSON.parse(this.user);
    this.changePinForm = this.formBuilder.group(
      {
        oldpin: [
          '',
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(4),
          ],
        ],
        newpin: [
          '',
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(4),
          ],
        ],
      },
      {
        updateOn: 'change',
      }
    );
    // this.loadScript('../../../assets/admin/js/jquery.min.js');
    // this.loadScript('../../../assets/admin/js/bootstrap.bundle.min.js');
  }
}
