import {Component, OnInit} from "@angular/core";

declare let $: any;

@Component({selector: "app-page-validation-panier", templateUrl: "./page-validation-panier.component.html", styleUrls: ["./page-validation-panier.component.scss"]})
export class PageValidationPanierComponent implements OnInit {
  pm?: string;
  compte = false;
  constructor() {}
  showData() {
    var etat = <HTMLInputElement> document.getElementById("signup");
    if (etat.checked) {
      this.compte = true;
    }
    else
    {
      this.compte = false;
    }
  }
  /* saveAccount(signUp: string){
    this.pm=signUp;
    $('.sign-up').removeClass('disabled');
    $('#'+signUp).addClass('disabled');
    // console.log(this.sp);
  } */
  initialization() {

    // ================================
    // Phone Number
    // ================================
    var input = <HTMLInputElement>document.querySelector("#phoneNumber");
    var input1 = <HTMLInputElement>document.querySelector("#phoneNumber1");
    //errorMsg = document.querySelector("#error-msg"),
    //validMsg = document.querySelector("#valid-msg");

    // Error messages based on the code returned from getValidationError
    var errorMap = [ "Ce numéro n'est pas valide", "Code du pays non valide", "Numéro trop court", "Numéro trop long", "Numéro invalide"];


    var intl=(<any>window).intlTelInput(input, {
      // allowDropdown: false,
      autoHideDialCode: false,
      // autoPlaceholder: "off",
      // dropdownContainer: document.body,
      // excludeCountries: ["us"],
      // formatOnDisplay: false,
      //initialCountry: "auto",
      initialCountry: "ne",
      // geoIpLookup: function(callback) {
      //     $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
      //         var countryCode = (resp && resp.country) ? resp.country : "";
      //         callback(countryCode);
      //     });
      // },
      hiddenInput: "full_number",
      //initialCountry: "ne",
      //localizedCountries: { 'ne': 'Niger' },
      // nationalMode: false,
      onlyCountries: ['ne'],
      // onlyCountries: ['us', 'gb', 'ch', 'ca', 'do'],
      // placeholderNumberType: "MOBILE",
      //preferredCountries: ['ne'],
      separateDialCode: true,
      utilsScript: "assets/plugin/build/js/utils.js",
    });

    var intl1=(<any>window).intlTelInput(input1, {
      // allowDropdown: false,
      autoHideDialCode: false,
      // autoPlaceholder: "off",
      // dropdownContainer: document.body,
      // excludeCountries: ["us"],
      // formatOnDisplay: false,
      //initialCountry: "auto",
      initialCountry: "ne",
      // geoIpLookup: function(callback) {
      //     $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
      //         var countryCode = (resp && resp.country) ? resp.country : "";
      //         callback(countryCode);
      //     });
      // },
      hiddenInput: "full_number",
      //initialCountry: "ne",
      //localizedCountries: { 'ne': 'Niger' },
      // nationalMode: false,
      onlyCountries: ['ne'],
      // onlyCountries: ['us', 'gb', 'ch', 'ca', 'do'],
      // placeholderNumberType: "MOBILE",
      //preferredCountries: ['ne'],
      separateDialCode: true,
      utilsScript: "assets/plugin/build/js/utils.js",
    });
  }

  ngOnInit() {
    this.initialization();
  }
}
