<div class="home-two">
    <div class="p-0 m-0">
      <app-page-header></app-page-header>
      <!-- Banner section start -->
      <section class="banner features">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-12">
              <img src="assets/images/step.png" alt="" style="" class="ml-auto">
            </div>
            <div class="col-lg-9 col-md- col-12 my-auto">
              <div class="ban-content ">
                <h3 class="text-white">Création d'un compte utilisateur</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Banner section end -->
    </div>
    <div class="overview mt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <ul class="steps">
              <li><span class="bg-primary text-white">1</span></li>
              <li><span>2</span></li>
              <li><span>3</span></li>
              <li><span>4</span></li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9 card m-auto p-5">
            <form name="form" (ngSubmit)="f.form.valid && onFormSubmit()" #f="ngForm" novalidate class="sl-form">
              <div>
                <h6>Choisissez le canal de réception de code OTP</h6>
                <div class="toggle">
                  <input type="radio" name="typeAccount" checked="checked" value="phone" (change)="onItemChange($event)" id="phone" />
                  <label for="phone"><i class="fa fa-sms mr-2"></i>SMS</label>
                  <input type="radio" name="typeAccount" value="email" id="email" (change)="onItemChange($event)" />
                  <label for="email"><i class="fas fa-envelope mr-2"></i>Email</label>
                </div>
                <p class="status text-justify" id="p_phone">
                  Recevez le code OTP par SMS si vous êtes au Niger, sinon passez par email pour vous identifier.
                </p>
                <p class="status text-justify" style="display: none;" id="p_email">
                  Recevez le code OTP par email.
                </p>
                <div class="d-flex mt-5">
                  <p class="notice">Vous avez déjà un compte? <a routerLink="/login" class="text-primary">Se connecter</a></p>
                  <button class="btn-sp color-primary float-right ml-auto mr-0"><span class="bh"></span> <span>Continuer</span></button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    <app-page-footer></app-page-footer>
  </div>
