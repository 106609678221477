
<!-- Document Wrapper  -->
<div id="main-wrapper">
    <app-user-header></app-user-header>

    <app-user-profilebar></app-user-profilebar>


    <!-- Admin Content Section  -->
    <div id="content" class="py-4">
        <div class="container">
            <div class="row">
                  <div class="col-lg-3" >
                    <app-user-left-menu></app-user-left-menu>
                  </div>
                  <!-- Middle Panel  -->
                  <div class="col-lg-9">
                    <app-store-navbar></app-store-navbar>
                    <div class="tab-content py-4" id="pills-tabContent">
                      <div class="row p-0 m-0">
                        <div class="col-6 p-0 m-0">
                          <h5 href="" class=" mb-4 m-0">Formulaire produit</h5>
                        </div>
                        <div class="col-6 p-0 m-0">

                        </div>
                      </div>
                      <form [formGroup]="productForm" id="change-password" method="post">
                        <div class="row">
                          <div class="form-group col-md-6">
                              <label for="Current-pass">Nom du produit</label>
                              <input type="text" formControlName="name" id="name" class="form-control" placeholder="nom du produit" aria-label="nom" required>
                          </div>
                          <div class="form-group col-md-6">
                              <label for="Current-pass">Categorie</label>
                              <select id="category" formControlName="category" class="form-control">
                                  <option value="Électronique">Électronique</option>
                                  <option value="Mode">Mode</option>
                                  <option value="Maison et jardin">Maison et jardin</option>
                                  <option value="Sports et loisirs">Sports et loisirs</option>
                                  <option value="Beauté et soins personnels">Beauté et soins personnels</option>
                                  <option value="Livres, films et musique">Livres, films et musique</option>
                                  <option value="Automobiles et moto">Automobiles et moto</option>
                                  <option value="Jouets et jeux">Jouets et jeux</option>
                                  <option value="Alimentation et boissons">Alimentation et boissons</option>
                                  <option value="Équipement informatique">Équipement informatique</option>
                                  <option value="Art et artisanat">Art et artisanat</option>
                                  <option value="Santé et bien-être">Santé et bien-être</option>
                                  <option value="Équipement de voyage">Équipement de voyage</option>
                              </select>
                          </div>
                          <div class="form-group col-md-6">
                              <label for="Current-pass">Prix</label>
                              <input type="number" formControlName="price" id="price" class="form-control" placeholder="prix" aria-label="price" required>
                          </div>
                          <div class="form-group col-md-6">
                              <label for="Current-pass">Quantité</label>
                              <input type="number" formControlName="quantity" id="quantity" class="form-control" placeholder="quantité" aria-label="quantity" required>
                          </div>
                          <div class="d-none">
                              <input type="hidden" formControlName="id" id="id" class="form-control" placeholder="id" aria-label="id" required>
                          </div>
                        </div>
                        <div class="form-group">
                            <label for="Current-pass">Description</label>
                            <textarea type="number" formControlName="description" id="description" class="form-control" placeholder="description du produit" ria-label="description" required></textarea>
                        </div>
                        <div class="text-danger" *ngIf="wrongCredentials">{{ errorMessage }}</div>
                        <button class="btn btn-sp color-primary float-right mt-2 ml-auto mr-0" type="submit" [disabled]="!productForm.valid" (click)="onFormSubmit()"><i class="far fa-save"></i> Enregistrer</button>
                        <a routerLink="/store/products" class="btn btn-sp color-dark text-white float-right skip mt-2 ml-auto mr-2">Annuler</a>
                      </form>
                    </div>
                  </div>
            </div>
        </div>
    </div>
    <!-- Content end -->

    <app-page-footer></app-page-footer>
</div>
<!-- Document Wrapper end -->
