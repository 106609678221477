import { Component, OnInit, ViewChild } from '@angular/core';
import { SweetAlertOptions, SweetAlertUpdatableParameters } from 'sweetalert2';
import { FlashMessageService } from '../../../services/flash-message.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
declare let $: any;
@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent implements OnInit {
  isSwalVisible = false;
  swalConfirmData: any;
  swalComponentContext: any;

  @ViewChild(SwalComponent) swalBox!: SwalComponent;
  swalOptions: SweetAlertOptions = {};
  constructor(private swalService: FlashMessageService) {
    this.swalService.swalEmitted.subscribe(
      (
        options:
          | Pick<SweetAlertOptions<any, any>, SweetAlertUpdatableParameters>
          | undefined
      ) => {
        if (!this.swalBox) {
          //just wait for the time to load the object if can't find it
          setTimeout(() => {
            if (this.swalBox) {
              this.isSwalVisible = true;
              this.swalBox.update(options);
              this.swalBox.fire();
            }
          }, 500);
        } else {
          this.isSwalVisible = true;
          this.swalBox.update(options);
          this.swalBox.fire();
        }
      }
    );
    //set the confirm function and execute the login in the child component
    this.swalService.swalConfirmEmitted$.subscribe(
      (confirmItem: {
        fnConfirm: ((item: string, data: any, context: any) => void) | null;
        confirmData: any;
        context: any;
      }) => {
        this.handleConfirm =
          confirmItem.fnConfirm == null
            ? this.resetHandleConfirm
            : confirmItem.fnConfirm;
        this.swalConfirmData = confirmItem.confirmData;
        this.swalComponentContext = confirmItem.context;
      }
    );
    //handle close the swal event
    this.swalService.swalCloseEmitted.subscribe((item: any) => {
      this.swalBox.close();
    });
  }

  initialization() {
    if ($(window).width() <= 575) {
      $('.footer .foo-nav h5').append('<i class="fas fa-plus fa-minus"></i>');
      $('.footer .foo-nav h5').on('click', () => {
        $(this).next('ul').slideToggle('');
        $(this).children('i').toggleClass('fa-plus');
      });
    }
  }
  handleConfirm(item: string, data: any, context: any): void {
    //this will be overwrite by this.swalService.swalConfirmEmitted$
  }
  //just for reset(remove) the existing handle confirm function
  resetHandleConfirm(item: string, data: any, context: any): void {
    //this will be overwrite by this.swalService.swalConfirmEmitted$
  }
  ngOnInit() {
    this.initialization();
  }

  currentYearLong(): number {
    return new Date().getFullYear();
  }
}
