<div class="error-page">
    <app-page-header></app-page-header>
    <!-- 404 Error section start -->
    <div class="error-section py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-11 m-auto">
            <img src="assets/images/404.png" alt="">
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-9 m-auto text-center">
            <div class="sec-heading">
              <h2 class="sec-title">Oops! Page Not Found</h2>
              <p class="sec-subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua.</p>
            </div>
            <button class="btn-sp color-primary"><a routerLink="/" class="text-white"><span class="bh"></span> <span>Back to
                Home</span></a></button>
          </div>
        </div>
      </div>
    </div>
    <!-- 404 Error section end -->
    <app-page-footer></app-page-footer>
  </div>
  
