<div class="terms-page">
    <app-page-header></app-page-header>
    <!-- Banner section start -->
  <section class="banner features">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-12">
          <img src="assets/images/step.png" alt="" style="" class="ml-auto">
        </div>
        <div class="col-lg-9 col-md- col-12 my-auto">
          <div class="ban-content ">
            <h3 class="text-white">Conditions d'utilisation de SmartPay</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
    <!-- Banner section end -->

    <!-- Faq section start -->
    <section class="terms-tab">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-10 m-auto text-center">
            <div class="sec-heading">
              <h2 class="sec-title">Conditions d'utilisation de SmartPay</h2>
              <p class="sec-subtitle">Vous pouvez également telecharger ces conditions en PDF</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-10 col-md-12 m-auto">

            <div class="tab-content">
              <div id="general-qs" class="tab-pane fade in active show">
                <div class="accordion" id="accordion">
                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-1" aria-expanded="true">
                      Article 1 - Objet
                    </h5>
                    <div id="faq-1" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">Les présentes conditions générales établissent les conditions
                          contractuelles exclusivement applicables entre la société <strong>“DEV4SMART”</strong> et les utilisateurs du service <strong>“SmartPay”</strong>.
                          <br>
                          <strong>“SmartPay”</strong> est un agrégateur de recharge électronique et paiement en ligne  appartenant à la société DEV4SMART
                          intervenant en qualité de prestataire technique, en partenariat avec les opérateurs Mobile Money (OTM) du Niger et les Banques partenaires. <br>
                          <br>
                          Le Client s’engage dès lors à se conformer aux présentes conditions générales d’utilisation ainsi qu’à toutes les modifications ultérieures qui
                          pourraient survenir au titre de l’utilisation du service <strong>“SmartPay”</strong>
                          <br>
                          <strong>DÉFINITIONS</strong>
                          <br><strong>•	Activités :</strong> désigne l'agrégation de recharge électronique et paiement en ligne  à travers le service dénommé <strong>“SmartPay”</strong>
                          mise à la disposition des Clients.
                          <br><strong>•	Banque ou Opérateur Mobile Money :</strong> Établissement bancaire émetteur de la Monnaie électronique, dûment autorisé par la BCEAO ;
                          <br><strong>•	Compte “SmartPay” :</strong> le compte d'agrégation de recharge électronique et paiement en ligne unique associé à un Client du service « SmartPay » et intégrant diverses
                          informations d’identification ;
                          <br><strong>•	Conditions Générales :</strong> désigne les conditions contractuelles, exclusivement applicables entre la Société et ses Clients, détaillées
                          dans les présentes conditions générales d’utilisation.
                          <br><strong>•	« Données » :</strong> désigne les informations fournies directement ou indirectement par l’Utilisateur dans le cadre de l’utilisation du service
                          “SmartPay”, notamment lors de son Inscription et de la création de son Compte. L’Utilisateur garantit qu’il fournit des informations exactes et à jour,
                          en particulier s’agissant de son identité et ses coordonnées.
                          <br><strong>•	« Données à Caractère Personnel » :</strong> désigne toute information relative à une personne physique ou morale identifiée.
                          <br><strong>•	Client :</strong> un Client détenant un compte “SmartPay” et dûment autorisé à l’utiliser ;
                          <br><strong>•	Société :</strong> désigne DEV4SMART, Société à Responsabilité Limitée, immatriculée au RCCM-NI-NIA-2016-B-3473 et le NIF 40675/R au Niger;
                          <br><strong>•	Transaction :</strong> désigne sans limitation :
                          <br>•	 L’achat de crédit téléphonique;
                          <br>•	 L'émission et la réception des paiements à travers la plateforme « SmartPay »;
                          <br>•	 Collecte ou don de fonds en toute simplicité;
                          <br>•	 Toute opération de débit ou de crédit passée sur le compte “SmartPay” du Client ou avec votre portefeuille externe (Mobile Money, cartes VISA ou Mastercard);
                          <br><strong>•	 Utilisateur :</strong> désigne tout Client ayant souscrit au service “SmartPay”.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                      <h5 class="collapsed" data-toggle="collapse" data-target="#faq-2" aria-expanded="false">
                        Article 2 – Engagement de "DEV4SMART"
                      </h5>
                      <div id="faq-2" class="collapse" data-parent="#accordion">
                        <div class="acr-body">
                          <p class="text-justify"><strong>“DEV4SMART”</strong>  s’engage à ce que le service “SmartPay” soit conforme aux lois et règlements applicables
                            à l’activité de paiement en ligne au Niger. A ce titre, “SmartPay” se réserve le droit de faire évoluer le service “SmartPay” et les présentes
                            conditions Générales d’Utilisation à tout moment, sans notification préalable.
                            <br>2.1. <strong>“SmartPay”</strong> met en place un dispositif de réception et de traitement des réclamations
                            des Clients par appel téléphonique, WhatsApp et l'email. “SmartPay" s’engage à traiter les réclamations du Client dans un délai minimal de vingt quatre (24) heures et maximal de soixante douze (72) heures,
                            selon la nature de la réclamation présentée.
                            <br>2.2. Dans les limites des présentes conditions générales d’utilisation et sous réserve de l’identification du client, “SmartPay” s’engage à rétablir le Compte de Monnaie électronique du Client dans un délai de soixante douze (72) heures;
                            <br>2.3. “SmartPay” met en place un dispositif permettant de garantir la traçabilité des réclamations reçues et traitées;
                            <br>2.4. Toutes les transactions effectuées par le client donnent lieu à la production d’un reçu électronique précisant notamment :
                            <br>• le numéro de référence de la transaction ;
                            <br>• la nature du service ;
                            <br>• le numéro d’immatriculation du distributeur ou du sous-distributeur, le cas échéant ;
                            <br>• l’heure, le montant et les frais de la transaction :
                            <br>• l’identité de l’expéditeur ou du récepteur de la transaction lorsqu’applicable.
                            <br>2.5. “SmartPay” s’engage expressément à loger dans un compte séquestre, ouvert auprès de ses Banques Partenaires, tous les paiements et
                            autres sommes reçus relativement aux transactions relatives à “SmartPay”.
                            <br>2.6. Afin d’optimiser le service “SmartPay”, “DEV4SMART” s’engage notamment à mettre en œuvre tous les moyens raisonnables pour
                            assurer le bon fonctionnement du service ainsi que l’exécution diligente des transferts et des transactions initiés par le Client.
                            <br>2.7. “SmartPay” n’est, en aucun cas et d’aucune façon, tenue responsable des pertes consécutives à la mauvaise manipulation de son
                            téléphone par le Client, au dysfonctionnement du téléphone, au mauvais fonctionnement du service ou à un retard du réseau mobile par
                            suite d’événements dont la société n’a pas la maîtrise, tels que le transport des données, la défaillance dans le fonctionnement des
                            matériels ou des réseaux de télécommunication, les interruptions de service par suite de cas fortuits ou de force majeure (guerre,
                            incendie, dégâts des eaux, soulèvement populaires…). Cette liste est simplement indicative et non limitative.
                            <br>2.8. De même, ne lui sont pas imputés les dommages directs ou indirects, survenus en conséquence d’un acte frauduleux ou sortant du code
                            de conduite défini par “SmartPay” d’un agent de distribution, d’un Distributeur ou sous distributeur causant un dommage au Client.
                            <br>2.9. Pour respecter les contraintes juridiques, d’audit et la réglementation en matière de lutte contre le blanchiment et le
                            financement du terrorisme, les informations reçues sur les Clients peuvent être transmises sur demande aux autorités compétentes.
                          </p>
                        </div>
                      </div>
                    </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-3" aria-expanded="false">
                      Article 3 – Conditions d’accès au service “SmartPay”
                    </h5>
                    <div id="faq-3" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">3.1 – Le compte “SmartPay” du client est activé pour effectuer des opérations d'achat ou de paiement à partir d'un portefeuille externe (Mobile Money, cartes VISA ou Mastercard, Transfert d'argent, etc.);
                          <br>3.2 – Pour avoir accès au Service, le client devra :
                          <br>•	 Être nécessairement une personne domiciliée dans le pays dans lequel cet accord s’applique ;
                          <br>•	 Remplir le formulaire de souscription électronique après téléchargement de l’application  web ou mobile;
                          <br>•	 Être équipé d’un terminal mobile et d’une carte SIM compatibles avec la technologie utilisée par “SmartPay”;
                          <br>•	 Être titulaire d’un numéro de téléphone connecté à la plateforme “SmartPay” ne faisant l’objet d’aucune
                          restriction (légale ou réglementaire);
                          <br>3.3 – “SmartPay” se réserve toutefois le droit de refuser l’ouverture d’un compte à tout client, sans avoir à s’en justifier.
                          <br>3.4 – À tout moment, “SmartPay” peut suspendre l’accès du client au service “SmartPay” au cas où il
                          existerait des faits laissant présumer l’utilisation frauduleuse ou la tentative d’utilisation frauduleuse de ses
                          services. Le client en est informé par tout moyen.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-4" aria-expanded="false">
                      Article 4 – Tarification
                    </h5>
                    <div id="faq-4" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">4.1 – Le service <strong>“SmartPay”</strong> ne comprend aucun frais de gestion ou d’abonnement.
                          <br>4.2 – Les frais applicables aux transactions sont disponibles sur l’application “SmartPay” et sont automatiquement prélevés sur le compte du client lors de la transaction
                          conformément aux <strong>conditions tarifaires</strong> en vigueur à ce moment.
                          <br>Les conditions tarifaires sont affichées au niveau du point de service ou sur le site internet de la société
                          et peuvent faire l’objet de mise à jour à tout moment, sans information préalable directe du client.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-5" aria-expanded="false">
                      Article 5 – Mot de passe relevant du compte “SmartPay”
                    </h5>
                    <div id="faq-5" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p>5.1 – Un mot de passe est choisi par chaque nouveau client lorsqu’il se connecte
                          pour la première fois à l’application du service “SmartPay”. Le mot de passe du client, qui est effectif
                          instantanément, lui permet d’accéder à l’interface de son application à tout moment;
                          <br>Il lui sera demandé ce meme mot de passe à chaque fois que sa session s'expire;
                          <br>5.2 – Le client peut à tout moment changer son mot de passe en allant dans l’application “SmartPay” et choisir l'option "Mot de passe oublié ?";
                          Il devra suivre les indications mentionnées dans le menu pour pouvoir procéder au changement.
                          <br>5.3 – Dès lors que le Client est connecté, il peut effectuer toute transaction avec son compte;
                          <br>5.4 – Pour tout problème relatif au mot de passe (réinitialisation, blocage, déblocage, etc.),
                          le client doit appeler le service client de “SmartPay” au +227 90 11 22 12 ou adresser un mail à l'adresse smartpay@dev4smart.net.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-6" aria-expanded="false">
                      Article 6 – Cas de perte ou de vol du téléphone ou de la carte sim associée au compte “SmartPay”
                    </h5>
                    <div id="faq-6" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">6.1 – En cas de perte du téléphone ou de la carte sim associée à son compte “SmartPay”, le Client s’engage
                          à informer instantanément “SmartPay” par email via smartpay@dev4smart.net.ou en appelant le service client +227 90 11 22 12, suivi d’une
                          notification écrite de demande de blocage de son compte “SmartPay” pour motif de vol + les pièces justificatives
                          qu’il est le propriétaire de la carte SIM (copie abonnement ou tout document équivalent).
                          <br>6.2 – Le client peut également appeler le service client de “SmartPay” pour orientations sur la procédure en vigueur.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-7" aria-expanded="false">
                      Article 7 – Responsabilité du client
                    </h5>
                    <div id="faq-7" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">7.1 – Le client est responsable de la bonne utilisation de son compte utilisateur
                          “SmartPay”. Il doit prendre toutes mesures nécessaires pour conserver et préserver le dispositif de
                          sécurité personnalisé (mot de passe) attaché à son compte et, en particulier, à ne pas le communiquer à des tiers.
                          Il est seul responsable de l’utilisation faite de son mot de passe, quel que soit l’utilisateur, et des
                          conséquences qui en résultent.
                          <br>7.2 – Sauf preuve contraire apportée par le client, toute connexion ou opération effectuée à partir de son mot de passe sera réputée avoir été effectuée par lui.
                          <br>7.3 – Aucune responsabilité ne pourra être imputée à “SmartPay” du fait de l’utilisation frauduleuse de son mot de passe par un tiers avant son opposition.
                          <br>7.4 – Le client s’engage à ce que les informations personnelles et de sécurité qu’il renseigne soient complètes,
                          réelles, exactes et à jour, et qu’elles n’aient ni pour objet ni pour effet de porter à confusion avec un tiers,
                          d’induire en erreur sur son identité, ou de porter atteinte à “SmartPay”, ou à des droits de tiers .
                          Il s’engage à les mettre systématiquement à jour pour tout changement y relatif .</p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-8" aria-expanded="false">
                      Article 8 – Achat de credit téléphonique ou paiement en ligne
                    </h5>
                    <div id="faq-8" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">8.1 – L’ordre d'achat de credit téléphonique ou paiement en ligne via un compte “SmartPay” est définitif et irrévocable.
                          <br>8.2 – Les transactions d'achat de credit téléphonique ou paiement en ligne peuvent être refusées pour plusieurs raisons :
                          <br>•	 Si le solde du client dans son portefeuille externe est inférieur au montant de la transaction qu'il souhaite effectuer ;
                          <br>•	 Si le montant de l'opération est supérieur aux plafonds autorisés ;
                          <br>•	 Si le plafond de disponibilité journalier est dépassé et ou la personne atteint le nombre de transaction journalier ;
                          <br>•	 Si le téléphone du client perd la connexion au réseau pendant la transaction ;
                          <br>•	 Si la session de l’utilisateur s'expire avant d'emettre les informations;
                          <br>•	Etc….
                          <br>8.3 – Les opérations peuvent être suspendues pendant un délai pour cause de maintenance des serveurs.
                          Le moment de cette brève interruption ne peut être déterminé à l’avance. Dans ce cas, le client aura la possibilité
                          de ré-effectuer la transaction qui n’aura pas abouti lors de la reprise du serveur.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-9" aria-expanded="false">
                      Article 9 – Colllecte ou don de fonds depuis “SmartPay”
                    </h5>
                    <div id="faq-9" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">Les clients ou partenaires peuvent effectuer des dons ou collectes de fonds depuis leur compte “SmartPay”.
                          <br>“SmartPay” ne peut être tenue responsable des difficultés rencontrées lors d'une opération de collecte ou de dons de fonds.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-10" aria-expanded="false">
                      Article 10 – Délai de conservation des documents & délai de réclamation
                    </h5>
                    <div id="faq-10" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">10.1 – En cas de réclamation du client, “SmartPay” s’engage à apporter les meilleurs soins aux
                          informations qu’ils seront amenés à fournir quant aux conditions d’exécution de l’opération contestée.
                          <br>10.2 – Tout document ou reproduction détenu par “SmartPay” relatif aux opérations visées
                          dans les présentes conditions générales seront conservées conformément à la réglementation en
                          vigueur.
                          <br>10.3 – Aucune réclamation du client n’est recevable trente (30) jours calendaires après la date de l’opération
                          que le client aurait contestée.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-11" aria-expanded="false">
                      Article 11 – Protection des données à caractère personnel
                    </h5>
                    <div id="faq-11" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">11.1 – La signature du présent vaut autorisation donnée à SmartPay agissant dans le cadre
                          du service de collecter et traiter, et au besoin transmettre ses données à caractère personnel Conformément à la
                          loi sur les données à caractère personnel en vigueur au Niger. Les données recueillies peuvent être : le Nom et Prénom, le numéro de téléphone, l'adresse email, les numéros RCCM et NIF des partenaires, etc. Ces données seront conservées pour une durée minimale de dix (10) ans.
                          <br>11.2 – Tout Client peut également, pour des motifs légitimes, s’opposer au traitement des Données personnelles le concernant.
                          <br>11.3 - SmartPay se réserve le droit d’utiliser ces données dans le cadre d’opérations de marketing direct, pour
                          communiquer, par SMS, par téléphone ou par courriel, ses offres commerciales sur ses produits et services.
                          <br>11.4 Le client disposera par ailleurs d’un droit d’opposition, d’accès, de rectification, de modification et de
                          suppression concernant les données qui le concernent. S’il souhaite exercer ce droit et obtenir communication des
                          informations le concernant, il peut s’adresser à SmartPay au +227 90 11 22 12 ou adresser un mail à l'adresse smartpay@dev4smart.net.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-12" aria-expanded="false">
                      Article 12 – Résiliation du compte “SmartPay”
                    </h5>
                    <div id="faq-12" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">12.1 – À tout moment, le Client peut informer le Service client de "SmartPay"
                          de sa volonté de clôturer son compte “SmartPay” par courrier physique ou électonique au smartpay@dev4smart.net. Le service Client “SmartPay”
                          procédera à la fermeture du compte “SmartPay” dans un délai de quarante-huit (48) heures après réception
                          du courrier et vérification de conformité.
                          <br>12.2 – L’acceptation d’une demande de résiliation de compte “SmartPay” est conditionnée par l’authentification
                          du demandeur, la validité du compte “SmartPay”.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-13" aria-expanded="false">
                      Article 13 – Comptes inactifs
                    </h5>
                    <div id="faq-13" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">13.1 – Tout compte client resté inactif pendant un délai de six (6) mois, “SmartPay” peut suspendre le compte.
                          <br>13.2 – En cas de décès, le compte ne peut être reactivé que par les ayant-droit du Client.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-14" aria-expanded="false">
                      Article 14 – Suspension et Clôture du compte « SmartPay »
                    </h5>
                    <div id="faq-14" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">14.1 – “SmartPay” se réserve le droit de suspendre temporairement l’accès
                          au compte pour des raisons techniques, de sécurité ou
                          de maintenance après en avoir avisé le client et sans que ces opérations
                          n’ouvrent droit à une quelconque indemnité. Il s’engage à limiter ce type
                          d’interruptions au strict nécessaire.
                          <br>14.2 – Le Compte du Client est clôturé par “SmartPay” dans les cas
                          suivants sans encourt d’une responsabilité quelconque ou justification :
                          <br>• Sur instructions des autorités judiciaires, policières et/ou de la BCEAO ;
                          <br>• Si “SmartPay” ou ses agents ont connaissance ou soupçonnent que le
                          Compte est utilisé à des fins frauduleuses ou illégales ;
                          <br>• Si “SmartPay” estime que le Client ne respecte pas les présentes
                          Conditions Générales d’Utilisation ou utilise de façon inappropriée le service au
                          risque de compromettre l’intégrité du système ;
                          <br>• Si un organisme national ou international dûment habilité diligente une
                          enquête ou lorsqu’une procédure pénale est engagée à l’encontre du Client ou
                          pour toute autre raison qui, de l’avis de SmartPay jette le trouble sur la
                          réputation commerciale de celle-ci ;
                          <br>• Si le Client notifie de la perte de son téléphone, suivant réception par
                          SmartPay d’une demande de clôture de compte spécifiant les raisons de
                          cette clôture et accompagné des documents produits lors de l’enregistrement ;
                          <br>• Si le Client notifie à “SmartPay” ou qu’il a révélé son code PIN à toute
                          autre personne, suivant réception par “SmartPay” d’une demande de
                          clôture de compte spécifiant les raisons de cette clôture et accompagné des
                          documents produits lors de l’enregistrement.
                          <br>14.3 – La clôture du compte à l’initiative de l’une ou l’autre partie entraîne la
                          résiliation sans formalité ni délai du présent contrat.
                          <br>14.4 – Périodes de validité du compte :
                          Un compte « SmartPay» reste valide tant que sa
                          dernière transaction a été effectuée dans un délai inférieur à 6 mois.
                          <br>• 14.5 Au-delà de 6 mois sans transaction sur le compte « SmartPay » du
                          client, SmartPay se réserve le droit de le clôturer, après en avoir
                          notifié le Client
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-15" aria-expanded="false">
                      Article 15 – Cas de force majeure
                    </h5>
                    <div id="faq-15" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">15.1 – En cas de force majeure tel que défini par la loi et les jurisprudences, ni les opérateurs de téléphonie, ni “SmartPay” ne pourront être tenus responsable pour un
                          retard ou une défaillance quelconque dans l’exécution de leurs prestations. Par événement de force majeure, il faut entendre tout fait imprévisible, insurmontable ou échappant au contrôle des parties et dont la survenance
                          empêche l’exécution normale de la présente convention comme par exemple : une décision gouvernementale, une guerre, des émeutes, un sabotage, un incendie, une inondation, un cyclone, une épidémie, une quarantaine, une grève, un lock-out, etc.
                          <br>15.2 – L’événement de force majeure aura pour effet de suspendre l’exécution
                          de l’obligation devenue impossible.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-16" aria-expanded="false">
                      Article 16 – Preuves des opérations effectuées
                    </h5>
                    <div id="faq-16" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">16.1 – Le client reconnaît la validité des documents et justificatifs physiques
                          et/ou électroniques produits par “SmartPay” lors de ses opérations ou transactions.
                          <br>16.2 – Toutes les opérations effectuées dans le cadre des présentes conditions
                          générales auront pour preuve les supports informatiques échangés de façon
                          journalière entre les partenaires et “SmartPay”
                          Les enregistrements des transactions et leur reproduction sur support
                          informatique constituent pour les partenaires, “SmartPay” et le client, un
                          mode valable et recevable de preuve de la réalisation de l’opération et la
                          justification de son imputation comptable, ce qui doit être accepté par le client
                          sans aucune réserve.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-17" aria-expanded="false">
                      Article 17 – Modifications
                    </h5>
                    <div id="faq-17" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">Les partenaires et “SmartPay” se réservent le droit d’apporter des modifications aux conditions générales de leurs services, lesquelles seront portées à la connaissance du client, soit par SMS, soit par voie d’affichage dans
                          le réseau de distribution de chacune des Parties dans un délai d’un (01) mois avant la date de leur entrée en vigueur.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-18" aria-expanded="false">
                      Article 18 – Blanchiment de capitaux
                    </h5>
                    <div id="faq-18" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">18.1 – SmartPay est soumis à l’ensemble de la réglementation relative à la lutte contre le blanchiment de capitaux et le financement du terrorisme et de la prolifération en vigueur au Niger.
                          <br>18.2 – En application des lois et règlements en vigueur en matière de lutte contre le blanchiment de capitaux et le financement du terrorisme et de la prolifération, “SmartPay” est tenue de s’informer auprès de tout Client
                          pour toute opération ou relation d’affaires de l’origine, de l’objet et de la destination de l’opération ou de l’ouverture du Compte. Il doit, par ailleurs,
                          réaliser toutes les diligences nécessaires à l’identification du client et le cas échéant, du Bénéficiaire effectif du Compte et/ou des Opérations de paiement liées à celui-ci.
                          <br>18.3 – Le Client reconnaît que “SmartPay” peut mettre un terme ou reporter à tout moment l’usage d’un identifiant, l’accès à un Compte ou l’exécution d’une opération ou d’un Remboursement en l’absence d’élément
                          suffisant sur son objet ou sa nature. Il est informé qu’une opération réalisée dans le cadre des présentes peut faire l’objet de l’exercice du droit à la
                          communication aux autorités compétentes du Niger.
                          <br>18.4 – Le Client peut, conformément à la réglementation, accéder à toutes les informations ainsi communiquées sous réserve que ce droit d’accès ne remette pas en cause la finalité de lutte contre le blanchiment des capitaux et le
                          financement du terrorisme et de la prolifération lorsque ces données sont relatives au demandeur.
                          <br>18.5 – Aucune poursuite et aucune action en responsabilité civile ne peut être
                          intentée ni aucune sanction professionnelle prononcée contre
                          “SmartPay”, ses dirigeants ou ses préposés qui ont fait de bonne foi les déclarations de soupçon auprès aux autorités compétentes du Niger .
                          <br>18.6 – Le client est informé que conformément aux dispositions légales et règlementaires en vigueur relatives au blanchiment
                           des capitaux et au financement du terrorisme, la Banque et/ou “SmartPay” pour des raisons d’identification, peuvent exiger la présentation de tout document probant.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-19" aria-expanded="false">
                      Article 19 – Sécurité
                    </h5>
                    <div id="faq-19" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">19.1. "DEV4SMART" s’engage à assurer la sécurité et la confidentialité des
                          données des Client et Partenaires, conformément à la réglementation en vigueur sur la protection des données personnelles.
                          <br>19.2 – “SmartPay” et ses Partenaires ne sont toutefois pas tenus
                          responsables à l’égard du Client des éventuelles erreurs, omissions,
                          interruptions ou retards des opérations réalisées résultant d’un accès non
                          autorisé au service “SmartPay” .
                          <br>19.3 – “DEV4SMART” n’est pas tenu responsable des vols, destructions ou
                          communications non autorisées de données résultant d’un accès non autorisé au
                          service “SmartPay”.
                          <br>19.4 – “SmartPay” demeure étrangère au lien de droit existant entre le
                          Client et le Bénéficiaire de l’Opération de Paiement. La société n’est pas tenue
                          responsable des fautes, manquements ou négligence du Client ou du
                          Bénéficiaire l’un envers l’autre.
                          <br>19.5 – “SmartPay” est responsable de la sécurité et de la confidentialité
                          des données qu’il échange avec le client dans le cadre des présentes au titre de
                          la création et de la gestion de son Compte, ainsi que des Opérations de
                          paiement associées au Compte.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-20" aria-expanded="false">
                      Article 20 – Clause de sauvegarde
                    </h5>
                    <div id="faq-20" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">20.1 – L’invalidité ou la nullité de l’une des clauses des présentes conditions générales, pour quelque motif que ce soit,
                          ne saurait en aucun cas affecter la validité et le respect des autres clauses des présentes conditions générales.
                          <br>20.2 – Le fait que l’une des parties n’ait pas exigé l’application d’une clause quelconque des présentes conditions générales, que ce soit de façon permanente ou temporaire, ne pourra en aucun cas être considéré comme une renonciation aux droits de cette partie découlant de ladite clause.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-21" aria-expanded="false">
                      Article 21 – Droit applicable – Attribution de juridiction
                    </h5>
                    <div id="faq-21" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">Les relations entre “SmartPay” et ses Clients sont régies par les lois en
                          vigueur au Niger en général.<br>
                          En cas de différend, les parties conviennent de recourir à un règlement amiable. A défaut d’accord amiable dans un délai de trente (30) jours à compter de la
                          survenance du différend, la partie la plus diligente peut saisir les juridictions compétentes du Niger.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h5 class="collapsed" data-toggle="collapse" data-target="#faq-22" aria-expanded="false">
                      Article 22 – Langue utilisée
                    </h5>
                    <div id="faq-22" class="collapse" data-parent="#accordion">
                      <div class="acr-body">
                        <p class="text-justify">La langue utilisée durant la relation tant précontractuelle que contractuelle est le
                          français.<br>
                          La souscription du contrat se fait en français. Toute traduction faite dans une autre langue ne l’est qu’à titre informatif.
                        </p>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Faq section end -->
    <!-- Question section start -->
    <!-- Question section end -->
    <app-page-footer></app-page-footer>
</div>
