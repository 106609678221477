<div class="home-one">
    <app-page-header></app-page-header>
    <!-- Banner section start -->
    <section class="banner v1 features">
        <div class="container mb-5">
          <div class="row">
            <div class="col-md-12 my-auto">
              <div class="ban-content">
                
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Banner section end -->
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-4">
                <img src="assets/images/access-denied.jpg" alt="">
                <h2 class="mt-3 mb-5">Accès non autorisé</h2>
            </div>
        </div>
        
    </div>
    <!-- speciality section end -->
    <app-page-footer></app-page-footer>
  </div>
