<!-- Document Wrapper  -->
<div id="main-wrapper">
    <app-user-header></app-user-header>

    <app-user-profilebar></app-user-profilebar>


    <!-- Admin Content Section  -->
    <div id="content" class="py-4">
        <div class="container">
            <div class="row">
                  <div class="col-lg-3">
                  <app-user-left-menu></app-user-left-menu>
                  </div>
                  <!-- Middle Panel  -->
                  <div class="col-lg-9">
                      <div class="profile-content">
                          <h3 class="admin-heading bg-dark text-white">
                              <p>Activité récente </p>
                              <span class="text-white">Transactions récentes</span>
                          </h3>

                          <!-- Admin Heading Title  -->
                          <div class="transaction-title bg-offwhite">
                              <div class="items">
                                  <div class="row">
                                      <div class="col"><span class="">Date</span></div>
                                      <div class="col">Description</div>
                                      <div class="col text-center">Status</div>
                                      <div class="col text-left">ID</div>
                                      <div class="col">Montant</div>
                                  </div>
                              </div>
                          </div>
                          <!-- Admin Heading Title End -->

                          <!-- Transaction List -->
                          <div class="transaction-area">

                                  <div class="items" *ngFor="let transaction of transactions
                                    | paginate
                                      : {
                                          itemsPerPage: tableSize,
                                          currentPage: page,
                                          totalItems: count
                                        };
                                    let i = index
                                  ">
                                      <a routerLink="/transaction/details">
                                          <div class="row">
                                                <div class="col pay-date">
                                                    <span class="date">{{ transaction.createdAt | date:'d':'fr'}},</span>
                                                    <span class="pay-month">{{ transaction.createdAt | date:'LLL':'fr'}} {{ transaction.createdAt | date:'HH:mm:ss':'fr'}}</span></div>
                                                <div class="col">
                                                  <span class="name">Recharge {{ transaction.paymentGateway }}</span>
                                                </div>
                                                <div class="col text-center">
                                                    <span *ngIf="transaction.status=='Successful'" class="payments-status text-success" ><i data-toggle="tooltip" data-original-title="Completed" class="fas fa-check-circle"></i></span>
                                                    <span *ngIf="transaction.status=='Failed'" class="payments-status text-danger" ><i data-toggle="tooltip" data-original-title="Cancelled" class="fas fa-times-circle"></i></span>
                                                    <span *ngIf="transaction.status=='Processing'" class="payments-status text-primary" ><i class="fas fa-ellipsis-h" data-toggle="tooltip" data-original-title="In Progress"></i></span>
                                                </div>
                                                <div class="col">
                                                    <span class="payment-amaount">{{ transaction.transactionId }}</span>
                                                </div>

                                                <div class="col">
                                                    <span class="payment-amaount">{{ transaction.amount }}</span>
                                                    <span class="currency">(XOF)</span>
                                                </div>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <!-- Transaction List End -->

                          <!-- Transaction Details Modal  -->
                          <div id="transaction-detail" class="modal fade" role="dialog" aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered details-area" role="document">
                                  <div class="modal-content">
                                      <div class="modal-body">
                                          <div class="modal_header">
                                              <span class="status bg-success">Paid</span>
                                              <div class="row">
                                                  <div class="col">
                                                      <div class="content-area">
                                                          <i class="fas fa-building"></i>
                                                          Desco Govt Water Ltd.
                                                      </div>
                                                  </div>
                                                  <div class="col">
                                                      <div class="content-area text-right">
                                                          <div class="price">
                                                              $559
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                          </div>
                                          <div class="modal-content-area">
                                              <h5 class="transcation-heading">Transaction Details</h5>
                                              <div class="transaction-area">
                                                  <div class="items">
                                                      <div class="row">
                                                          <div class="col">
                                                              <span class="name">Gas Bill Paid By:</span>
                                                          </div>
                                                          <div class="col">
                                                              <span class="decs">{{ user.firstname }} {{ user.lastname }}</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="items">
                                                      <div class="row">
                                                          <div class="col">
                                                              <span class="name">Transaction ID:</span>
                                                          </div>
                                                          <div class="col">
                                                              <span class="decs">8974HSDB32056KSDS</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="items">
                                                      <div class="row">
                                                          <div class="col">
                                                              <span class="name">Transaction ID:</span>
                                                          </div>
                                                          <div class="col">
                                                              <span class="decs">8974HSDB32056KSDS</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="items">
                                                      <div class="row">
                                                          <div class="col">
                                                              <span class="name">Description:</span>
                                                          </div>
                                                          <div class="col">
                                                              <span class="decs">This bill pay via Credit Card</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <!-- Transaction Modal End -->
                          <pagination-controls
                              previousLabel="Prev"
                              nextLabel="Next"
                              (pageChange)="onTableDataChange($event)"
                          ></pagination-controls>
                      </div>
                      <!-- Recent Activity End -->
                  </div>
                  <!-- Middle Panel End -->
            </div>
        </div>
    </div>
    <!-- Content end -->

    <app-page-footer></app-page-footer>
  </div>
  <!-- Document Wrapper end -->
