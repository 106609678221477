<!-- Header start -->
    <div class="header-top1">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-sm-8">
                    <div class="d-inline-flex ml-auto">
                        <a href="#" class="top-text"><i class="fas fa-phone-alt"></i> +227 90232825 | +227 98984250</a>
                        <a href="#" class="top-text"><i class="fas fa-envelope"></i> contact@smartpay.com</a>
                    </div>
                </div>
                <div class="col-sm-4 text-sm-right">
                      <div class="social-icons">
                          <a href="#"><i class="fab fa-facebook-f"></i></a>
                          <a href="#"><i class="fab fa-twitter"></i></a>
                      </div>
                      <div class="header-language dropdown" style="z-index:1000;position:relative;">
                        <a href="dropdown-toggle" class="langbtn d-flex" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="assets/images/flags/france.png" alt="" class="mr-1" style="width:16px;height: 16px;">
                            <span style="margin-top:-5px;">FR</span>
                            <i class="fas fa-angle-down"></i>
                        </a>
                        <ul class="list-unstyled dropdown-menu">
                            <li class="active "><a href="#" class="d-flex"><img src="assets/images/flags/france.png" alt="" class="mr-1" style="width:16px;height: 16px;"> <span style="margin-top:-5px;">FR</span></a></li>
                            <li ><a href="#" class="d-flex"><img src="assets/images/flags/english.png" alt="" class="mr-1" style="width:16px;height: 16px;"> <span style="margin-top:-5px;">EN</span></a></li>
                        </ul>
                      </div>
                </div>
            </div>
        </div>
    </div>

    <!--header -->
    <header class="header02">
        <div class="container d-flex align-items-center">
            <a class="logo d-inline-flex" href="/">
                <img src="assets/images/logo.png" style="width: 60px; height:60px;" alt="">
            </a>
            <nav class="primary-menu ml-auto">
                <a id="mobile-menu-toggler" href="#"><i class="fas fa-bars"></i></a>
                <ul>
                      <li><a class="text-white login btn-round btn-outline btn nav-b" href="/">Accueil</a></li>
                      <li><a class="text-white login btn-round btn-outline btn nav-b" routerLink="/page-ecommerce">E-commerce</a></li>
                      <li><a class="text-white login btn-round btn-outline btn nav-b" routerLink="/shop">Social Shop</a></li>
                      <li><a class="text-white login btn-round btn-outline btn nav-b" routerLink="/page-restau-list">Restauration</a></li>
                      <li class="has-menu-child pro-menu-drop">
                          <a class="text-white" routerLink="#">
                              <div class="header-pro-thumb">
                                  <img class="rounded-circle" src="assets/admin/images/profile.png" alt="">
                              </div> {{ user.firstname }} {{ user.lastname }} <i class="fas fa-chevron-down"></i>
                          </a>
                          <ul class="dropdown-menu-md sub-menu profile-drop">
                              <li class="dropdown-header bg-dark">
                                  <div>
                                      <!-- <h6 class="hidden-xs m-b-0 text-primary text-ellipsis">{{ user.firstname }} {{ user.lastname }}</h6> -->
                                      <div class="small text-white"><span>{{ user.username }}</span></div>
                                  </div>
                              </li>
                              <!-- <li>
                                  <hr class="mx-n3 mt-0">
                              </li> -->
                              <li class="nav__create-new-profile-link">
                                  <a routerLink="/profile" routerLinkActive="active-menu">
                                      <span>Profile</span>
                                  </a>
                              </li>
                              <li class="nav__create-new-profile-link">
                                  <a routerLink="/dashboard" routerLinkActive="active-menu">
                                      <span>Tableau de bord</span>
                                  </a>
                              </li>
                              <li class="divider"></li>
                              <li class="nav__dropdown-menu-items">
                                  <a routerLink="profile-notifications.html">
                                      <i class="icon icon-setting"></i>
                                      <span>Parametres</span>
                                  </a>
                              </li>
                              <li class="nav__dropdown-menu-items">
                                  <a (click)="Logout()" href="#"><i class="icon icon-logout"></i><span>Deconnexion</span></a></li>
                          </ul>
                      </li>
                </ul>
            </nav>
        </div>
    </header>
    <!--end main header-->
