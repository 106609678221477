import { Component, OnInit } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-page-restau-list',
  templateUrl: './page-restau-list.component.html',
  styleUrls: ['./page-restau-list.component.scss']
})
export class PageRestauListComponent implements OnInit {

  constructor() {}

  initialization() {
    // ============================
    // Button Animation
    // ============================
    $(".btn").on("mouseenter mousemove",  (e: { pageX: number; pageY: number; }) => {
      var parentOffset = $(this).offset(),
        relX = e.pageX - parentOffset.left,
        relY = e.pageY - parentOffset.top;
      $(this).find(".bh").css({top: relY, left: relX});
      var origin = relX + "px" + " " + relY + "px";
    }).on("mouseout",  (e: { pageX: number; pageY: number; }) => {
      var parentOffset = $(this).offset(),
        relX = e.pageX - parentOffset.left,
        relY = e.pageY - parentOffset.top;
      $(this).find(".bh").css({top: relY, left: relX});
    });
  }

  ngOnInit() {
    this.initialization();
  }

}
