<div class="features">
    <div class="container">
      <div class="row">
        <div class="col-md-12 m-auto">
          <div class="row mx-0" id="container">
            <div class="col-md-7 h-100">
              
              <a href="/accueil" class=""><img src="assets/images/logo1.png" class="my-4 ml-3" style="width:60px;"alt=""></a>
              <div *ngIf="this.wrongCredentials==true" class="alert alert-danger" role="alert">
                {{ errorMessage }}
              </div>
              <form [formGroup]="forgotPasswordForm" (ngSubmit)="onFormSubmit()" novalidate class="sl-form mx-auto mt-5">
                <ng-otp-input  id="Code" class="text-center"  (onInputChange)="onOtpChange($any($event))" required  [config]="{length:6, allowNumbersOnly:true}"></ng-otp-input>
                <div class="text-danger p-0" *ngIf="isWrongOtp">
                  {{ errorMessage }}
                </div>
                <div class="mt-5">
                  <button type="submit" class="w-100 btn btn-sp color-dark">
                    <span class="spinner-border spinner-border-sm" id="spinner" role="status" aria-hidden="true"></span>
                    <span class="ml-2" id="text">Envoyer</span>
                  </button>
                </div>
                <button type="button" class="mt-2 w-100 btn btn-sp color-primary" ><a routerLink="/login" class="text-white">Se Connecter</a></button>
                
              </form>
            </div>
            <div class="col-md-5 overlay-container p-0">
              <div class="overlay p-2 pt-5">
                  <h5 class="text-white ml-3">Confirmer le code OTP</h5>
                  <img src="assets/images/enter-otp.png" alt="" class="mb-5" style="width: 250px;">
                  <span style="font-size:13px;z-index: 100000; position: relative;" class="mt-5">Vous n’avez pas de compte ? <br><a routerLink="/inscription/account-type" class="text-dark">Créer un compte</a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
