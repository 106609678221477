<div id="main-wrapper">
    <app-user-header></app-user-header>
    
    <app-user-profilebar></app-user-profilebar>
    
  
    <!-- Admin Content Section  -->
    <div id="content" class="py-4">
        <div class="container">
            <div class="row">
                <div class="col-lg-3" style="width:20%;">
                <app-user-left-menu></app-user-left-menu>
                </div>
                <!-- Middle Panel  -->
                <div class="col-lg-9 profile-area">
                    <div class="profile-content">
                        <h3 class="admin-heading">Liste des coupons de credit
                            <a href="#" data-toggle="modal" data-target="#step" class="btn-link pbtn float-right"><i class="fa fa-plus-circle mr-1"></i>Ajouter un coupon</a>
                        </h3>
                        
                        <div class="tab-content px-0" id="pills-tabContent">
                            <div class="tab-pane fade show active px-0" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <div id="send-money" class="form bg-offwhite pb-5 px-0">
                                    <select name="operateur" class="custom-select mb-3" (change)="onSelectChange($event)" style="width:150px; height: 40px;">
                                        <option selected="selected" value="93000000">Niger Télécom</option>
                                        <option value="96000000">Airtel</option>
                                        <option value="90000000">Zamani</option>
                                        <option value="94000000">Moov</option>
                                    </select>
                                    <div class="row payment">
                                        <div class="col-md-3 col-sm-6" [(ngModel)]="coupons" *ngFor="let coupon of coupons" >
                                            <div class="single-payment cp_selected" style="height: 200px !important;"  id="{{ coupon.cfa }}">
                                                <img src="{{'../../../assets/admin/images/operator/' + coupon.operator + '.png'}}" class="mx-auto" style="position: relative;width: calc(100% - 60px);top: 0;transform: translateY(0%);" alt="">
                                                <hr>
                                                <div class="">
                                                    <p>{{ coupon.cfa }}</p>
                                                    <p>XOF</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                      
                                    <ul class="pager mt-4">
                                        <li>&nbsp;</li>
                                        <li>
                                            <a href="withdraw-money-balance.html" class="btn btn-default mr-0">
                                                <span class="bh"></span>
                                                <span>Next</span>
                                                <i class="fas fa-chevron-right"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- Middle Panel End -->
            </div>
        </div>
    </div>
    <!-- Content end -->
    <!-- Modal -->
    <div id="modal" class="modal fade step-secourity" id="step" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered " role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        <p>Ajouter un coupon</p>
                    </h5>

                    <button type="button" class="close" data-dismiss="modal" style="position: relative !important;" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>                                                    
                </div>
                <div class="modal-body">
                      <form [formGroup]="couponForm">
                            <div class="input-group">
                                <select formControlName="operator" #operator name="operator" class="custom-select " style="width:150px; height: 40px;" placeholder="Séléctionner l'opérateur">
                                    <option value="null">Séléctionner l'opérateur...</option>
                                    <option selected="selected" value="NT">Niger Télécom</option>
                                    <option value="AN">Airtel</option>
                                    <option value="ZT">Zamani</option>
                                    <option value="MA">Moov</option>
                                </select>
                            </div>
                            <div style="font-size:10px;" class="p-0 mb-3" *ngIf="couponForm.controls['operator'].invalid && (couponForm.controls['operator'].dirty || couponForm.controls['operator'].touched)">
                                <div class="text-danger p-0" *ngIf="couponForm.controls['operator'].hasError('required')">
                                    Séléctionner un opérateur svp!
                                </div>
                            </div>
                            <div class="input-group mt-3">
                                <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">XOF</span>
                                </div>
                                <input type="number" formControlName="cfa" #cfa class="form-control" placeholder="Montant du coupon" aria-label="cfa" aria-describedby="basic-addon1">
                            </div>
                          <!-- ============================================================ -->
                          <div style="font-size:10px;" class="p-0" *ngIf="couponForm.controls['cfa'].invalid && (couponForm.controls['cfa'].dirty || couponForm.controls['cfa'].touched) || this.isWrongCoupon">
                              <div class="text-danger p-0" *ngIf="couponForm.controls['cfa'].hasError('required')">
                                  Le montant est requis
                              </div>
                              <div class="text-danger p-0" *ngIf="this.isWrongCoupon">
                                  Veuillez entrez un coupon valide qui n'existe pas svp
                              </div>
                          </div>
                      </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sp color-dark btn-modal-close" data-dismiss="modal">Fermer</button>
                    <button type="submit" (click)="addCoupon(operator.value, cfa.value)" [disabled]="couponForm.invalid" class="btn btn-sp color-primary"><i class="fa fa-save"></i> Enregistrer</button>
                </div>
            </div>
        </div>
    </div>
    <app-page-footer></app-page-footer>
</div>
  <!-- Document Wrapper end -->
