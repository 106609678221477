<div class="blog-page">
    <app-page-header-shop></app-page-header-shop>
    <!-- Banner section start -->
    <section class="banner features pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8 m-auto">
            <div class="ban-content text-center">
              <!-- <select class="form-control form-control-lg" id="Categorie">
                <option value="Électronique">Électronique</option>
                <option value="Mode">Mode</option>
                <option value="Maison et jardin">Maison et jardin</option>
                <option value="Sports et loisirs">Sports et loisirs</option>
                <option value="Beauté et soins personnels">Beauté et soins personnels</option>
                <option value="Livres, films et musique">Livres, films et musique</option>
                <option value="Automobiles et moto">Automobiles et moto</option>
                <option value="Jouets et jeux">Jouets et jeux</option>
                <option value="Alimentation et boissons">Alimentation et boissons</option>
                <option value="Équipement informatique">Équipement informatique</option>
                <option value="Art et artisanat">Art et artisanat</option>
                <option value="Santé et bien-être">Santé et bien-être</option>
                <option value="Équipement de voyage">Équipement de voyage</option>
              </select> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner section end -->

    <!-- Blog single post section start -->

    <section class="blog-single-post">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <form action="">
              <div class="row mb-5">
                <div class="col-md-4">
                  <div class="input-group input-group-lg">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"><i class="fa fa-tags"></i></label>
                    </div>
                    <select class="custom-select form-control form-control-lg" id="Categorie">
                      <option value="Électronique">Électronique</option>
                      <option value="Mode">Mode</option>
                      <option value="Maison et jardin">Maison et jardin</option>
                      <option value="Sports et loisirs">Sports et loisirs</option>
                      <option value="Beauté et soins personnels">Beauté et soins personnels</option>
                      <option value="Livres, films et musique">Livres, films et musique</option>
                      <option value="Automobiles et moto">Automobiles et moto</option>
                      <option value="Jouets et jeux">Jouets et jeux</option>
                      <option value="Alimentation et boissons">Alimentation et boissons</option>
                      <option value="Équipement informatique">Équipement informatique</option>
                      <option value="Art et artisanat">Art et artisanat</option>
                      <option value="Santé et bien-être">Santé et bien-être</option>
                      <option value="Équipement de voyage">Équipement de voyage</option>
                    </select>
                  </div>

                </div>
                <div class="col-md-4">
                  <div class="input-group input-group-lg">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"><i class="fa fa-store"></i></label>
                    </div>
                    <select class="custom-select form-control form-control-lg" id="Categorie">
                      <option value="Électronique">Électronique</option>
                      <option value="Mode">Mode</option>
                      <option value="Maison et jardin">Maison et jardin</option>
                      <option value="Sports et loisirs">Sports et loisirs</option>
                      <option value="Beauté et soins personnels">Beauté et soins personnels</option>
                      <option value="Livres, films et musique">Livres, films et musique</option>
                      <option value="Automobiles et moto">Automobiles et moto</option>
                      <option value="Jouets et jeux">Jouets et jeux</option>
                      <option value="Alimentation et boissons">Alimentation et boissons</option>
                      <option value="Équipement informatique">Équipement informatique</option>
                      <option value="Art et artisanat">Art et artisanat</option>
                      <option value="Santé et bien-être">Santé et bien-être</option>
                      <option value="Équipement de voyage">Équipement de voyage</option>
                    </select>
                  </div>

                </div>
                <div class="input-group col-md-4">
                  <input type="text" class="form-control form-control-lg" placeholder="search" aria-label="search" aria-describedby="basic-addon1">
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                  </div>
                </div>
              </div>
            </form>
            <article class="post-item">
              <!-- Testimonial section start -->
              <!-- <div class="testimonial ">
                <div class="container">
                  <div class="test-caro owl-carousel">
                    <div class="single-testimonial">
                      <div class="">
                        <img src="assets/images/blog/1.jpg" alt="">
                      </div>
                    </div>
                    <div class="single-testimonial">
                      <div class="">
                        <img src="assets/images/blog/1.jpg" alt="">
                      </div>
                    </div>
                    <div class="single-testimonial">
                      <div class="">
                        <img src="assets/images/blog/1.jpg" alt="">
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- Testimonial section end -->
              <!-- <figure class="post-thumb">
                <img src="assets/images/blog/1.jpg" alt="">
              </figure> -->
              <div class="row" *ngFor="let categorie of categories; let i = index">
                <div class="col-12" *ngIf="categorie.items.length!=0">
                  <img src="assets/images/blog/1.jpg" alt="">
                  <div class="mt-5">
                    <a class="btn btn-primary mb-3 mr-1" href="#carouselExampleIndicators{{i}}" role="button" data-slide="prev">
                        <i class="fa fa-arrow-left"></i>
                    </a>
                    <a class="btn btn-primary mb-3 " href="#carouselExampleIndicators{{i}}" role="button" data-slide="next">
                        <i class="fa fa-arrow-right"></i>
                    </a>
                    <h5 class="float-right">{{ categorie.name }}</h5>
                  </div>
                  <div id="carouselExampleIndicators{{i}}" class="carousel slide mb-5" data-ride="carousel">
                    <div class="carousel-inner">
                      <div class="carousel-item" *ngFor="let item of categorie.items; let indice = index" [class.active]="indice == 0">
                        <div class="row">
                          <div class="col-md-3 mb-3" *ngFor="let produit of item">
                            <div class="card">
                              <img *ngIf="produit.images.length>0" style="height: 255px;min-height: 255px;max-height: 255px;position: relative;" ngSrc="merchant-store/v1/logo/{{ produit.images[0].id }}" fill priority alt="Second slide"  crossorigin="anonymous" class="card-img-top">
                              <img *ngIf="produit.images.length==0" src="assets/images/blog/t1.jpg" class="card-img-top">

                              <div class="card-body">
                                <h5 class="card-title text">{{ produit.name }}</h5>
                                <p class="card-text text">{{ produit.description }}.</p>
                                <span class="mr-5"><i class="fa fa-money-bill-alt text-primary"></i> {{ produit.price }} XOF</span>
                                <span style="cursor: pointer;" (click)="addProductToShoppingCart(produit)" class="float-right"><i class="fa fa-cart-arrow-down"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
    <!-- Blog single post section end -->
    <app-page-footer></app-page-footer>
  </div>

