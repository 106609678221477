<!-- Document Wrapper  -->
<div id="main-wrapper">
    <app-user-header></app-user-header>

    <app-user-profilebar></app-user-profilebar>


    <!-- Admin Content Section  -->
    <div id="content" class="py-4">
        <div class="container">
            <div class="row">
              <div class="col-lg-3" style="width:20%;">
                <app-user-left-menu></app-user-left-menu>
              </div>
             <!-- Middle Panel  -->
                  <div class="col-lg-9">
                      <div class="profile-content">
                          <h3 class="admin-heading"><p>Transfert</p></h3>
                          <ul class="nav nav-pills">
                            <li class="nav-item">
                                <a class="nav-link active">Transfert</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link">Confirmation</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link">Statut</a>
                            </li>
                          </ul>
                          <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                  <form [formGroup]="transferForm" class="sl-form">
                                      <div class="d-flex justify-content-center align-items-center">
                                          <div class="form-group">
                                              <div class="text-center mb-4">
                                                  <h3 class="text-5 msg-header">Envoyer de l'argent a un compte SMARTPAY</h3>
                                                  <!-- <p class="text-4 text-center">Le transfert de crédit mobile international est un moyen simple d’offrir du crédit et de maintenir le contact avec vos proches résidant à l'étranger.</p> -->
                                              </div>
                                          </div>
                                      </div>
                                      <h5>Montant</h5>
                                      <div class="input-group mb-4">
                                          <div class="input-group-prepend btn-sp-prepend">
                                            <strong class="input-group-text px-4 text-white" style="background-color: transparent !important;">
                                              XOF
                                            </strong>
                                          </div>
                                          <input type="number" min="50" step="5" id="amount" formControlName="amount" #amount class="form-control form-control-lg" value="50">
                                      </div>
                                      <h5>Compte SMARTPAY</h5>
                                      <div class="input-group">
                                          <div class="input-group-prepend btn-sp-prepend">
                                            <strong class="input-group-text px-4 text-white" style="background-color: transparent !important;">
                                              SPW-
                                            </strong>
                                          </div>
                                          <input type="text" id="account_number" formControlName="account_number" #account_number value="{{ account_number }}" class="form-control form-control-lg" showMaskTyped="true" mask="0000-0000-000000">
                                      </div>
                                      <div class="p-0" *ngIf="transferForm.controls['account_number'].invalid && (transferForm.controls['account_number'].dirty || transferForm.controls['account_number'].touched)">
                                        <div class="text-danger p-0" *ngIf="transferForm.controls['account_number'].hasError('required')">
                                          Le numéro du compte est requis
                                        </div>
                                        <div class="text-danger p-0" *ngIf="transferForm.controls['account_number'].hasError('minlength') || transferForm.controls['account_number'].hasError('maxlength')">
                                          Veuillez entrez un numéro valide svp
                                        </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-md-6">
                                              <p class="text-left"><b>Frais de transaction </b></p>
                                          </div>
                                          <div class="col-md-6">
                                              <div class="text-right">
                                                  {{ pf }} FCFA
                                              </div>
                                          </div>
                                      </div>
                                      <div class="row">
                                          <div class="col-md-6">
                                              <p class="text-left"><b>Vous enverrez</b></p>
                                          </div>
                                          <div class="col-md-6">
                                              <span class="float-right">{{ amount.value }} CFA</span>
                                          </div>
                                      </div>
                                      <ul class="pager mt-4">
                                          <li>&nbsp;</li>
                                          <li>
                                              <button type="submit" class="btn btn-sp color-primary mr-0" (click)="onFormSubmit(account_number.value,amount.value)" [disabled]="transferForm.invalid">
                                                Continuer <i class="fas fa-chevron-right"></i>
                                              </button>
                                          </li>
                                      </ul>
                                  </form>
                            </div>

                        </div>
                      </div>

                  </div>
                  <!-- Middle Panel End -->
            </div>
        </div>
    </div>
    <!-- Content end -->

    <app-page-footer></app-page-footer>
</div>
  <!-- Document Wrapper end -->
