<div class="home-two">
  <div class="p-0 m-0">
    <app-page-header></app-page-header>
    <!-- Banner section start -->
    <section class="banner features">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-12">
            <img src="assets/images/step.png" alt=""  class="ml-auto">
          </div>
          <div class="col-lg-9 col-md- col-12 my-auto">
            <div class="ban-content ">
              <h3 class="text-white">Ouvrez votre compte gratuitement</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner section end -->
  </div>
  <div class="overview mt-5">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <ul class="steps">
            <li><span class="bg-primary text-white">1</span></li>
            <li><span>2</span></li>
            <li><span>3</span></li>
            <li><span>4</span></li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-md-9 card m-auto p-5">
          <form name="form" (ngSubmit)="f.form.valid && onFormSubmit()" #f="ngForm" novalidate class="sl-form">
            <div>
              <h6>Choisissez le type de compte:</h6>
              <div class="toggle">
                <input type="radio" name="typeAccount" checked="checked" value="particulier" (change)="onItemChange($event)" id="particulier" />
                <label for="particulier"><i class="fa fa-user mr-2"></i>Individuel</label>
                <input type="radio" name="typeAccount" value="professionnel" id="professionnel" (change)="onItemChange($event)" />
                <label for="professionnel"><i class="fas fa-briefcase mr-2"></i>Professionnel</label>
              </div>
              <p class="status text-justify" id="p_particulier">
                Un compte individuel ou client vous permet d'effectuer des opérations d'achat ou faire de don de fonds à partir de votre portefeuille Mobile Money, vos cartes bancaires VISA ou Mastercard.
              </p>
              <p class="status text-justify" style="display: none;" id="p_professionnel">
                Un compte professionnel ou entreprise vous permet de recharger vos proches en crédit de communication, d'accepter des paiements ou de distribuer de fonds via le Mobile Money, par cartes bancaires VISA ou Mastercard de façon centralisée.
              </p>
              <div class="d-flex mt-5">
                <p class="notice">Vous avez déjà un compte? <a routerLink="/login" class="text-primary">Se connecter</a></p>
                <button class="btn-sp color-primary float-right ml-auto mr-0"><span class="bh"></span> <span>Continuer</span></button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>

  <app-page-footer></app-page-footer>
</div>



