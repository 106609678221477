<div class="landing-ecommerce">
    <app-page-header></app-page-header>
    <!-- Banner section start -->
    <section class="banner features pb-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 col-md-12 m-auto">
            <div class="ban-content text-center">
              <h3 class="text-white" style="text-transform:none;">Vendez à travers une boutique en ligne</h3>
              <h6 class="text-white" style="text-transform:none;">Configurez votre boutique en ligne, générez le lien, partagez sur vos réseaux sociaux et encaissez vos payements.</h6>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner section end -->

    <div class="bg-light">
      <div class="container ">
        <div class="row">
          <div class="col-md-4">
            <img src="assets/images/boutique.png" alt="" class="">
          </div>
          <div class="col-md-8 my-auto">
              <span class="tagline bar">A propos du e-commerce</span>
              <p class="text-justify">
                Détenteur d'une boutique ou d'un magasin commercial? Optez pour la monétisation de votre business en souscrivant à notre offre boutique en ligne et acceptez les paiements via SMARTPAY.
              </p>
              <a href="/inscription/account-type" class="btn btn-filled btn-round"><span class="bh"></span> <span>Ouvrir un compte</span></a>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Landing overview section start -->
    <div class="landing-overview py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-10 m-auto">
            <div class="sec-heading">
              <h3 class="">Pourquoi utiliser notre service e-commerce ?</h3>
            </div>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-lg-3 col-sm-6">
            <div class="iconBox boxed-shadow text-center">
              <!-- <img src="assets/images/icons/1.png" alt="" class="img-icon"> -->
  
              <!-- Roysha icon -->
              <span class="roysha-icon roysha-api"><span class="path1"></span><span class="path2"></span><span
                  class="path3"></span><span class="path4"></span></span>
  
              <h5><a href="javascript:void(0)">Créer votre boutique</a></h5>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="iconBox boxed-shadow text-center">
              <!-- <img src="assets/images/icons/2.png" alt="" class="img-icon"> -->
  
              <!-- Roysha icon -->
              <span class="roysha-icon roysha-online-pay"><span class="path1"></span><span class="path2"></span><span
                  class="path3"></span><span class="path4"></span><span class="path5"></span><span
                  class="path6"></span><span class="path7"></span><span class="path8"></span><span
                  class="path9"></span><span class="path10"></span><span class="path11"></span><span
                  class="path12"></span><span class="path13"></span><span class="path14"></span></span>
  
              <h5><a href="javascript:void(0)">Gestion commandes</a></h5>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="iconBox boxed-shadow text-center">
              <!-- <img src="assets/images/icons/3.png" alt="" class="img-icon"> -->
  
              <!-- Roysha icon -->
              <span class="roysha-icon roysha-low-cost"><span class="path1"></span><span class="path2"></span><span
                  class="path3"></span><span class="path4"></span><span class="path5"></span><span
                  class="path6"></span><span class="path7"></span><span class="path8"></span><span
                  class="path9"></span><span class="path10"></span><span class="path11"></span><span
                  class="path12"></span><span class="path13"></span><span class="path14"></span><span
                  class="path15"></span><span class="path16"></span><span class="path17"></span><span
                  class="path18"></span><span class="path19"></span><span class="path20"></span><span
                  class="path21"></span><span class="path22"></span><span class="path23"></span><span
                  class="path24"></span><span class="path25"></span><span class="path26"></span><span
                  class="path27"></span><span class="path28"></span><span class="path29"></span><span
                  class="path30"></span></span>
  
              <h5><a href="javascript:void(0)">Collecte paiements</a></h5>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="iconBox boxed-shadow text-center">
              <!-- <img src="assets/images/icons/3.png" alt="" class="img-icon"> -->
  
              <!-- Roysha icon -->
              <span class="roysha-icon roysha-hand-cash"><span class="path1"></span><span class="path2"></span><span
                  class="path3"></span><span class="path4"></span><span class="path5"></span><span
                  class="path6"></span><span class="path7"></span><span class="path8"></span><span
                  class="path9"></span><span class="path10"></span></span>
  
              <h5><a href="javascript:void(0)">Retrait instantané</a></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Landing overview section end -->
  
    <!-- Partners section start -->
    <div class="partners bg-light py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-10 m-auto">
            <div class="sec-heading">
              <span class="tagline">Liste des partenaires qui utilisent notre service de paiement en ligne </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="partners-logo owl-carousel">
              <a href="javascript:void(0)"><img src="assets/images/partners/1.png" alt=""></a>
              <a href="javascript:void(0)"><img src="assets/images/partners/2.png" alt=""></a>
              <a href="javascript:void(0)"><img src="assets/images/partners/3.png" alt=""></a>
              <a href="javascript:void(0)"><img src="assets/images/partners/4.png" alt=""></a>
              <a href="javascript:void(0)"><img src="assets/images/partners/5.png" alt=""></a>
              <a href="javascript:void(0)"><img src="assets/images/partners/6.png" alt=""></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Partners section end -->

    <!-- Receive works start -->
  <section class="receive-works bg-offwhite">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-10 m-auto">
          <div class="sec-heading">
            <span class="tagline">Fonctionnement</span>
            <h2 class="sec-title">Comment créer votre boutique en ligne</h2>
            <p class="sec-subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ul class="nav nav-tabs cmn-tab-nav">
            <li>
              <a class="active" data-toggle="tab" href="#get-payment">Ouvrir un compte
                <span class="nav-arrow"></span>
              </a>
            </li>
            <li>
              <a data-toggle="tab" href="#find-location">Se connecter
                <span class="nav-arrow"></span>
              </a>
            </li>
            <li>
              <a data-toggle="tab" href="#insert-code">Configurer
                <span class="nav-arrow"></span>
              </a>
            </li>
            <li>
              <a data-toggle="tab" href="#cash-pickup">Partager</a>
            </li>
          </ul>

          <div class="tab-content">
            <div id="get-payment" class="tab-pane fade in active show">
              <div class="row align-items-center">
                <div class="col-md-6">
                  <figure class="imgBox">
                    <img src="assets/images/step.png" alt="">
                  </figure>
                </div>
                <div class="col-md-6">
                  <div class="content-box">
                    <h2>Vos Informations</h2>
                    <p></p>
                    <ul class="steps vertical">
                      <li>
                        <span>1</span>
                        Cliquez sur le bouton "Ouvrir un compte"
                        <p>Passez sur "compte professionnel". </p>
                      </li>
                      <li>
                        <span>2</span>
                        Renseignez votre email
                        <p>Saisir votre email valide pour recevoir le code OTP.</p>
                      </li>
                      <li>
                        <span>3</span>
                        Saisissez le code
                        <p>Saisir le code OTP que vous avez reçu sur votre adresse email et valider.</p>
                      </li>
                      <li>
                        <span>4</span>
                        Complétez vos informations
                        <p>Complétez vos informations de connexion, les informations de votre boutique et valider.</p>
                      </li>
                    </ul>
                    <a href="/inscription/account-type" class="btn btn-filled btn-round"><span class="bh"></span> <span>Ouvrir un compte</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div id="find-location" class="tab-pane fade">
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="content-box">
                    <h2>Connexion au dashboard</h2>
                    <p>Connectez-vous à votre dashboard en renseignant vos donnez de connexion pour finaliser la configuration de votre boutique.</p>
                    <p>Vous pouvez y accéder en téléchargeant l'application mobile.</p>
                    <span class="d-flex">
                      <a href="javascript:void(0)" class="img-fluid mb-2"><img src="assets/images/google2.png" alt="" style="width:200px;"></a>
                      <a href="javascript:void(0)" class="img-fluid mb-2 ml-2"><img src="assets/images/apple2.png" alt="" style="width:200px;"></a>
                      </span>
                      <p>Ou directement sur la plateforme Web en vous connectant.</p>
                    <a href="/login" class="btn btn-filled btn-round"><span class="bh"></span> 
                      <span>
                        Connexion
                      </span></a>
                  </div>
                </div>
                <div class="col-md-6">
                  <figure class="imgBox">
                    <img src="assets/images/login.png" alt="">
                  </figure>
                </div>
              </div>
            </div>
            <div id="insert-code" class="tab-pane fade">
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="content-box">
                    <h2>Finalisez la configuration</h2>
                    <p>Mettez à jour les informations relatives à votre boutique.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <figure class="imgBox">
                    <img src="assets/images/computer.png" alt="">
                  </figure>
                </div>
              </div>
            </div>
            <div id="cash-pickup" class="tab-pane fade">
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="content-box">
                    <h2>Lien personnalisé</h2>
                    <p>Générez un lien unique et personnalisé à partager sur Facebook, Whatsapp, Twitter, Instagram ou à envoyer par mail</p>
                    <ul class="steps vertical">
                      <li>
                        <span>1</span>
                        Find a Nearest Location or Wallet
                        <p>Lorem ipsum qitame coctetr asipm scing elised eiusm tempor incidid untdolore consistal.</p>
                      </li>
                      <li>
                        <span>2</span>
                        Confirm Your Identity or Access Wallet
                        <p>Lorem ipsum qitame coctetr asipm scing elised eiusm tempor incidid untdolore consistal.</p>
                      </li>
                      <li>
                        <span>3</span>
                        Confirm Your Access and Request Payment
                        <p>Lorem ipsum qitame coctetr asipm scing elised eiusm tempor incidid untdolore consistal.</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6">
                  <figure class="imgBox">
                    <img src="assets/images/rs.png" alt="">
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- System works end -->
  
    <!-- Fun facts section start -->
    <!-- <div class="py-5 features " style="position: relative;">
      <div class="container">
        
  
        <div class="row">
          <div class="col-12 align-items-center">
            <div class="sec-heading">
              <h3 class="text-white">Tarification des paiements</h3>
            </div>
            <div class="text-center my-4">
              <a href="javascript:void(0)" class="btn btn-filled btn-round"><span class="bh"></span> <span>petite entreprise</span></a>
              <a href="javascript:void(0)" class="btn btn-outline btn-round"><span class="bh"></span> <span>grande entreprise</span></a>
            </div>
          </div>
          <div class="col-md-3">
            <span class="text-white text-center">0.5% Par facture</span>
            
          </div>
          <div class="col-md-3 text-center">
            <span class="text-white">1.25% Retrait instantané</span>
            
          </div>
          <div class="col-md-3 text-center">
            <span class="text-white ">2.0% Frais de transfert</span>
            
          </div>
          <div class="col-md-3 text-center">
            <span class="text-white">0.0% Remboursement en espèces</span>
            
          </div>
        </div>
      </div>
    </div> -->
    <!-- Fun facts section end -->

    <!-- Fun facts section start -->
    <!-- <section class=" funfacts">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-10 m-auto text-center">
            <div class="sec-heading">
              <h2 class="sec-title">Plan de commissionnement</h2>
              <p class="sec-subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
            </div>

            <ul class="fun-group">
              <li>
                <span>0.5%</span>
                Par facture
              </li>
              <li>
                <span>1.25%</span>
                Retrait instantané
              </li>
              <li>
                <span>2.0%</span>
                Frais de transfert
              </li>
              <li>
                <span>0.0%</span>
                Remboursement
              </li>
            </ul> <br><br>
            <a href="/inscription/account-type" class="btn btn-outline btn-round"><span class="bh"></span> <span>Ouvrir un compte</span></a>
          </div>
        </div>
      </div>
    </section> -->
    <!-- Fun facts section end -->
  
    <!-- Testimonial section start -->
    <section class="testimonial bg-light">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-10 m-auto">
            <div class="sec-heading">
              <h2 class="sec-title">Témoignages de nos partenaires</h2>
              <p class="sec-subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
            </div>
          </div>
        </div>
        <div class="test-caro owl-carousel">
          <div class="single-testimonial">
            <div class="tst-body">
              Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod pempor incididunt ut labore et dolore
              magna aliqua. Quis ipsum suspen risse ultrices gravida.
            </div>
            <div class="tst-head">
              <img src="assets/images/avatar.png" class="avatar" alt="">
              <h6>Jhon Doe</h6>
              <span>Business analist</span>
            </div>
          </div>
          <div class="single-testimonial">
            <div class="tst-body">
              Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod pempor incididunt ut labore et dolore
              magna aliqua. Quis ipsum suspen risse ultrices gravida.
            </div>
            <div class="tst-head">
              <img src="assets/images/avatar.png" class="avatar" alt="">
              <h6>Jhon Doe</h6>
              <span>Business analist</span>
            </div>
          </div>
          <div class="single-testimonial">
            <div class="tst-body">
              Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod pempor incididunt ut labore et dolore
              magna aliqua. Quis ipsum suspen risse ultrices gravida.
            </div>
            <div class="tst-head">
              <img src="assets/images/avatar.png" class="avatar" alt="">
              <h6>Jhon Doe</h6>
              <span>Business analist</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Testimonial section end -->
    <app-page-footer></app-page-footer>
  </div>
  
