<div class="faq-page">
  <app-page-header></app-page-header>
  <section class="banner v1 features">
    <div class="container mb-5">
      <div class="row">
        <div class="col-md-8 my-auto">
          <div class="ban-content">
            <h2 class="text-white text-justify" style="text-transform: none;">Foire aux questions</h2>
          </div>
        </div>
        <div class="col-md-4">
          <img src="assets/images/infrastructure.png" alt="" class="">
        </div>
      </div>
    </div>
  </section>

  <!-- Faq section start -->
  <section class="faq-tab">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-10 m-auto text-center">
          <div class="sec-heading">
            <h2 class="sec-title">Foire aux questions</h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-10 col-md-12 m-auto">
          <ul class="row nav nav-tabs">
            <li class="col-md-3">
              <a class="active" data-toggle="tab" href="#general-qs">
                <!-- <img src="assets/images/icons/9.png" alt=""> -->

                <!-- Roysha icon -->
                <span class="roysha-icon roysha-ask">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                  <span class="path5"></span>
                </span>
                Questions générales
              </a>
            </li>
            <li class="col-md-3">
              <a data-toggle="tab" href="#recharges">
                <!-- <img src="assets/images/icons/12.png" alt=""> -->

                <!-- Roysha icon -->
                <span class="roysha-icon roysha-bank-diposite">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                  <span class="path5"></span>
                  <span class="path6"></span>
                  <span class="path7"></span>
                  <span class="path8"></span>
                  <span class="path9"></span>
                  <span class="path10"></span>
                  <span class="path11"></span>
                  <span class="path12"></span>
                  <span class="path13"></span>
                  <span class="path14"></span>
                  <span class="path15"></span>
                  <span class="path16"></span>
                </span>
                Recharge ShapShap
              </a>
            </li>
            <li class="col-md-3">
              <a data-toggle="tab" href="#payment">
                <!-- <img src="assets/images/icons/11.png" alt=""> -->

                <!-- Roysha icon -->
                <span class="roysha-icon roysha-fast-transfer">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                  <span class="path5"></span>
                  <span class="path6"></span>
                  <span class="path7"></span>
                  <span class="path8"></span>
                  <span class="path9"></span>
                  <span class="path10"></span>
                  <span class="path11"></span>
                  <span class="path12"></span>
                  <span class="path13"></span>
                  <span class="path14"></span>
                  <span class="path15"></span>
                  <span class="path16"></span>
                  <span class="path17"></span>
                  <span class="path18"></span>
                </span>
                Paiement en ligne
              </a>
            </li>
            <li class="col-md-3">
              <a data-toggle="tab" href="#collecte">
                <!-- <img src="assets/images/icons/10.png" alt=""> -->

                <!-- Roysha icon -->
                <span class="roysha-icon roysha-hand-cash"><span class="path1"></span><span class="path2"></span><span
                  class="path3"></span><span class="path4"></span><span class="path5"></span><span
                  class="path6"></span><span class="path7"></span><span class="path8"></span><span
                  class="path9"></span><span class="path10"></span></span>
                Collecte de fonds
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div id="general-qs" class="tab-pane fade in active show">
              <div class="accordion" id="accordion">

                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-1" aria-expanded="true">
                    Dois-je payer quelque frais pour avoir un compte SmartPay ?
                  </h5>
                  <div id="faq-1" class="collapse show" data-parent="#accordion">
                    <div class="acr-body">
                      <p>La création d’un compte SmartPay est <strong>totalement gratuite .</strong></p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-2" aria-expanded="true">
                    Quels sont les prérequis pour ouvrir un compte SmartPay?
                  </h5>
                  <div id="faq-2" class="collapse" data-parent="#accordion">
                    <div class="acr-body">
                      <p>Vous devez disposer d’un ordinateur pour le canal web ou d'un smartphone munit d’une connexion internet avec un numéro de téléphone ou une adresse email valide pour utiliser l'application mobile.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-3" aria-expanded="true">
                    Comment créer un compte SmartPay ?
                  </h5>
                  <div id="faq-3" class="collapse" data-parent="#accordion">
                    <div class="acr-body">
                      <p>L'application SmartPay dispose de deux 2 types de compte :<br><br>
                        - Un Compte Client (Individuel).<br>
                        - Un compte Entreprise (Professionnel).
                        Pour créer un compte SmartPay il faut :<br><br>
                        . Aller sur le site https://smartpay.ne puis cliquer sur "Ouvrir un compte" ou Télécharger et installer l’application sur votre téléphone (smartphone) depuis Playstore ou App Store et s'inscrire.
                        <br>. Renseigner les formulaires d’inscription en respectant les instructions.
                        <br>. Un code d’activation vous sera envoyé par SMS ou Email et vous devrez le fournir pour activer votre compte.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-4" aria-expanded="true">
                    Quelles sont les informations requises pour ouvrir un compte client SmartPay ?
                  </h5>
                  <div id="faq-4" class="collapse" data-parent="#accordion">
                    <div class="acr-body">
                      <p>Les instructions à suivre pour ouverture :<br><br>
                        <strong>1ère étape :</strong> Votre numéro mobile<br>
                        Avertissement :<br>
                        Le numéro de téléphone est obligatoire. En continuant, vous confirmez être le propriétaire
                        ou l’utilisateur principal de ce numéro de téléphone mobile. Vous acceptez de recevoir des
                        SMS automatisés pour confirmer votre numéro de téléphone. Les messages et les données
                        peuvent être facturés.
                        <br><br>
                        <strong>2ème étape :</strong> Confirmer votre numéro de téléphone
                        Un code va être envoyé sur votre numéro de téléphone si non veuille cliquer sur renvoyer le
                        code. Vous n’avez que 3 essais.
                        <strong>3ème étape :</strong> Configurer votre profil
                        Ces informations doivent être exactes :<br>
                        - Adresse email.<br>
                        - Prénom.<br>
                        - Nom.<br>
                        - Confirmez votre mot de passe (mot de passe correspondent).<br>
                        - Créez un mot de passe (8 caractères ou plus, Utiliser au mains 2 des caractères
                        suivants : lettres, chiffres et symboles).<br><br>
                        <strong>4ème étape :</strong> Ajouter votre adresse<br>
                        - Date de naissance<br>
                        - Ville/Municipalité/village<br>
                        - État/Province/Région<br>
                        - Numero de RCCM et NIF pour un compte professionnel<br>
                        <br>
                        <strong>5ème étape :</strong> Votre compte est ouvert !<br>
                        Vous pouvez utiliser votre nouveau compte pour acheter de crédit de communication,
                        effectuer de paiement et des achats en ligne en toute sécurité !
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-5" aria-expanded="true">
                    Quels sont les services disponibles sur SmartPay?
                  </h5>
                  <div id="faq-5" class="collapse" data-parent="#accordion">
                    <div class="acr-body">
                      <p><strong>SmartPay Wallet offre plusieurs types de services à ses clients notamment :</strong>
                        <br>- Achat de crédit téléphonique
                        <br>- Paiement des biens et services en ligne
                        <br>- Collecte ou don de fonds à travers la création d'une cagnotte
                        <br>- Paiement multicanal (Wallet Mobile Money ou cartes bancaires)</p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-6" aria-expanded="false">
                    Est-il possible de faire un paiement en ligne en utilisant mon compte SmartPay ?
                  </h5>
                  <div id="faq-6" class="collapse" data-parent="#accordion">
                    <div class="acr-body">
                      <p>Oui, vous pouvez effectuer un paiement en ligne à partir de votre application SmartPay avec
                        tout commerce en ligne agréé au réseau SmartPay.ne et à travers votre wallet Mobile Money et par cartes VISA ou mastercard. </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-7" aria-expanded="false">
                    Comment recharger votre numéro de téléphone ou celui d'un proche sur SmartPay?
                  </h5>
                  <div id="faq-7" class="collapse" data-parent="#accordion">
                    <div class="acr-body">
                      <p><strong>Pour envoyer le crédit téléphonique vous devez :</strong>
                        <br>– Avoir un compte et vous connecter à l’application SmartPay
                        <br>– Cliquer sur le bouton « Recharger» de la page d’accueil de l’application.
                        <br>– Renseigner le numéro de téléphone du bénéficiaire et le montant à envoyer en FCFA;
                        <br>– Sélectionner l’opérateur que vous vouliez utiliser;
                        <br>– Confirmer la transaction;
                        <br>– Télécharger le recu électronique.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-8" aria-expanded="false">
                    Qu’est-ce que ce qu’une cagnotte ?
                  </h5>
                  <div id="faq-8" class="collapse" data-parent="#accordion">
                    <div class="acr-body">
                      <p>Une cagnotte c’est le fait de lancer une campagne pour collecter de l’argent des personnes qui
                        souhaitent faire un don/sadkate djaria. </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-9" aria-expanded="false">
                    Les différents types des cagnottes ?
                  </h5>
                  <div id="faq-9" class="collapse" data-parent="#accordion">
                    <div class="acr-body">
                      <p> - Donner une nouvelle vie à une ancienne (Mosquée, Ecole publique / privée
                        ou Makaranta, etc...);
                        <br>- Aider une association des Horphelins;
                        <br>- Cas d’Incendie dans (une maison,marche,);
                        <br>- Cas inondation dans (une ville,quartie,maison,etc…);
                        <br>- Crise de famine;
                        <br>- Pandemie;
                        <br>- Etc…
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-10" aria-expanded="false">
                    Comment collecter de fonds depuis votre compte SmartPay?
                  </h5>
                  <div id="faq-10" class="collapse" data-parent="#accordion">
                    <div class="acr-body">
                      <p><strong>Pour collecter le fonds, vous devez :</strong>
                        <br>– Avoir un compte professionnel et vous connecter à l’application SmartPay;
                        <br>– Cliquer sur le bouton « Collecte de fonds» de la page d’accueil de l’application;
                        <br>– Créer votre campagne de collecte de fonds (cagnotte) et la publier sur le site SmartPay et vos portails réseaux sociaux ;
                        <br>
                        <strong>Les différents moyens de paiement acceptés :</strong>
                        <br>- Les opérateurs Mobile Money du Niger (Airtel Money, Flooz, ZCash).
                        <br>- Carte Bancaires (Visa, MasterCard, etc…).
                        <br>- Les services de transfert d’argent.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-11" aria-expanded="false">
                    L’application SmartPay est-elle sécurisée ?
                  </h5>
                  <div id="faq-11" class="collapse" data-parent="#accordion">
                    <div class="acr-body">
                      <p>Nous avons mis en place de nombreuses mesures de sécurité à toutes les étapes de
                        l’utilisation de la solution <strong>SmartPay.</strong></p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-12" aria-expanded="false">
                    Que se passe-t-il si je ne reçois pas de confirmation/notification après avoir initié une
                    transaction ?
                  </h5>
                  <div id="faq-12" class="collapse" data-parent="#accordion">
                    <div class="acr-body">
                      <p>Vous pouvez contacter notre Service client au <strong>(+227) 20 37 04 40 / 90 23 28 25</strong> ou par mail
                        sur <strong>smartpay@dev4smart.net</strong>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-13" aria-expanded="false">
                    Comment puis-je obtenir de l’aide ?
                  </h5>
                  <div id="faq-13" class="collapse" data-parent="#accordion">
                    <div class="acr-body">
                      <p>Vérifiez l’onglet « Historique » de votre application, et l’actualiser pour afficher les
                        transactions récentes.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div id="recharges" class="tab-pane fade in show">
              <div class="accordion" id="accordion">


                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-14" aria-expanded="false">
                    Comment recharger votre numéro de téléphone ou celui d'un proche sur SmartPay?
                  </h5>
                  <div id="faq-14" class="collapse show" data-parent="#accordion">
                    <div class="acr-body">
                      <p><strong>Pour envoyer le crédit téléphonique vous devez :</strong>
                        <br>– Avoir un compte client et vous connecter à l’application SmartPay;
                        <br>– Cliquer sur le bouton « Recharger» de la page d’accueil de l’application;
                        <br>– Renseigner le numéro de téléphone du bénéficiaire et le montant à envoyer en FCFA;
                        <br>– Sélectionner l’opérateur que vous vouliez utiliser;
                        <br>– Confirmer la transaction;
                        <br>– Télécharger le recu électronique.
                      </p>
                    </div>
                  </div>
                </div>


              </div>
            </div>

            <div id="payment" class="tab-pane fade in show">
              <div class="accordion" id="accordion">

                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-15" aria-expanded="false">
                    Est-il possible de faire un paiement en ligne en utilisant mon compte SmartPay ?
                  </h5>
                  <div id="faq-15" class="collapse show" data-parent="#accordion">
                    <div class="acr-body">
                      <p><strong> Oui, pour effectuer un paiement sur SmartPay, vous devez :</strong>
                        <br>– Avoir un compte client et vous connecter à l’application SmartPay;
                        <br>– Cliquer sur le bouton «Shopping» de la page d’accueil de l’application;
                        <br>– Sélectionner la boutique partenaire agréée au réseau SmartPay.ne;
                        <br>– Depuis la boutique en ligne du partenaire, sélectionner le produit que vous voulez acquerir;
                        <br>– Renseigner le moyen de paiement de votre choix et confirmer la transaction;
                        <br>– Télécharger le recu électronique.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div id="collecte" class="tab-pane fade in show">
              <div class="accordion" id="accordion">

                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-16" aria-expanded="false">
                    Qu’est-ce que ce qu’une cagnotte ?
                  </h5>
                  <div id="faq-16" class="collapse" data-parent="#accordion">
                    <div class="acr-body">
                      <p>Une cagnotte c’est le fait de lancer une campagne pour collecter de l’argent des personnes qui
                        souhaitent faire un don/sadkate djaria. </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-17" aria-expanded="false">
                    Les différents types des cagnottes ?
                  </h5>
                  <div id="faq-17" class="collapse" data-parent="#accordion">
                    <div class="acr-body">
                      <p> - Donner une nouvelle vie à une ancienne (Mosquée, Ecole publique / privée
                        ou Makaranta, etc...);
                        <br>- Aider une association des Horphelins;
                        <br>- Cas d’Incendie dans (une maison,marche,);
                        <br>- Cas inondation dans (une ville,quartie,maison,etc…);
                        <br>- Crise de famine;
                        <br>- Pandemie;
                        <br>- Etc…
                      </p>
                    </div>
                  </div>
                </div>


                <div class="accordion-item">
                  <h5 class="collapsed" data-toggle="collapse" data-target="#faq-18" aria-expanded="false">
                    Comment collecter de fonds depuis votre compte SmartPay?
                  </h5>
                  <div id="faq-18" class="collapse show" data-parent="#accordion">
                    <div class="acr-body">
                      <p><strong>Pour collecter le fonds, vous devez :</strong>
                        <br>– Avoir un compte professionnel et vous connecter à l’application SmartPay;
                        <br>– Cliquer sur le bouton « Collecte de fonds» de la page d’accueil de l’application;
                        <br>– Créer votre campagne de collecte de fonds (cagnotte) et la publier sur le site SmartPay et vos portails réseaux sociaux ;
                        <br>
                        <strong>Les différents moyens de paiement acceptés :</strong>
                        <br>- Les opérateurs Mobile Money du Niger (Airtel Money, Flooz, ZCash).
                        <br>- Carte Bancaires (Visa, MasterCard, etc…).
                        <br>- Les services de transfert d’argent.
                      </p>
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
  <app-page-footer></app-page-footer>
</div>
