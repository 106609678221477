<div class="features">
    <div class="container">
      <div class="row">
        <div class="col-md-12 m-auto">
          <div class="row mx-0" id="container">
            <div class="col-md-6 h-100">
              
              <a href="/accueil" class=""><img src="assets/images/logo1.png" class="my-4 ml-3" style="width:60px;"alt=""></a>
              <div *ngIf="this.wrongCredentials==true" class="alert alert-danger" role="alert">
                {{ errorMessage }}
              </div>
              <form [formGroup]="forgotPasswordForm" (ngSubmit)="onForgotPasswordFormSubmit()" novalidate class="sl-form mx-3 mt-2">
                <!-- <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-primary"><i class="fa fa-envelope text-white"></i></span>
                  </div>
                  <input type="email" formControlName="email" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"  placeholder="Email" aria-label="email" aria-describedby="basic-addon1" required>
                  <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                        <div *ngIf="f['email'].errors['required']">entrez une adresse email svp!!!</div>
                    </div>
                </div> -->
                <div class="col-md-12">
                    <div class="form-group mb-2">
                      <label for="password">Mot de passe</label>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-primary"><i class="fa fa-lock text-white"></i></span>
                        </div>
                        <input type="password" formControlName="password" id="password" class="form-control form-control-lg" placeholder="***********" required>
                      </div>
                      <div class="text-success" *ngIf="forgotPasswordForm.controls['password'].valid">
                        <i class="fa fa-check-circle mr-2"></i>
                        Valide
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group mb-2">
                      <label for="confirmPassword">Confirmer le mot de passe</label>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-primary"><i class="fa fa-lock text-white"></i></span>
                        </div>
                        <input type="password" id="confirmPassword" formControlName="confirmPassword" class="form-control form-control-lg" placeholder="***********" required>
                      </div>
                      <div class="text-danger text-justify" style="font-size: 10px!important;" *ngIf="forgotPasswordForm.controls['confirmPassword'].hasError('NoPassswordMatch') && (forgotPasswordForm.controls['confirmPassword'].dirty || forgotPasswordForm.controls['confirmPassword'].touched)">
                        Le mot de passe et le mot de passe de confirmation ne correspondent pas
                      </div>
                      <div class="p-0" style="font-size: 10px!important;" *ngIf="forgotPasswordForm.controls['password'].invalid && (forgotPasswordForm.controls['password'].dirty || forgotPasswordForm.controls['password'].touched)">
                        <div class="" [ngClass]="forgotPasswordForm.controls['password'].hasError('required') || forgotPasswordForm.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                          <i class="fa {{ forgotPasswordForm.controls['password'].hasError('required') ||
                          forgotPasswordForm.controls['password'].hasError('minlength') ? 'fa-times-circle' :
                          'fa-check-circle' }}">
                          </i>
                          Doit comporter au moins 8 caractères !
                        </div>
                        <div class="" [ngClass]="forgotPasswordForm.controls['password'].hasError('required') || forgotPasswordForm.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                          <i class="fa {{ forgotPasswordForm.controls['password'].hasError('required') ||
                          forgotPasswordForm.controls['password'].hasError('hasSmallCase') ? 'fa-times-circle' :
                          'fa-check-circle' }}">
                          </i>
                          Doit avoir au moins une lettre minuscle!
                        </div>
                        <div class="" [ngClass]="forgotPasswordForm.controls['password'].hasError('required') || forgotPasswordForm.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                          <i class="fa {{ forgotPasswordForm.controls['password'].hasError('required') ||
                          forgotPasswordForm.controls['password'].hasError('hasCapitalCase') ? 'fa-times-circle' :
                          'fa-check-circle' }}">
                          </i>
                          Doit avoir au moins une lettre majuscle!
                        </div>
                        <div class="" [ngClass]="forgotPasswordForm.controls['password'].hasError('required') || forgotPasswordForm.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                          <i class="fa {{ forgotPasswordForm.controls['password'].hasError('required') ||
                          forgotPasswordForm.controls['password'].hasError('hasNumber') ? 'fa-times-circle' :
                          'fa-check-circle' }}">
                          </i>
                          Doit avoir au moins un chiffre!
                        </div>
                        <div class="" [ngClass]="forgotPasswordForm.controls['password'].hasError('required') || forgotPasswordForm.controls['password'].hasError('hasSpecialCharacters')  ? 'text-danger' : 'text-success'">
                          <i class="fa {{ forgotPasswordForm.controls['password'].hasError('required') ||
                          forgotPasswordForm.controls['password'].hasError('hasSpecialCharacters') ? 'fa-times-circle' :
                          'fa-check-circle' }}">
                          </i>
                          Doit avoir au moins un caractère spécial!
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="mt-3">
                  <button type="submit" class="w-100 btn btn-sp color-dark">
                    <span class="spinner-border spinner-border-sm" id="spinner" role="status" aria-hidden="true"></span>
                    <span class="ml-2" id="text">Envoyer</span>
                  </button>
                </div>
                <!-- <button type="button" class="mt-2 w-100 btn btn-sp color-primary" ><a routerLink="/login" class="text-white">Se Connecter</a></button> -->
                
              </form>
            </div>
            <div class="col-md-6 overlay-container p-0">
              <div class="overlay p-5">
                  <h5 class="text-white">Modifier le mots de passe</h5>
                  <img src="assets/images/forgot-password-pana.png" alt="" class="mb-5" style="width: 300px;">
                  <span style="font-size:13px;z-index: 100000; position: relative;" class="mt-5">Vous n’avez pas de compte ? <a routerLink="/inscription/pre/identification" class="text-dark">Créer un compte</a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
