import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalService } from '../../../services/local.service';
import { AuthService } from 'src/app/services/auth.service';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { SweetAlertOptions } from 'sweetalert2';
declare let $: any;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const EMAIL_VERIFICATION_API="/user/v1/secure/send-guard-otp";
@Component({
  selector: 'app-page-signup-set-email',
  templateUrl: './page-signup-set-email.component.html',
  styleUrls: ['./page-signup-set-email.component.scss'],
})
export class PageSignupSetEmailComponent implements OnInit {
  [x: string]: any;
  isValidFormSubmitted = false;
  validateEmail = true;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  email = '';
  type!: string | null;
  errorMessage!: string;
  private data: any = [];
  signupForm!: FormGroup;
  swalOptions: SweetAlertOptions = { icon: 'info' };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private localStore: LocalService,
    private authService: AuthService,
    private swalService: FlashMessageService
  ) {}

  async onFormSubmit(username: string) {
    this.email = username;
    try {
      this.data = await this.authService.sendOTP(
        this.email,
        null,
        this.localStore.getData('send-otp-to-user')
      );
      if ('status' in this.data && 'comptor' in this.data) {
        if (
          this.data['status'] == true &&
          parseInt(this.data['comptor']) <= 3
        ) {
          this.validateEmail = true;
          this.localStore.removeData('user-email');
          this.localStore.saveData('user-email', this.email);
          this.router.navigateByUrl('/inscription/confirmation-otp');
        }
      } else {
        this.validateEmail = false;
        if ('message' in this.data) {
          this.errorMessage = this.data['message'];
        } else {
          this.errorMessage = 'une erreur est survenue';
        }
        this.swalOptions.icon = 'info';
        this.swalOptions.html = this.errorMessage;
        this.swalService.show(this.swalOptions);
      }
    } catch (error) {
      this.validateEmail = false;
      if ('message' in this.data) {
        this.errorMessage = this.data['message'];
      } else {
        this.errorMessage = 'une erreur est survenue';
      }
      this.swalOptions.icon = 'info';
      this.swalOptions.html = this.errorMessage;
      this.swalService.show(this.swalOptions);
    }
  }

  ngOnInit() {
    if (
      !this.localStore.getData('send-otp-to-user') || (
      this.localStore.getData('send-otp-to-user') != 'COBE' &&
      this.localStore.getData('send-otp-to-user') != 'CBE')
    ) {
      this.router.navigateByUrl('/inscription/identification');
    }
    this.signupForm = this.formBuilder.group(
      {
        username: [
          null,
          [Validators.required, Validators.pattern(this.emailPattern)],
        ],
      },
      {
        updateOn: 'change',
      }
    );
  }
}
