<div class="home-two">
    <div class="p-0 m-0">
      <app-page-header></app-page-header>
      <!-- Banner section start -->
      <section class="banner features">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-12">
              <img src="assets/images/step.png" alt="" style="" class="ml-auto">
            </div>
            <div class="col-lg-9 col-md- col-12 my-auto">
              <div class="ban-content ">
                <h3 class="text-white">Ouvrez votre compte gratuitement</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Banner section end -->
    </div>
    <div class="py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <ul class="steps">
              <li><span class="bg-primary text-white">1</span></li>
              <li><span class="bg-primary text-white">2</span></li>
              <li><span>3</span></li>
              <li><span>4</span></li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9 card m-auto p-5">
            <div class="sl-form-wrap">
              <h3 class="d-block">Validez votre numéro de téléphone</h3>
              <div class="content-box">
                <p class="mt-4 text-justify">
                  Entrez votre numéro pour recevoir le code OTP par SMS.
                </p>
              </div>
              <!-- <div class="face-snap-card" *ngIf="signupObservable$ | async as phoneModel">
                <h2>Phone: {{ phoneModel.phone | uppercase }}</h2>
                <h2>Code: {{ phoneModel.code | uppercase }}</h2>
              </div> -->
              <form [formGroup]="signupForm" class="sl-form">
                <div class="d-flex justify-content-center align-items-center">
                  <div class="form-group w-100">
                    <!-- <label for="phone">Numéro de téléphone</label> -->
                    <input type="tel" id="phone" formControlName="phone" value="" class="form-control" placeholder="numéro de téléphone">
                    <input type="hidden" id="code" name="code" #code value="ne">
                    <input type="hidden" id="dialcode" name="dialcode" #dialcode value="227">
                    <div class="" *ngIf="!wrongNumber && (signupForm.controls['phone'].dirty || signupForm.controls['phone'].touched)">
                      <span id="valid-msg" class="text-success"><i class="fa fa-check mr-2"></i> Numéro valide</span>
                    </div>
                    <span id="error-msg" class="text-danger"></span>
                    <div class="" *ngIf="wrongNumber">
                      <span id="error-req" class="text-danger"><i class="fas fa-exclamation-triangle mr-2"></i>{{ errorMessage }}</span>
                    </div>
                  </div>
                </div>
                <div class="d-flex my-2">
                  <p class="notice">Vous avez déjà un compte? <a routerLink="/login" class="text-primary">Se connecter</a></p>
                  <button id="submit" class="btn-sp color-primary float-right ml-auto mr-0"  (click)="onFormSubmit(code.value,dialcode.value)" [disabled]="!signupForm.valid">
                    <span class="ml-2" id="text">Envoyer</span>
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>

      </div>
    </div>

    <app-page-footer></app-page-footer>
  </div>


