<div class="home-two">
    <div class="p-0 m-0">
      <app-page-header></app-page-header>
      <!-- Banner section start -->
      <section class="banner features">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-12">
              <img src="assets/images/step.png" alt="" style="" class="ml-auto">
            </div>
            <div class="col-lg-9 col-md- col-12 my-auto">
              <div class="ban-content ">
                <h3 class="text-white">Ouvrez votre compte gratuitement</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Banner section end -->
    </div>
    <div class="py-5">
      <div class="container sl-form-wrap">

        <div class="row">
          <div class="col-md-12">
            <ul class="steps">
              <li><span class="bg-primary text-white">1</span></li>
              <li><span class="bg-primary text-white">2</span></li>
              <li><span class="">3</span></li>
              <li><span class="">4</span></li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9 m-auto card p-5">
            <header class="mb-2">
              <h3 class="">Création d'un compte particulier ou business</h3>
            </header>
            <form [formGroup]="signupForm" class="sl-form">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope"></i></span>
                </div>
                <input formControlName="username" type="email" #username class="form-control form-control-lg" placeholder="Email professionnel" aria-label="email" aria-describedby="basic-addon1" required>
              </div>
              <div class="" *ngIf="!validateEmail">
                <span id="error-req" class="text-danger"><i class="fas fa-exclamation-triangle mr-2"></i>{{ errorMessage }}</span>
              </div>
              <div class="p-0" *ngIf="signupForm.controls['username'].invalid && (signupForm.controls['username'].dirty || signupForm.controls['username'].touched)">
                <div class="text-danger p-0" *ngIf="signupForm.controls['username'].hasError('required')">
                  L'adresse email est requise
                </div>
                <div class="text-danger p-0" *ngIf="signupForm.controls['username'].hasError('pattern')">
                  Veuillez entrez une adresse email valide svp
                </div>
              </div>
              <div class="d-flex mt-5">
                <p class="notice">Vous avez déjà un compte? <a routerLink="/login" class="text-primary">Se connecter</a></p>
                <button class="btn-sp color-primary float-right ml-auto mr-0" type="submit" (click)="onFormSubmit(username.value)" [disabled]="signupForm.invalid">
                  <span class="ml-2" id="text">Envoyer</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <app-page-footer></app-page-footer>
  </div>
