import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";

// Common Components
import {PageNotFoundComponent} from "./components/front/page-not-found/page-not-found.component";
import {PageAccueilComponent} from "./components/front/page-accueil/page-accueil.component";
import { PageAproposComponent } from './components/front/page-apropos/page-apropos.component';
import { PageContactComponent } from './components/front/page-contact/page-contact.component';
import { PageEcommerceComponent } from './components/front/page-ecommerce/page-ecommerce.component';
import { PageTransfertComponent } from './components/front/page-transfert/page-transfert.component';
import { PageIpTvComponent } from './components/front/page-ip-tv/page-ip-tv.component';
import { PageFaqComponent } from './components/front/page-faq/page-faq.component';
import {PageTermsComponent} from "./components/front/page-terms/page-terms.component";
import {PagePolitiqueComponent} from "./components/front/page-politique/page-politique.component";
import { PageLoginComponent } from './components/login/page-login/page-login.component';
// SIGN UP
import { PageSignupChooseAccountTypeComponent } from './components/inscription/page-signup-choose-account-type/page-signup-choose-account-type.component';
import { PageSignupSetEmailComponent } from './components/inscription/page-signup-set-email/page-signup-set-email.component';
import { PageSignupSetPhoneComponent } from './components/inscription/page-signup-set-phone/page-signup-set-phone.component';
import { PageSignupSetCodeComponent } from './components/inscription/page-signup-set-code/page-signup-set-code.component';
import { PageSignupCustomerComponent } from './components/inscription/page-signup-customer/page-signup-customer.component';
import { PageSignupCompanyComponent } from './components/inscription/page-signup-company/page-signup-company.component';
import { PageSignupIdentificationComponent } from './components/inscription/page-signup-identification/page-signup-identification.component';


// Cagnotte
import { PageCagnotteComponent } from './components/front/page-cagnotte/page-cagnotte.component';
import { PageCagnotteDetailsComponent } from './components/front/page-cagnotte-details/page-cagnotte-details.component';
import { PageCagnotteListComponent } from './components/front/page-cagnotte-list/page-cagnotte-list.component';

// Shop
import { PageShopDetailsComponent } from './components/front/page-shop-details/page-shop-details.component';
import { PageShopListComponent } from './components/front/page-shop-list/page-shop-list.component';
import { PageSocialShopComponent } from './components/front/page-social-shop/page-social-shop.component';
// Panier
import { PagePanierComponent } from './components/front/page-panier/page-panier.component';
import { PageValidationPanierComponent } from './components/front/page-validation-panier/page-validation-panier.component';


// Restaurant
import { PageRestauDetailsComponent } from './components/front/page-restau-details/page-restau-details.component';
import { PageRestauListComponent } from './components/front/page-restau-list/page-restau-list.component';

// Dashboard
import { UserDashboardComponent } from './components/back/user-dashboard/user-dashboard.component';
import { UserProfileComponent } from './components/back/user-profile/user-profile.component';
import { UserEditProfileComponent } from './components/back/user-edit-profile/user-edit-profile.component';
import { UserSettingComponent } from './components/back/user-setting/user-setting.component';
import { UserContactListComponent } from './components/back/user-contact-list/user-contact-list.component';
import { UserChangePasswordComponent } from './components/back/user-change-password/user-change-password.component';
// TRANSACTION
import { UserTransactionComponent } from './components/back/user-transaction/user-transaction.component';
import { UserTransactionDetailsComponent } from './components/back/user-transaction-details/user-transaction-details.component';
// RECHARGE
import { UserRechargeComponent } from './components/back/recharge/user-recharge/user-recharge.component';
import { UserRechargeConfirmeComponent } from './components/back/recharge/user-recharge-confirme/user-recharge-confirme.component';
import { UserRechargeConfirmeMobilemoneyComponent } from './components/back/recharge/user-recharge-confirme-mobilemoney/user-recharge-confirme-mobilemoney.component';
import { UserRechargeMontantComponent } from './components/back/recharge/user-recharge-montant/user-recharge-montant.component';
import { UserRechargeStatutComponent } from './components/back/recharge/user-recharge-statut/user-recharge-statut.component';
//SHAPSHAP
import { UserShapshapComponent } from './components/back/shapshap/user-shapshap/user-shapshap.component';
import { UserShapshapConfirmeComponent } from './components/back/shapshap/user-shapshap-confirme/user-shapshap-confirme.component';
import { UserShapshapMontantComponent } from './components/back/shapshap/user-shapshap-montant/user-shapshap-montant.component';
import { UserShapshapStatutComponent } from './components/back/shapshap/user-shapshap-statut/user-shapshap-statut.component';
//TRANSFERT
import { UserTransferComponent } from './components/back/transfer/user-transfer/user-transfer.component';
import { UserTransferConfirmeComponent } from './components/back/transfer/user-transfer-confirme/user-transfer-confirme.component';
import { UserTransferStatutComponent } from './components/back/transfer/user-transfer-statut/user-transfer-statut.component';
//STORE
import { ProductListComponent } from "./components/back/store/product/product-list/product-list.component";
import { ProductAddComponent } from './components/back/store/product/product-add/product-add.component';
import { ProductEditComponent } from "./components/back/store/product/product-edit/product-edit.component";
import { StoreInfoComponent } from './components/back/store/setting/store-info/store-info.component';

import { AuthGuard } from "./services/auth.guard";
import { NgxPermissionsGuard } from 'ngx-permissions';
// FORGOT PASSWORD
import { PageForgotPasswordSetEmailComponent } from './components/login/page-forgot-password-set-email/page-forgot-password-set-email.component';
import { PageForgotPasswordSetOtpComponent } from './components/login/page-forgot-password-set-otp/page-forgot-password-set-otp.component';
import { PageForgotPasswordSetNewPasswordComponent } from './components/login/page-forgot-password-set-new-password/page-forgot-password-set-new-password.component';
// COUPONS
import { CouponsComponent } from './components/coupons/coupons.component';
import { PermissionDeniedComponent } from "./components/permission-denied/permission-denied.component";

const routes: Routes = [
  {
    path: 'permission-denied',
    component: PermissionDeniedComponent,
  },
  {
    path: 'accueil',
    component: PageAccueilComponent,
  },
  {
    path: 'apropos',
    component: PageAproposComponent,
  },
  {
    path: 'page-ecommerce',
    component: PageEcommerceComponent,
  },
  {
    path: 'page-transfert',
    component: PageTransfertComponent,
  },
  {
    path: 'ip-tv',
    component: PageIpTvComponent,
  },
  {
    path: 'contact',
    component: PageContactComponent,
  },
  {
    path: 'faq',
    component: PageFaqComponent,
  },
  {
    path: 'terms-conditions',
    component: PageTermsComponent,
  },
  {
    path: 'politique-confidentialite',
    component: PagePolitiqueComponent,
  },
  {
    path: 'page-cagnotte',
    component: PageCagnotteComponent,
  },
  {
    path: 'page-cagnotte-list',
    component: PageCagnotteListComponent,
  },
  {
    path: 'page-cagnotte-details',
    component: PageCagnotteDetailsComponent,
  },
  {
    path: 'shop/stores',
    component: PageShopListComponent,
  },
  {
    path: 'shop',
    component: PageSocialShopComponent,
  },
  {
    path: 'shop/stores/details/:id',
    component: PageShopDetailsComponent,
  },
  {
    path: 'page-panier',
    component: PagePanierComponent,
  },
  {
    path: 'page-validation-panier',
    component: PageValidationPanierComponent,
  },
  {
    path: 'page-restau-list',
    component: PageRestauListComponent,
  },
  {
    path: 'page-restau-details',
    component: PageRestauDetailsComponent,
  },
  {
    path: 'login',
    component: PageLoginComponent,
  },
  {
    path: 'forgot-password',
    component: PageForgotPasswordSetEmailComponent,
  },
  {
    path: 'forgot-password/set-otp',
    component: PageForgotPasswordSetOtpComponent,
  },
  {
    path: 'forgot-password/set-new-password',
    component: PageForgotPasswordSetNewPasswordComponent,
  },
  {
    path: 'inscription/account-type',
    component: PageSignupChooseAccountTypeComponent,
  },
  {
    path: 'inscription/set-phone',
    component: PageSignupSetPhoneComponent,
  },
  {
    path: 'inscription/set-email',
    component: PageSignupSetEmailComponent,
  },
  {
    path: 'inscription/identification',
    component: PageSignupIdentificationComponent,
  },
  {
    path: 'inscription/confirmation-otp',
    component: PageSignupSetCodeComponent,
  },
  {
    path: 'inscription/customer',
    component: PageSignupCustomerComponent,
  },
  {
    path: 'inscription/company',
    component: PageSignupCompanyComponent,
  },
  {
    path: 'coupons',
    component: CouponsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: '/accueil',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: UserDashboardComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'DASHBOARD'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile/edit',
    component: UserEditProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile/edit/securite',
    component: UserChangePasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'contacts',
    component: UserContactListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'parametre',
    component: UserSettingComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'SETTING'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'recharge',
    component: UserRechargeComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'RECHARGE'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'recharge/montant',
    component: UserRechargeMontantComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'RECHARGE'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'recharge/confirmation',
    component: UserRechargeConfirmeComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'RECHARGE'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'recharge/confirmation/mobilemoney',
    component: UserRechargeConfirmeMobilemoneyComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'RECHARGE'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'recharge/statut',
    component: UserRechargeStatutComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'RECHARGE'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'shapshap',
    component: UserShapshapComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'SHAPSHAP'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'shapshap/montant',
    component: UserShapshapMontantComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'SHAPSHAP'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'shapshap/confirmation',
    component: UserShapshapConfirmeComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'SHAPSHAP'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'shapshap/statut',
    component: UserShapshapStatutComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'SHAPSHAP'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'transfer',
    component: UserTransferComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'CICO'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'transfer/confirme',
    component: UserTransferConfirmeComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'CICO'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'transfer/statut',
    component: UserTransferStatutComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'CICO'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'store/products',
    component: ProductListComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'STORE'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'store/products/add',
    component: ProductAddComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'STORE'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'store/products/edit/:id',
    component: ProductEditComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'STORE'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'store/info',
    component: StoreInfoComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'STORE'],
        redirectTo: '/permission-denied',
      },
    },
  },
  {
    path: 'transaction',
    component: UserTransactionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'transaction/details',
    component: UserTransactionDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      className: 'error-page',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
