import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { LocalService } from 'src/app/services/local.service';
import { StoreService } from 'src/app/services/store.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-store-info',
  templateUrl: './store-info.component.html',
  styleUrls: ['./store-info.component.css'],
})
export class StoreInfoComponent implements OnInit {
  user: any = [];
  store: any = [];
  storeForm!: FormGroup;
  data: any = [];
  swalOptions: SweetAlertOptions = { icon: 'info' };
  formData!: FormData;
  selectedFile!: File;
  get f() {
    return this.storeForm.controls;
  }
  constructor(
    private tokenStorageService: TokenStorageService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private localStore: LocalService,
    private swalService: FlashMessageService,
    private storeService: StoreService
  ) {}
  async onFileSelected(event: any) {
    this.selectedFile = <File>event.target.files[0];
    this.formData = new FormData();
    this.formData.append('store_id', this.user.merchantStore.id);
    this.formData.append('store-file', this.selectedFile);
    event.target.value = null;
    try {
      const res = await this.storeService.uploadStoreLogo(this.formData);
      if ('status' in res && res['status'] == true) {
        //window.location.reload();
        this.swalOptions.icon = 'success'; //set the icon
        this.swalOptions.html = `Le logo a été ajouter`;
        this.swalService.show(this.swalOptions);
      } else {
        this.swalOptions.icon = 'error';
        this.swalOptions.html = "Le logo n'a pas été ajouter";
        this.swalService.show(this.swalOptions);
      }
    } catch (error) {
      this.swalOptions.icon = 'error';
      this.swalOptions.html = "Le logo n'a pas été ajouter";
      this.swalService.show(this.swalOptions);
    }
  }
  async onBannerSelected(event: any) {
    this.selectedFile = <File>event.target.files;
    this.formData = new FormData();
    this.formData.append('store_id', this.user.merchantStore.id);
    for (const file of event.target.files) {
      this.formData.append('banner-files', file);
    }
    event.target.value = null;
    try {
      const res = await this.storeService.uploadStoreBanner(this.formData);
      if ('status' in res && res['status'] == true) {
        //window.location.reload();
        this.swalOptions.icon = 'success'; //set the icon
        this.swalOptions.html = `La banniere a été ajouter`;
        this.swalService.show(this.swalOptions);
      } else {
        this.swalOptions.icon = 'error';
        this.swalOptions.html = "La banniere n'a pas été ajouter";
        this.swalService.show(this.swalOptions);
      }
    } catch (error) {
      this.swalOptions.icon = 'error';
      this.swalOptions.html = "La banniere n'a pas été ajouter";
      this.swalService.show(this.swalOptions);
    }
  }
  async onFormSubmit() {
    try {
      this.data = await this.storeService.updateStoreInfo(this.storeForm.value);
      if ('status' in this.data && this.data['status'] == true) {
        this.router.navigate(['/store/info']).then(() => {
          this.swalOptions.icon = 'success';
          this.swalOptions.html =
            'Les informations ont été enregistrer avec succés!';
          this.swalService.show(this.swalOptions);
        });
      } else {
        this.swalOptions.icon = 'info';
        this.swalOptions.html =
          'La modification a echoué veuillez ressayer svp!';
        this.swalService.show(this.swalOptions);
      }
    } catch (error) {
      this.swalOptions.icon = 'error';
      this.swalOptions.html = 'La modification a echoué veuillez ressayer svp!';
      this.swalService.show(this.swalOptions);
    }
  }
  async initialisation() {
    try {
      this.store = await this.storeService.getStoreInfo();
      console.log(this.store);
      if ('name' in this.store) {
        this.storeForm.setValue({
          store_name: this.store['name'],
          address: this.store['address'],
          phone: this.store['phone'],
          description: this.store['description']
        });
      } else {
        window.location.reload();
      }
    } catch (error) {
      window.location.reload();
    }
  }
  ngOnInit() {
    this.user = this.tokenStorageService.getUser();
    this.user = JSON.parse(this.user);
    this.initialisation();
    console.log(this.store);
    this.storeForm = this.formBuilder.group(
      {
        store_name: [this.store.name, [Validators.required]],
        description: [this.store.description, [Validators.required]],
        address: [this.store.address, [Validators.required]],
        phone: [this.store.phone, [Validators.required]],
      },
      {
        updateOn: 'change',
      }
    );
  }
}
