import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { LocalService } from 'src/app/services/local.service';
import { StoreService } from 'src/app/services/store.service';
import { SweetAlertOptions } from 'sweetalert2';
declare let $: any;
@Component({
  selector: 'app-page-shop-list',
  templateUrl: './page-shop-list.component.html',
  styleUrls: ['./page-shop-list.component.scss'],
})
export class PageShopListComponent implements OnInit {
  stores: any = [];
  data: any = [];
  storeId!: string;
  page: number = 1;
  count: number = 0;
  tableSize: number = 3;
  tableSizes: any = [3, 6, 9, 12];
  swalOptions: SweetAlertOptions = { icon: 'info' };
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private localStore: LocalService,
    private storeService: StoreService,
    private swalService: FlashMessageService
  ) {}

  ngOnInit() {
    this.fetchPosts();
  }
  async fetchPosts() {
    try {
      this.data = await this.storeService.getStores();
      if ('data' in this.data) {
        this.stores = this.data['data'];console.log(this.stores);
      }
    } catch (error) {}
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.fetchPosts();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.fetchPosts();
  }
}
