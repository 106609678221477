<div class="home-one">
    <app-page-header></app-page-header>
    <!-- Banner section start -->
    <section class="banner v1 features pb-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12 my-auto">
            <div class="ban-content">
              <h1 style="text-transform:uppercase;">Envoyez de l'argent à vos proches dans le monde entier</h1>
              <p>Des transferts d'argent rapides et sécurisés avec d'excellents taux</p>
              
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <form class="currency-form" action="#">
              <h4>Où souhaitez-vous envoyer de l'argent ?</h4>
              <div class="row">
                <div class="col-6 form-field">
                  <label for="rcv_country">Envoi à partir de:</label>
                  <select id="rcv_country" name="rcv_country">
                    <option value="niger"><img src="assets/images/flags/niger.png" alt="" class="">Niger</option>
                    <option value="canada">Canada</option>
                    <option value="australia">Australia</option>
                  </select>
                </div>
                <div class="col-6 form-field">
                  <label for="del_type">Vers:</label>
                  <select id="del_type" name="del_type">
                    <option>Selectionnez le Pays</option>
                    <option value="niger">Niger</option>
                    <option value="canada">Canada</option>
                    <option value="australia">Australia</option>
                  </select>
                </div>
              </div>
              <button class="btn btn-block btn-filled form-btn">
                <span class="bh"></span> <span>Commencer <i class="fas fa-arrow-right"></i></span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner section end -->
    <div class="py-3" style="background-color: #455A64;">
      <div class="container">
        <div class="row">
          <div class="col-12"><h5 class="text-white text-center">Envoi d'argent rapide et sécurisé vers: </h5></div>
          <div class="col-md-2"><img src="assets/images/flags/niger1.png" style="width: 32px !important;" class="mx-auto"><span class="d-block text-center text-white">NIGER</span></div>
          <div class="col-md-2"><img src="assets/images/flags/mali1.png" style="width: 32px !important;" class="mx-auto"><span class="d-block text-center text-white">MALI</span></div>
          <div class="col-md-2"><img src="assets/images/flags/senegal1.png" style="width: 32px !important;" class="mx-auto"><span class="d-block text-center text-white">SENEGAL</span></div>
          <div class="col-md-2"><img src="assets/images/flags/nigeria1.png" style="width: 32px !important;" class="mx-auto"><span class="d-block text-center text-white">NIGERIA</span></div>
          <div class="col-md-2"><img src="assets/images/flags/burkina-faso1.png" style="width: 32px !important;" class="mx-auto"><span class="d-block text-center text-white">BURKINA</span></div>
          <div class="col-md-2"><img src="assets/images/flags/all-flags.png" style="width: 32px !important;" class="mx-auto"><span class="d-block text-center text-white">+10 PAYS</span></div>
        </div>
      </div>
    </div>
    <!-- Easy steps section start -->
    <div class="easy-steps py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 m-auto">
              <h4 class="text-center mb-5" style="text-transform:none;">Un moyen facile et rapide d’envoyer de l’argent</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-10 col-md-12 m-auto">
            <ul class="steps">
              <li><span>1</span> Sélectionner un pays</li>
              <li><span>2</span> Choisissez un service</li>
              <li><span>3</span> Entreé les informations</li>
              <li><span>4</span> Confirmer et envoyer</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Easy steps section end -->
  
    <!-- Payment service section start -->
    <div class="payment-service bg-light">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-5">
            <figure class="imgBox">
              <img src="assets/images/why-send.png" alt="">
            </figure>
          </div>
          <div class="col-md-7">
            <div class="content-box">
              <h4>Pourquoi envoyer de l’argent avec notre service de paiement</h4>
              <ul class="list">
                <li><i class="fas fa-check-circle"></i> Nous sommes réglementé comme tout service de transfert d'argent. </li>
                <li><i class="fas fa-check-circle"></i> En partenariat avec des institutions financières connu du monde entier.</li>
                <li><i class="fas fa-check-circle"></i> Des technologies de paiement sécurisées</li>
                <li><i class="fas fa-check-circle"></i> Support client 24 h/24 et 7 j/7.</li>
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <!-- Payment service section end -->
  
    <!-- Services section start -->
    <div class="services py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-10 m-auto">
            <div class="sec-heading">
              <h4 class="text-center">Paiement sécurisé avec des frais de transfert peu élevés</h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="iconBox boxed text-center">
              <!-- <img src="assets/images/icons/1.png" alt="" class="img-icon"> -->
  
              <!-- Roysha icon -->
              <span class="roysha-icon roysha-secured"><span class="path1"></span><span class="path2"></span><span
                  class="path3"></span><span class="path4"></span><span class="path5"></span><span
                  class="path6"></span><span class="path7"></span><span class="path8"></span><span
                  class="path9"></span><span class="path10"></span><span class="path11"></span><span
                  class="path12"></span><span class="path13"></span></span>
  
              <h5><a href="javascript:void(0)">Service de paiement sécurisé</a></h5>
            </div>
          </div>
          <div class="col-md-4">
            <div class="iconBox boxed text-center">
              <!-- <img src="assets/images/icons/2.png" alt="" class="img-icon"> -->
  
              <!-- Roysha icon -->
              <span class="roysha-icon roysha-amount"><span class="path1"></span><span class="path2"></span><span
                  class="path3"></span><span class="path4"></span><span class="path5"></span><span
                  class="path6"></span><span class="path7"></span><span class="path8"></span><span
                  class="path9"></span><span class="path10"></span><span class="path11"></span></span>
  
              <h5><a href="javascript:void(0)">Transfert rapide et à faible coût</a></h5>
            </div>
          </div>
          <div class="col-md-4">
            <div class="iconBox boxed text-center">
              <!-- <img src="assets/images/icons/3.png" alt="" class="img-icon"> -->
  
              <!-- Roysha icon -->
              <span class="roysha-icon roysha-wallet"><span class="path1"></span><span class="path2"></span><span
                  class="path3"></span><span class="path4"></span><span class="path5"></span><span
                  class="path6"></span><span class="path7"></span><span class="path8"></span><span
                  class="path9"></span><span class="path10"></span><span class="path11"></span><span
                  class="path12"></span><span class="path13"></span><span class="path14"></span></span>
  
              <h5><a href="javascript:void(0)">Suivi de l’argent en temps réel</a></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Services section end -->
    <div class="bg-primary py-5" >
      <div class="container">
        
        <div class="row">
          <div class="col-12">
            <h4 class="text-center text-white">Frais de transaction pour l'envoi d'argent en ligne vers le Mali depuis le Niger.</h4>
            <h6 class="text-center text-white">Sélectionnez un pays ci-dessous pour découvrir nos tarifs</h6>
          </div>
          <div class="col-md-12 mt-3">
            <form class="currency-form-v2"  action="#">
              <h4>Send Money</h4>
              <div class="form-field">
                <div class="curr-select">
                  <span class="selected">
                    <img src="assets/images/flags/{{main}}.png" alt="">{{mainC}}&nbsp;<i
                      class="fas fa-caret-down"></i></span>
                  <ul>
                    <li (click)="selectedCurrency(second)"><a href="javascript:void(0)"><img
                          src="assets/images/flags/{{second}}.png" alt="">{{secondC}}</a></li>
                    <li (click)="selectedCurrency(third)"><a href="javascript:void(0)"><img
                          src="assets/images/flags/{{third}}.png" alt="">{{thirdC}}</a></li>
                  </ul>
                </div>
              </div>
              <div class="form-field">
                <div class="join-field">
                  <input type="text" name="send_amount" (change)="amountChanged($event)" [value]="amount"
                    placeholder="$1,000.00">
                  <select name="rcv_country" style="position: relative; z-index: 2000;">
                    <option>Select Country</option>
                    <option value="usa">USA</option>
                    <option value="canada">Canada</option>
                    <option value="australia">Australia</option>
                  </select>
                  <!-- <button class="btn btn-block btn-filled form-btn"><span class="bh"></span> <span>Send</span></button> -->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Payment system works section start -->
    <div class="bg-light">
      <div class="container">
        <div class="row">
          <div class="col-md-7 my-auto">
              <h3 class="mb-3">Nous sommes à votre service 24H/24 et 7jrs/7.</h3>
              <p class="text-justify">
                Avez-vous des questions ? Recherchez des réponses dans notre centre d'aide. Ou appelez-nous pour obtenir une assistance conviviale. Notre service clientèle sera heureux de vous aider.
              </p>
          </div>
          <div class="col-md-5">
            <img src="assets/images/contact-us1.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- Payment system works section end -->
    <!-- speciality section start -->
    <section class="partners bg-offwhite">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-10 m-auto">
            <div class="sec-heading">
              <span class="tagline">NOS PARTENAIRES</span>
              <h2 class="sec-title">Soutenu par des investisseurs réputés</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="partners-logo owl-carousel">
              <a href="javascript:void(0)"><img src="assets/images/partners/1.png" alt=""></a>
              <a href="javascript:void(0)"><img src="assets/images/partners/2.png" alt=""></a>
              <a href="javascript:void(0)"><img src="assets/images/partners/3.png" alt=""></a>
              <a href="javascript:void(0)"><img src="assets/images/partners/4.png" alt=""></a>
              <a href="javascript:void(0)"><img src="assets/images/partners/5.png" alt=""></a>
              <a href="javascript:void(0)"><img src="assets/images/partners/6.png" alt=""></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- speciality section end -->
    <app-page-footer></app-page-footer>
  </div>
  
