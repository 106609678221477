import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { RechargeService } from 'src/app/services/recharge.service';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss'],
})
export class UserDashboardComponent implements OnInit {
  title = 'Dashboard | SmartPay';
  user: any;
  transactions: any;
  data: any = [];
  page: number = 1;
  count: number = 0;
  tableSize: number = 7;
  tableSizes: any = [3, 6, 9, 12];
  constructor(
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private titleService: Title,
    private rechargeService: RechargeService,
    private localStore:LocalService
  ) {}

  ngOnInit() {
    this.user = JSON.parse(this.localStore.getData('user'));
    console.log(this.user);
    this.titleService.setTitle(this.title);
    this.fetchPosts();
    // this.loadScript('../../../assets/admin/js/jquery.min.js');
    // this.loadScript('../../../assets/admin/js/bootstrap.bundle.min.js');
  }
  async fetchPosts() {
    try {
      this.data = await this.rechargeService.transaction();
      if ('data' in this.data) {
        this.transactions = this.data['data'];
      }
    } catch (error) {}
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.fetchPosts();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.fetchPosts();
  }
}
