<div class="blog-page">
    <app-page-header></app-page-header>
    <!-- Banner section start -->
    <section class="banner features pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <img src="assets/images/cagnotte.png" alt="" class="">
          </div>
          <div class="col-md-8 m-auto">
            <div class="ban-content text-center" >
              <h1 style="text-transform: none;">Lancez votre collecte de fonds gratuitement sans commission avec SMARTPAY </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner section end -->
  <div class="py-5">
    <div class="container">
      <h3 class="text-center">Une collecte de fonds en quelques clics </h3>
      <div class="row">
        <div class="col-md-4">
          <div class="iconBox boxed-shadow text-center">
            <!-- <img src="assets/images/icons/1.png" alt="" class="img-icon"> -->

            <!-- Roysha icon -->
            <span class="roysha-icon roysha-form"><span class="path1"></span><span class="path2"></span><span
                  class="path3"></span><span class="path4"></span><span class="path5"></span><span
                  class="path6"></span><span class="path7"></span><span class="path8"></span><span
                  class="path9"></span><span class="path10"></span><span class="path11"></span><span
                  class="path12"></span><span class="path13"></span><span class="path14"></span><span
                  class="path15"></span><span class="path16"></span><span class="path17"></span><span
                  class="path18"></span><span class="path19"></span><span class="path20"></span></span>

            <h5><a href="javascript:void(0)">Créez votre collecte</a></h5>
            <p>Créez votre collecte en quelques secondes. Celle-ci sera soumise à validation (très rapide) et vous pourrez commencer à collecter via notre plateforme 100% gratuite</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="iconBox boxed-shadow text-center">
            <!-- <img src="assets/images/icons/2.png" alt="" class="img-icon"> -->

            <!-- Roysha icon -->
            <span class="roysha-icon roysha-low-cost"><span class="path1"></span><span class="path2"></span><span
                  class="path3"></span><span class="path4"></span><span class="path5"></span><span
                  class="path6"></span><span class="path7"></span><span class="path8"></span><span
                  class="path9"></span><span class="path10"></span><span class="path11"></span><span
                  class="path12"></span><span class="path13"></span><span class="path14"></span><span
                  class="path15"></span><span class="path16"></span><span class="path17"></span><span
                  class="path18"></span><span class="path19"></span><span class="path20"></span><span
                  class="path21"></span><span class="path22"></span><span class="path23"></span><span
                  class="path24"></span><span class="path25"></span><span class="path26"></span><span
                  class="path27"></span><span class="path28"></span><span class="path29"></span><span
                  class="path30"></span></span>


            <h5><a href="javascript:void(0)">Pas de commission</a></h5>
            <p>Sur SMARTPAY nous ne prélevons aucune commission sur les dons récoltés via notre plateforme. L'intégralité des sommes collectées est reversé aux initiateurs des collectes (moins les frais bancaire et commissions des moyens de paiements)</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="iconBox boxed-shadow text-center">
            <!-- <img src="assets/images/icons/3.png" alt="" class="img-icon"> -->

            <!-- Roysha icon -->
            <span class="roysha-icon roysha-mobile-app"><span class="path1"></span><span class="path2"></span><span
                class="path3"></span><span class="path4"></span><span class="path5"></span><span
                class="path6"></span><span class="path7"></span><span class="path8"></span><span
                class="path9"></span><span class="path10"></span><span class="path11"></span><span
                class="path12"></span><span class="path13"></span><span class="path14"></span><span
                class="path15"></span><span class="path16"></span><span class="path17"></span></span>

            <h5><a href="javascript:void(0)">Récupérez les dons collectés</a></h5>
            <p>Connectez-vous à votre tableau de bord pour récupérer les sommes que vous avez collectées. Vous n'êtes pas obligé d'attendre la fin de votre campagne pour solliciter des virements.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-light py-5">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="content-box">
            <h2>Comment ça marche?</h2>
            <ul class="steps vertical">
              <li>
                <span>1</span>
                Inscrivez-vous sur SMARTPAY
                <p class="text-justify">La première étape d’une collecte de fonds sur SMARTPAY consiste à créer une page. Il est possible de la personnaliser (insertion de logo, de photo, d’audio ou de vidéo) en fonction de vos besoins. Nous validons votre collecte dans un meilleur délai et vous pourrez vous préparer à recevoir vos premiers dons via notre plateforme.</p>
              </li>
              <li>
                <span>2</span>
                Faites-le connaître
                <p class="text-justify">Faites connaitre à tous vos proches votre campagne de collecte en ligne via Facebook, Twitter et autres réseaux sociaux. Invitez-les à soutenir votre collecte de fonds et surtout à participer.</p>
              </li>
              <li>
                <span>3</span>
                Suivez en permanence votre collecte
                <p class="text-justify">Grâce à des statistiques mises à votre disposition, vous serez en mesure de suivre en temps réel l’avancement de chacune des collectes effectuées sur votre page personnelle.</p>
              </li>
              <li>
                <span>4</span>
                Récupérez l’argent collecté
                <p class="text-justify">Sur votre tableau de bord, vous pouvez à tout moment demander un virement bancaire sur votre compte ou transférer l’argent directement sur le compte de l’association destinataire de votre collecte. Et ce, sans obliger d’attendre la date limite de votre campagne !</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-3" style="background-color: #455A64;">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h3 class=" text-white">Créer votre collecte de fonds gratuitement sans commission</h3>
        </div>
        <div class="col-md-4 my-auto">
          <button class="btn btn-sp color-primary float-right" style="text-transform:none;">Je crée ma campagne</button>
        </div>
      </div>
    </div>
  </div>
  <div class="py-5">
    <div class="container">
      <h3 class="text-center">Découvrez les Collectes</h3>
      <div class="row">
        <div class="col-md-6">
          <div class="card shadow">
            <img src="assets/images/blog/1.jpg" alt="">
            <div class="card-body">

              <h5 style="text-transform:uppercase"><a routerLink="/page-cagnotte-details" class="">titre</a></h5>
              <p class="text-justify">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. amet consectetur
              </p>
              <span class="mr-5"><i class="fa fa-user text-primary"></i> Mr Abdoul Madjid</span>
              <span class=""><i class="fa fa-tags text-primary"></i> Religion</span>
            </div>
            <div class="card-footer bg-light">
              <div class="row">
                <div class="col-md-4">
                  <div class="text-center">
                    <i class="fa fa-piggy-bank text-center text-primary"></i>
                    <span class="d-block" style=" font-size: 10px;">300.000 CFA</span>
                    <span class="text-dark">MONTANT</span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="text-center">
                    <i class="fa fa-donate text-center text-primary"></i>
                    <span class="d-block" style="font-size: 10px;">60.000 CFA</span>
                    <span class="">COLLECTE</span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="text-center">
                    <i class="fa fa-clock text-center text-primary"></i>
                    <span class="d-block" style="font-size: 10px;">47</span>
                    <span class="">JOURS</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card shadow">
            <img src="assets/images/blog/1.jpg" alt="">
            <div class="card-body">

              <h5 style="text-transform:uppercase"><a routerLink="/page-cagnotte-details" class="">titre</a></h5>
              <p class="text-justify">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. amet consectetur
              </p>
              <span class="mr-5"><i class="fa fa-user text-primary"></i> Mr Abdoul Madjid</span>
              <span class=""><i class="fa fa-tags text-primary"></i> Religion</span>
            </div>
            <div class="card-footer bg-light">
              <div class="row">
                <div class="col-md-4">
                  <div class="text-center">
                    <i class="fa fa-piggy-bank text-center text-primary"></i>
                    <span class="d-block" style="font-size: 10px;">400.000 CFA</span>
                    <span class="">MONTANT</span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="text-center">
                    <i class="fa fa-donate text-center text-primary"></i>
                    <span class="d-block" style="font-size: 10px;">350.000 CFA</span>
                    <span class="">COLLECTE</span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="text-center">
                    <i class="fa fa-clock text-center text-primary"></i>
                    <span class="d-block" style="font-size: 10px;">22</span>
                    <span class="">JOURS</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-5"><button class="btn btn-sp color-primary mx-auto"><a routerLink="/page-cagnotte-list" class="text-white">Afficher la liste des collectes</a></button></div>
    </div>
  </div>

    <app-page-footer></app-page-footer>
  </div>

