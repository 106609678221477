import { Injectable } from '@angular/core';
import { LocalService } from './local.service';

const TOKEN_KEY = 'token';
const USER_KEY = 'user';
@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor(private localStore: LocalService) { }
  logout() {
    this.localStore.clearData();
  }
  public saveToken(token: string) {
    this.localStore.removeData(TOKEN_KEY);
    this.localStore.saveData(TOKEN_KEY, token);
  }
  public getToken(): any {
    return this.localStore.getData(TOKEN_KEY);
  }
  public saveUser(user: any) {
    this.localStore.removeData(USER_KEY);
    this.localStore.saveData(USER_KEY, JSON.stringify(user));
  }
  public getUser(){
    return this.localStore.getData(USER_KEY);
  }
}
