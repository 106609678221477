<!-- Document Wrapper  -->
<div id="main-wrapper">
    <app-user-header></app-user-header>

    <app-user-profilebar></app-user-profilebar>


    <!-- Admin Content Section  -->
    <div id="content" class="py-4">
        <div class="container">
            <div class="row">
                  <div class="col-lg-3" style="width:20%;">
                    <app-user-left-menu></app-user-left-menu>
                  </div>
                  <!-- Middle Panel  -->
                  <div class="col-lg-9 profile-area">
                    <h3 class="admin-heading bg-offwhite">
                        <p>Profile</p>
                        <span>Changer le mots de passe</span>
                    </h3>
                    <!-- Edit personal info  -->
                    <div class="bg-offwhite shadow p-5">
                        <div class="content-edit-area">
                            <div class="edit-header">
                                <!-- <h5 class="title">Changer le mots de passe</h5> -->
                                <a routerLink="/profile" class=""><button  type="button" class="close" style="margin-right: 15px;"><span aria-hidden="true">&times;</span></button></a>
                            </div>
                            <div class="edit-content mb-4">
                                <form [formGroup]="updateForm" id="change-password" method="post">
                                    <div class="row">
                                      <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="language">Langue</label>
                                            <select class="lang-select custom-select" id="country-language" name="language">
                                                <option value="en">EN</option>
                                                <option value="fr">FR</option>
                                            </select>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="timezone">Time Zone</label>
                                            <select class="custom-select" id="timezone" name="timezone_id">
                                                <option value="-12">(GMT-12:00) International Date Line West
                                                </option>
                                                <option value="-11">(GMT-11:00) Midway Island, Samoa</option>
                                                <option value="-10">(GMT-10:00) Hawaii</option>
                                                <option value="-9">(GMT-09:00) Alaska</option>
                                                <option value="-8">(GMT-08:00) Pacific Time (US & Canada)
                                                </option>
                                                <option value="-8">(GMT-08:00) Tijuana, Baja California</option>
                                                <option value="-7">(GMT-07:00) Arizona</option>
                                                <option value="-7">(GMT-07:00) Chihuahua, La Paz, Mazatlan
                                                </option>
                                                <option value="-7">(GMT-07:00) Mountain Time (US & Canada)
                                                </option>
                                                <option selected="selected" value="-6">(GMT-06:00) Central
                                                    America
                                                </option>
                                                <option value="-6">(GMT-06:00) Central Time (US & Canada)
                                                </option>
                                                <option value="-6">(GMT-06:00) Guadalajara, Mexico City,
                                                    Monterrey
                                                </option>
                                                <option value="-6">(GMT-06:00) Saskatchewan</option>
                                                <option value="-5">(GMT-05:00) Bogota, Lima, Quito, Rio Branco
                                                </option>
                                                <option value="-5">(GMT-05:00) Eastern Time (US & Canada)
                                                </option>
                                                <option value="-5">(GMT-05:00) Indiana (East)</option>
                                                <option value="-4">(GMT-04:00) Atlantic Time (Canada)</option>
                                                <option value="-4">(GMT-04:00) Caracas, La Paz</option>
                                                <option value="-4">(GMT-04:00) Manaus</option>
                                                <option value="-4">(GMT-04:00) Santiago</option>
                                                <option value="-3.5">(GMT-03:30) Newfoundland</option>
                                                <option value="-3">(GMT-03:00) Brasilia</option>
                                                <option value="-3">(GMT-03:00) Buenos Aires, Georgetown</option>
                                                <option value="-3">(GMT-03:00) Greenland</option>
                                                <option value="-3">(GMT-03:00) Montevideo</option>
                                                <option value="-2">(GMT-02:00) Mid-Atlantic</option>
                                                <option value="-1">(GMT-01:00) Cape Verde Is.</option>
                                                <option value="-1">(GMT-01:00) Azores</option>
                                                <option value="0">(GMT+00:00) Casablanca, Monrovia, Reykjavik
                                                </option>
                                                <option value="0">(GMT+00:00) Greenwich Mean Time : Dublin,
                                                    Edinburgh, Lisbon, London
                                                </option>
                                                <option value="1">(GMT+01:00) Amsterdam, Berlin, Bern, Rome,
                                                    Stockholm, Vienna
                                                </option>
                                                <option value="1">(GMT+01:00) Belgrade, Bratislava, Budapest,
                                                    Ljubljana, Prague
                                                </option>
                                                <option value="1">(GMT+01:00) Brussels, Copenhagen, Madrid,
                                                    Paris
                                                </option>
                                                <option value="1">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb
                                                </option>
                                                <option value="1">(GMT+01:00) West Central Africa</option>
                                                <option value="2">(GMT+02:00) Amman</option>
                                                <option value="2">(GMT+02:00) Athens, Bucharest, Istanbul
                                                </option>
                                                <option value="2">(GMT+02:00) Beirut</option>
                                                <option value="2">(GMT+02:00) Cairo</option>
                                                <option value="2">(GMT+02:00) Harare, Pretoria</option>
                                                <option value="2">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia,
                                                    Tallinn, Vilnius
                                                </option>
                                                <option value="2">(GMT+02:00) Jerusalem</option>
                                                <option value="2">(GMT+02:00) Minsk</option>
                                                <option value="2">(GMT+02:00) Windhoek</option>
                                                <option value="3">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
                                                <option value="3">(GMT+03:00) Moscow, St. Petersburg,
                                                    Volgograd
                                                </option>
                                                <option value="3">(GMT+03:00) Nairobi</option>
                                                <option value="3">(GMT+03:00) Tbilisi</option>
                                                <option value="3.5">(GMT+03:30) Tehran</option>
                                                <option value="4">(GMT+04:00) Abu Dhabi, Muscat</option>
                                                <option value="4">(GMT+04:00) Baku</option>
                                                <option value="4">(GMT+04:00) Yerevan</option>
                                                <option value="4.5">(GMT+04:30) Kabul</option>
                                                <option value="5">(GMT+05:00) Yekaterinburg</option>
                                                <option value="5">(GMT+05:00) Islamabad, Karachi, Tashkent
                                                </option>
                                                <option value="5.5">(GMT+05:30) Sri Jayawardenapura</option>
                                                <option value="5.5">(GMT+05:30) Chennai, Kolkata, Mumbai, New
                                                    Delhi
                                                </option>
                                                <option value="5.75">(GMT+05:45) Kathmandu</option>
                                                <option value="6">(GMT+06:00) Almaty, Novosibirsk</option>
                                                <option value="6">(GMT+06:00) Astana, Dhaka</option>
                                                <option value="6.5">(GMT+06:30) Yangon (Rangoon)</option>
                                                <option value="7">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                                                <option value="7">(GMT+07:00) Krasnoyarsk</option>
                                                <option value="8">(GMT+08:00) Beijing, Chongqing, Hong Kong,
                                                    Urumqi
                                                </option>
                                                <option value="8">(GMT+08:00) Kuala Lumpur, Singapore</option>
                                                <option value="8">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                                                <option value="8">(GMT+08:00) Perth</option>
                                                <option value="8">(GMT+08:00) Taipei</option>
                                                <option value="9">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                                                <option value="9">(GMT+09:00) Seoul</option>
                                                <option value="9">(GMT+09:00) Yakutsk</option>
                                                <option value="9.5">(GMT+09:30) Adelaide</option>
                                                <option value="9.5">(GMT+09:30) Darwin</option>
                                                <option value="10">(GMT+10:00) Brisbane</option>
                                                <option value="10">(GMT+10:00) Canberra, Melbourne, Sydney
                                                </option>
                                                <option value="10">(GMT+10:00) Hobart</option>
                                                <option value="10">(GMT+10:00) Guam, Port Moresby</option>
                                                <option value="10">(GMT+10:00) Vladivostok</option>
                                                <option value="11">(GMT+11:00) Magadan, Solomon Is., New
                                                    Caledonia
                                                </option>
                                                <option value="12">(GMT+12:00) Auckland, Wellington</option>
                                                <option value="12">(GMT+12:00) Fiji, Kamchatka, Marshall Is.
                                                </option>
                                                <option value="13">(GMT+13:00) Nuku'alofa</option>
                                            </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="Current-pass">Mots de passe actuel</label>
                                        <input type="text" type="password" formControlName="oldPassword" id="oldPassword" class="form-control" placeholder="Mot de passe actuel" aria-label="password" required>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-6 mb-3">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i class="fa fa-lock"></i></span>
                                          </div>
                                          <input type="password" formControlName="password" id="password" class="form-control" placeholder="Mot de passe" aria-label="password" aria-describedby="basic-addon1" required>

                                          <div class="p-0" *ngIf="updateForm.controls['password'].invalid && (updateForm.controls['password'].dirty || updateForm.controls['password'].touched)">
                                              <div class="" [ngClass]="updateForm.controls['password'].hasError('required') || updateForm.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                                                <i class="fa {{ updateForm.controls['password'].hasError('required') ||
                                                updateForm.controls['password'].hasError('minlength') ? 'fa-times-circle' :
                                                'fa-check-circle' }}">
                                                </i>
                                                Doit comporter au moins 8 caractères !
                                              </div>
                                              <div class="" [ngClass]="updateForm.controls['password'].hasError('required') || updateForm.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                                                <i class="fa {{ updateForm.controls['password'].hasError('required') ||
                                                updateForm.controls['password'].hasError('hasSmallCase') ? 'fa-times-circle' :
                                                'fa-check-circle' }}">
                                                </i>
                                                Doit avoir au moins une lettre minuscle!
                                              </div>
                                              <div class="" [ngClass]="updateForm.controls['password'].hasError('required') || updateForm.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                                                <i class="fa {{ updateForm.controls['password'].hasError('required') ||
                                                updateForm.controls['password'].hasError('hasCapitalCase') ? 'fa-times-circle' :
                                                'fa-check-circle' }}">
                                                </i>
                                                Doit avoir au moins une lettre majuscle!
                                              </div>
                                              <div class="" [ngClass]="updateForm.controls['password'].hasError('required') || updateForm.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                                                <i class="fa {{ updateForm.controls['password'].hasError('required') ||
                                                updateForm.controls['password'].hasError('hasNumber') ? 'fa-times-circle' :
                                                'fa-check-circle' }}">
                                                </i>
                                                Doit avoir au moins un chiffre!
                                              </div>
                                              <div class="" [ngClass]="updateForm.controls['password'].hasError('required') || updateForm.controls['password'].hasError('hasSpecialCharacters')  ? 'text-danger' : 'text-success'">
                                                <i class="fa {{ updateForm.controls['password'].hasError('required') ||
                                                updateForm.controls['password'].hasError('hasSpecialCharacters') ? 'fa-times-circle' :
                                                'fa-check-circle' }}">
                                                </i>
                                                Doit avoir au moins un caractère spécial!
                                              </div>
                                          </div>
                                        </div>
                                        <div class="text-success" *ngIf="updateForm.controls['password'].valid">
                                          <i class="fa fa-check-circle mr-2"></i>
                                          Valide
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="input-group mb-3">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i class="fa fa-lock"></i></span>
                                          </div>
                                          <input type="password" formControlName="confirmPassword" id="confirmPassword" class="form-control" placeholder="Confirmez le mot de passe" aria-label="password" aria-describedby="basic-addon1" required>
                                          <div class="text-danger text-justify" *ngIf="updateForm.controls['confirmPassword'].hasError('NoPassswordMatch') && (updateForm.controls['confirmPassword'].dirty || updateForm.controls['confirmPassword'].touched)">
                                            Les mots de passe ne correspondent pas
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-danger" *ngIf="wrongCredentials">{{ errorMessage }}</div>
                                    <button class="btn btn-sp color-primary float-right mt-2 ml-auto mr-0" type="submit" [disabled]="!updateForm.valid" (click)="onFormSubmit()"><i class="far fa-save"></i> Enregistrer</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- Edit personal info End -->
                  </div>
                  <!-- Middle Panel End -->
            </div>
        </div>
    </div>
    <!-- Content end -->

    <app-page-footer></app-page-footer>
  </div>
  <!-- Document Wrapper end -->

