import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalService } from 'src/app/services/local.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-user-transfer-statut',
  templateUrl: './user-transfer-statut.component.html',
  styleUrls: ['./user-transfer-statut.component.css']
})
export class UserTransferStatutComponent {
  balance: any;
  transactionId: any;
  user: any = [];
  transferData: any = [];
  c2s: boolean = false;
  userData: any = [];
  montant: any;
  pm: any;
  pf: any;
  account_number: any;
  total: any;
  constructor(
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private localStore: LocalService
  ) {}

  ngOnInit() {
    this.transferData = JSON.parse(
      this.localStore.getData('transfer-status')
    );
    if (
      this.transferData &&
      'status' in this.transferData &&
      this.transferData['status'] == true
    ) {
      this.c2s = true;
    }
    this.userData = this.tokenStorageService.getUser();
    this.user = JSON.parse(this.userData);
    this.balance = this.user.wallet.balance;
    this.account_number = this.localStore.getData('transfer_account_number');
    this.pm = this.localStore.getData('payment_method');
    this.montant = this.localStore.getData('transfer_amount');
    this.pf = 0;
    this.total = parseInt(this.montant) + this.pf;
    if (this.transferData['data']['transactionDetails']['transactionId']) {
      this.transactionId = this.transferData['data']['transactionDetails']['transactionId'];
    }
  }
}
