import {Component, OnInit} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxPermissionsService } from "ngx-permissions";
import { Categorie } from "src/app/models/categorie";
import { ProduitPanier } from "src/app/models/produit-panier";
import { FlashMessageService } from "src/app/services/flash-message.service";
import { LocalService } from "src/app/services/local.service";
import { StoreService } from "src/app/services/store.service";
import { SweetAlertOptions } from "sweetalert2";

declare let $: any;

@Component({
  selector: 'app-page-social-shop',
  templateUrl: './page-social-shop.component.html',
  styleUrls: ['./page-social-shop.component.scss'],
})
export class PageSocialShopComponent implements OnInit {
  pm?: string;
  permissions: any = [];
  produits: any = [];
  data: any = [];
  panier: Array<ProduitPanier> = [];
  storeId!: string;
  page: number = 1;
  count: number = 0;
  tableSize: number = 3;
  tableSizes: any = [3, 6, 9, 12];
  swalOptions: SweetAlertOptions = { icon: 'info' };
  categories: Array<Categorie> = [
    {
      name: 'Mode',
      items: [],
    },
    {
      name: 'Électronique',
      items: [],
    },
    {
      name: 'Maison et jardin',
      items: [],
    },
    {
      name: 'Sports et loisirson',
      items: [],
    },
    {
      name: 'Beauté et soins personnels',
      items: [],
    },
    {
      name: 'Livres, films et musique',
      items: [],
    },
    {
      name: 'Automobiles et moto',
      items: [],
    },
    {
      name: 'Jouets et jeux',
      items: [],
    },
    {
      name: 'Alimentation et boissons',
      items: [],
    },
    {
      name: 'Équipement informatique',
      items: [],
    },
    {
      name: 'Art et artisanat',
      items: [],
    },
    {
      name: 'Santé et bien-être',
      items: [],
    },
    {
      name: 'Équipement de voyage',
      items: [],
    },
  ];
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private localStore: LocalService,
    private storeService: StoreService,
    private swalService: FlashMessageService,
    private ngxPermissionsService: NgxPermissionsService
  ) {}

  ngOnInit() {
    if (this.localStore.getData('panier')) {
      this.panier = JSON.parse(this.localStore.getData('panier'));
    }
    this.fetchPosts();
    this.permissions = this.ngxPermissionsService.getPermissions();
    console.log(this.permissions);
  }
  updatePanier(shopping_cart: Array<any>, id: string, quantity: Object) {
    return shopping_cart.map((item) =>
      item.id === id ? { ...item, ...quantity } : item
    );
  }
  async addProductToShoppingCart(p: any) {
    try {
      if (this.localStore.getData('user')) {
        let produit = new ProduitPanier();
        produit.name = p.name;
        produit.price = p.price;
        produit.quantity = 1;
        produit.id = p.id;
        produit.description = p.description;
        produit.image = p.images.length > 0 ? p.images['0'].id : '';
        const addItemRes = await this.storeService.addItemToCart(produit);
        if ('status' in addItemRes && addItemRes['status'] == true) {
          let existProduit = this.panier.find((x) => x.id == produit.id);
          if (!existProduit) {
            this.panier.push(produit);
            this.localStore.saveData('panier', JSON.stringify(this.panier));
            this.router.navigate(['/page-panier']);
          } else {
            this.panier = this.updatePanier(this.panier, produit.id, {
              quantity: existProduit.quantity + 1,
            });
            this.localStore.saveData('panier', JSON.stringify(this.panier));
            this.router.navigate(['/page-panier']);
          }
        } else {
        }
      } else {
        this.router.navigate(['/login']).then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
      }
    } catch (error) {}
  }
  async fetchPosts() {
    try {
      this.data = await this.storeService.getProducts();
      if ('data' in this.data) {
        this.produits = this.data['data'];
        for (let categorie of this.categories) {
          let item = [];
          for (let produit of this.produits) {
            if (categorie.name == produit.category) {
              item.push(produit);
              if (item.length == 4) {
                categorie.items.push(item);
                item = [];
              }
            }
          }
          if (item.length > 0 && item.length < 4) {
            categorie.items.push(item);
          }
        }
        console.log(this.categories);
      }
    } catch (error) {}
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.fetchPosts();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.fetchPosts();
  }
  changePaymentMethodTo(paymentMethod: string) {
    this.pm = paymentMethod;
    $('.payment-method').removeClass('selected');
    $('#' + paymentMethod).addClass('selected');
    // console.log(this.sp);
  }
}
