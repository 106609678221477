import {  Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/app/models/product';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { LocalService } from 'src/app/services/local.service';
import { StoreService } from 'src/app/services/store.service';
import { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.css'],
})
export class ProductEditComponent {
  errorMessage = '';
  wrongCredentials: boolean = false;
  productForm!: FormGroup;
  data: any = [];
  product:any=[];
  id: string = '';
  swalOptions: SweetAlertOptions = { icon: 'info' };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,private route: ActivatedRoute,
    private swalService: FlashMessageService,
    private storeService: StoreService
  ) {}
  async onFormSubmit() {
    try {
      this.data = await this.storeService.editProduct(this.productForm.value);
      if (
        this.data &&
        'status' in this.data &&
        'data' in this.data &&
        this.data['status'] == true &&
        this.data['data']
      ) {
        this.router.navigate(['/store/products']).then(() => {
          this.swalOptions.icon = 'success';
          this.swalOptions.html = 'Le produit à été ajouter avec succés!';
          this.swalService.show(this.swalOptions);
        });
      } else {
        this.swalOptions.icon = 'info';
        this.swalOptions.html ='La transaction a echoué veuillez ressayer svp!';
        this.swalService.show(this.swalOptions);
        //this.errorMessage = this.data['message'];
      }
    } catch (error) {
      this.swalOptions.icon = 'error';
      this.swalOptions.html = "Le produit n'a pas été ajouter!";
      this.swalService.show(this.swalOptions);
      //this.errorMessage = "La transaction a echoué veuillez ressayer svp!";
    }
  }
  async initialisation() {
    try {
      this.product = await this.storeService.getProductById(this.id);
      console.log(this.product);
      if ('name' in this.product && 'id' in this.product) {
          this.productForm.setValue({
            name: this.product['name'],
            category: this.product['category'],
            product_id: this.id,
            description: this.product['description'],
            price: this.product['price'],
            quantity: this.product['quantity'],
          });
      } else {
        this.router.navigate(['/store/products']).then(() => {
          this.swalOptions.icon = 'error';
          this.swalOptions.html =
            "Une erreur s'est produite ou le produit n'existe pas!";
          this.swalService.show(this.swalOptions);
        });
      }
    } catch (error) {
      console.log(error);
      this.router.navigate(['/store/products']).then(() => {
        this.swalOptions.icon = 'error';
        this.swalOptions.html = 'Une erreur s\'est produite ou le produit n\'existe pas!';
        this.swalService.show(this.swalOptions);
      });
    }
  }
  async ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.initialisation();
    this.productForm = this.formBuilder.group(
      {
        name: [this.product.name, Validators.required],
        category: [this.product.category, Validators.required],
        description: [this.product.description, Validators.required],
        price: [this.product.price, Validators.required],
        quantity: [this.product.quantity, Validators.required],
        product_id:this.id
      },
      {
        updateOn: 'change',
      }
    );
  }
}
