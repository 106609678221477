import {Component, OnInit} from "@angular/core";

declare let $: any;

@Component({selector: "app-page-restau-details", templateUrl: "./page-restau-details.component.html", styleUrls: ["./page-restau-details.component.scss"]})
export class PageRestauDetailsComponent implements OnInit {
  pm?: string;
  constructor() {}
  /* changePaymentMethodTo(paymentMethod: string){
    this.pm=paymentMethod;
  } */
  changePaymentMethodTo(paymentMethod: string){
    this.pm=paymentMethod;
    $('.payment-method').removeClass('selected');
    $('#'+paymentMethod).addClass('selected');
    // console.log(this.sp);
  }
  initialization() {
    // ============================
    // Button Animation
    // ============================
    $(".btn").on("mouseenter mousemove",  (e: { pageX: number; pageY: number; }) => {
      var parentOffset = $(this).offset(),
        relX = e.pageX - parentOffset.left,
        relY = e.pageY - parentOffset.top;
      $(this).find(".bh").css({top: relY, left: relX});
      var origin = relX + "px" + " " + relY + "px";
    }).on("mouseout",  (e: { pageX: number; pageY: number; }) => {
      var parentOffset = $(this).offset(),
        relX = e.pageX - parentOffset.left,
        relY = e.pageY - parentOffset.top;
      $(this).find(".bh").css({top: relY, left: relX});
    });

    //======================
    // Testimonial
    //======================
    $(".test-caro").owlCarousel({
      autoplay: true,
      dots: true,
      loop: true,
      margin: 30,
      nav: false,
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 1
        }
      }
    });
  }

  ngOnInit() {
    this.initialization();
  }
}
